import { Injectable, EventEmitter } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as uuid from 'uuid';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class S3UploadService {
  public progress: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    const poolData = { UserPoolId: environment.UserPoolId, ClientId: environment.ClientId };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: environment.Username,
      Password: environment.Password,
    });
    const userData = { Username: environment.Username, Pool: userPool };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const userLogin = `cognito-idp.${environment.DefaultRegion}.amazonaws.com/${environment.UserPoolId}`;
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        AWS.config.update({
          region: environment.DefaultRegion,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: environment.IdentityPoolId,
            Logins: {
              [userLogin]: result.getIdToken().getJwtToken(),
            },
          }),
        });
      },
      onFailure: function (err) {
        console.error(err);
      },
      newPasswordRequired(userAttributes) {
        const userAttr = userAttributes;

        delete userAttr.email_verified;

        // unsure about this field, but I don't send this back
        delete userAttr.phone_number_verified;

        // Get these details and call
        cognitoUser.completeNewPasswordChallenge(environment.Password, userAttr, this);
      },
    });
  }

  uploadfile(file, index, type) {
    const uniquename = uuid.v4();
    const fileExtension = file.name.replace(/^.*\./, '');
    const filename: string = 'file/' + uniquename + '.' + fileExtension;

    const S3Client = new AWS.S3();

    const params = {
      Bucket: environment.BucketName,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type,
    };

    return new Promise((res, rej) => {
      S3Client.upload(params, function (err: any, data: any) {
        if (err) {
          console.error(err);
          return rej(err);
        }

        return res(data);
      }).on('httpUploadProgress', (event) => {
        // change here
        const progress = Math.round((event.loaded / event.total) * 100);

        this.progress.emit({ progress, index, type });
      });
    });
  }
}
