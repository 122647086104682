<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/reports"><a>Reports</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page">
      <em class="fas fa-file circle-icon file-icon mr-1"></em>Details
    </li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 mb-2 heading-border">{{ reportDetails?.report?.title }}</h1>
    <p class="color-grey-text">
      You can select a different date range on the left to view other dates. On the top left you can export the data to
      Excel or similar programs.
    </p>
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="card mb-3 border-0">
          <div class="card-header bg-white px-4">
            <h6 class="text-dark font-weight-bold fs16 my-1">DETAILS</h6>
          </div>
          <div class="card-body pb-0">
            <div class="row mt-2">
              <div class="col-md-6 col-12 p-1">
                <div class="bg-icon-cogs mb-43">
                  <h2 class="text-dark fs14 m-0">{{ reportDetails?.report?.source }}</h2>
                  <span class="color-light-black op-32 fs12">Source</span>
                </div>
                <div class="bg-icon-calendar mb-43">
                  <h2 class="text-dark fs14 m-0 c-pointer">
                    {{ reportDetails?.report?.start_date | date: dateFormat }}
                  </h2>
                  <span class="color-light-black op-32 fs12">Start date</span>
                </div>
              </div>
              <div class="col-md-6 col-12 p-1">
                <div class="bg-icon-clock mb-43">
                  <h2 class="text-dark fs14 m-0">{{ reportDetails?.report?.time_frame }}</h2>
                  <span class="color-light-black op-32 fs12">Time frame</span>
                </div>
                <div class="bg-user mb-43">
                  <h2 class="text-dark fs14 m-0">{{ reportDetails?.report?.user_name }}</h2>
                  <span class="color-light-black op-32 fs12">Owner</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive list-table">
          <table class="table bg-white shadow mb-0" aria-describedby="tab">
            <thead class="thead-light">
              <tr *ngIf="dateCheck">
                <th scope="col">StartDate</th>
                <th scope="col">Action</th>
              </tr>
              <tr *ngIf="!dateCheck">
                <th scope="col">StartDate</th>
                <th scope="col">EndDate</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody *ngIf="dateCheck">
              <ng-container *ngFor="let report of dateValue">
                <tr class="c-pointer" (click)="reportDetail(report)">
                  <td>{{ report.start_date | date: dateFormat }}</td>
                  <td class="op-1" tooltip="View"><em class="fas fa-eye color-blue c-pointer"></em></td>
                </tr>
              </ng-container>
            </tbody>
            <tbody *ngIf="!dateCheck">
              <ng-container *ngFor="let report of dateValue">
                <tr class="c-pointer" (click)="reportDetail(report)">
                  <td>{{ report.start_date | date: dateFormat }}</td>
                  <td>{{ report.end_date | date: dateFormat }}</td>
                  <td class="op-1" tooltip="View"><em class="fas fa-eye color-blue c-pointer"></em></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <p class="text-danger text-center mb-0 mt-3" *ngIf="dateValue.length === 0">No Record Found</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-3 border-0">
          <div class="card-header bg-white px-4 border-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="align-self-center">
                <h6 class="text-dark font-weight-bold fs16 mb-0" *ngIf="!selectReport.end_date">
                  REPORTS - {{ selectReport?.start_date | date: dateFormat }}
                </h6>
                <h6 class="text-dark font-weight-bold fs14 mb-0" *ngIf="selectReport.end_date">
                  REPORTS - {{ selectReport?.start_date | date: dateFormat }} -
                  {{ selectReport?.end_date | date: dateFormat }}
                </h6>
              </div>
              <form class="form-inline">
                <div class="input-group wid-150 mr-3">
                  <div class="input-group-prepend h33"></div>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-more"
                  tooltip="Save"
                  (click)="getDownload()"
                  *ngIf="reportView.length > 0"
                >
                  <em class="fas fa-file circle-icon file-icon fs12 c-pointer"></em> Save
                </button>
              </form>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive list-table">
              <table class="table bg-white shadow mb-0" aria-describedby="tab">
                <thead class="thead-light">
                  <tr *ngIf="reportCheck == 1">
                    <th scope="col">Staff</th>
                    <th scope="col">Context</th>
                    <th scope="col">Total Hours</th>
                  </tr>
                  <tr *ngIf="reportCheck == 2">
                    <th scope="col">Client</th>
                    <th scope="col">Location</th>
                    <th scope="col">Incident Type</th>
                    <th scope="col">Count</th>
                  </tr>
                  <tr *ngIf="reportCheck == 3">
                    <th scope="col">Staff</th>
                    <th scope="col">Client</th>
                    <th scope="col">Location</th>
                    <th scope="col">Total Hours</th>
                  </tr>
                  <tr *ngIf="reportCheck == 4">
                    <th scope="col">Client</th>
                    <th scope="col">Location</th>
                    <th scope="col">Total Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="reportCheck == 1">
                    <tr *ngFor="let details of reportView">
                      <td>{{ details.StaffName }}</td>
                      <td>{{ details.TimeCount }}</td>
                      <td>{{ details.TotalMinutes }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="reportCheck == 2">
                    <tr *ngFor="let details of reportView">
                      <td>{{ details.ClientName }}</td>
                      <td>{{ details.LocationName }}</td>
                      <td>{{ details.IncidentTypeName }}</td>
                      <td>{{ details.IncidentCount }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="reportCheck == 3">
                    <tr *ngFor="let details of reportView">
                      <td>{{ details.StaffName }}</td>
                      <td>{{ details.ClientName }}</td>
                      <td>{{ details.LocationName }}</td>
                      <td>{{ details.TotalMinutes }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="reportCheck == 4">
                    <tr *ngFor="let details of reportView">
                      <td>{{ details.ClientName }}</td>
                      <td>{{ details.LocationName }}</td>
                      <td>{{ details.TotalMinutes }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p class="text-danger text-center mb-0 mt-3" *ngIf="reportView.length === 0">No Record Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-body p-0" *ngIf="showReport">
  <div class="table-responsive list-table">
    <button
      type="button"
      class="btn btn-primary btn-more float-right"
      tooltip="Save"
      (click)="getDownload()"
      *ngIf="reportView.length > 0"
    >
      <em class="fas fa-file circle-icon file-icon fs12 c-pointer"></em>Save
    </button>
    <table class="table bg-white shadow mb-0" #table>
      <thead class="thead-light">
        <tr>
          <th></th>
          <th></th>
          Report Details
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th>Source</th>
          <th>{{ reportDetails?.report?.source }}</th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th scope="col">Time Frame</th>
          <th scope="col">{{ reportDetails?.report?.time_frame }}</th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th scope="col">Start Date</th>
          <th scope="col">{{ reportDetails?.report?.start_date | date: dateFormat }}</th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th scope="col">Owner By</th>
          <th scope="col">{{ reportDetails?.report?.user_name }}</th>
          <th></th>
          <th></th>
        </tr>
        <tr></tr>
        <tr>
          <th scope="col">Reports</th>
          <th scope="col" *ngIf="!selectReport.end_date">{{ selectReport?.start_date | date: dateFormat }}</th>
          <th scope="col" *ngIf="selectReport.end_date">
            {{ selectReport?.start_date | date: dateFormat }} - {{ selectReport?.end_date | date: dateFormat }}
          </th>
          <th></th>
          <th></th>
        </tr>
        <tr></tr>
        <tr *ngIf="reportCheck == 1">
          <th scope="col">S.No</th>
          <th scope="col">Staff Name</th>
          <th scope="col">Context</th>
          <th scope="col">Total Hours</th>
        </tr>
        <tr *ngIf="reportCheck == 2">
          <th scope="col">S.No</th>
          <th scope="col">Client Name</th>
          <th scope="col">Location Name</th>
          <th scope="col">Incident Type</th>
          <th scope="col">Count</th>
        </tr>
        <tr *ngIf="reportCheck == 3">
          <th scope="col">S.No</th>
          <th scope="col">Staff Name</th>
          <th scope="col">Client Name</th>
          <th scope="col">Location Name</th>
          <th scope="col">Total Hours</th>
        </tr>
        <tr *ngIf="reportCheck == 4">
          <th scope="col">S.No</th>
          <th scope="col">Client Name</th>
          <th scope="col">Location Name</th>
          <th scope="col">Total Hours</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="reportCheck == 1">
          <tr *ngFor="let details of reportView; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ details.StaffName }}</td>
            <td>{{ details.TimeCount }}</td>
            <td>{{ details.TotalMinutes }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="reportCheck == 2">
          <tr *ngFor="let details of reportView; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ details.ClientName }}</td>
            <td>{{ details.LocationName }}</td>
            <td>{{ details.IncidentTypeName }}</td>
            <td>{{ details.IncidentCount }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="reportCheck == 3">
          <tr *ngFor="let details of reportView; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ details.StaffName }}</td>
            <td>{{ details.ClientName }}</td>
            <td>{{ details.LocationName }}</td>
            <td>{{ details.TotalMinutes }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="reportCheck == 4">
          <tr *ngFor="let details of reportView; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ details.ClientName }}</td>
            <td>{{ details.LocationName }}</td>
            <td>{{ details.TotalMinutes }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <p class="text-danger text-center mb-0 mt-3" *ngIf="reportView.length === 0">No Record Found</p>
  </div>
</div>
