<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div class="table-responsive list-table">
  <table class="table bg-white shadow mb-0">
    <caption class="d-none">
      List Data
    </caption>
    <thead class="thead-light">
      <tr>
        <th scope="col">
          S.No
          <span class="font-icon ml-2">
            <em
              class="fas fa-sort-up up-arrow"
              [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'id' }"
              (click)="upside('asc', 'id')"
            ></em>
            <em
              class="fas fa-sort-up down-arrow"
              [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'id' }"
              (click)="down('desc', 'id')"
            ></em>
          </span>
        </th>

        <th scope="col">
          First Name
          <span class="font-icon ml-2">
            <em
              class="fas fa-sort-up up-arrow"
              [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'firstName' }"
              (click)="upside('asc', 'firstName')"
            ></em>
            <em
              class="fas fa-sort-up down-arrow"
              [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'firstName' }"
              (click)="down('desc', 'firstName')"
            ></em>
          </span>
        </th>
        <th scope="col">
          Last Name
          <span class="font-icon ml-2">
            <em
              class="fas fa-sort-up up-arrow"
              [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'surName' }"
              (click)="upside('asc', 'surName')"
            ></em>
            <em
              class="fas fa-sort-up down-arrow"
              [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'surName' }"
              (click)="down('desc', 'surName')"
            ></em>
          </span>
        </th>
        <th scope="col">
          Phone
          <span class="font-icon ml-2">
            <em
              class="fas fa-sort-up up-arrow"
              [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'phone' }"
              (click)="upside('asc', 'phone')"
            ></em>
            <em
              class="fas fa-sort-up down-arrow"
              [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'phone' }"
              (click)="down('desc', 'phone')"
            ></em>
          </span>
        </th>

        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="
          let staff of staffList | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
          let i = index
        "
      >
        <tr class="c-pointer" (click)="viewStaffDetails(staff.id)">
          <td>{{ page !== 1 ? i + 1 + pagesize * (page - 1) : i + 1 }}</td>
          <td>{{ staff.firstName }}</td>
          <td>{{ staff.surName }}</td>
          <td>{{ staff?.phonecode?.countries_isd_code }} {{ staff.phone }}</td>
          <td class="op-1" tooltip="View"><em class="fas fa-eye color-blue c-pointer"></em></td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="bg-white text-center py-4 roaster-pagination" *ngIf="staffList.length > 0">
  <pagination-controls
    (pageChange)="pageChange($event)"
    maxSize="7"
    previousLabel=""
    nextLabel=""
    autoHide="true"
  ></pagination-controls>
</div>
<div class="bg-white text-center py-4" *ngIf="staffList.length === 0">
  <p class="text-danger">No Data Found</p>
</div>
