<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="backClicked()"><a>Staff Details</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-users mr-1"></em>Incidents</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-self-center">
        <h1 class="color-black fs20 text-uppercase pl-2 mt-1 mb-2 heading-border">
          {{ incidentInfo?.incidenttype?.incident_name }} # {{ incidentInfo?.id }}
        </h1>
        <p class="color-grey-text">
          Below you can manage any information related this client including locations, required certifications and
          blacklistings.
        </p>
      </div>
      <button
        type="button"
        class="btn btn-primary bg-light-green border-0 mt-2"
        *ngIf="incidentInfo.status && userGroup === 'Full access'"
        (click)="changeStatus(!incidentInfo.status)"
        [disabled]="isLoding"
      >
        Resolved
      </button>
      <button
        type="button"
        class="btn btn-primary bg-red border-0 mt-4"
        *ngIf="!incidentInfo.status && userGroup === 'Full access'"
        (click)="changeStatus(!incidentInfo.status)"
        [disabled]="isLoding"
      >
        Resolve
      </button>
    </div>

    <div class="row">
      <div class="col-md-6">
        <app-incident-staff-about [incidentInfo]="incidentInfo"></app-incident-staff-about>
        <app-incident-staff-staff
          [staffList]="staffList"
          [incidentId]="incidentId"
          [incidentStatus]="incidentStatus"
        ></app-incident-staff-staff>
      </div>
      <div class="col-md-6">
        <app-incident-staff-events
          [eventList]="eventList"
          [incidentId]="incidentId"
          [incidentStatus]="incidentStatus"
        ></app-incident-staff-events>
      </div>
    </div>
  </div>
</div>
