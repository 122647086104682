import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
var AvailabilityStaffComponent = /** @class */ (function () {
    function AvailabilityStaffComponent(apiService, toastr, modalService) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.modalService = modalService;
        this.loading = false;
        this.dayStart = new Date(new Date().setHours(0, 0, 0, 0));
        this.weekDays = [
            {
                day: 1,
                weekDay: 'Monday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 2,
                weekDay: 'Tuesday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 3,
                weekDay: 'Wednesday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 4,
                weekDay: 'Thursday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 5,
                weekDay: 'Friday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 6,
                weekDay: 'Saturday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
            {
                day: 7,
                weekDay: 'Sunday',
                isAllDay: false,
                fromTime: '00:00',
                toTime: '00:00',
                fromTimePicker: false,
                toTimePicker: false,
                actFromTime: this.dayStart,
                actToTime: this.dayStart,
            },
        ];
    }
    AvailabilityStaffComponent.prototype.ngOnInit = function () {
        this.getStaffUnavailabilityList();
    };
    AvailabilityStaffComponent.prototype.closeModel = function () {
        this.apiService.closeClientModel.emit(8);
    };
    AvailabilityStaffComponent.prototype.getStaffUnavailabilityList = function () {
        var _this = this;
        this.apiService.get('staff/staff-unavailability-list/' + this.editStaffInfo.id).subscribe(function (staff) {
            if (staff.status === 200) {
                var staffList_1 = staff.staff;
                _this.weekDays = _this.weekDays.map(function (sa) {
                    var currSA = staffList_1.find(function (wd) { return sa.day === wd.weekday; });
                    if (currSA) {
                        sa.isAllDay = currSA.is_allday;
                        sa.fromTime = currSA.from_time;
                        sa.toTime = currSA.end_time;
                        var _a = currSA.from_time.split(':'), hrFrom = _a[0], minFrom = _a[1];
                        sa.actFromTime = new Date(new Date().setHours(hrFrom, minFrom));
                        var _b = currSA.from_time.split(':'), hrTo = _b[0], minTo = _b[1];
                        sa.actToTime = new Date(new Date().setHours(hrTo, minTo));
                    }
                    return sa;
                });
            }
        }, function (err) {
            _this.errorMessage(err);
        });
    };
    AvailabilityStaffComponent.prototype.checkAllDay = function (check, weekDay) {
        this.weekDays = this.weekDays.map(function (wd) {
            if (wd.weekDay === weekDay) {
                wd.fromTime = '00:00';
                wd.toTime = '00:00';
                wd.isAllDay = check;
                wd.fromTimePicker = false;
                wd.toTimePicker = false;
            }
            return wd;
        });
    };
    AvailabilityStaffComponent.prototype.showFromTime = function (weekDay) {
        this.weekDays = this.weekDays.map(function (wd) {
            if (wd.weekDay === weekDay) {
                wd.fromTimePicker = true;
                wd.toTimePicker = false;
            }
            else {
                wd.fromTimePicker = false;
                wd.toTimePicker = false;
            }
            return wd;
        });
    };
    AvailabilityStaffComponent.prototype.showToTime = function (weekDay) {
        this.weekDays = this.weekDays.map(function (wd) {
            if (wd.weekDay === weekDay) {
                wd.fromTimePicker = false;
                wd.toTimePicker = true;
            }
            else {
                wd.fromTimePicker = false;
                wd.toTimePicker = false;
            }
            return wd;
        });
    };
    AvailabilityStaffComponent.prototype.formatChange = function (time, weekDay, fromOrTo) {
        var newTime = new Date(time).toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
        });
        weekDay[fromOrTo] = newTime;
    };
    // Time validation for from and end time
    AvailabilityStaffComponent.prototype.timeChecking = function (fromTime, toTime) {
        var startTime = moment(fromTime, 'HH:mm');
        var endTime = moment(toTime, 'HH:mm');
        if (startTime.isSame(endTime)) {
            return true;
        }
        else {
            return false;
        }
    };
    AvailabilityStaffComponent.prototype.errorMessage = function (err) {
        this.loading = false;
        if (err.error.message) {
            this.toastr.error(err.error.message);
        }
    };
    AvailabilityStaffComponent.prototype.addStaffUnavailability = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dataValue;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                dataValue = {
                    staff_unavailability: this.weekDays.map(function (wd) {
                        return {
                            is_allday: wd.isAllDay,
                            weekday: wd.day,
                            from_time: wd.fromTime,
                            end_time: wd.toTime,
                        };
                    }),
                };
                this.apiService.post('staff/staff-unavailability/' + this.editStaffInfo.id, dataValue).subscribe(function (staff) {
                    if (staff.status === 200) {
                        _this.loading = false;
                        _this.toastr.success(staff.message);
                        _this.closeModel();
                    }
                }, function (err) {
                    _this.errorMessage(err);
                });
                return [2 /*return*/];
            });
        });
    };
    return AvailabilityStaffComponent;
}());
export { AvailabilityStaffComponent };
