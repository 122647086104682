import { Component, OnInit, TemplateRef, Input, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, S3UploadService } from '../../services';
import { environment } from '../../../environments/environment';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { validFormat } from '../../constants/defaultValues';
@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
})
export class IncidentsComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() incidentsList;
  @Input() clientId;
  @Input() clientInfo;
  incidentsForm: FormGroup;
  isSubmitted = false;
  incidentTypeList: any = [];
  clientList: any = [];
  locationList: any = [];
  staffList: any = [];
  fileIds: any = [];
  loading = false;
  minDate = new Date();

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  fileError = false;
  fileSizeError = false;
  fileFormatError = false;
  supportFormat = '';
  fileUrl: any = environment.fileUrl;
  uploadfileList = [];
  imageObject = {};
  progress = [{ progress: 0 }];
  multiFileIds = [];
  domainInfo: any = {};
  dateFormat;

  constructor(
    private apiService: ApiService,
    private s3upload: S3UploadService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    const format = validFormat.toString();
    this.supportFormat = format.replace(/,/g, ', ');
    this.createForm();
    this.getClientList();
    this.getIncidentType();
    this.getStaffList();
    this.getLocationList(this.clientId);
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 10) {
        this.getClientList();
        this.getIncidentType();
        this.getStaffList();
        this.getLocationList(this.clientId);
      }
    });
    this.s3upload.progress.subscribe((progress: any) => {
      if (progress['type'] === 3) {
        this.progress[progress['index']] = progress;
        if (this.progress && this.progress.length > 0) {
          const completeUpload = [];
          this.progress.forEach((key) => {
            if (key && key['progress']) {
              if (key['progress'] === 100) {
                completeUpload.push(key);
                if (this.progress && this.progress.length === completeUpload.length) {
                  this.loading = false;
                }
              }
            }
          });
        }
      }
    });
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }

  getIncidentType() {
    this.apiService.get('incident').subscribe((incidenttype) => {
      if (incidenttype['status'] === 200) {
        this.incidentTypeList = incidenttype['incidents'];
      }
    });
  }

  getClientList() {
    this.apiService.get('client/list').subscribe((client) => {
      if (client['status'] === 200) {
        this.clientList = client['clients'];
      }
      if (this.clientInfo && this.clientInfo['is_deleted'] === 0) {
        this.incidentsForm.controls['client_id'].setValue(parseInt(this.clientId));
      } else {
        this.incidentsForm.controls['client_id'].setValue('');
      }
    });
  }

  getLocationList(clientId) {
    if (clientId) {
      this.apiService.get('/client/' + clientId + '/location', { isParent: true }).subscribe((location) => {
        if (location['status'] === 200) {
          this.locationList = location['location'];
          if (this.locationList && this.locationList.length > 0) {
            if (this.clientInfo && this.clientInfo['is_deleted'] === 0) {
              this.incidentsForm.controls['location_id'].setValue(this.locationList[0] && this.locationList[0]['id']);
            } else {
              this.incidentsForm.controls['location_id'].setValue('');
            }
          } else {
            this.locationList = [];
            this.incidentsForm.controls['location_id'].setValue('');
          }
        } else {
          this.locationList = [];
          this.incidentsForm.controls['location_id'].setValue('');
        }
      });
    }
  }

  getStaffList() {
    this.apiService.get('/staff/list').subscribe((staff) => {
      if (staff['status'] === 200) {
        this.staffList = staff['staffs'];
      }
    });
  }

  changeClient() {
    this.getLocationList(this.incidentsForm.value['client_id']);
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  createForm() {
    this.incidentsForm = this.formBuilder.group({
      incident_date: ['', Validators.required],
      staff_id: ['', Validators.required],
      location_id: ['', [Validators.required]],
      client_id: ['', [Validators.required]],
      incident_type_id: ['', [Validators.required]],
      description: [''],
    });
  }

  get f() {
    return this.incidentsForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  clearForm() {
    this.files = [];
    this.modalRef.hide();
    this.incidentsForm.reset({
      client_id: parseInt(this.clientId),
      location_id: this.locationList && this.locationList[0] && this.locationList[0]['id'],
      staff_id: '',
      incident_type_id: '',
    });
    this.isSubmitted = false;
    this.fileError = false;
    this.fileIds = [];
    this.fileSizeError = false;
    this.fileFormatError = false;
    this.uploadfileList = [];
    this.imageObject = {};
    this.multiFileIds = [];
    this.progress = [];
    this.apiService.closeClientModel.emit(4);
  }

  createIncidents() {
    this.isSubmitted = true;
    if (this.incidentsForm.invalid) {
      return;
    }
    const datavalue = this.incidentsForm.value;
    if (this.uploadfileList && this.uploadfileList.length > 0) {
      this.fileIds = [];
      this.uploadfileList.forEach((item) => {
        const data = { file_path: item.file_path, name: item.name, type: item.type };
        this.fileIds.push(data);
      });
      datavalue['file_ids'] = this.fileIds;
    }
    if (datavalue['incident_date']) {
      /*let date = datavalue['incident_date'].getDate();
      date = date > 9 ? date : '0' + date;
      let month = datavalue['incident_date'].getMonth() + 1;
      month = month > 10 ? month : '0' + month;
      datavalue['incident_date'] = datavalue['incident_date'].getFullYear() + '-' + month + '-' + date;*/
      datavalue['incident_date'] = formatDate(datavalue['incident_date'], 'yyyy-MM-dd', 'en');
    }
    this.loading = true;

    this.apiService.post('incident/report', datavalue).subscribe((incident) => {
      this.loading = false;
      if (incident['status'] === 200) {
        this.toastr.success(incident['message']);
        this.clearForm();
        this.viewIncident(incident['incident']['id']);
      }
    });
  }

  uploadS3Bucket(file, index) {
    let fileType = file.type.slice(0, file.type.indexOf('/'));
    const fileExtension = file.name.replace(/^.*\./, '');
    if (fileType === 'application') fileType = 'document';
    const type = 3;
    this.s3upload
      .uploadfile(file, index, type)
      .then((data: any) => {
        const fileData = { file_path: data['Key'], name: file['name'], type: fileType, format: fileExtension };
        this.uploadfileList[index] = fileData;
        //this.fileIds.push({file_path:data['Key'], name: file['name'], type:fileType});

        /*this.apiService.post('incident/report-upload', {file_path:data['Key'], name: file['name']}).subscribe(fileInfo => {
        if(fileInfo['status'] === 200) {
          let fileResult = fileInfo['file'];
          
          this.uploadfileList.forEach(item => {
            if(item['file_path'] === fileResult['file_path']) {
              item['id'] = fileResult['id'];
            }
          })
        }
      })*/
      })
      .catch((err) => {
        console.error(err);
      });
  }

  readURL(file, index) {
    let type = file.type.slice(0, file.type.indexOf('/'));
    const fileExtension = file.name.replace(/^.*\./, '');
    if (type === 'application') type = 'document';
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadfileList[index] = { imageSrc: reader.result, name: file['name'], type: type, format: fileExtension };
    };
    reader.readAsDataURL(file);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      if (this.files && this.files.length > 0) {
        const formatErrors = [];
        const sizeErros = [];
        this.files.forEach((key, index) => {
          const fileSize = key.size / 1024 / 1024;
          const fileExtension = key.name.replace(/^.*\./, '');
          if (!validFormat.includes(fileExtension)) {
            this.fileError = true;
            this.fileFormatError = true;
            formatErrors.push(true);
            this.removeFile(key['id']);
          } else {
            if (fileSize < 5) {
              const fileIndex = this.multiFileIds.findIndex((obj) => obj === key['id']);
              if (sizeErros.length > 0 || formatErrors.length > 0) {
                this.fileError = true;
              } else {
                this.fileError = false;
              }
              if (sizeErros.length > 0) {
                this.fileSizeError = true;
              } else {
                this.fileSizeError = false;
              }
              if (formatErrors.length > 0) {
                this.fileFormatError = true;
              } else {
                this.fileFormatError = false;
              }
              if (fileIndex === -1) {
                this.multiFileIds.push(key['id']);
                this.readURL(key['nativeFile'], index);
                this.uploadS3Bucket(key['nativeFile'], index);
              }
            } else {
              this.fileError = true;
              this.fileSizeError = true;
              sizeErros.push(true);
              this.removeFile(key['id']);
            }
          }
        });
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  deleteUploadFile(fileIds, index, fileindex) {
    this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe((deleteFiles) => {
      if (fileIds && fileIds.length === 1) {
        this.uploadfileList.splice(index, 1);
        this.fileIds.splice(index, 1);
      }
    });
  }

  uploadCancel() {
    this.clearForm();
    /*if(this.uploadfileList && this.uploadfileList.length > 0) {
      let fileIds = [];
      this.uploadfileList.forEach(item => fileIds.push(item['id']));
      this.deleteUploadFile(this.fileIds, -1, -1);
      this.clearForm();
    } else {
      this.clearForm();
    }*/
  }

  removeUploadFile(file, index) {
    ///let fileindex = this.fileIds.findIndex(obj => obj === file.id);
    this.removeFile(this.files[index]['id']);
    this.uploadfileList.splice(index, 1);
    //this.deleteUploadFile([file['id']], index, fileindex);
  }

  viewIncident(incidentId) {
    this.router.navigate(['/incident-client-detail/' + incidentId]);
  }
}
