import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-equipments-staff',
  templateUrl: './equipments-staff.component.html',
})
export class EquipmentsStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() equipmentList;
  @Input() staffId;
  equipmentData: any = [];
  equipmentForm: FormGroup;
  isSubmitted = false;
  loading = false;
  selectEquipment = {};
  inputText = '';
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.getEquipmentlist();
    this.createForm();
    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id'] && data['group']) {
        this.userGroup = data['group'];
        localStorage.setItem('userGroup', data['group']);
      }
    });

    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 16) {
        this.getEquipmentlist();
      }
    });
  }

  createForm() {
    this.equipmentForm = this.formBuilder.group({
      equipment_id: ['', Validators.required],
      tags: ['', Validators.required],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  deletePopup(template: TemplateRef<any>, equipment) {
    this.selectEquipment = equipment;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
  }

  get f() {
    return this.equipmentForm.controls;
  }

  getEquipmentlist() {
    this.apiService.get('equipment').subscribe((equipment) => {
      if (equipment['status'] === 200) {
        this.equipmentData = equipment['equipments'];
      }
    });
  }

  clearForm() {
    this.equipmentForm.reset({ equipment_id: '' });
    this.modalRef.hide();
    this.isSubmitted = false;
    this.loading = false;
    this.selectEquipment = {};
    this.apiService.closeClientModel.emit(5);
  }

  createStaffEquipment() {
    this.isSubmitted = true;
    let tagValue = this.equipmentForm.controls['tags'].value ? this.equipmentForm.controls['tags'].value : [];
    if (this.inputText != '') {
      tagValue.push({ display: this.inputText, value: this.inputText });
      this.inputText = '';
      tagValue = _.uniqBy(tagValue, 'value');
      this.equipmentForm.controls['tags'].setValue(tagValue);
    }

    if (this.equipmentForm.invalid) {
      return;
    }

    const dataValue = this.equipmentForm.value;
    dataValue['staff_id'] = this.staffId;
    this.loading = true;
    const tags = dataValue['tags'];
    if (tags && tags.length > 0) {
      const arrayTag = [];
      tags.forEach((item) => arrayTag.push(item['value']));
      dataValue['tags'] = arrayTag.toString();
    }

    this.apiService.post('staff/equipment', dataValue).subscribe((equipment) => {
      this.loading = false;
      if (equipment['status'] === 200) {
        this.clearForm();
        this.toastr.success(equipment['message']);
      }
    });
  }

  deleteEquipments() {
    this.apiService.delete('staff/equipment/' + this.selectEquipment['id']).subscribe((deleteequipment) => {
      if (deleteequipment['status'] === 200) {
        this.toastr.success(deleteequipment['message']);
        this.clearForm();
      } else {
        this.toastr.error(deleteequipment['message']);
        this.clearForm();
      }
    });
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }
}
