import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
})
export class StaffLinksComponent implements OnInit {
  staffId;
  staffInfo: any = {};
  staffIncidentsList: any = [];
  fileList: any = [];
  certificateList: any = [];
  blackList: any = [];
  equipmentList: any = [];
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');
  public loading = false;
  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.staffId = this.route.snapshot.params.id;
    this.getStaffInfo();
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 5) {
        this.getStaffInfo();
      }
    });

    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id'] && data['group']) {
        this.userGroup = data['group'];
        localStorage.setItem('userGroup', data['group']);
      }
    });
  }

  getStaffInfo() {
    this.loading = true;
    this.apiService.get('staff/view/' + this.staffId).subscribe(
      (staff) => {
        if (staff['status'] === 200) {
          this.staffInfo = staff['staff'];
          if (this.staffInfo && this.staffInfo['id']) {
            this.staffIncidentsList = this.staffInfo['incidents'];
            this.fileList = this.staffInfo['files'];
            this.certificateList = this.staffInfo['certificates'];
            this.blackList = this.staffInfo['blacklists'];
            this.equipmentList = this.staffInfo['equipments'];
          }
        } else {
          this.staffIncidentsList = [];
          this.fileList = [];
          this.certificateList = [];
          this.blackList = [];
          this.equipmentList = [];
          this.staffInfo = {};
        }
        this.loading = false;
        this.apiService.closeClientModel.emit(16);
      },
      (err) => {
        this.loading = false;
      },
    );
  }
}
