import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'eventArrayModifier' })
export class EventArrayModifier implements PipeTransform {
  transform(array: any, dateArray: any): any {
    const newArray = dateArray.map((date: string) => {
      const currDate = array.find((data: any) => data.keyDate === date);
      if (currDate) {
        return currDate;
      } else {
        return {
          keyDate: date,
          data: null,
        };
      }
    });
    return newArray;
  }
}
