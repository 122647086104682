<div class="card mb-3 border-0">
  <div class="card-header bg-white px-md-4 px-1">
    <div class="card-title mb-2">
      <h1 class="text-dark font-weight-bold fs16 mb-0 fs16">BLACKLISTING</h1>
    </div>
  </div>
  <div class="card-body table-responsive text-dark">
    <table class="table" aria-describedby="tab">
      <tbody>
        <tr *ngFor="let list of blackList">
          <th scope="row" class="border-0"><em class="fas fa-ban circle-icon ban-icon"></em></th>
          <td class="border-0 fs12 pl-0">{{ list?.client?.name }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="blackList.length === 0">
      <p>No blacklistings recorded. Please use the related client or location to blacklist staff members.</p>
    </div>
  </div>
</div>
