<div class="card border-0 mb-3 layout-card-5">
  <div class="card-header bg-white pb-0 px-md-4 px-1">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h1 class="text-dark font-weight-bold fs16 mb-0">EQUIPMENTS</h1>
      <ul class="list-unstyled d-inline-flex mb-0">
        <li class="mr-2 ml-4">
          <button
            type="button"
            class="btn btn-primary btn-more"
            tooltip="Add"
            (click)="openModal(template4)"
            *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'"
          >
            <em class="fas fa-plus mr-2"></em>Add
          </button>
        </li>
        <ng-template #template4 class="modal-content">
          <div class="modal-header bg-primary">
            <h4 class="modal-title fs16 text-center text-white">Mark equipment as handed-out</h4>
            <button type="button" class="close pull-right text-white" aria-label="Close" (click)="clearForm()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-secondary">
              Please fill in the form below to register equipment which has been handed-out to the employee.
            </p>
            <form [formGroup]="equipmentForm">
              <div class="form-row">
                <div class="form-group">
                  <h2 class="font-weight-bold fs14" [ngClass]="{ 'text-danger': isSubmitted && f.equipment_id.errors }">
                    Equipment
                  </h2>
                  <select
                    id="inputState"
                    class="form-control"
                    formControlName="equipment_id"
                    [ngClass]="{ 'invalid-field': isSubmitted && f.equipment_id.errors }"
                  >
                    <option value="" disabled selected>--Select Equipments--</option>
                    <option [ngValue]="equipment.id" *ngFor="let equipment of equipmentData">
                      {{ equipment.name }} {{ equipment.size ? ', ' + equipment?.size : '' }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <h2 class="font-weight-bold fs14" [ngClass]="{ 'text-danger': isSubmitted && f.tags.errors }">Tag</h2>

                  <tag-input
                    formControlName="tags"
                    [(inputText)]="inputText"
                    [ngClass]="{ 'invalid-field': isSubmitted && f.tags.errors }"
                    (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                  ></tag-input>
                </div>
              </div>
              <div class="float-right">
                <ul class="d-inline-flex list-unstyled">
                  <li class="mr-2">
                    <button
                      type="button"
                      class="btn btn-primary bg-secondary text-white"
                      tooltip="Cancel"
                      (click)="clearForm()"
                    >
                      Cancel
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn btn-primary"
                      [disabled]="loading"
                      tooltip="Add"
                      (click)="createStaffEquipment()"
                    >
                      Add
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </ng-template>
        <li><em class="fas fa-compress circle-icon compress-icon"></em></li>
      </ul>
    </div>
  </div>
  <div class="card-header pb-0" *ngIf="equipmentList.length > 0">
    <div class="row">
      <div class="col-md-6">
        <p class="float-left">Name</p>
      </div>
      <div class="col-md-6">
        <p class="float-right">Actions</p>
      </div>
    </div>
  </div>
  <div class="card-body text-dark">
    <div class="row" *ngFor="let equipments of equipmentList">
      <div class="col-md-6">
        <p class="float-left">{{ equipments?.equipment?.name }} ({{ equipments?.tags }})</p>
      </div>
      <div class="col-md-6">
        <p class="border-0 float-right" *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'">
          <em
            class="fas fa-trash delete-icon c-pointer"
            tooltip="Delete"
            (click)="deletePopup(delete, equipments)"
          ></em>
        </p>
      </div>
    </div>
    <div *ngIf="equipmentList.length === 0">
      <p>No equipment handed out.</p>
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Equipment</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="clearForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete equipment?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="clearForm()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteEquipments()"
    >
      Yes
    </button>
  </div>
</ng-template>
