import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-location-certification',
  templateUrl: './location-certification.component.html',
})
export class LocationCertificationComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() certificateList;
  @Input() clientId;
  @Input() locationCertificateList;
  @Input() locationId;
  certificateData: any = [];
  selectCertificate: any = {};
  certificateInfo = '';
  isSubmit = false;
  showError = false;
  loading = false;

  constructor(private apiService: ApiService, private toastr: ToastrService, private modalService: BsModalService) {}

  ngOnInit() {
    this.getCertificateList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  deletePopup(template: TemplateRef<any>, certificate) {
    this.selectCertificate = certificate;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
  }

  getCertificateList() {
    this.apiService.get('certificate').subscribe((certificate) => {
      if (certificate['status'] === 200) {
        this.certificateData = certificate['certificates'];
      }
    });
  }

  changeAction() {
    this.isSubmit = false;
    this.showError = false;
  }

  addCerticate() {
    this.isSubmit = true;
    if (this.certificateInfo === '') {
      this.showError = true;
      return;
    }

    this.loading = true;

    this.apiService
      .post('client/' + this.clientId + '/certificate', {
        certificate_id: this.certificateInfo,
        location: this.locationId,
      })
      .subscribe(
        (certicate) => {
          this.loading = false;
          if (certicate['status'] === 200) {
            this.toastr.success(certicate['message']);
            this.clearForm();
          }
        },
        (error) => {
          this.loading = false;
          this.isSubmit = false;
          if (error.error.message) {
            this.toastr.error(error.error.message);
          }
        },
      );
  }

  deleteCertificate() {
    this.apiService
      .delete('client/' + this.clientId + '/certificate/delete/' + this.selectCertificate['id'])
      .subscribe((deletecertificate) => {
        if (deletecertificate['status'] === 200) {
          this.toastr.success(deletecertificate['message']);
          this.clearForm();
        } else {
          this.toastr.error(deletecertificate['message']);
          this.clearForm();
        }
      });
  }

  clearForm() {
    this.modalRef.hide();
    this.isSubmit = false;
    this.loading = false;
    this.showError = false;
    this.certificateInfo = '';
    this.selectCertificate = {};
    this.apiService.closeClientModel.emit(14);
  }
}
