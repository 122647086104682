import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../services';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-dateformat',
  templateUrl: './dateformat.component.html',
})
export class DateformatComponent implements OnInit {
  customdate = '';
  message = '';
  ft1 = '';
  ft2 = '';
  ft3 = '';
  ft4 = '';
  ft5 = '';
  date = '';
  dateformat = '';
  selectedFormat = '';
  @Input() updatedateformat;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() datesformat = new EventEmitter<string>();
  domainInfo: any = {};

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.ft1 = moment().format('DD/MM/YYYY');
    this.ft2 = moment().format('MM/DD/YYYY');
    this.ft3 = moment().format('YYYY/MM/DD');
    this.ft4 = moment().format('MMMM DD, YYYY');
    this.ft5 = moment().format('DD MMMM, YYYY');
    if (this.updatedateformat) {
      if (this.updatedateformat === 'dd/MM/yyyy') {
        this.fdate1();
      } else if (this.updatedateformat === 'MM/dd/yyyy') {
        this.fdate2();
      } else if (this.updatedateformat === 'yyyy/MM/dd') {
        this.fdate3();
      } else if (this.updatedateformat === 'dd MMM, yyyy') {
        this.fdate5();
      } else {
        this.fdate4();
      }
    } else {
      this.fdate4();
    }
  }
  dateformatsubmit() {
    // date="2020-04-01";
    // alert(this.date)
    if (this.date == '') {
      this.date = this.ft4;
      this.dateformat = 'MMM dd,yyyy';
    }
    this.messageEvent.emit(this.date);
    this.datesformat.emit(this.dateformat);
    this.apiService.closeClientModel.emit(13);
  }
  fdate1() {
    this.date = this.ft1;
    this.dateformat = 'dd/MM/yyyy';
  }
  fdate2() {
    this.date = this.ft2;
    this.dateformat = 'MM/dd/yyyy';
  }
  fdate3() {
    this.date = this.ft3;
    this.dateformat = 'yyyy/MM/dd';
  }

  fdate4() {
    this.date = this.ft4;
    this.dateformat = 'MMM dd,yyyy';
  }
  fdate5() {
    this.date = this.ft5;
    this.dateformat = 'dd MMM, yyyy';
  }
  closeModel() {
    this.apiService.closeClientModel.emit(13);
  }
}
