<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/clients"><a>Locations</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-user mr-1"></em>Location Details</li>
  </ol>
</nav>
<div class="roaster-body client-layout">
  <p class="color-red" *ngIf="clientInfo.is_deleted">
    This location is hidden and won't be shown as an option to select.
  </p>

  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">{{ clientInfo?.name }}</h1>
  <p class="color-grey-text">
    Below you can manage any information related this location including roles, required certifications and
    blacklistings.
  </p>
  <div class="row">
    <div class="col-md-8">
      <app-about [clientInfo]="clientInfo"></app-about>
      <app-location [locationList]="locationList" [clientId]="clientId"></app-location>
      <app-incidents [incidentsList]="incidentsList" [clientId]="clientId" [clientInfo]="clientInfo"></app-incidents>
    </div>
    <div class="col-md-4">
      <app-certificates [certificateList]="certificateList" [clientId]="clientId"></app-certificates>
      <app-files [fileList]="fileList" [clientId]="clientId"></app-files>
      <app-blacklisting [blackList]="blackList" [clientId]="clientId"></app-blacklisting>
    </div>
  </div>
</div>
