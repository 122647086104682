import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import * as Moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services';
import * as MomentRange from 'moment-range';
import { dateFormats } from '../constants/defaultValues';
import { TabDirective } from 'ngx-bootstrap/tabs';

const moment = MomentRange.extendMoment(Moment);

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
})
export class ReportComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect;
  @ViewChild('multiSelectStaff') multiSelectStaff;
  @ViewChild('singleSelectStaff') singleSelectStaff;
  @ViewChild('multiSelectIncident') multiSelectIncident;

  public datePickerConfig: Partial<BsDaterangepickerConfig>;

  public byStaffMemberTable = true;

  public byStaffGroupTable = false;

  public byLocationTable = false;

  public bySubLocationTable = false;

  public showLocationDropdown = false;

  public byClientTable = false;

  public reportList: any = [];

  public selectedDate = new Date();

  public reportType = 'By staff member detailed';

  public selectedLocationDropdown = 'Location';

  public selectedStaffMember = 'ALL';
  public selectedStaffId = ['All'];
  public selectedIncidentType = 'ALL';
  public selectedIncidentTypeId: any[] = [];
  public selectedClient = [];
  public selectedStaff = [];
  public selectedStaffIncidentMember = 'All';
  public selectedStaffIncidentMemberId: any[] = [];
  public selectedClientIncidentId: any[] = [];

  public selectedClientsForFilter = [];
  public selectedStaffsForFilter = [];

  public downloadReportType = 'Excel';

  public dates: any = [];

  public staffTotalWorkHours = '';

  public locationList: any = [];

  public fromDate = '';

  public toDate = '';
  bsValue = '';

  public maxFromDate: Date;
  public minToDate: Date;

  @ViewChild('datepickerFull') datepickerFull;

  @ViewChild('fromDatePickerFull') fromDatePickerFull;

  @ViewChild('toDatePickerFull') toDatePickerFull;

  public reportParameter = '';

  public searchText = '';

  public clientList: any = [];
  public incidentTypeList: any = [];

  clientLocationList: any = [];

  public subLocation: any = [];

  public selectedLocationId: any = [];

  public selectedSubLocationId: any = [];

  public selectedClientId: any = [];
  public loading = false;
  clientDropDownList: any = [];
  staffDropDownList: any = [];
  incidentDropDownList: any[];
  // By staff drop down
  byStaffDropDownList = [
    {
      id: 'byStaffMember',
      name: 'By staff member detailed',
    },
    { id: 'byStaffGroup', name: 'By staffmembers (groups)' },
    { id: 'byLocation', name: 'By Role' },
    { id: 'byClient', name: 'By Location' },
  ];

  selectedHours = 'byStaffMember';
  public singleByStaffSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: '',
    unSelectAllText: '',
    allowSearchFilter: true,
  };
  public clientSettings = {
    singleSelection: false,
    idField: 'clientId',
    textField: 'client_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public clientFilterSettings = {
    singleSelection: false,
    idField: 'clientId',
    textField: 'client_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public staffSettings = {
    singleSelection: false,
    idField: 'staffId',
    textField: 'staff_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    allSelected: 'Selected All',
  };
  public singleStaffSettings = {
    singleSelection: false,
    idField: 'staffId',
    textField: 'staff_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  public incidentSettings = {
    singleSelection: false,
    idField: 'incidentId',
    textField: 'incident_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  staffList: any[];
  page = 1;
  pagesize = 10;
  totalCount = 0;
  incidentTable = Array;
  num = 20;
  incidentReportList: any = [];
  selectedIncident: any = [];
  search = '';
  domainInfo: any = {};
  jumpDateFormat: string;

  form = new FormGroup({
    dateFull: new FormControl(new Date()),
    fromDateFull: new FormControl(),
    toDateFull: new FormControl(),
  });
  selectedClientName = 'All';

  constructor(private apiService: ApiService, private toastr: ToastrService) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'ddd,Do MMM YYYY',
        showWeekNumbers: false,
        showTodayButton: true,
      },
    );
    this.jumpDateFormat = dateFormats[this.domainInfo.dateformat];
  }

  ngOnInit() {
    this.setAllForLocationSub();
    this.generateDates();
    this.getClientList();
    this.getIncidentType();
    this.searchText = '';
    this.reportParameter = 'byStaffMember';
    this.filterReports();
  }

  public generateDates() {
    this.dates = [];
    const fromDate = moment(this.datepickerFull['_bsValue']).startOf('isoWeek');
    const endDate = moment(this.datepickerFull['_bsValue']).endOf('isoWeek');

    const range = moment().range(fromDate, endDate);
    this.dates = Array.from(range.by('days')).map((date) => moment(date).format(this.jumpDateFormat));
  }

  public setAllForLocationSub() {
    this.selectedClientId.push('All');
    this.selectedLocationId.push('All');
    this.selectedSubLocationId.push('All');
  }

  public clientChange(input) {
    this.selectedClientId = [];
    this.selectedClientName = input.name;
    if (input.id === 'All') {
      this.selectedClientId.push('All');
    } else {
      this.selectedClientId.push(input.id);
    }
    this.filterReports();
  }

  public getClientLocationList(clientID) {
    this.apiService.get(`client/${clientID}/location`).subscribe((location: any) => {
      if (location.status === 200) {
        this.clientLocationList = location.location;
        this.clientLocationList.unshift({ id: 'All', name: 'All' });
        this.subLocation = [];
        this.selectedSubLocationId.push('All');
        this.subLocation.unshift({ id: 'All', name: 'All' });
      }
    });
  }

  public locationChange(input) {
    if (input === 'All') {
      this.selectedLocationId = [];
      this.selectedLocationId.push('All');
    } else {
      const zeroIndex = this.selectedLocationId.indexOf('All');
      if (zeroIndex > -1) {
        this.selectedLocationId.splice(zeroIndex, 1);
      }
      const index = this.selectedLocationId.indexOf(input);
      if (index >= 0) {
        this.selectedLocationId.splice(index, 1);
      } else {
        this.selectedLocationId.push(input);
      }
      this.subLocation = [];
      const detail = this.clientLocationList.find((location) => location.id === +input);
      if (detail.locations.length > 0) {
        this.subLocation = detail.locations;
        const findIndexSubLocation = this.subLocation.find((x) => x.id === 'All');
        if (!findIndexSubLocation) {
          this.subLocation.unshift({ id: 'All', name: 'All' });
        }
      } else {
        this.subLocation.unshift({ id: 'All', name: 'All' });
      }
    }
  }

  public subTablelocationChange(input) {
    this.selectedLocationId = [];
    if (input === 'All') {
      this.selectedLocationId.push('All');
      this.selectedSubLocationId = [];
      this.subLocation = [];
      this.selectedSubLocationId.push('All');
      this.subLocation.push({ id: 'All', name: 'All' });
    } else {
      this.selectedLocationId.push(input);
      this.subLocation = [];
      const detail = this.clientLocationList.find((location) => location.id === +input);
      if (detail.locations.length > 0) {
        this.subLocation = detail.locations;
        const findIndexSubLocation = this.subLocation.find((x) => x.id === 'All');
        if (!findIndexSubLocation) {
          this.subLocation.unshift({ id: 'All', name: 'All' });
        }
      } else {
        this.subLocation.unshift({ id: 'All', name: 'All' });
      }
    }
  }

  public subLocationChange(input) {
    if (input === 'All') {
      this.selectedSubLocationId = [];
      this.selectedSubLocationId.push('All');
    } else {
      const zeroIndex = this.selectedSubLocationId.findIndex((x) => x === 'All');
      if (zeroIndex >= 0) {
        this.selectedSubLocationId.splice(zeroIndex, 1);
      }
      const index = this.selectedSubLocationId.indexOf(input);
      if (index >= 0) {
        this.selectedSubLocationId.splice(index, 1);
      } else {
        this.selectedSubLocationId.push(input);
      }
    }
  }

  public getClientList() {
    this.apiService.get('client/list').subscribe((client: any) => {
      if (client.status === 200) {
        this.clientList = client.clients;
        this.clientList.forEach((values) => {
          this.selectedClient.push({ clientId: values.id, client_name: values.name });
        });
        this.clientDropDownList = this.selectedClient;
      }
    });
  }
  public getIncidentType() {
    this.apiService.get('incident').subscribe((incidenttype) => {
      if (incidenttype['status'] === 200) {
        this.incidentTypeList = incidenttype['incidents'];
        this.incidentTypeList.forEach((values) => {
          this.selectedIncident.push({ incidentId: values.id, incident_name: values.incident_name });
        });
        this.incidentDropDownList = this.selectedIncident;
      }
    });
  }

  public searchReports(text) {
    this.searchText = text;
    this.filterReports();
  }
  public searchIncidentReports(text) {
    this.search = text;
    this.page = 1;
    this.getIncidentReport();
  }
  public toggleReportLocation(data) {
    this.resetLocations();
    if (data === 'Location') {
      this.selectedLocationDropdown = 'Location';
      this.toggleReport('Location', 'byLocation');
    } else {
      this.selectedLocationDropdown = 'SubLocation';
      this.toggleReport('SubLocation', 'bySubLocation');
    }
  }

  public toggleReport(reportName, reportType) {
    this.selectedHours = reportName;
    if (reportType === 'byLocation') {
      this.reportParameter = reportType;
      this.reportType = reportName;
    } else if (reportType === 'byStaffGroup') {
      this.reportParameter = reportType;
      this.reportType = reportName;
      this.resetLocations();
    } else if (reportType === 'byStaffMember') {
      this.reportParameter = reportType;
      this.reportType = reportName;
      this.resetLocations();
    } else if (reportType === 'byClient') {
      this.reportParameter = reportType;
      this.reportType = reportName;
      this.resetLocations();
    }
    this.filterReports();
  }

  public resetLocations() {
    this.selectedLocationId = [];
    this.selectedSubLocationId = [];
    this.selectedClientId = [];
    this.selectedLocationId.push('All');
    this.selectedClientId.push('All');
    this.selectedSubLocationId.push('All');
  }
  staffDetails: any[] = [];
  public filterReports() {
    const queryParams = {
      source: 'hours',
      dateFormat: moment(this.datepickerFull['_bsValue']).format('YYYY-MM-DD'),
    };
    if (this.reportParameter === 'byStaffMember') {
      queryParams['byStaffMember'] = String(this.selectedStaffId);
      this.showLocationDropdown = false;
      this.byLocationTable = false;
      this.byStaffMemberTable = true;
      this.byStaffGroupTable = false;
      this.byClientTable = false;
      this.bySubLocationTable = false;
      this.bySubLocationTable = false;
    }
    if (this.reportParameter === 'byStaffGroup') {
      queryParams['byStaffGroup'] = true;
      this.showLocationDropdown = false;
      this.byLocationTable = false;
      this.byStaffMemberTable = false;
      this.byStaffGroupTable = true;
      this.byClientTable = false;
      this.bySubLocationTable = false;
    }
    if (this.reportParameter === 'byLocation') {
      const zeroIndexClient = this.selectedClientId.findIndex((x) => x === 'All');
      if (zeroIndexClient >= 0) {
        this.selectedClientId.splice(zeroIndexClient, 1);
      }
      queryParams['byLocation'] = String(this.selectedLocationId);
      queryParams['byClient'] = String(this.selectedClientId);
      this.bySubLocationTable = false;
      this.byLocationTable = true;
      this.showLocationDropdown = true;
      this.byStaffMemberTable = false;
      this.byStaffGroupTable = false;
      this.byClientTable = false;
    }
    if (this.reportParameter === 'bySubLocation') {
      if (this.selectedSubLocationId.length > 0) {
        const zeroIndexClient = this.selectedClientId.findIndex((x) => x === 'All');
        if (zeroIndexClient >= 0) {
          this.selectedClientId.splice(zeroIndexClient, 1);
        }
      }
      queryParams['byLocation'] = String(this.selectedLocationId);
      queryParams['bySubLocation'] = String(this.selectedSubLocationId);
      queryParams['byClient'] = String(this.selectedClientId);
      this.bySubLocationTable = true;
      this.byLocationTable = false;
      this.showLocationDropdown = true;
      this.byStaffMemberTable = false;
      this.byStaffGroupTable = false;
      this.byClientTable = false;
    }
    if (this.reportParameter === 'byClient') {
      queryParams['byClientReport'] = 'All';
      this.showLocationDropdown = false;
      this.byLocationTable = false;
      this.byStaffMemberTable = false;
      this.byStaffGroupTable = false;
      this.byClientTable = true;
      this.bySubLocationTable = false;
    }
    if (this.searchText && this.searchText !== '') {
      queryParams['search'] = this.searchText;
    }
    this.reportList = [];
    this.apiService.get('report/filter-report', queryParams).subscribe(
      (res) => {
        this.generateDates();
        if (this.reportParameter === 'byStaffGroup') {
          this.reportList = res['reportList'][0].shiftListInfo;
          this.staffTotalWorkHours = res['reportList'][0].totalWorkHours;
        } else if (this.reportParameter === 'byLocation' || this.reportParameter === 'bySubLocation') {
          this.reportList = res['reportList'];
          this.locationList = [];
          res['reportList'].forEach((element) => {
            element.shiftListInfo.forEach((locationId) => {
              if (!this.locationList.includes(locationId.location)) {
                if (this.reportParameter === 'bySubLocation') {
                  this.locationList.push({ id: locationId.sublocation, locationName: locationId.subLocationName });
                } else {
                  this.locationList.push({ id: locationId.location, locationName: locationId.locationName });
                }
              }
            });
          });
          this.locationList = this.locationList.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
          this.reportList = res['reportList'];
        } else {
          this.reportList = res['reportList'];
          this.getStaffList();
        }
      },
      (err) => {
        this.toastr.warning(err.message);
      },
    );
  }

  public downloadReportsByExcel(reportType) {
    const queryParams = {
      source: 'hours',
      dateFormat: moment(this.datepickerFull['_bsValue']).format('YYYY-MM-DD'),
      exportBy: 'excel',
    };
    if (this.reportParameter === 'byStaffMember') {
      queryParams['byStaffMember'] = String(this.selectedStaffId);
    }

    if (this.reportParameter === 'byStaffGroup') {
      queryParams['byStaffGroup'] = true;
    }
    if (this.reportParameter === 'byLocation') {
      if (this.selectedLocationId.length > 0) {
        const zeroIndexClient = this.selectedClientId.findIndex((x) => x === 'All');
        if (zeroIndexClient >= 0) {
          this.selectedClientId.splice(zeroIndexClient, 1);
        }
      }
      queryParams['byLocation'] = String(this.selectedLocationId);
      queryParams['byClient'] = String(this.selectedClientId);
    }
    if (this.reportParameter === 'bySubLocation') {
      if (this.selectedSubLocationId.length > 0) {
        const zeroIndexClient = this.selectedClientId.findIndex((x) => x === 'All');
        if (zeroIndexClient >= 0) {
          this.selectedClientId.splice(zeroIndexClient, 1);
        }
      }
      queryParams['byLocation'] = String(this.selectedLocationId);
      queryParams['bySubLocation'] = String(this.selectedSubLocationId);
      queryParams['byClient'] = String(this.selectedClientId);
    }
    if (this.reportParameter === 'byClient') {
      queryParams['byClientReport'] = 'All';
    }
    if (this.searchText && this.searchText !== '') {
      queryParams['search'] = this.searchText;
    }
    this.apiService.get('report/filter-report', queryParams, { responseType: 'blob' }).subscribe(
      (res) => {
        const downloadURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${this.selectedHours}.xlsx`;
        link.click();
      },
      (err) => {
        this.toastr.warning(err.message);
      },
    );
  }
  public getStaffDetails(report) {
    this.selectedStaffMember = report.firstName + report.surName;
    this.selectedStaffId = [report.id];
    this.filterReports();
  }
  getStaffList() {
    this.apiService.get('staff/list').subscribe((staff: any) => {
      if (staff.status === 200) {
        this.staffList = staff.staffs;
        this.staffList.forEach((values) => {
          this.selectedStaff.push({ staffId: values.id, staff_name: values.firstName + ' ' + values.surName });
        });
        this.staffDropDownList = this.selectedStaff;
      } else {
        this.staffDropDownList = [];
      }
    });
  }
  insertValue: any;
  indexValue: number;
  getIncidentDetails(incidentValue, type) {
    if (type == 'INCIDENT') {
      this.selectedIncidentType = incidentValue.incident_name;
      if (this.selectedIncidentTypeId.length > 0) {
        this.selectedIncidentTypeId.forEach((id, i) => {
          if (id == incidentValue.id) {
            this.indexValue = i;
            this.selectedIncidentTypeId.splice(this.indexValue, 1);
          } else {
            this.insertValue = incidentValue.id;
          }
        });
        this.selectedIncidentTypeId.push(this.insertValue);
      } else {
        this.selectedIncidentTypeId.push(incidentValue.id);
      }
    } else if (type == 'CLIENT') {
      this.selectedClient = incidentValue.name;
      if (this.selectedClientIncidentId.length > 0) {
        this.selectedClientIncidentId.forEach((id, i) => {
          if (id == incidentValue.id) {
            this.selectedClientIncidentId.splice(i, 1);
            this.indexValue = i;
          } else {
            this.insertValue = incidentValue.id;
          }
        });
        this.selectedClientIncidentId.push(this.insertValue);
      } else {
        this.selectedClientIncidentId.push(incidentValue.id);
      }
    } else {
      this.selectedStaffIncidentMember = incidentValue.firstName + incidentValue.surName;
      if (this.selectedStaffIncidentMemberId.length > 0) {
        this.selectedStaffIncidentMemberId.forEach((id, i) => {
          if (id == incidentValue.id) {
            this.selectedStaffIncidentMemberId.splice(i, 1);
            this.indexValue = i;
          } else {
            this.insertValue = incidentValue.id;
          }
        });
        this.selectedStaffIncidentMemberId.push(this.insertValue);
      } else {
        this.selectedStaffIncidentMemberId.push(incidentValue.id);
      }
    }
  }

  getIncidentReport() {
    this.loading = true;
    const filter = [];
    if (this.fromDate === 'Invalid date') {
      this.fromDate = '';
    }
    if (this.toDate === 'Invalid date') {
      this.toDate = '';
    }
    if (this.selectedClientIncidentId && this.selectedClientIncidentId.length > 0) {
      this.selectedClientIncidentId.forEach((item) => {
        filter.push(`clientId[]=${item}`);
      });
    }
    if (this.selectedStaffIncidentMemberId && this.selectedStaffIncidentMemberId.length > 0) {
      this.selectedStaffIncidentMemberId.forEach((item) => {
        filter.push(`staffId[]=${item}`);
      });
    }
    if (this.selectedIncidentTypeId && this.selectedIncidentTypeId.length > 0) {
      this.selectedIncidentTypeId.forEach((item) => {
        filter.push(`incidentTypeId[]=${item}`);
      });
    }

    const filterValue = filter.length > 0 ? `&${filter.join('&')}` : '';

    this.apiService
      .get(
        `report/incident-report?page=${this.page}&pagesize=${this.pagesize}${filterValue}&search=${this.search}&fromDate=${this.fromDate}&toDate=${this.toDate}`,
      )
      .subscribe((res) => {
        this.loading = false;
        if (res['status'] === 200) {
          this.totalCount = res['pageDetails']['total'];
          this.page = res['pageDetails']['page'];
          this.pagesize = res['pageDetails']['pageSize'];
          this.incidentReportList = res['reportList'];
        } else {
          this.loading = false;
          this.incidentReportList = [];
        }
      });
  }
  pageChange(newPage: number) {
    this.page = newPage;
    this.getIncidentReport();
  }

  // Client dropdown
  onClientSelect(item: any) {
    this.selectedClientIncidentId.push(item.clientId);
    this.page = 1;
    this.getIncidentReport();
  }

  onClientDeSelect(item: any) {
    this.selectedClientIncidentId = this.selectedClientIncidentId.filter((value) => value !== item.clientId);
    this.page = 1;
    this.getIncidentReport();
  }
  onClientSelectAll(item: any) {
    item.map((value) => this.selectedClientIncidentId.push(value.clientId));
    this.selectedClientIncidentId = this.selectedClientIncidentId.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.page = 1;
    this.getIncidentReport();
  }
  onClientDeselectAll() {
    this.selectedClientIncidentId = [];
    this.page = 1;
    this.getIncidentReport();
  }

  // Staff dropdown
  onStaffSelect(item: any, type = null) {
    if (type) {
      this.selectedStaffsForFilter.push(item);
      this.selectedStaffId = this.selectedStaffsForFilter.map((it) => it.staffId);
      this.filterReports();
    } else {
      this.selectedStaffIncidentMemberId.push(item.staffId);
      this.page = 1;
      this.getIncidentReport();
    }
  }

  onStaffDeSelect(item: any, type = null) {
    if (type) {
      this.selectedStaffsForFilter = this.selectedStaffsForFilter.filter((value) => value.staffId !== item.staffId);
      this.selectedStaffId = this.selectedStaffsForFilter.map((it) => it.staffId);
      this.filterReports();
    } else {
      this.selectedStaffIncidentMemberId = this.selectedStaffIncidentMemberId.filter((value) => value !== item.staffId);
      this.page = 1;
      this.getIncidentReport();
    }
  }

  onStaffSelectAll(item: any, type = null) {
    if (type) {
      item.map((value) => this.selectedStaffsForFilter.push(value));
      this.selectedStaffsForFilter = this.selectedStaffsForFilter.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.selectedStaffId = ['All'];
      this.filterReports();
    } else {
      item.map((value) => this.selectedStaffIncidentMemberId.push(value.staffId));
      this.selectedStaffIncidentMemberId = this.selectedStaffIncidentMemberId.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      });
      this.page = 1;
      this.getIncidentReport();
    }
  }
  onStaffDeselectAll(type = null) {
    if (type) {
      this.selectedStaffsForFilter = [];
      this.selectedStaffId = ['All'];
      this.filterReports();
    } else {
      this.selectedStaffIncidentMemberId = [];
      this.page = 1;
      this.getIncidentReport();
    }
  }

  // Incident dropdown
  onIncidentSelect(item: any) {
    this.selectedIncidentTypeId.push(item.incidentId);
    this.page = 1;
    this.getIncidentReport();
  }

  onIncidentDeSelect(item: any) {
    this.selectedIncidentTypeId = this.selectedIncidentTypeId.filter((value) => value !== item.incidentId);
    this.page = 1;
    this.getIncidentReport();
  }
  onIncidentSelectAll(item: any) {
    item.map((value) => this.selectedIncidentTypeId.push(value.incidentId));
    this.selectedIncidentTypeId = this.selectedIncidentTypeId.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    this.page = 1;
    this.getIncidentReport();
  }
  onIncidentDeselectAll() {
    this.selectedIncidentTypeId = [];
    this.page = 1;
    this.getIncidentReport();
  }

  // downloadIncidentReportsByExcel

  downloadIncidentReportsByExcel(reportType) {
    const exportBy = reportType;
    const filter = [];
    if (this.selectedClientIncidentId && this.selectedClientIncidentId.length > 0) {
      this.selectedClientIncidentId.forEach((item) => {
        filter.push(`clientId[]=${item}`);
      });
    }
    if (this.selectedStaffIncidentMemberId && this.selectedStaffIncidentMemberId.length > 0) {
      this.selectedStaffIncidentMemberId.forEach((item) => {
        filter.push(`staffId[]=${item}`);
      });
    }
    if (this.selectedIncidentTypeId && this.selectedIncidentTypeId.length > 0) {
      this.selectedIncidentTypeId.forEach((item) => {
        filter.push(`incidentTypeId[]=${item}`);
      });
    }
    const filterValue = filter.length > 0 ? `&${filter.join('&')}` : '';
    this.apiService
      .get(
        `report/incident-report?search=${this.search}${filterValue}&fromDate=${this.fromDate}&toDate=${this.toDate}&exportBy=${exportBy}`,
        null,
        { responseType: 'blob' },
      )
      .subscribe(
        (res) => {
          const downloadURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = `incident-report-${moment().format('DDMMYYYYHHmmss')}.xlsx`;
          link.click();
        },
        (err) => {
          this.toastr.warning(err.message);
        },
      );
  }
  dateValidation() {
    if (this.fromDate !== 'Invalid Date' && this.toDate !== 'Invalid Date') {
      if (moment(this.toDate).isBefore(this.fromDate)) {
        this.toastr.error('Enter valid Date');
      } else if (moment(this.fromDate).isAfter(this.toDate)) {
        this.toastr.error('Enter valid Date');
      } else {
        this.page = 1;
        this.getIncidentReport();
      }
    }
  }
  fromDateChange() {
    this.fromDate = moment(this.fromDatePickerFull['_bsValue']).format('YYYY-MM-DD');
    this.dateValidation();
  }
  toDateChange() {
    this.toDate = moment(this.toDatePickerFull['_bsValue']).format('YYYY-MM-DD');
    this.dateValidation();
  }
  onDropDownClose() {
    this.getIncidentReport();
  }

  // By Staff dropdown
  onByStaffSelect(item: any, type = null) {
    this.selectedHours = item.name;
    if (item.id === 'byLocation') {
      this.reportParameter = item.id;
      this.reportType = item.name;
    } else if (item.id === 'byStaffGroup') {
      this.reportParameter = item.id;
      this.reportType = item.name;
      this.resetLocations();
    } else if (item.id === 'byStaffMember') {
      this.reportParameter = item.id;
      this.reportType = item.name;
      this.resetLocations();
    } else if (item.name === 'byClient') {
      this.reportParameter = item.id;
      this.reportType = item.name;
      this.resetLocations();
    }
    this.filterReports();
  }

  onByStaffDeSelect(item: any, type = null) {
    this.reportParameter = '';
    this.reportType = '';
    this.filterReports();
  }

  // Client Filter dropdown
  onClientFilterSelect(item: any) {
    this.selectedClientsForFilter.push(item);
    this.selectedClientId.push(item.clientId);
    this.filterReports();
  }

  onClientFilterDeSelect(item: any) {
    this.selectedClientsForFilter = this.selectedClientsForFilter.filter((it) => it.clientId !== item.clientId);
    this.selectedClientId = this.selectedClientId.filter((value) => value !== item.clientId);
    this.filterReports();
  }
  onClientFilterSelectAll(item: any) {
    item.map((value) => this.selectedClientId.push(value.clientId));
    this.selectedClientsForFilter.push(item);
    this.filterReports();
  }
  onClientFilterDeselectAll() {
    this.selectedClientId = [];
    this.selectedClientsForFilter = [];
    this.filterReports();
  }

  resetAllOptions() {
    this.selectedStaffIncidentMemberId = [];
    this.selectedStaffsForFilter = [];
    this.selectedClientsForFilter = [];
    this.selectedStaffId = ['All'];
  }

  selectHourType(item) {
    this.resetAllOptions();
    this.selectedHours = item.id;
    this.reportParameter = item.id;
    this.reportType = item.name;
    if (['byStaffGroup', 'byStaffMember', 'byClient'].includes(item.id)) {
      this.resetLocations();
    }
    this.filterReports();
  }

  onSelect(data: TabDirective) {
    if (data.heading === 'Incident Report') {
      this.page = 1;
      this.getIncidentReport();
    } else {
      this.showLocationDropdown = false;
      this.byLocationTable = false;
      this.byStaffMemberTable = false;
      this.byStaffGroupTable = true;
      this.byClientTable = false;
      this.bySubLocationTable = false;
      this.selectedStaffId = ['All'];
      this.selectedClientId = [];
      this.searchText = '';

      this.selectedStaffsForFilter = [];
      this.selectedClientsForFilter = [];

      this.filterReports();
    }
  }
}
