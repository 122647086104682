import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
})
export class AboutComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() clientInfo;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 7) {
        this.closeModel();
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  editClientModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  changeLoginInfo(template: TemplateRef<any>) {
    this.editClientModal(template);
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.editClientModal(template);
  }

  removeClient() {
    this.apiService.delete('client/delete/' + this.clientInfo['id']).subscribe(
      (client: any) => {
        if (client['status'] === 200) {
          this.toastr.success(client.message);
          this.closeModel();
          //this.router.navigate(['/clients']);
        } else {
          this.toastr.error(client.message);
          this.closeModel();
        }
      },
      (err) => {
        this.closeModel();
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }

  revertClient() {
    this.apiService.get('client/revert/' + this.clientInfo['id']).subscribe((client: any) => {
      if (client['status'] === 200) {
        this.toastr.success(client.message);
        this.closeModel();
        //this.router.navigate(['/clients']);
      }
    });
  }

  closeModel() {
    this.modalRef.hide();
    this.apiService.closeClientModel.emit(4);
  }
}
