import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { passwordRegEx } from '../constants/defaultValues';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(apiService, toastr, formBuilder, router, route) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.router = router;
        this.route = route;
        this.isSubmitted = false;
        this.loading = false;
        this.otp_code = '';
        this.passwordRegEx = passwordRegEx;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.otp_code = this.route.snapshot.queryParamMap.get('otp_code');
        this.createForm();
    };
    ResetPasswordComponent.prototype.checkPasswords = function (group) {
        var pass = group.get('new_password').value;
        var confirmPass = group.get('confirm_password').value;
        return pass === confirmPass ? null : { notSame: true };
    };
    ResetPasswordComponent.prototype.createForm = function () {
        this.changeLoginForm = this.formBuilder.group({
            otp_code: [this.otp_code, Validators.required],
            new_password: ['', Validators.required],
            confirm_password: ['', Validators.required],
        }, { validator: this.checkPasswords });
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "f", {
        get: function () {
            return this.changeLoginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.isSubmitted = true;
        if (!this.changeLoginForm.valid) {
            return;
        }
        var queryValues = this.changeLoginForm.value;
        this.loading = true;
        this.apiService.post('auth/reset', queryValues).subscribe(function (data) {
            _this.loading = false;
            _this.isSubmitted = false;
            if (data['status'] === 200) {
                _this.toastr.success(data['message']);
                _this.router.navigate(['/login']);
            }
            else {
                _this.toastr.error(data['message']);
            }
        }, function (err) {
            _this.loading = false;
            _this.isSubmitted = false;
            if (err.error.message) {
                _this.toastr.error(err.error.message);
            }
        });
    };
    ResetPasswordComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
