<div class="card mb-3 border-0">
  <div class="card-header bg-white px-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-self-center">
        <h6 class="text-dark font-weight-bold fs16 mb-0">STAFF</h6>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-more"
        tooltip="Add"
        (click)="openModal(template2)"
        *ngIf="!incidentStatus"
      >
        <em class="fas fa-plus fs12 c-pointer"></em> Add
      </button>
      <ng-template #template2 class="modal-content">
        <div class="modal-header bg-primary">
          <h4 class="modal-title text-center text-white">Add a staff member to the incident</h4>
          <button type="button" class="close text-white" aria-label="Close" (click)="clearForm()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body w-100 modal-lg">
          <h1 class="fs14 font-weight-bold">Incident Staff Member</h1>
          <p class="text-secondary">
            The form below allows you to add related employees to the incident. Please click "Add" after selecting the
            related employee.
          </p>
          <form>
            <h1 class="fs14 font-weight-bold">Staff</h1>
            <div class="form-group">
              <select
                id="inputState"
                class="form-control"
                [(ngModel)]="staffId"
                name="staffId"
                (change)="changeAction()"
                [ngClass]="{ 'invalid-field': showError && isSubmit }"
              >
                <option value="" selected disabled>--Select Staff--</option>
                <option [ngValue]="staff.id" *ngFor="let staff of newStaffList">
                  {{ staff?.firstName }} {{ staff?.surName }}
                </option>
              </select>
            </div>
            <div class="float-right">
              <ul class="d-inline-flex list-unstyled">
                <li class="mr-2">
                  <button
                    type="button"
                    tooltip="Cancel"
                    class="btn btn-primary bg-secondary text-white"
                    (click)="clearForm()"
                  >
                    Cancel
                  </button>
                </li>
                <li>
                  <button
                    type="submit"
                    tooltip="Add"
                    [disabled]="isSubmit"
                    class="btn btn-primary"
                    (click)="createIncidentStaff()"
                  >
                    Add
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngFor="let staff of staffList">
      <div
        class="d-flex justify-content-between align-items-center mb-4 c-pointer"
        (click)="redirectUrl(staff.staff_id)"
      >
        <div class="bg-user align-self-center">
          <h2 class="text-dark fs14 pt12 mr-2 mb-0">{{ staff?.staff?.firstName }} {{ staff?.staff?.surName }}</h2>
        </div>
        <div><em class="fas fa-eye fs12 color-light-green pt12 c-pointer" tooltip="View"></em></div>
      </div>
    </ng-container>
  </div>
</div>
