import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-app-list-data-staff',
  templateUrl: './app-list-data-staff.component.html',
})
export class AppListDataStaffComponent implements OnInit {
  page = 1;
  staffList: any = [];
  pagesize = 10;
  totalCount = 0;
  search;
  sortFiled;
  sortType: any = '';
  userGroup = localStorage.getItem('userGroup');
  userId: any = localStorage.getItem('userid');
  showHidden = 0;
  public loading = false;

  constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) {}

  ngOnInit() {
    this.getStaffList();
    this.apiService.searchValue.subscribe((data) => {
      if (data && data.id === 2) {
        this.page = 1;
        this.search = data.value;
        this.showHidden = data.showHidden;
        this.getStaffList();
      }
    });
  }
  upside(format, field) {
    if (this.sortType == 'asc') {
      this.sortType = 'desc';
      this.sortFiled = field;
      this.getStaffList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getStaffList();
    }
  }

  down(format, field) {
    if (this.sortType == 'desc') {
      this.sortType = 'asc';
      this.sortFiled = field;
      this.getStaffList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getStaffList();
    }
  }

  getStaffList() {
    this.loading = true;
    const queryParams = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) {
      queryParams['search'] = this.search;
    }
    if (this.sortType != '' && this.sortFiled != '') {
      queryParams['sort'] = this.sortFiled + ':' + this.sortType;
    }
    queryParams['showHidden'] = this.showHidden;
    this.apiService.get('staff', queryParams).subscribe(
      (staff: any) => {
        if (staff.status === 200) {
          this.staffList = staff.staffs;
          this.totalCount = staff.pageDetails.total;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getStaffList();
  }

  viewStaffDetails(staffId) {
    this.userId = parseInt(this.userId);
    if ((this.userGroup != 'Staff' && this.userGroup != 'Supervisor') || this.userId === staffId) {
      this.router.navigate(['/staff-details/' + staffId]);
    }
  }
}
