import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
var ViewClientinfoComponent = /** @class */ (function () {
    function ViewClientinfoComponent(formBuilder, apiService, toastr, modalService) {
        this.formBuilder = formBuilder;
        this.apiService = apiService;
        this.toastr = toastr;
        this.modalService = modalService;
        this.submitted = false;
        // @Input() info: any;
        this.viewDomainlist = [];
        this.isreadonlyenabled = true;
        this.phoneCode = [];
        this.editdetailsarray = [];
        this.defaultCountryCode = 'NZ';
        this.timeformatvalue = '';
        this.dateformatvalue = '';
        this.domainInfo = {};
    }
    ViewClientinfoComponent.prototype.ngOnInit = function () {
        this.customercreationform = this.formBuilder.group({
            Name: ['', Validators.required],
            CompanyName: ['', Validators.required],
            CompanyWebsite: ['', Validators.required],
            MobileNumber: ['', Validators.required],
            countryCode: ['', Validators.required],
            Email: [
                '',
                [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,})$/)],
            ],
            Domain: ['', Validators.required],
            TimeFormat: ['', Validators.required],
            TimeZone: ['', Validators.required],
            DateFormat: ['', Validators.required],
            staff_count: ['', Validators.required],
        });
        this.viewclientdetails();
    };
    Object.defineProperty(ViewClientinfoComponent.prototype, "f", {
        get: function () {
            return this.customercreationform.controls;
        },
        enumerable: true,
        configurable: true
    });
    ViewClientinfoComponent.prototype.getPhoneCode = function () {
        var _this = this;
        this.apiService.get('phone-code').subscribe(function (phoneCode) {
            if (phoneCode.status === 200) {
                _this.phoneCode = phoneCode['phonecodes'];
                if (_this.phoneCode && _this.phoneCode.length > 0) {
                    _this.setcountryFlag();
                }
            }
        });
    };
    ViewClientinfoComponent.prototype.setcountryFlag = function () {
        var _this = this;
        var intphoneData = this.phoneCode.filter(function (obj) { return obj.countries_iso_code === _this.defaultCountryCode; });
        var phoneData = this.phoneCode.filter(function (obj) { return obj.id === _this.viewDomainlist['countryCode']; });
        if (phoneData && phoneData.length > 0) {
            this.setcountrycodeflag(phoneData[0]);
        }
        else {
            this.setcountrycodeflag(intphoneData[0]);
        }
    };
    ViewClientinfoComponent.prototype.setcountrycodeflag = function (intphoneData) {
        this.countryCode = intphoneData.countries_isd_code;
        this.countryFlag = intphoneData.countries_iso_code;
        this.nzcode = intphoneData.id;
        this.customercreationform.patchValue({
            countryCode: this.nzcode,
        });
    };
    ViewClientinfoComponent.prototype.makeEditable = function () {
        this.isreadonlyenabled = false;
    };
    ViewClientinfoComponent.prototype.isNumberKey = function (evt, value) {
        if (evt > 31 && (evt < 48 || evt > 57)) {
            return false;
        }
        else if (value.length > 9) {
            return false;
        }
        return true;
    };
    ViewClientinfoComponent.prototype.viewclientdetails = function () {
        var _this = this;
        // alert("2")
        this.apiService.get('domain/view-domain/' + this.id).subscribe(function (client) {
            if (client.status === 200) {
                _this.viewDomainlist = client.viewDomain;
                _this.getPhoneCode();
                var timeformat = '';
                var dateformat = '';
                _this.timeformatvalue = _this.viewDomainlist['timeformat'];
                _this.dateformatvalue = _this.viewDomainlist['dateformat'];
                if (_this.timeformatvalue == 'hh:mm @') {
                    timeformat = moment().format('hh:mm A');
                }
                if (_this.timeformatvalue == 'hh:mm:ss @') {
                    timeformat = moment().format('hh:mm:ss A');
                }
                if (_this.timeformatvalue == 'HH:mm') {
                    timeformat = moment().format('HH:mm');
                }
                if (_this.timeformatvalue == 'HH:mm:ss') {
                    timeformat = moment().format('HH:mm:ss');
                }
                // dateformat
                if (_this.dateformatvalue == 'dd/MM/yyyy') {
                    dateformat = moment().format('DD/MM/YYYY');
                }
                if (_this.dateformatvalue == 'MM/dd/yyyy,') {
                    dateformat = moment().format('MM/DD/YYYY');
                }
                if (_this.dateformatvalue == 'yyyy/MM/dd') {
                    dateformat = moment().format('YYYY/MM/DD');
                }
                if (_this.dateformatvalue == 'MMM dd,yyyy') {
                    dateformat = moment().format('MMMM DD, YYYY');
                }
                if (_this.dateformatvalue == 'dd MMM, yyyy') {
                    dateformat = moment().format('DD MMMM, YYYY');
                }
                _this.customercreationform.patchValue({
                    Name: _this.viewDomainlist['name'],
                    CompanyName: _this.viewDomainlist['clientname'],
                    CompanyWebsite: _this.viewDomainlist['companyWebsite'],
                    MobileNumber: _this.viewDomainlist['mobileNumber'],
                    Email: _this.viewDomainlist['email'],
                    Domain: _this.viewDomainlist['subdomain'],
                    TimeFormat: timeformat,
                    TimeZone: _this.viewDomainlist['timezone'],
                    DateFormat: dateformat,
                    staff_count: _this.viewDomainlist['staff_count'],
                });
            }
        });
    };
    ViewClientinfoComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.customercreationform.invalid) {
            return;
        }
        var object = {};
        object['name'] = this.customercreationform.get('Name').value;
        object['clientname'] = this.customercreationform.get('CompanyName').value;
        object['companyWebsite'] = this.customercreationform.get('CompanyWebsite').value;
        object['mobileNumber'] = this.customercreationform.get('MobileNumber').value;
        object['email'] = this.customercreationform.get('Email').value;
        object['domain'] = this.customercreationform.get('Domain').value;
        object['timeFormat'] = this.customercreationform.get('TimeFormat').value;
        object['dateFormat'] = this.customercreationform.get('DateFormat').value;
        object['timezone'] = '10';
        this.apiService.put('domain/update-domain/' + this.id, object).subscribe(function (client) {
            if (client.status === 200) {
                _this.toastr.success(client.message);
                _this.apiService.closeClientModel.emit(14);
            }
        });
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.customercreationform.value))
    };
    ViewClientinfoComponent.prototype.closeModel = function () {
        this.apiService.closeClientModel.emit(14);
    };
    ViewClientinfoComponent.prototype.timeformatModal = function (template) {
        this.modalRef1 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    return ViewClientinfoComponent;
}());
export { ViewClientinfoComponent };
