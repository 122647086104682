<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div class="container-fluid bg-grey p-3">
  <div class="row pb-2rem mt-4">
    <div class="col-xl-7">
      <div class="row margin-top">
        <div class="col-md-6 mb-3">
          <div class="card h-100 border-0">
            <div class="card-body p-3 b-shawdow bg-white">
              <h1 class="fs16 text-lightgey">MISSING EQUIPMENT</h1>
              <hr class="float-left mt-0 b-color w-14" />
              <div class="d-flex mt-4 align-items-center">
                <div class="square b-blue display-inline-block mr-3"></div>
                <h2 class="display-inline-block mb-0 fs40">
                  {{ dashboardInfo.equipment ? dashboardInfo.equipment : 0 }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <div class="card h-100 border-0">
            <div class="card-body p-3 b-shawdow bg-white">
              <h1 class="fs16 text-lightgey">EXPIRY CERTIFICATIONS</h1>
              <hr class="float-left mt-0 b-color w-14" />
              <div class="d-flex mt-4 align-items-center">
                <div class="square b-green display-inline-block mr-3"></div>
                <h2 class="display-inline-block mb-0 fs40">
                  {{ dashboardInfo.certificate ? dashboardInfo.certificate : 0 }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3 mb-xl-0">
          <div class="card h-100 border-0">
            <div class="card-body p-3 b-shawdow bg-white">
              <h1 class="fs16 text-lightgey">UNSOLVED INCIDENTS</h1>
              <hr class="float-left mt-0 b-color w-14" />
              <div class="d-flex mt-4 align-items-center">
                <div class="square b-green display-inline-block mr-3"></div>
                <h2 class="display-inline-block mb-0 fs40">
                  {{ dashboardInfo.incident ? dashboardInfo.incident : 0 }}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-3 mb-xl-0">
          <div class="card h-100 border-0">
            <div class="card-body p-3 b-shawdow bg-white">
              <h1 class="fs16 text-lightgey">BLOCKED USER</h1>
              <hr class="float-left mt-0 b-color w-14" />
              <div class="d-flex mt-4 align-items-center">
                <div class="square b-green display-inline-block mr-3"></div>
                <h2 class="display-inline-block mb-0 fs40">
                  {{ dashboardInfo.blockedUser ? dashboardInfo.blockedUser : 0 }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-5">
      <div class="card h-100 border-0">
        <div class="card-body p-3 b-shawdow bg-white">
          <div>
            <div [ng2-highcharts]="userStatistics"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-xl-7 mb-3 mb-xl-0">
      <div class="card border-0 h-100">
        <div class="card-body b-shawdow bg-white">
          <div [ng2-highcharts]="lineChart"></div>
        </div>
      </div>
    </div>
    <div class="col-xl-5">
      <div class="card border-0 h-100">
        <div class="card-body p-0 b-shawdow bg-white">
          <h4 class="mb-0 fs16 p-3">MISSING EQUIPMENT</h4>
          <div class="table-responsive-md">
            <table class="table" aria-describedby="dashboard">
              <thead class="bg-violet">
                <tr>
                  <th scope="col">staff</th>
                  <th scope="col">equipments</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let equipment of missingEquipment
                      | paginate
                        : {
                            currentPage: equipmentPage,
                            itemsPerPage: pagesize,
                            totalItems: equipmentCount,
                            id: 'Equipment'
                          }
                  "
                >
                  <td class="c-pointer" routerLink="/staff-details/{{ equipment.staff_id }}">
                    {{ equipment?.staff_name }}
                  </td>
                  <td class="c-pointer" routerLink="/staff-details/{{ equipment.staff_id }}">
                    {{ equipment?.equipment_name }} ({{ equipment?.tags }})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="bg-white text-center py-4 roaster-pagination" *ngIf="missingEquipment.length > 0">
            <pagination-controls
              (pageChange)="pageChanges($event)"
              maxSize="5"
              previousLabel=""
              nextLabel=""
              autoHide="true"
              id="Equipment"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6 mb-3 mb-xl-0">
      <div class="bg-white">
        <h4 class="mb-0 fs16 p-3">EXPIRY CERTIFICATIONS</h4>
        <div class="table-responsive white-space">
          <table class="table" aria-describedby="dashboard">
            <thead class="bg-violet">
              <tr>
                <th scope="col">Staff</th>
                <th scope="col">Subject</th>
                <th scope="col">Expiry date</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let cerificate of expiryCertificate
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount, id: 'Certificate' }
                "
              >
                <td class="c-pointer" routerLink="/staff-details/{{ cerificate.staff_id }}">
                  {{ cerificate?.staff_name }}
                </td>
                <td class="c-pointer" routerLink="/staff-details/{{ cerificate.staff_id }}">
                  {{ cerificate?.certificate_name }}
                </td>
                <td class="c-pointer" routerLink="/staff-details/{{ cerificate.staff_id }}">
                  {{ cerificate?.expire_date | date: dateFormat }}
                </td>
                <td class="text-center text-danger c-pointer" tooltip="Delete">
                  <em class="fa fa-trash" aria-hidden="true" (click)="deletePopup(delete, cerificate)"></em>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="bg-white text-center py-4 roaster-pagination" *ngIf="expiryCertificate.length > 0">
            <pagination-controls
              (pageChange)="pageChange($event)"
              maxSize="5"
              previousLabel=""
              nextLabel=""
              autoHide="true"
              id="Certificate"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="bg-white">
        <h4 class="mb-0 fs16 p-3">UNSOLVED INCIDENTS</h4>
        <div class="table-responsive white-space">
          <table class="table" aria-describedby="dashboard">
            <thead class="bg-violet">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Location</th>
                <th scope="col">Staff</th>
                <th scope="col">Subject</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let incident of unResolvedIncidents
                    | paginate
                      : { currentPage: incidentPage, itemsPerPage: pagesize, totalItems: incidentCount, id: 'Incident' }
                "
              >
                <tr class="c-pointer" routerLink="/incident-client-detail/{{ incident.id }}">
                  <td>{{ incident?.incident_date | date: dateFormat }}</td>
                  <td>{{ incident?.client_name }}</td>
                  <td>{{ incident?.staff_names }}</td>
                  <td>{{ incident?.incident_name }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div class="bg-white text-center py-4 roaster-pagination" *ngIf="unResolvedIncidents.length > 0">
            <pagination-controls
              (pageChange)="pageChanged($event)"
              maxSize="5"
              previousLabel=""
              nextLabel=""
              autoHide="true"
              id="Incident"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs16font-weight-normal">Delete Certificate</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete certificate?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="modalRef.hide()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteCertificate()"
    >
      Yes
    </button>
  </div>
</ng-template>
