import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-list-data',
  templateUrl: './list-data.component.html',
})
export class ListDataComponent implements OnInit {
  page = 1;
  clientList: any = [];
  pagesize = 10;
  totalCount = 0;
  @Input() search;
  sortFiled;
  sortType: any = '';
  up;
  userGroup = localStorage.getItem('userGroup');
  userId: any = localStorage.getItem('userid');
  showHidden = 0;
  public loading = false;

  constructor(private apiService: ApiService, private router: Router, private toastr: ToastrService) {}

  ngOnInit() {
    this.getClientList();
    this.apiService.searchValue.subscribe((data) => {
      if (data && data.id === 1) {
        this.page = 1;
        this.search = data.value;
        this.showHidden = data.showHidden;
        this.getClientList();
      }
    });
  }
  upside(format, field) {
    if (this.sortType == 'asc') {
      this.sortType = 'desc';
      this.sortFiled = field;
      this.getClientList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getClientList();
    }
  }

  down(format, field) {
    if (this.sortType == 'desc') {
      this.sortType = 'asc';
      this.sortFiled = field;
      this.getClientList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getClientList();
    }
  }

  getClientList() {
    this.loading = true;
    const queryParams = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) {
      queryParams['search'] = this.search;
    }
    if (this.sortType != '' && this.sortFiled != '') {
      queryParams['sort'] = this.sortFiled + ':' + this.sortType;
    }
    queryParams['showHidden'] = this.showHidden;
    this.apiService.get('client', queryParams).subscribe(
      (client: any) => {
        if (client.status === 200) {
          this.clientList = client.clients;
          this.totalCount = client.pageDetails.total;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getClientList();
  }

  viewClientDetails(clientId) {
    if (this.userGroup != 'Staff' && this.userGroup != 'Supervisor') {
      this.router.navigate(['/client-details/' + clientId]);
    }
  }
}
