<div class="card mb-3 border-0">
  <div class="card-header bg-white pl-4 pr-2">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 bd-highlight">
        <h6 class="text-dark font-weight-bold fs16 mb-0">BLACKLISTING</h6>
      </div>
      <div class="bd-highlight mr-3">
        <button type="button" class="btn btn-primary btn-more" tooltip="Add" (click)="openModal(template1)">
          <em class="fas fa-plus mr-2"></em>Add
        </button>
        <ng-template #template1 class="modal-content template-4">
          <div class="modal-header bg-primary">
            <h4 class="modal-title w-100 text-center fs18 font-weight-normal text-white">Blacklist a staff member</h4>
            <button
              aria-label="Close"
              class="close text-white font-weight-normal op1"
              type="button"
              aria-label="Close"
              (click)="clearForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="font-weight-bold">Blacklisting</p>
            <p class="text-secondary">Please select the employee who is undesired for any new shifts.</p>
            <p class="text-primary">
              NOTE : This will only effect future shifts. Please remove the employee from existing shifts manually (if
              desired).
            </p>
            <form>
              <p class="font-weight-bold">Staff</p>
              <div class="form-group">
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="blackStaff"
                  name="blackStaff"
                  (change)="changeAction()"
                  [ngClass]="{ 'invalid-field': showError && isSubmit }"
                >
                  <option value="" selected disabled>--Select Staff--</option>
                  <option [ngValue]="staff.id" *ngFor="let staff of staffList">
                    {{ staff?.firstName }} {{ staff?.surName }}
                  </option>
                </select>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-4 mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary bg-secondary text-white mr-2"
                    tooltip="Cancel"
                    (click)="clearForm()"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    [disabled]="isSubmit"
                    tooltip="Add"
                    class="btn btn-primary"
                    (click)="createBlackList()"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ng-template>
      </div>
      <div class="bd-highlight"><em class="fas fa-compress circle-icon compress-icon"></em></div>
    </div>
  </div>
  <div class="card-body text-dark">
    <ul class="list-unstyled">
      <li class="mb-5">
        <div class="row d-flex align-items-center" *ngFor="let list of blackList">
          <div class="col-md-10 col-10 d-flex align-items-center">
            <div class="fa-blacklisting">
              <h2 class="text-dark fs16 mt-2">{{ list?.staff?.firstName }} {{ list?.staff?.surName }}</h2>
            </div>
          </div>
          <div class="col-md-1 col-1"></div>
        </div>
        <div class="row d-flex align-items-center" *ngFor="let list of locationBlackList">
          <div class="col-md-10 col-10 d-flex align-items-center">
            <div class="fa-blacklisting">
              <h2 class="text-dark fs16 mt-2">{{ list?.staff?.firstName }} {{ list?.staff?.surName }}</h2>
            </div>
          </div>
          <div class="col-md-1 col-1">
            <em class="fas fa-trash delete-icon c-pointer" tooltip="Delete" (click)="deleteList(delete, list)"></em>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="blackList?.length === 0 && locationBlackList?.length === 0">
      <p>No blacklistings recorded. Please use the plus icon to blacklist a staff member.</p>
    </div>
  </div>
</div>
<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title">Delete Blacklist</h4>
    <button type="button" class="close" aria-label="Close" (click)="clearForm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete the blacklist?</p>
    <button type="button" class="button-value mx-2" (click)="clearForm()">No</button>
    <button type="button" class="button-danger mx-2" (click)="deleteBlackList()">Yes</button>
  </div>
</ng-template>
