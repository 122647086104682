<div class="card mb-3 border-0">
  <div class="card-header bg-white px-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-self-center">
        <h6 class="text-dark font-weight-bold fs16 mb-0">EVENTS</h6>
      </div>
      <button type="button" class="btn btn-primary btn-more" (click)="openModal(template3)" *ngIf="!incidentStatus">
        <em class="fas fa-plus fs12 c-pointer"></em> Add
      </button>

      <ng-template #template3 class="modal-content">
        <div class="modal-header bg-primary">
          <h4 class="modal-title fs16 text-center text-white">Add an event to the incident</h4>
          <button type="button" class="close pull-right text-white" aria-label="Close" (click)="uploadCancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <p class="font-weight-bold">Event</p>
            <p class="text-secondary">
              The form below allows you to add an event to the incident. With each event you can upload one or more
              files to attach to the incident.
            </p>
          </div>
          <form [formGroup]="incidentsForm" (ngSubmit)="createIncidents()">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label
                  for="inputEmail4"
                  class="font-weight-bold"
                  [ngClass]="{ 'text-danger': isSubmitted && f.subject.errors }"
                  >Subject</label
                >
                <input
                  type="text"
                  placeholder=""
                  class="form-control"
                  formControlName="subject"
                  (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                  [ngClass]="{ 'invalid-field': isSubmitted && f.subject.errors }"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1" class="font-weight-bold">Description</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                formControlName="description"
                placeholder="Type here"
                (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
              ></textarea>
            </div>
            <div class="form-group">
              <form>
                <label class="font-weight-bold">Event Image</label>
                <div class="image-upload-wrap">
                  <div
                    class="drop-container"
                    ngFileDrop
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    [ngClass]="{ 'is-drop-over': dragOver }"
                  >
                    <input
                      class="file-upload-input"
                      type="file"
                      ngFileSelect
                      [options]="options"
                      (uploadOutput)="onUploadOutput($event)"
                      [uploadInput]="uploadInput"
                      hidden
                      #uploader1
                      multiple
                    />
                    <div class="drag-text text-center color-dark-blue">
                      <h3 class="fs18 mb-0">
                        <span class="op-25">Drag and Drop your Files here<span class="drag-or">Or</span> </span
                        ><button class="btn btn-secondary btn-create border-0 fs14 my-2" (click)="uploader1.click()">
                          Upload your Files
                        </button>
                      </h3>
                    </div>
                  </div>
                  <!-- Start File list -->
                  <ul class="list-group mt-4 px-3">
                    <li class="list-group-item my-2" *ngFor="let fileInfo of uploadfileList; let i = index">
                      <div class="media">
                        <img
                          src="{{ fileUrl }}{{ fileInfo?.file_path }}"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo?.file_path && fileInfo.type === 'image'"
                        />
                        <img
                          [src]="fileInfo.imageSrc"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="!fileInfo?.file_path && fileInfo.type === 'image'"
                        />

                        <img
                          src="./assets/images/file-icons/audio.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo.type === 'audio'"
                        />
                        <img
                          src="./assets/images/file-icons/video.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo.type === 'video'"
                        />

                        <ng-container *ngIf="fileInfo.type === 'document'">
                          <img
                            src="./assets/images/file-icons/pdf.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'pdf'"
                          />
                          <img
                            src="./assets/images/file-icons/excel.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'xlsx' || fileInfo.format === 'xls' || fileInfo.format === 'csv'"
                          />
                          <img
                            src="./assets/images/file-icons/word.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'doc' || fileInfo.format === 'docx'"
                          />
                          <img
                            src="./assets/images/file-icons/ppt.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'ppt' || fileInfo.format === 'pptx'"
                          />

                          <img
                            src="./assets/images/file-icons/document.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="
                              fileInfo.format != 'pdf' &&
                              fileInfo.format != 'xlsx' &&
                              fileInfo.format != 'xls' &&
                              fileInfo.format != 'csv' &&
                              fileInfo.format != 'doc' &&
                              fileInfo.format != 'docx' &&
                              fileInfo.format != 'ppt' &&
                              fileInfo.format != 'pptx'
                            "
                          />
                        </ng-container>

                        <img
                          src="./assets/images/file-icons/document.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="
                            fileInfo.type !== 'video' &&
                            fileInfo.type !== 'image' &&
                            fileInfo.type !== 'document' &&
                            fileInfo.type !== 'audio'
                          "
                        />

                        <div class="media-body align-self-center text-left">
                          <h1 class="fs18 color-light-black mt-0">{{ fileInfo?.name }}</h1>
                          <progressbar [value]="progress[i]?.progress" [striped]="true" class="w-50"
                            >{{ progress[i]?.progress }}%</progressbar
                          >
                        </div>
                        <div class="align-self-center">
                          <em class="far fa-times-circle c-pointer fs20" (click)="removeUploadFile(fileInfo, i)"></em>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- End File list -->
                </div>
              </form>
              <div *ngIf="fileError">
                <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
                <p class="text-danger" *ngIf="fileFormatError">Valid extenstions {{ supportFormat }}</p>
              </div>
            </div>
            <div class="float-right p-2">
              <ul class="d-inline-flex list-unstyled">
                <li class="mr-2">
                  <button
                    type="button"
                    class="btn btn-primary bg-secondary text-white"
                    tooltip="Cancel"
                    (click)="uploadCancel()"
                  >
                    Cancel
                  </button>
                </li>
                <li><button type="submit" [disabled]="loading" tooltip="Save" class="btn btn-primary">Save</button></li>
              </ul>
            </div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="card-body text-dark">
    <div class="media mb-4" *ngFor="let report of eventList">
      <div class="media-body">
        <div class="bg-icon-alert">
          <h2 class="color-light-black fs18 font-weight-normal mb-0">
            {{ report.subject }} by {{ report?.user?.firstName }} {{ report?.user?.surName }} ({{
              report?.created_at | dateTimezoneConvert
            }})
          </h2>

          <textarea class="form-control border-0 bg-white no-resizer color-light-black fs12 op-32" autosize disabled>
            {{ report?.description || '-' }}
          </textarea>
          <div class="mt-3" *ngIf="report.files && report.files.length > 0">
            <ng-container *ngFor="let files of report.files">
              <img
                src="{{ fileUrl }}{{ files?.file?.file_path }}"
                class="mr-3 incident-event-img c-pointer"
                alt="Event Image"
                *ngIf="files?.file?.type === 'image'"
                (click)="viewFile(files?.file?.file_path)"
              />
              <a
                href="javascript:void(0)"
                class="mr-3 incident-event-img c-pointer"
                *ngIf="files?.file?.type != 'image'"
                (click)="viewFile(files?.file?.file_path)"
                >{{ files?.file?.name }}</a
              >
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
