import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
var CertificationsComponent = /** @class */ (function () {
    function CertificationsComponent(modalService, apiService, toastr, formBuilder) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.certificateList = [];
        this.submitted = false;
        this.title = 'Add';
        this.page = 1;
        this.pagesize = 10;
        this.search = '';
        this.isLoading = false;
        this.sortType = '';
        this.isSubmitEnable = false;
        this.loading = false;
    }
    Object.defineProperty(CertificationsComponent.prototype, "f", {
        get: function () {
            return this.certificateForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    // Add Certification
    CertificationsComponent.prototype.addCertificationModal = function (template, mode) {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg modal-dialog-centered roaster-modal',
        });
        if (mode === 'A') {
            this.certificateForm.reset();
            this.submitted = false;
        }
    };
    CertificationsComponent.prototype.onValueChange = function (data) {
        var _this = this;
        this.certificateForm.valueChanges.subscribe(function (val) {
            var changesExist = false;
            if (val.name !== data.name) {
                changesExist = true;
            }
            if (val.notes !== data.notes) {
                changesExist = true;
            }
            if (changesExist) {
                _this.isSubmitEnable = true;
            }
            else {
                _this.isSubmitEnable = false;
            }
        });
    };
    CertificationsComponent.prototype.editCertificationModal = function (template, value) {
        this.isSubmitEnable = false;
        this.certificateForm = this.formBuilder.group({
            id: value.id,
            name: value.name,
            notes: value.notes,
        });
        this.title = 'Edit';
        this.onValueChange(value);
        this.addCertificationModal(template, 'E');
    };
    CertificationsComponent.prototype.openDeleteModal = function (deleteCertificate, value) {
        this.selectedCertificate = value;
        this.modalRef = this.modalService.show(deleteCertificate, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-dialog-centered roaster-modal',
        });
    };
    // Certificate List
    CertificationsComponent.prototype.getCertificateList = function () {
        var _this = this;
        this.loading = true;
        var params = { page: this.page, pagesize: this.pagesize };
        if (this.search && this.search.length > 0)
            params['search'] = this.search;
        if (this.sortType != '' && this.sortFiled != '') {
            params['sort'] = this.sortFiled + ':' + this.sortType;
        }
        this.apiService.get('certificate/list', params).subscribe(function (data) {
            if (data['status'] === 200) {
                _this.certificateList = data.certificates;
                var pageDetails = data.pageDetails;
                _this.totalCount = pageDetails['total'];
            }
            else {
                _this.certificateList = [];
                _this.totalCount = 0;
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
        });
    };
    CertificationsComponent.prototype.pageChange = function (newPage) {
        this.page = newPage;
        this.getCertificateList();
    };
    CertificationsComponent.prototype.searchText = function (value) {
        this.search = value;
        this.page = 1;
        this.getCertificateList();
    };
    CertificationsComponent.prototype.upside = function (format, field) {
        this.sortType = format;
        this.sortFiled = field;
        this.getCertificateList();
    };
    CertificationsComponent.prototype.downSide = function (format, field) {
        this.sortType = format;
        this.sortFiled = field;
        this.getCertificateList();
    };
    CertificationsComponent.prototype.omit_special_char = function (event) {
        var k = event.charCode;
        var value = event.target.value;
        if (value.length === 0) {
            if (k === 32) {
                return false;
            }
        }
        else {
            return ((k > 64 && k < 91) ||
                (k > 96 && k < 123) ||
                (k >= 44 && k <= 57) ||
                (k >= 96 && k <= 105) ||
                [13, 39, 32, 63].includes(k));
        }
    };
    // Add and Edit certificate Form
    CertificationsComponent.prototype.addEditCertificate = function () {
        var _this = this;
        this.submitted = true;
        if (this.certificateForm.invalid) {
            return;
        }
        var certificateData = this.certificateForm.value;
        if (!this.certificateForm.value.id) {
            this.isLoading = true;
            this.apiService.post('certificate', certificateData).subscribe(function (data) {
                if (data.status === 200) {
                    _this.toastr.success(data.message);
                    _this.closeForm();
                    _this.getCertificateList();
                }
                _this.isLoading = false;
            }, function (err) {
                if (err.error.message) {
                    _this.toastr.error(err.error.message);
                }
                _this.isLoading = false;
            });
        }
        else {
            this.isLoading = true;
            this.apiService.put('certificate/update/' + certificateData.id, certificateData).subscribe(function (editData) {
                if (editData.status === 200) {
                    _this.toastr.success(editData.message);
                    _this.closeForm();
                    _this.getCertificateList();
                }
                _this.isLoading = false;
            }, function (errMsg) {
                if (errMsg.error.message) {
                    _this.toastr.error(errMsg.error.message);
                }
                _this.isLoading = false;
            });
        }
    };
    // Delete certificate
    CertificationsComponent.prototype.deleteCertification = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.delete('certificate/delete/' + this.selectedCertificate.id).subscribe(function (deletefile) {
            _this.toastr.clear();
            if (deletefile.status === 200) {
                _this.toastr.success(deletefile.message);
                _this.closeForm();
                _this.getCertificateList();
            }
            else {
                _this.toastr.error(deletefile.message);
            }
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            _this.toastr.error(err.message);
        });
    };
    CertificationsComponent.prototype.closeForm = function () {
        this.submitted = false;
        this.selectedCertificate = {};
        this.certificateForm.reset(this.createForm());
        this.modalRef.hide();
        this.isLoading = false;
        this.title = 'Add';
    };
    CertificationsComponent.prototype.createForm = function () {
        this.certificateForm = this.formBuilder.group({
            id: [''],
            name: ['', Validators.required],
            notes: [''],
        });
    };
    CertificationsComponent.prototype.ngOnInit = function () {
        this.getCertificateList();
        this.createForm();
    };
    return CertificationsComponent;
}());
export { CertificationsComponent };
