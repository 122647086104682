import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
})
export class EquipmentsComponent implements OnInit {
  modalRef: BsModalRef;
  equipmentList = [];
  equipmentForm: FormGroup;
  submitted = false;
  selectedEquipment: any;
  page = 1;
  pagesize = 10;
  totalCount = 0;
  search: string;
  sortType: any = '';
  sortFiled;
  title = 'Add';
  isLoading = false;
  isSubmitEnable = false;
  domainInfo: any = {};
  dateFormat;
  public loading = false;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  get f() {
    return this.equipmentForm.controls;
  }

  //Add Equipment
  addEquipmentModal(template: TemplateRef<any>, mode) {
    // this.title = 'Add';
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
    if (mode === 'A') {
      this.equipmentForm.reset();
      this.submitted = false;
    }
  }

  editEquipmentModal(template, value) {
    this.isSubmitEnable = false;
    this.equipmentForm = this.formBuilder.group({
      id: value.id,
      name: value.name,
      inventory: value.inventory,
      equipment_size: value.size,
    });
    this.title = 'Edit';
    this.onValueChange(value);
    this.addEquipmentModal(template, 'E');
  }

  openDeleteModal(deleteCertificate: TemplateRef<any>, value) {
    this.selectedEquipment = value;
    this.modalRef = this.modalService.show(deleteCertificate, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered roaster-modal',
    });
  }

  // Equipment List
  getEquipmentList() {
    this.loading = true;
    const queryParams = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) {
      queryParams['search'] = this.search;
    }
    if (this.sortType != '' && this.sortFiled != '') {
      queryParams['sort'] = this.sortFiled + ':' + this.sortType;
    }
    this.apiService.get('equipment/list', queryParams).subscribe(
      (data: any) => {
        this.equipmentList = data.equipments;
        this.totalCount = data.pageDetails.total;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getEquipmentList();
  }

  upside(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getEquipmentList();
  }

  downSide(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getEquipmentList();
  }

  onValueChange(data) {
    this.equipmentForm.valueChanges.subscribe((val) => {
      let changesExist = false;
      if (val.name !== data.name) {
        changesExist = true;
      }
      if (val.inventory !== data.inventory) {
        changesExist = true;
      }
      if (val.equipment_size !== data.size) {
        changesExist = true;
      }
      if (changesExist) {
        this.isSubmitEnable = true;
      } else {
        this.isSubmitEnable = false;
      }
    });
  }

  // Add and Edit certificate Form
  addEditEquipment() {
    this.submitted = true;
    if (this.equipmentForm.invalid) {
      return;
    }
    const equipmentData = this.equipmentForm.value;
    if (!this.equipmentForm.value.id) {
      this.isLoading = true;
      this.apiService.post('equipment', equipmentData).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastr.success(data.message);
            this.getEquipmentList();
            this.clearForm();
          }
          this.isLoading = false;
        },
        (err) => {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
          this.isLoading = false;
        },
      );
    } else {
      this.isLoading = true;
      this.apiService.put('equipment/update/' + equipmentData.id, equipmentData).subscribe(
        (editData: any) => {
          if (editData.status === 200) {
            this.toastr.success(editData.message);
            this.getEquipmentList();
            this.clearForm();
          }
          this.isLoading = false;
        },
        (errMsg) => {
          if (errMsg.error.message) {
            this.toastr.error(errMsg.error.message);
          }
          this.isLoading = false;
        },
      );
    }
  }

  omit_special_char(event) {
    const k: any = event.charCode;
    const value: any = event.target.value;
    if (value.length === 0) {
      if (k === 32) {
        return false;
      }
    } else {
      return (
        (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k >= 96 && k <= 105) || k == 32 || k == 13
      );
    }
  }

  clearForm() {
    this.submitted = false;
    this.equipmentForm.reset();
    this.selectedEquipment = {};
    this.modalRef.hide();
    this.title = 'Add';
    this.isLoading = false;
  }

  // Delete certificate
  deleteEquipmentData() {
    this.isLoading = true;
    this.apiService.delete('equipment/delete/' + this.selectedEquipment.id).subscribe(
      (deletefile: any) => {
        this.toastr.clear();
        if (deletefile.status === 200) {
          this.toastr.success(deletefile.message);
          this.modalRef.hide();
          this.getEquipmentList();
        } else {
          this.toastr.error(deletefile.message);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(err.message);
      },
    );
  }

  createForm() {
    this.equipmentForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      inventory: ['', Validators.required],
      equipment_size: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getEquipmentList();
    this.createForm();
    this.page = 1;
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }
}
