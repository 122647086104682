<ngx-loading [show]="loader" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="getCurrentWeekschedule()">
      <a>Scheduler</a>
    </li>
    <li *ngIf="eventName" class="breadcrumb-item fs16 fw500 c-pointer" (click)="getCurrentWeekschedule()">
      <a>{{ eventName }}</a>
    </li>
  </ol>
</nav>

<div class="container-fluid px-0 schedule-layout">
  <div class="card bg-gray border-0">
    <div class="card-body">
      <div class="position-relative mt-md-5">
        <div class="row align-items-center roaster-date roaster-body py-2">
          <div class="col-xl-3 mb-5 mb-md-3 mb-xl-0">
            <select
              class="form-control fs14 font-weight-bold selected-item"
              [(ngModel)]="staffId"
              (change)="handleChange()"
              *ngIf="userGroup != 'Staff'"
            >
              <option selected value="">All</option>
              <option selected value="null">Unschedule</option>
              <option [ngValue]="staff.id" *ngFor="let staff of staffList">
                {{ staff?.firstName }} {{ staff?.surName }}
              </option>
            </select>
          </div>
          <div class="col-xl-5 mb-3 mb-xl-0">
            <em class="fas fa-angle-left mx-2 custom-icon c-pointer" (click)="getWeekDays('Sub')"></em>
            {{ startOfWeek }} - {{ endOfWeek }}
            <em class="fas fa-angle-right mx-2 custom-icon c-pointer" (click)="getWeekDays('Add')"></em>
          </div>
          <div class="col-xl-4">
            <div class="d-flex align-items-center">
              <label class="font-weight-bold mb-0 mr-2">Jump:</label>
              <div class="input-group input-group float-right">
                <input
                  class="form-control color-dark-blue"
                  #datepickerFull="bsDatepicker"
                  bsDatepicker
                  [(ngModel)]="bsValue"
                  [maxDate]="maxDate"
                  [bsConfig]="{ selectFromOtherMonth: true, showWeekNumbers: false, dateInputFormat: jumpDateFormat }"
                  placeholder="Select date"
                  (bsValueChange)="onDateSelect($event)"
                  (click)="shwWarn(!datepickerFull.isOpen)"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text bg-transparent calender-icon"
                    (click)="datepickerFull.toggle(); shwWarn(datepickerFull.isOpen)"
                    [attr.aria-expanded]="datepickerFull.isOpen"
                  >
                    <img src="assets/images/calendar.svg" alt="calendar" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative mt5">
        <!-- scheduler-table -->
        <div class="table-responsive scheduler-table">
          <table class="table table-bordered schedule-table" aria-describedby="schedule">
            <caption class="d-none">
              scheduler table
            </caption>
            <thead class="border-bottom">
              <tr class="">
                <td></td>
                <ng-container *ngFor="let day of weekDays; let i = index">
                  <td class="" [ngClass]="{ 'bg-dark-blue color-white': today === weekMomentDays[i] }">
                    {{ day | date: 'EEE' }}
                    <br />
                    <span>{{ day | date: 'dd' }}</span>
                  </td>
                </ng-container>
              </tr>
            </thead>
            <tbody class="bg-white">
              <ng-container *ngFor="let scheduleInfo of scheduleList">
                <tr
                  class="mb-57px"
                  [ngClass]="
                    '#e74c3c' == scheduleInfo.background_color
                      ? 'bg-1'
                      : '#e67e22' === scheduleInfo.background_color
                      ? 'bg-2'
                      : '#f39c12' === scheduleInfo.background_color
                      ? 'bg-3'
                      : '#34495e' === scheduleInfo.background_color
                      ? 'bg-4'
                      : '#8e44ad' === scheduleInfo.background_color
                      ? 'bg-5'
                      : '#3498db' === scheduleInfo.background_color
                      ? 'bg-6'
                      : '#27ae60' === scheduleInfo.background_color
                      ? 'bg-7'
                      : '#16a085' === scheduleInfo.background_color
                      ? 'bg-8'
                      : 'bg-1'
                  "
                >
                  <td colspan="8" class="tbody-title schedule-head">
                    {{ scheduleInfo?.clientName }}
                  </td>
                </tr>
                <tr *ngFor="let schedule of scheduleInfo.data">
                  <td>
                    <span class="color-light-black d-block text-truncate" tooltip="{{ schedule?.location }}">{{
                      schedule?.location
                    }}</span>
                    <a
                      class="color-dark-blue position-relative"
                      href="javascript:void(0)"
                      *ngIf="schedule.isSublocation"
                    >
                      <em class="fas fa-arrow-circle-right position-absolute mt-1"></em>
                      <span class="pl-4 d-inline-block" tooltip="{{ schedule?.sublocation }}">
                        {{ schedule?.sublocation }}</span
                      >
                    </a>
                  </td>
                  <ng-container
                    *ngFor="let scheduler of schedule.data | eventArrayModifier: weekMomentDays; let i = index"
                  >
                    <td class="jr-plan" *ngIf="scheduler">
                      <ul class="list-unstyled">
                        <li
                          class="mb-2 position-relative"
                          dropdown
                          *ngFor="let shift of scheduler.data"
                          [tooltip]="template"
                        >
                          <ng-template #template>
                            <li>Code: {{ shift.code }}</li>
                            <li>Info: {{ shift.additional_notes }}</li>
                            <li>Added by {{ shift.createdByName }}</li>
                            <li>Created Date: {{ shift.createdAtDate }}</li>
                            <li>Created Time: {{ shift.createdAtTime }}</li>
                          </ng-template>
                          <div
                            class="{{ shift?.className }}"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="shift?.event !== null && shift?.event !== undefined"
                            (click)="
                              redirectEventUrl(
                                'scheduler-event/' + encodeURL(shift.event),
                                false,
                                shift,
                                schedule.client_id,
                                schedule.sublocationId,
                                schedule.locationId
                              )
                            "
                          >
                            <span class="d-block fs12">{{ shift?.event }}</span>
                          </div>
                          <div
                            class="card-green p-2 rounded c-pointer"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="!isEvent(shift) && shift?.status === 3"
                          >
                            <span class="d-block fs12"
                              >{{
                                timeFormat === '12HoursFormat'
                                  ? tConvert(
                                      (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                                    )
                                  : (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                              }}
                              -
                              {{
                                timeFormat === '12HoursFormat'
                                  ? tConvert((shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1])
                                  : (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1]
                              }}
                            </span>
                            <span class="d-block fs12 font-weight-bold">{{ shift.staff_Name }}</span>
                          </div>
                          <div
                            class="card-yello p-2 rounded c-pointer"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="!isEvent(shift) && shift?.status === 2"
                          >
                            <span class="d-block fs12"
                              >{{
                                timeFormat === '12HoursFormat'
                                  ? tConvert(
                                      (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                                    )
                                  : (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                              }}
                              -
                              {{
                                timeFormat === '12HoursFormat'
                                  ? tConvert((shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1])
                                  : (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1]
                              }}
                            </span>
                            <span class="d-block fs12 font-weight-bold">{{ shift?.staff_Name }}</span>
                          </div>
                          <div
                            class="card-red p-2 rounded c-pointer"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="!isEvent(shift) && shift?.status === 1"
                          >
                            <span class="d-block fs12"
                              >{{
                                timeFormat === '12HoursFormat'
                                  ? tConvert(
                                      (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                                    )
                                  : (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                              }}
                              -
                              {{
                                timeFormat === '12HoursFormat'
                                  ? tConvert((shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1])
                                  : (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1]
                              }}
                            </span>
                            <span class="d-block fs12 font-weight-bold">{{ shift?.staff_Name }}</span>
                          </div>
                          <div
                            class="card-blue p-2 rounded c-pointer"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="
                              !isEvent(shift) &&
                              shift.status != 1 &&
                              shift.status != 2 &&
                              shift?.status != 3 &&
                              shift?.status != 5
                            "
                          >
                            <span class="d-block fs12"
                              >{{
                                timeFormat === '12HoursFormat'
                                  ? tConvert(
                                      (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                                    )
                                  : (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                              }}
                              -
                              {{
                                timeFormat === '12HoursFormat'
                                  ? tConvert((shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1])
                                  : (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1]
                              }}
                            </span>
                            <span class="d-block fs12 font-weight-bold">Unschedule</span>
                          </div>
                          <div
                            class="card-grey p-2 rounded c-pointer"
                            dropdownToggle
                            (click)="dropdownToggleClick()"
                            *ngIf="!isEvent(shift) && shift?.status === 5"
                          >
                            <span class="d-block fs12"
                              >{{
                                timeFormat === '12HoursFormat'
                                  ? tConvert(
                                      (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                                    )
                                  : (shift?.start_time.split(':'))[0] + ':' + (shift?.start_time.split(':'))[1]
                              }}
                              -
                              {{
                                timeFormat === '12HoursFormat'
                                  ? tConvert((shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1])
                                  : (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1]
                              }}
                            </span>
                            <span class="d-block fs12 font-weight-bold">{{ shift?.staff_Name }}</span>
                          </div>
                          <ul
                            *dropdownMenu
                            class="dropdown-menu dropdown-menu-right custom-dd-menu"
                            role="menu"
                            aria-labelledby="button-basic"
                          >
                            <li
                              *ngIf="
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor')
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler/staff-on-shift/',
                                    shift.id,
                                    shift.index,
                                    false,
                                    shift.work_date,
                                    scheduler.keyDate,
                                    shift?.end_time
                                  )
                                "
                              >
                                <img
                                  src="./assets/images/schedule-dropdown-icons/user-plus.svg"
                                  class="pr-2"
                                  alt="icon"
                                />
                                <span class="dd-txt">Roster shift</span>
                              </a>
                            </li>
                            <li
                              *ngIf="
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor')
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler/staff-on-shift/',
                                    shift.id,
                                    shift.index,
                                    true,
                                    shift.work_date,
                                    scheduler.keyDate,
                                    shift?.end_time
                                  )
                                "
                              >
                                <img
                                  src="./assets/images/schedule-dropdown-icons/arrow-cross.svg"
                                  class="pr-2"
                                  alt="icon"
                                />
                                <span class="dd-txt"></span>Roster all shifts</a
                              >
                            </li>
                            <li
                              *ngIf="
                                shift.status === 1 &&
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced')
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAction(shift, 'Inquire', scheduler.keyDate)"
                              >
                                <em class="fa fa-phone" aria-hidden="true"></em>
                                <span class="dd-txt"></span>Inquire shift
                              </a>
                            </li>
                            <li
                              *ngIf="
                                shift.status === 1 &&
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced')
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAllAction(shift, 'Inquire', schedule.client_id, scheduler.keyDate)"
                              >
                                <em class="fa fa-phone" aria-hidden="true"></em>
                                <span class="dd-txt"></span>Inquire all similar shifts</a
                              >
                            </li>
                            <li
                              *ngIf="
                                shift.status === 2 &&
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced' || userId === shift.staff_id)
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAction(shift, 'Confirm', scheduler.keyDate)"
                              >
                                <em class="fa fa-check pr-2" aria-hidden="true"></em>
                                <span class="dd-txt"></span>Confirm shift
                              </a>
                            </li>
                            <li
                              *ngIf="
                                shift.status === 2 &&
                                checkShiftEndTime(scheduler.keyDate, shift) &&
                                (userGroup === 'Full access' || userGroup === 'Advanced' || userId === shift.staff_id)
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAllAction(shift, 'Confirm', schedule.client_id, scheduler.keyDate)"
                              >
                                <em class="fa fa-check pr-2" aria-hidden="true"></em>
                                <span class="dd-txt"></span>Confirm all similar shifts</a
                              >
                            </li>
                            <li
                              *ngIf="
                                shift.permanent === 0 &&
                                shift.status !== 5 &&
                                shift.staff_id &&
                                ((scheduler.keyDate === today &&
                                  (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1] >
                                    currentTime) ||
                                  scheduler.keyDate > today ||
                                  (getNextShiftDate(shift) >= today &&
                                    getNextShiftDate(shift) + ' ' + shift.end_time >= nextDaycurrentTime)) &&
                                (userGroup === 'Full access' ||
                                  userGroup === 'Advanced' ||
                                  userGroup === 'Supervisor' ||
                                  (userGroup === 'Staff' && shift.staff_id === userId && shift.status === 2))
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAction(shift, 'Decline', scheduler.keyDate)"
                              >
                                <em class="fas fa-ban circle-icon ban-icon"></em>
                                <span class="dd-txt"></span>Decline</a
                              >
                            </li>

                            <li
                              *ngIf="
                                shift.permanent === 1 &&
                                shift.status !== 5 &&
                                shift.staff_id &&
                                ((scheduler.keyDate === today &&
                                  (shift?.end_time.split(':'))[0] + ':' + (shift?.end_time.split(':'))[1] >
                                    currentTime) ||
                                  scheduler.keyDate > today ||
                                  (getNextShiftDate(shift) >= today &&
                                    getNextShiftDate(shift) + ' ' + shift.end_time >= nextDaycurrentTime)) &&
                                (userGroup === 'Full access' ||
                                  userGroup === 'Advanced' ||
                                  userGroup === 'Supervisor' ||
                                  (userGroup === 'Staff' && shift.staff_id === userId && shift.status === 2))
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAction(shift, 'Decline', scheduler.keyDate)"
                                *ngIf="
                                  userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor'
                                "
                              >
                                <em class="fas fa-ban circle-icon ban-icon"></em>
                                <span class="dd-txt"></span>Decline</a
                              >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="shiftAction(shift, 'Decline', scheduler.keyDate)"
                                *ngIf="userGroup === 'Staff' && shift.staff_id === userId && shift.status === 2"
                              >
                                <em class="fas fa-ban circle-icon ban-icon"></em>
                                <span class="dd-txt"></span>Decline</a
                              >
                            </li>
                            <li
                              *ngIf="
                                scheduler.keyDate >= lastEditDate &&
                                shift.isAllowedEdit === true &&
                                (userGroup === 'Full access' || userGroup === 'Advanced')
                              "
                            >
                              <a
                                href="javascript:void(0)"
                                (click)="openEditShiftModal(editShift, shift, false, scheduler.keyDate)"
                                class="dropdown-item"
                              >
                                <img src="./assets/images/schedule-dropdown-icons/pencil.svg" class="pr-2" alt="icon" />
                                <span class="dd-txt"></span>Edit shift</a
                              >
                            </li>
                            <li
                              *ngIf="
                                scheduler.keyDate > today &&
                                shift.isAllowedEdit === true &&
                                (userGroup === 'Full access' || userGroup === 'Advanced')
                              "
                            >
                              <a
                                class="dropdown-item pl-3"
                                href="javascript:void(0)"
                                (click)="openEditShiftModal(editShift, shift, true, scheduler.keyDate)"
                              >
                                <em class="fa fa-cog" aria-hidden="true"></em>
                                <span class="dd-txt"></span>Edit all similar shifts
                              </a>
                            </li>
                            <li
                              *ngIf="
                                scheduler.keyDate >= lastEditDate &&
                                (userGroup === 'Full access' || userGroup === 'Advanced')
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler/delete-tracking/',
                                    shift.id,
                                    shift.index,
                                    false,
                                    shift.work_date,
                                    null,
                                    null
                                  )
                                "
                              >
                                <img src="./assets/images/schedule-dropdown-icons/trash.svg" class="pr-2" alt="icon" />
                                <span class="dd-txt"></span>Delete shift</a
                              >
                            </li>
                            <li
                              *ngIf="
                                scheduler.keyDate >= today &&
                                (userGroup === 'Full access' || userGroup === 'Advanced') &&
                                shift.status !== 5
                              "
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler/delete-tracking/',
                                    shift.id,
                                    shift.index,
                                    true,
                                    shift.work_date,
                                    null,
                                    null
                                  )
                                "
                              >
                                <img src="./assets/images/schedule-dropdown-icons/trash.svg" class="pr-2" alt="icon" />
                                <span class="dd-txt"></span>Delete all shifts</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler-history/',
                                    shift.id,
                                    shift.index,
                                    false,
                                    scheduler.keyDate,
                                    null,
                                    null
                                  )
                                "
                              >
                                <img
                                  src="./assets/images/schedule-dropdown-icons/history.svg"
                                  class="pr-2"
                                  alt="icon"
                                />
                                <span class="dd-txt">History</span>
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                (click)="
                                  redirectUrl(
                                    '/scheduler/time-tracking/',
                                    shift.shiftId,
                                    shift.index,
                                    false,
                                    scheduler.keyDate,
                                    null,
                                    null
                                  )
                                "
                              >
                                <img src="./assets/images/schedule-dropdown-icons/clock.svg" class="pr-2" alt="icon" />
                                <span class="dd-txt">Time Tracking</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <p class="text-center" *ngIf="scheduleList.length === 0">
            Currently there are no shifts scheduled for this week.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Start Edit Shift-->
<ng-template #editShift>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Edit Shift</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="cancelEdit()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <h1 class="color-black op-09 fs16">Edit Shift</h1>
      <p class="color-black op-05 fs14 mb-0">
        Limited information can be changed once a shift has been created. You will find the allowed options in the form
        below. If you need to make other changes you will need to delete the shift(s) and recreate the shift(s) new.
      </p>
      <form class="modal-form mt-3" [formGroup]="shiftForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <div class="col-md-6">
            <label>Code</label>
            <input type="text" class="form-control" formControlName="code" />
          </div>
          <div class="col-md-6">
            <label>Additional notes</label>
            <input type="text" class="form-control" formControlName="additional_notes" />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-6">
            <label [ngClass]="{ 'text-danger': submitted && f.startHr.errors }">Work - Start-Time</label>
            <div class="row">
              <div class="col-md-5">
                <select
                  id="st"
                  class="form-control"
                  formControlName="startHr"
                  (change)="selectStartTime($event.target.value)"
                >
                  <ng-container *ngFor="let startHr of hourList">
                    <option [ngValue]="startHr" *ngIf="+startHr > +showHour">{{ startHr }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-5">
                <select
                  id="st1"
                  class="form-control"
                  formControlName="startMin"
                  (change)="selectStartMin($event.target.value)"
                >
                  <ng-container *ngFor="let startMin of minitsList">
                    <option [ngValue]="startMin">{{ startMin }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label [ngClass]="{ 'text-danger': submitted && f.endHr.errors }">Work - End Time</label>
            <div class="row">
              <div class="col-md-5">
                <select
                  id="st"
                  class="form-control"
                  formControlName="endHr"
                  (change)="selectendHr($event.target.value)"
                  [ngClass]="{ 'invalid-field': submitted && f.endHr.errors }"
                >
                  <ng-container *ngFor="let endHr of endHourList">
                    <option [ngValue]="endHr" *ngIf="+endHr > +showHour">{{ endHr }}</option>
                  </ng-container>
                </select>
              </div>
              <div class="col-md-5">
                <select
                  id="st1"
                  class="form-control"
                  formControlName="endMin"
                  (change)="onEndMinSelection($event.target.value)"
                  [ngClass]="{ 'invalid-field': submitted && f.endHr.errors }"
                >
                  <ng-container *ngFor="let endMin of endMinitsList">
                    <option [ngValue]="endMin">{{ endMin }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div *ngIf="submitted && f.endHr.errors" class="invalid-feedback my-2">
              <div *ngIf="f.endHr.errors.toTimeError">Start Time Hour and End Time Hour Must Differ</div>
            </div>
            <div *ngIf="submitted && f.startHr.errors" class="invalid-feedback my-2">
              <div *ngIf="f.startHr.errors.startHrTimeerrors" class="invalid-feedback">
                Start Time should be lesser than current time
              </div>
            </div>

            <div *ngIf="submitted && f.endHr.errors" class="invalid-feedback my-2">
              <div *ngIf="f.endHr.errors.endHrTimeerrors" class="invalid-feedback">
                End Time should be lesser than current time
              </div>
            </div>

            <div *ngIf="submitted && f.endHr.errors" class="invalid-feedback my-2">
              <div *ngIf="f.endHr.errors.endHrerrors">Invalid End Time</div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea rows="4" class="form-control" formControlName="brief"></textarea>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="cancelEdit()"
          >
            Cancel
          </button>
          <button
            type="submit"
            tooltip="Update"
            [disabled]="loading"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- End Edit Shift -->
