<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/staff" *ngIf="userGroup != 'Staff'"><a>Staff</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-user mr-1"></em>Staff Details</li>
  </ol>
</nav>
<div class="roaster-body staff-layout">
  <p class="color-red" *ngIf="staffInfo.is_deleted">
    This staff member is hidden and won't be shown as an option to select or roster.
  </p>
  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">
    {{ staffInfo?.staff_firstname }} {{ staffInfo?.staff_surname }}
  </h1>
  <p class="color-grey-text">
    The staff profile provides you the option to access and manage any information related to the employee.
  </p>
  <div class="row">
    <div class="col-xl-8">
      <app-about-staff [staffInfo]="staffInfo"></app-about-staff>
      <app-equipments-staff [equipmentList]="equipmentList" [staffId]="staffId"></app-equipments-staff>
      <app-incidents-staff
        [staffIncidentsList]="staffIncidentsList"
        [staffId]="staffId"
        [staffInfo]="staffInfo"
      ></app-incidents-staff>
    </div>
    <div class="col-xl-4">
      <!-- <app-availability-staff></app-availability-staff> -->
      <app-certificates-staff [certificateList]="certificateList" [staffId]="staffId"></app-certificates-staff>
      <app-files-staff [fileList]="fileList" [staffId]="staffId"></app-files-staff>
      <app-blacklisting-staff [blackList]="blackList"></app-blacklisting-staff>
    </div>
  </div>
</div>
