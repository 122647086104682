import { EventEmitter } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as uuid from 'uuid';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var S3UploadService = /** @class */ (function () {
    function S3UploadService() {
        this.progress = new EventEmitter();
        var poolData = { UserPoolId: environment.UserPoolId, ClientId: environment.ClientId };
        var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: environment.Username,
            Password: environment.Password,
        });
        var userData = { Username: environment.Username, Pool: userPool };
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        var userLogin = "cognito-idp." + environment.DefaultRegion + ".amazonaws.com/" + environment.UserPoolId;
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                var _a;
                AWS.config.update({
                    region: environment.DefaultRegion,
                    credentials: new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: environment.IdentityPoolId,
                        Logins: (_a = {},
                            _a[userLogin] = result.getIdToken().getJwtToken(),
                            _a),
                    }),
                });
            },
            onFailure: function (err) {
                console.error(err);
            },
            newPasswordRequired: function (userAttributes) {
                var userAttr = userAttributes;
                delete userAttr.email_verified;
                // unsure about this field, but I don't send this back
                delete userAttr.phone_number_verified;
                // Get these details and call
                cognitoUser.completeNewPasswordChallenge(environment.Password, userAttr, this);
            },
        });
    }
    S3UploadService.prototype.uploadfile = function (file, index, type) {
        var _this = this;
        var uniquename = uuid.v4();
        var fileExtension = file.name.replace(/^.*\./, '');
        var filename = 'file/' + uniquename + '.' + fileExtension;
        var S3Client = new AWS.S3();
        var params = {
            Bucket: environment.BucketName,
            Key: filename,
            Body: file,
            ACL: 'public-read',
            ContentType: file.type,
        };
        return new Promise(function (res, rej) {
            S3Client.upload(params, function (err, data) {
                if (err) {
                    console.error(err);
                    return rej(err);
                }
                return res(data);
            }).on('httpUploadProgress', function (event) {
                // change here
                var progress = Math.round((event.loaded / event.total) * 100);
                _this.progress.emit({ progress: progress, index: index, type: type });
            });
        });
    };
    S3UploadService.ngInjectableDef = i0.defineInjectable({ factory: function S3UploadService_Factory() { return new S3UploadService(); }, token: S3UploadService, providedIn: "root" });
    return S3UploadService;
}());
export { S3UploadService };
