<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500"><a>Locations</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-users mr-1"></em>Location List</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Locations</h1>
    <p class="color-grey-text" *ngIf="showHidden === 0">
      This list allows you to access all information related to your locations. You can also
      <a href="javascript:void(0)" (click)="viewHideClient(1)">view hidden locations.</a>
    </p>
    <p class="color-grey-text" *ngIf="showHidden === 1">
      This list allows you to access all information related to your locations. You can also
      <a href="javascript:void(0)" (click)="viewHideClient(0)">hide hidden clients.</a>
    </p>
    <div class="row mt-3">
      <div class="col-md-5 col-xl-3 search-box">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text pr-0" id="basic-addon1">
              <em class="fas fa-search color-light-grey fs12"></em>
            </span>
          </div>
          <input
            type="text"
            class="form-control fs14"
            placeholder="search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            (input)="searchText($event.target.value)"
            [(ngModel)]="search"
          />
        </div>
      </div>
      <div class="col-md-5 col-xl-3 offset-xl-6 offset-md-2">
        <button
          type="button"
          class="btn btn-secondary btn-create border-0 fs12 float-md-right"
          tooltip="ADD LOCATION"
          (click)="addLocationModal(addLocation)"
          *ngIf="userGroup === 'Advanced' || userGroup === 'Full access'"
        >
          + ADD LOCATION
        </button>
      </div>
    </div>
    <div class="mt-1">
      <app-list-data></app-list-data>
    </div>
  </div>

  <ng-template #addLocation>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Add Location</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-client></app-add-client>
    </div>
  </ng-template>
</div>
