import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-certificates-staff',
  templateUrl: './certificates-staff.component.html',
})
export class CertificatesStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() certificateList;
  @Input() staffId;
  showCertificate: boolean;
  certificateData: any = [];
  certificateForm: FormGroup;
  isSubmitted = false;
  loading = false;
  certificateInfo;
  type = '';
  selectCertificate = {};
  today: Date;
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');
  domainInfo: any = {};
  dateFormat;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
  ) {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
  }

  maxCertificate() {
    this.showCertificate = true;
  }
  hideCertificate() {
    this.showCertificate = false;
  }

  ngOnInit() {
    this.getCertificatelist();
    this.createForm();
    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id'] && data['group']) {
        this.userGroup = data['group'];
        localStorage.setItem('userGroup', data['group']);
      }
    });
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }

  createForm() {
    this.certificateForm = this.formBuilder.group({
      certificate_id: ['', Validators.required],
      expire_date: ['', Validators.required],
      certification_number: [''],
    });
  }

  openModal(template: TemplateRef<any>, type) {
    this.type = type;
    this.today = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')['_d'];
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  deletePopup(template: TemplateRef<any>, certificate) {
    this.selectCertificate = certificate;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
  }

  openUpdateModal(template: TemplateRef<any>, certificate, type) {
    this.certificateInfo = certificate;
    this.type = type;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    this.setFormData(certificate);
  }

  setFormData(data) {
    if (data['expire_date']) data['expire_date'] = new Date(data['expire_date']);
    this.certificateForm.patchValue({
      id: data.id,
      certificate_id: data.certificate_id,
      expire_date: data.expire_date,
      certification_number: data.certification_number,
    });
  }

  get f() {
    return this.certificateForm.controls;
  }

  getCertificatelist() {
    this.apiService.get('certificate').subscribe((certificate) => {
      if (certificate['status'] === 200) {
        this.certificateData = certificate['certificates'];
      }
    });
  }

  clearForm() {
    this.certificateForm.reset({ certificate_id: '' });
    this.modalRef.hide();
    this.isSubmitted = false;
    this.loading = false;
    this.selectCertificate = {};
    this.certificateInfo = {};
    this.apiService.closeClientModel.emit(5);
  }

  createStaffCertificate() {
    this.isSubmitted = true;
    if (this.certificateForm.invalid) {
      return;
    }

    const dataValue = this.certificateForm.value;
    dataValue['staff_id'] = this.staffId;

    if (dataValue['expire_date']) {
      dataValue['expire_date'] = moment(dataValue['expire_date']).format('YYYY-MM-DD');
    } else {
      delete dataValue['expire_date'];
    }

    if (dataValue['certification_number'] === '' || dataValue['certification_number'] === null)
      delete dataValue['certification_number'];

    this.loading = true;
    if (this.certificateInfo && this.certificateInfo['id']) {
      this.loading = false;
      this.apiService.put('staff-certificate/' + this.certificateInfo['id'], dataValue).subscribe(
        (certificate) => {
          if (certificate['status'] === 200) {
            this.clearForm();
            this.toastr.success(certificate['message']);
          }
        },
        (error) => {
          this.errorMessage(error);
        },
      );
    } else {
      this.apiService.post('staff-certificate', dataValue).subscribe(
        (certificate) => {
          this.loading = false;
          if (certificate['status'] === 200) {
            this.clearForm();
            this.toastr.success(certificate['message']);
          }
        },
        (error) => {
          this.errorMessage(error);
        },
      );
    }
  }

  errorMessage(error) {
    this.isSubmitted = false;
    this.loading = false;
    if (error.error.message) {
      this.toastr.error(error.error.message);
    }
  }
  deleteCertificate() {
    this.apiService.delete('staff-certificate/' + this.selectCertificate['id']).subscribe((deletecertificate) => {
      if (deletecertificate['status'] === 200) {
        this.toastr.success(deletecertificate['message']);
        this.clearForm();
      } else {
        this.toastr.error(deletecertificate['message']);
        this.clearForm();
      }
    });
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }
}
