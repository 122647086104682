import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../services';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(apiService, title, toastr, formBuilder) {
        this.apiService = apiService;
        this.title = title;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.submitted = false;
        this.applicationData = {};
        this.isEdit = false;
        this.loading = false;
    }
    Object.defineProperty(SettingsComponent.prototype, "f", {
        get: function () {
            return this.applicationForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SettingsComponent.prototype.CreateApplication = function () {
        var _this = this;
        this.submitted = true;
        if (this.applicationForm.invalid) {
            return;
        }
        var applicationName = this.applicationForm.value;
        if (this.applicationData && this.applicationData['id']) {
            this.apiService.put('application/update/' + this.applicationData['id'], applicationName).subscribe(function (data) {
                _this.sucessMessage(data, applicationName);
            }, function (err) {
                _this.errorMessage(err);
            });
        }
        else {
            this.apiService.post('application', applicationName).subscribe(function (data) {
                _this.sucessMessage(data, applicationName);
            }, function (err) {
                _this.errorMessage(err);
            });
        }
    };
    SettingsComponent.prototype.sucessMessage = function (data, applicationName) {
        this.toastr.clear();
        if (data.status === 200) {
            this.closeForm(data, applicationName);
        }
    };
    SettingsComponent.prototype.errorMessage = function (err) {
        this.toastr.clear();
        if (err.error.message) {
            this.toastr.error(err.error.message);
        }
    };
    SettingsComponent.prototype.closeForm = function (data, applicationName) {
        this.toastr.success(data.message);
        this.applicationForm.reset();
        this.submitted = false;
        this.title.setTitle(applicationName.name);
        localStorage.setItem('applicationName', data['application']['name']);
        this.getApplicationName();
    };
    SettingsComponent.prototype.onReset = function () {
        this.submitted = false;
        this.getApplicationName();
    };
    SettingsComponent.prototype.getApplicationName = function () {
        var _this = this;
        this.loading = true;
        this.apiService.get('application').subscribe(function (data) {
            if (data['status'] === 200) {
                _this.applicationData = data['application'];
                _this.applicationForm.patchValue({ name: _this.applicationData['name'] });
                _this.isEdit = true;
            }
            else {
                _this.isEdit = false;
                _this.applicationForm.patchValue({ name: '' });
                _this.applicationData = {};
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.isEdit = false;
            _this.applicationForm.patchValue({ name: '' });
            _this.applicationData = {};
        });
    };
    SettingsComponent.prototype.ngOnInit = function () {
        this.getApplicationName();
        this.applicationForm = this.formBuilder.group({
            name: ['', Validators.required],
        });
    };
    return SettingsComponent;
}());
export { SettingsComponent };
