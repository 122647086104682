import { Component, OnInit, Input, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incident-staff',
  templateUrl: './incident-staff.component.html',
})
export class IncidentStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() staffList;
  @Input() incidentId;
  @Input() incidentStatus;
  newStaffList: any = [];
  isSubmit = false;
  showError = false;
  staffId = '';

  constructor(
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.getStaffList();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  getStaffList() {
    this.apiService.get('staff/list').subscribe((staff) => {
      if (staff['status'] === 200) {
        this.newStaffList = staff['staffs'];
      }
    });
  }

  redirectUrl(id) {
    this.router.navigate(['/staff-details/' + id]);
  }

  changeAction() {
    this.isSubmit = false;
    this.showError = false;
  }

  createIncidentStaff() {
    this.isSubmit = true;
    if (this.staffId === '') {
      this.showError = true;
      return;
    }

    this.apiService
      .post('incident/report/' + this.incidentId + '/staff', { staff_id: this.staffId })
      .subscribe((staff) => {
        if (staff['status'] === 200) {
          this.toastr.success(staff['message']);
          this.clearForm();
        } else {
          this.toastr.error(staff['message']);
          this.isSubmit = false;
          this.showError = false;
        }
      });
  }

  clearForm() {
    this.modalRef.hide();
    this.isSubmit = false;
    this.showError = false;
    this.staffId = '';
    this.apiService.closeClientModel.emit(11);
  }
}
