import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services';
import * as moment from 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-clientinfo',
  templateUrl: './view-clientinfo.component.html',
})
export class ViewClientinfoComponent implements OnInit {
  modalRef1: BsModalRef;
  customercreationform: FormGroup;
  submitted = false;
  @Input() id: string;
  // @Input() info: any;
  viewDomainlist = [];
  isreadonlyenabled = true;
  phoneCode: any = [];
  editdetailsarray = [];
  countryCode;
  nzcode;
  countryFlag;
  defaultCountryCode: any = 'NZ';
  timeformatvalue = '';
  dateformatvalue = '';
  domainInfo: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.customercreationform = this.formBuilder.group({
      Name: ['', Validators.required],
      CompanyName: ['', Validators.required],
      CompanyWebsite: ['', Validators.required],
      MobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      Email: [
        '',
        [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,})$/)],
      ],
      Domain: ['', Validators.required],
      TimeFormat: ['', Validators.required],
      TimeZone: ['', Validators.required],
      DateFormat: ['', Validators.required],
      staff_count: ['', Validators.required],
    });
    this.viewclientdetails();
  }
  get f() {
    return this.customercreationform.controls;
  }

  getPhoneCode() {
    this.apiService.get('phone-code').subscribe((phoneCode: any) => {
      if (phoneCode.status === 200) {
        this.phoneCode = phoneCode['phonecodes'];
        if (this.phoneCode && this.phoneCode.length > 0) {
          this.setcountryFlag();
        }
      }
    });
  }
  setcountryFlag() {
    const intphoneData = this.phoneCode.filter((obj) => obj.countries_iso_code === this.defaultCountryCode);

    const phoneData = this.phoneCode.filter((obj) => obj.id === this.viewDomainlist['countryCode']);
    if (phoneData && phoneData.length > 0) {
      this.setcountrycodeflag(phoneData[0]);
    } else {
      this.setcountrycodeflag(intphoneData[0]);
    }
  }
  setcountrycodeflag(intphoneData) {
    this.countryCode = intphoneData.countries_isd_code;
    this.countryFlag = intphoneData.countries_iso_code;
    this.nzcode = intphoneData.id;

    this.customercreationform.patchValue({
      countryCode: this.nzcode,
    });
  }

  makeEditable() {
    this.isreadonlyenabled = false;
  }
  isNumberKey(evt, value) {
    if (evt > 31 && (evt < 48 || evt > 57)) {
      return false;
    } else if (value.length > 9) {
      return false;
    }
    return true;
  }

  viewclientdetails() {
    // alert("2")
    this.apiService.get('domain/view-domain/' + this.id).subscribe((client: any) => {
      if (client.status === 200) {
        this.viewDomainlist = client.viewDomain;
        this.getPhoneCode();
        let timeformat = '';
        let dateformat = '';
        this.timeformatvalue = this.viewDomainlist['timeformat'];
        this.dateformatvalue = this.viewDomainlist['dateformat'];
        if (this.timeformatvalue == 'hh:mm @') {
          timeformat = moment().format('hh:mm A');
        }
        if (this.timeformatvalue == 'hh:mm:ss @') {
          timeformat = moment().format('hh:mm:ss A');
        }
        if (this.timeformatvalue == 'HH:mm') {
          timeformat = moment().format('HH:mm');
        }
        if (this.timeformatvalue == 'HH:mm:ss') {
          timeformat = moment().format('HH:mm:ss');
        }
        // dateformat
        if (this.dateformatvalue == 'dd/MM/yyyy') {
          dateformat = moment().format('DD/MM/YYYY');
        }
        if (this.dateformatvalue == 'MM/dd/yyyy,') {
          dateformat = moment().format('MM/DD/YYYY');
        }
        if (this.dateformatvalue == 'yyyy/MM/dd') {
          dateformat = moment().format('YYYY/MM/DD');
        }
        if (this.dateformatvalue == 'MMM dd,yyyy') {
          dateformat = moment().format('MMMM DD, YYYY');
        }
        if (this.dateformatvalue == 'dd MMM, yyyy') {
          dateformat = moment().format('DD MMMM, YYYY');
        }
        this.customercreationform.patchValue({
          Name: this.viewDomainlist['name'],

          CompanyName: this.viewDomainlist['clientname'],
          CompanyWebsite: this.viewDomainlist['companyWebsite'],
          MobileNumber: this.viewDomainlist['mobileNumber'],
          Email: this.viewDomainlist['email'],
          Domain: this.viewDomainlist['subdomain'],
          TimeFormat: timeformat,
          TimeZone: this.viewDomainlist['timezone'],
          DateFormat: dateformat,
          staff_count: this.viewDomainlist['staff_count'],
        });
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.customercreationform.invalid) {
      return;
    }
    const object = {};
    object['name'] = this.customercreationform.get('Name').value;
    object['clientname'] = this.customercreationform.get('CompanyName').value;
    object['companyWebsite'] = this.customercreationform.get('CompanyWebsite').value;
    object['mobileNumber'] = this.customercreationform.get('MobileNumber').value;
    object['email'] = this.customercreationform.get('Email').value;
    object['domain'] = this.customercreationform.get('Domain').value;
    object['timeFormat'] = this.customercreationform.get('TimeFormat').value;
    object['dateFormat'] = this.customercreationform.get('DateFormat').value;
    object['timezone'] = '10';

    this.apiService.put('domain/update-domain/' + this.id, object).subscribe((client: any) => {
      if (client.status === 200) {
        this.toastr.success(client.message);
        this.apiService.closeClientModel.emit(14);
      }
    });

    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.customercreationform.value))
  }
  closeModel() {
    this.apiService.closeClientModel.emit(14);
  }
  timeformatModal(template: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
}
