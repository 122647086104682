import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService, OnlyNumber, ExcelService } from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUploaderModule } from 'ngx-uploader';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { NgxLoadingModule } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ClientsComponent } from './clients/clients.component';
import { AddShiftComponent } from './add-shift/add-shift.component';
import { ListDataComponent } from './list-data/list-data.component';
import { AddClientComponent } from './add-client/add-client.component';
import { StaffComponent } from './staff/staff.component';
import { StaffLinksComponent } from './staff-details/staff-details.component';
import { AppListDataStaffComponent } from './app-list-data-staff/app-list-data-staff.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AboutComponent } from './client-details-component/about/about.component';
import { LocationComponent } from './client-details-component/location/location.component';
import { IncidentsComponent } from './client-details-component/incidents/incidents.component';
import { CertificatesComponent } from './client-details-component/certificates/certificates.component';
import { FilesComponent } from './client-details-component/files/files.component';
import { BlacklistingComponent } from './client-details-component/blacklisting/blacklisting.component';
import { AboutStaffComponent } from './staff-details-component/about-staff/about-staff.component';
import { EquipmentsStaffComponent } from './staff-details-component/equipments-staff/equipments-staff.component';
import { IncidentsStaffComponent } from './staff-details-component/incidents-staff/incidents-staff.component';
import { CertificatesStaffComponent } from './staff-details-component/certificates-staff/certificates-staff.component';
import { FilesStaffComponent } from './staff-details-component/files-staff/files-staff.component';
import { BlacklistingStaffComponent } from './staff-details-component/blacklisting-staff/blacklisting-staff.component';
import { AddStaffComponent } from './add-staff/add-staff.component';
import { TimeTrackingComponent } from './scheduler/time-tracking/time-tracking.component';
import { DeleteTrackingComponent } from './scheduler/delete-tracking/delete-tracking.component';
import { StaffOnShiftComponent } from './scheduler/staff-on-shift/staff-on-shift.component';
import { ChangeLoginInfoComponent } from './change-login-info/change-login-info.component';
import { SettingsComponent } from './settings/settings.component';
import { TagInputModule } from 'ngx-chips';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { IncidentDetailComponent } from './client-details-component/incident-detail/incident-detail.component';
import { IncidentAboutComponent } from './client-details-component/incident-detail/incident-about/incident-about.component';
import { IncidentStaffComponent } from './client-details-component/incident-detail/incident-staff/incident-staff.component';
import { IncidentEventsComponent } from './client-details-component/incident-detail/incident-events/incident-events.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { CertificationsComponent } from './settings/certifications/certifications.component';
import { IncidentTypeComponent } from './settings/incident-type/incident-type.component';
import { EquipmentsComponent } from './settings/equipments/equipments.component';
import { IncidentStaffDetailComponent } from './staff-details-component/incident-staff-detail/incident-staff-detail.component';
import { IncidentStaffAboutComponent } from './staff-details-component/incident-staff-detail/incident-staff-about/incident-staff-about.component';
import { IncidentStaffStaffComponent } from './staff-details-component/incident-staff-detail/incident-staff-staff/incident-staff-staff.component';
import { IncidentStaffEventsComponent } from './staff-details-component/incident-staff-detail/incident-staff-events/incident-staff-events.component';
import { LocationDetailComponent } from './client-details-component/location-detail/location-detail.component';
import { LocationAboutComponent } from './client-details-component/location-detail/location-about/location-about.component';
import { LocationBlackListingComponent } from './client-details-component/location-detail/location-blacklisting/location-blacklisting.component';
import { LocationFilesComponent } from './client-details-component/location-detail/location-files/location-files.component';
import { LocationCertificationComponent } from './client-details-component/location-detail/location-certification/location-certification.component';
import { LocationSublocationComponent } from './client-details-component/location-detail/location-sublocation/location-sublocation.component';
import { SublocationDetailComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-detail.component';
import { SublocationAboutComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-about/sublocation-about.component';
import { SublocationCertificationComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-certification/sublocation-certification.component';
import { SublocationBlacklistingComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-blacklisting/sublocation-blacklisting.component';
import { SublocationIncidentComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-incident/sublocation-incident.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { SchedulerHistoryComponent } from './scheduler/scheduler-history/scheduler-history.component';
import { EventComponent } from './scheduler/event/event.component';
import { LocationIncidentsComponent } from './client-details-component/location-detail/location-incidents/location-incidents.component';
import { AddIncidentComponent } from './add-incident/add-incident.component';
import { ProfileComponent } from './profile/profile.component';
import { AutosizeModule } from 'ngx-autosize';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SupportComponent } from './support/support.component';
import { CustomerCreationComponent } from './customer-creation/customer-creation.component';
import { SuperadminprofileComponent } from './superadminprofile/superadminprofile.component';
import { AppLayoutsuperadminComponent } from './layout/app-layoutsuperadmin/app-layoutsuperadmin.component';
import { HeadersuperadminComponent } from './layout/headersuperadmin/headersuperadmin.component';
import { SidebarsuperadminComponent } from './layout/sidebarsuperadmin/sidebarsuperadmin.component';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { TimeformatComponent } from './timeformat/timeformat.component';
import { TimezoneComponent } from './timezone/timezone.component';
import { DateformatComponent } from './dateformat/dateformat.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { AddClientinfoComponent } from './add-clientinfo/add-clientinfo.component';
import { ViewClientinfoComponent } from './view-clientinfo/view-clientinfo.component';
import { AddclientComponent } from './addclient/addclient.component';
import { EventArrayModifier } from './pipes/event-array-modifier.pipe';
import { DateTimezoneConvertPipe } from './pipes/date-timezone-convert.pipe';
import { mapKey } from './constants/defaultValues';
import { AvailabilityStaffComponent } from './staff-details-component/availability-staff/availability-staff.component';
import { ReportComponent } from './report/report.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ReportListPipe } from './pipes/report-list.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    ClientsComponent,
    AddShiftComponent,
    ListDataComponent,
    AddClientComponent,
    StaffComponent,
    StaffLinksComponent,
    AppListDataStaffComponent,
    ClientDetailsComponent,
    ForgotPasswordComponent,
    AboutComponent,
    LocationComponent,
    IncidentsComponent,
    CertificatesComponent,
    FilesComponent,
    BlacklistingComponent,
    EquipmentsStaffComponent,
    AboutStaffComponent,
    EquipmentsStaffComponent,
    IncidentsStaffComponent,
    CertificatesStaffComponent,
    FilesStaffComponent,
    BlacklistingStaffComponent,
    AddStaffComponent,
    TimeTrackingComponent,
    DeleteTrackingComponent,
    StaffOnShiftComponent,
    ChangeLoginInfoComponent,
    SettingsComponent,
    SchedulerComponent,
    IncidentDetailComponent,
    IncidentAboutComponent,
    IncidentStaffComponent,
    IncidentEventsComponent,
    ReportsComponent,
    ReportDetailComponent,
    CertificationsComponent,
    IncidentTypeComponent,
    EquipmentsComponent,
    IncidentStaffDetailComponent,
    IncidentStaffAboutComponent,
    IncidentStaffStaffComponent,
    IncidentStaffEventsComponent,
    LocationDetailComponent,
    LocationAboutComponent,
    LocationSublocationComponent,
    LocationBlackListingComponent,
    LocationCertificationComponent,
    LocationFilesComponent,
    SublocationDetailComponent,
    SublocationAboutComponent,
    SublocationCertificationComponent,
    SublocationBlacklistingComponent,
    SublocationIncidentComponent,
    DashboardComponent,
    OnlyNumber,
    SchedulerHistoryComponent,
    LocationIncidentsComponent,
    AddIncidentComponent,
    ProfileComponent,
    EventComponent,
    ResetPasswordComponent,
    PrivacyPolicyComponent,
    SupportComponent,
    CustomerCreationComponent,
    SuperadminprofileComponent,
    AppLayoutsuperadminComponent,
    HeadersuperadminComponent,
    SidebarsuperadminComponent,
    ViewprofileComponent,
    TimeformatComponent,
    TimezoneComponent,
    DateformatComponent,
    ClientInfoComponent,
    AddClientinfoComponent,
    ViewClientinfoComponent,
    AddclientComponent,
    EventArrayModifier,
    DateTimezoneConvertPipe,
    ReportListPipe,
    AvailabilityStaffComponent,
    ReportComponent,
  ],
  imports: [
    MyDateRangePickerModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxPaginationModule,
    NgxUploaderModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    AgmCoreModule.forRoot({ apiKey: mapKey, libraries: ['places'] }),
    ProgressbarModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TagInputModule,
    TooltipModule.forRoot(),
    Ng2HighchartsModule,
    NgxLoadingModule.forRoot({
      backdropBorderRadius: '14px',
      fullScreenBackdrop: true,
    }),
    AutosizeModule,
    PaginationModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }, ExcelService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
