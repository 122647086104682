/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../header/header.component.ngfactory";
import * as i2 from "../header/header.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../services/api.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "../sidebar/sidebar.component.ngfactory";
import * as i9 from "../sidebar/sidebar.component";
import * as i10 from "./app-layout.component";
var styles_AppLayoutComponent = [];
var RenderType_AppLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppLayoutComponent, data: {} });
export { RenderType_AppLayoutComponent as RenderType_AppLayoutComponent };
export function View_AppLayoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, [[null, "sidemenuToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sidemenuToggle" === en)) {
        var pd_0 = (_co.sideMenuChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_HeaderComponent_0, i1.RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i2.HeaderComponent, [i3.BsModalService, i4.ApiService, i5.Router, i6.Title], null, { sidemenuToggle: "sidemenuToggle" }), (_l()(), i0.ɵeld(2, 0, null, null, 4, "aside", [["class", "own-sidemenu-main"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { sidemenuclosed: 0, sidemenuopend: 1 }), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-sidebar", [], null, null, null, i8.View_SidebarComponent_0, i8.RenderType_SidebarComponent)), i0.ɵdid(6, 114688, null, 0, i9.SidebarComponent, [i5.Router, i4.ApiService], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "section", [["class", "page-content"]], null, null, null, null, null)), i0.ɵdid(8, 278528, null, 0, i7.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(9, { show_pagecontent: 0 }), (_l()(), i0.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(11, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "own-sidemenu-main"; var currVal_1 = _ck(_v, 4, 0, _co.toggleClass, !_co.toggleClass); _ck(_v, 3, 0, currVal_0, currVal_1); _ck(_v, 6, 0); var currVal_2 = "page-content"; var currVal_3 = _ck(_v, 9, 0, _co.toggleClass); _ck(_v, 8, 0, currVal_2, currVal_3); _ck(_v, 11, 0); }, null); }
export function View_AppLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-app-layout", [], null, null, null, View_AppLayoutComponent_0, RenderType_AppLayoutComponent)), i0.ɵdid(1, 114688, null, 0, i10.AppLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLayoutComponentNgFactory = i0.ɵccf("app-app-layout", i10.AppLayoutComponent, View_AppLayoutComponent_Host_0, {}, {}, []);
export { AppLayoutComponentNgFactory as AppLayoutComponentNgFactory };
