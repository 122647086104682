import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  isCollapsed = true;
  userId = localStorage.getItem('userid');
  userType = localStorage.getItem('userType');
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id'] && data['group']) {
        this.userGroup = data['group'];
        localStorage.setItem('userGroup', data['group']);
      }
    });
  }

  isLinkActive(type) {
    const activeUrl = this.router.url.split('/')[1];
    const urlIndex = activeUrl.indexOf(type);
    if (urlIndex > -1) {
      return true;
    } else {
      return false;
    }
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
}
