import * as tslib_1 from "tslib";
import { MapsAPILoader } from '@agm/core';
import * as i0 from "@angular/core";
import * as i1 from "@agm/core/services/maps-api-loader/maps-api-loader";
var GeolocationService = /** @class */ (function () {
    function GeolocationService(mapsAPILoader) {
        this.mapsAPILoader = mapsAPILoader;
    }
    GeolocationService.prototype.getCurrentCountry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            if ('geolocation' in navigator) {
                                navigator.geolocation.getCurrentPosition(function (position) {
                                    _this.mapsAPILoader.load().then(function () {
                                        var geocoder = new google.maps.Geocoder();
                                        var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                                        var request = { latLng: latlng };
                                        geocoder.geocode(request, function (results, status) {
                                            if (status === google.maps.GeocoderStatus.OK) {
                                                var address_components = results[0].address_components;
                                                var address = address_components
                                                    .filter(function (r) {
                                                    if (r.types[0] == 'country') {
                                                        return r;
                                                    }
                                                })
                                                    .map(function (r) {
                                                    return r.short_name;
                                                });
                                                resolve(address[0]);
                                            }
                                        });
                                    });
                                }, function (error) {
                                    console.error(error);
                                    resolve('NZ');
                                });
                            }
                            else {
                                resolve('NZ');
                            }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GeolocationService.prototype.getLocationAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            _this.mapsAPILoader.load().then(function () {
                                var geocoder = new google.maps.Geocoder();
                                var request = { address: address };
                                geocoder.geocode(request, function (results, status) {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        resolve({ lat: results[0].geometry.location.lat(), long: results[0].geometry.location.lng() });
                                    }
                                });
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GeolocationService.prototype.getMarkedAddress = function (address) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                            _this.mapsAPILoader.load().then(function () {
                                var geocoder = new google.maps.Geocoder();
                                var latlng = new google.maps.LatLng(address.lat, address.lng);
                                var request = { latLng: latlng };
                                geocoder.geocode(request, function (results, status) {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        var address_components = results[0].formatted_address;
                                        resolve({ address: address_components });
                                    }
                                });
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GeolocationService.ngInjectableDef = i0.defineInjectable({ factory: function GeolocationService_Factory() { return new GeolocationService(i0.inject(i1.MapsAPILoader)); }, token: GeolocationService, providedIn: "root" });
    return GeolocationService;
}());
export { GeolocationService };
