import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-incident-about',
  templateUrl: './incident-about.component.html',
})
export class IncidentAboutComponent implements OnInit {
  @Input() incidentInfo;
  domainInfo: any = {};
  dateFormat;
  timeFormat;
  constructor(private router: Router) {}

  ngOnInit() {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo')) || {};
    const splittedTime = this.domainInfo.timeformat ? this.domainInfo.timeformat.split(':') : null;
    if (splittedTime && splittedTime[0] === 'hh') {
      this.timeFormat = '12HoursFormat';
    }
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }

  tConvert(time) {
    if (!time) return time;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }
  redirectUrl(id) {
    this.router.navigate(['/client-details/' + id]);
  }
}
