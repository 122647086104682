import { Component } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor() {
    const domainInfo = localStorage.getItem('domainInfo');
    let timezone = 'NZ';
    if (domainInfo && domainInfo !== 'undefined') {
      const domainInfoObj = JSON.parse(domainInfo);
      if (domainInfoObj && domainInfoObj.timezone) {
        timezone = domainInfoObj.timezone;
      }
    }
    moment.tz.setDefault(timezone);
  }
  title = 'just-roaster';
}
