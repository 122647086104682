import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
var CertificatesStaffComponent = /** @class */ (function () {
    function CertificatesStaffComponent(apiService, toastr, formBuilder, modalService) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.certificateData = [];
        this.isSubmitted = false;
        this.loading = false;
        this.type = '';
        this.selectCertificate = {};
        this.userGroup = localStorage.getItem('userGroup');
        this.userLogId = localStorage.getItem('userLogId');
        this.domainInfo = {};
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    }
    CertificatesStaffComponent.prototype.maxCertificate = function () {
        this.showCertificate = true;
    };
    CertificatesStaffComponent.prototype.hideCertificate = function () {
        this.showCertificate = false;
    };
    CertificatesStaffComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getCertificatelist();
        this.createForm();
        this.apiService.getProfileChanges('profile:').subscribe(function (data) {
            if (data && data['staff_id'] && data['group']) {
                _this.userGroup = data['group'];
                localStorage.setItem('userGroup', data['group']);
            }
        });
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
        this.dateFormat =
            this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
    };
    CertificatesStaffComponent.prototype.createForm = function () {
        this.certificateForm = this.formBuilder.group({
            certificate_id: ['', Validators.required],
            expire_date: ['', Validators.required],
            certification_number: [''],
        });
    };
    CertificatesStaffComponent.prototype.openModal = function (template, type) {
        this.type = type;
        this.today = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')['_d'];
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    };
    CertificatesStaffComponent.prototype.deletePopup = function (template, certificate) {
        this.selectCertificate = certificate;
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
    };
    CertificatesStaffComponent.prototype.openUpdateModal = function (template, certificate, type) {
        this.certificateInfo = certificate;
        this.type = type;
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
        this.setFormData(certificate);
    };
    CertificatesStaffComponent.prototype.setFormData = function (data) {
        if (data['expire_date'])
            data['expire_date'] = new Date(data['expire_date']);
        this.certificateForm.patchValue({
            id: data.id,
            certificate_id: data.certificate_id,
            expire_date: data.expire_date,
            certification_number: data.certification_number,
        });
    };
    Object.defineProperty(CertificatesStaffComponent.prototype, "f", {
        get: function () {
            return this.certificateForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CertificatesStaffComponent.prototype.getCertificatelist = function () {
        var _this = this;
        this.apiService.get('certificate').subscribe(function (certificate) {
            if (certificate['status'] === 200) {
                _this.certificateData = certificate['certificates'];
            }
        });
    };
    CertificatesStaffComponent.prototype.clearForm = function () {
        this.certificateForm.reset({ certificate_id: '' });
        this.modalRef.hide();
        this.isSubmitted = false;
        this.loading = false;
        this.selectCertificate = {};
        this.certificateInfo = {};
        this.apiService.closeClientModel.emit(5);
    };
    CertificatesStaffComponent.prototype.createStaffCertificate = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.certificateForm.invalid) {
            return;
        }
        var dataValue = this.certificateForm.value;
        dataValue['staff_id'] = this.staffId;
        if (dataValue['expire_date']) {
            dataValue['expire_date'] = moment(dataValue['expire_date']).format('YYYY-MM-DD');
        }
        else {
            delete dataValue['expire_date'];
        }
        if (dataValue['certification_number'] === '' || dataValue['certification_number'] === null)
            delete dataValue['certification_number'];
        this.loading = true;
        if (this.certificateInfo && this.certificateInfo['id']) {
            this.loading = false;
            this.apiService.put('staff-certificate/' + this.certificateInfo['id'], dataValue).subscribe(function (certificate) {
                if (certificate['status'] === 200) {
                    _this.clearForm();
                    _this.toastr.success(certificate['message']);
                }
            }, function (error) {
                _this.errorMessage(error);
            });
        }
        else {
            this.apiService.post('staff-certificate', dataValue).subscribe(function (certificate) {
                _this.loading = false;
                if (certificate['status'] === 200) {
                    _this.clearForm();
                    _this.toastr.success(certificate['message']);
                }
            }, function (error) {
                _this.errorMessage(error);
            });
        }
    };
    CertificatesStaffComponent.prototype.errorMessage = function (error) {
        this.isSubmitted = false;
        this.loading = false;
        if (error.error.message) {
            this.toastr.error(error.error.message);
        }
    };
    CertificatesStaffComponent.prototype.deleteCertificate = function () {
        var _this = this;
        this.apiService.delete('staff-certificate/' + this.selectCertificate['id']).subscribe(function (deletecertificate) {
            if (deletecertificate['status'] === 200) {
                _this.toastr.success(deletecertificate['message']);
                _this.clearForm();
            }
            else {
                _this.toastr.error(deletecertificate['message']);
                _this.clearForm();
            }
        });
    };
    CertificatesStaffComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    return CertificatesStaffComponent;
}());
export { CertificatesStaffComponent };
