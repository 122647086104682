import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services';

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
})
export class TimeTrackingComponent implements OnInit {
  shiftId;
  trackInfo: any = {};
  clockArray: any = [];
  public index: number;
  public loading = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.shiftId = this.route.snapshot.params.id;
    this.index = this.route.snapshot.params.index;
    this.getTimeTrack();
  }

  getTimeTrack() {
    this.loading = true;
    this.apiService.get('shift/clock-in-time/' + this.shiftId).subscribe(
      (trackData) => {
        if (trackData['status'] === 200) {
          this.trackInfo = trackData;
          this.clockArray = trackData['clockArray'];
        } else {
          this.trackInfo = {};
          this.clockArray = [];
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  backSchedule() {
    this.router.navigate(['/scheduler'], {
      queryParams: {
        offset: this.route.snapshot.queryParamMap.get('offset'),
        clientId: this.route.snapshot.queryParamMap.get('clientId'),
        staffId: this.route.snapshot.queryParamMap.get('staffId'),
        top: this.route.snapshot.queryParamMap.get('top'),
      },
    });
  }
}
