import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() sidemenuToggle = new EventEmitter<boolean>();
  shouldShow: boolean;
  modalRef: BsModalRef;
  userInfo: any = {};
  fileUrl = environment.fileUrl;
  userName = '';
  @ViewChild('blockAccount') public blockAccount: TemplateRef<any>;
  userType = localStorage.getItem('userType');
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');
  organizationId;
  headerTitle = 'Add Shift';
  subscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private router: Router,
    private title: Title,
  ) {}

  openAddShift(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  ngOnInit() {
    this.getApplicationName();
    if (localStorage.getItem('user')) {
      this.userInfo = JSON.parse(localStorage.getItem('user'));
    }
    this.userName = this.userInfo['name']
      ? this.userInfo['name']
      : this.userInfo['staff_surname']
      ? this.userInfo['staff_firstname'] + ' ' + this.userInfo['staff_surname']
      : this.userInfo['staff_firstname'];
    this.organizationId = this.userInfo['organization_id'];

    // this.apiService.shiftModal.subscribe((data) => {
    //   this.closeModal();
    // });

    this.subscription = this.apiService.schedulerList().subscribe(() => {
      this.closeModal();
    });

    this.apiService.headerModel.subscribe((data) => {
      if (data === 'Add Past Shift') {
        this.headerTitle = 'Add Past Shift';
      } else {
        this.headerTitle = 'Add Shift';
      }
    });
    if (this.userType === '2') {
      this.getProfile(this.userInfo['id']);
    } else {
      this.getClientProfile(this.userInfo['id']);
    }

    this.apiService.profileUpdates.subscribe((data) => {
      if (data === 1) {
        if (this.userType === '2') {
          this.getProfile(this.userInfo['id']);
        } else {
          this.getClientProfile(this.userInfo['id']);
        }
      }
    });

    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id']) {
        this.getProfile(data['staff_id']);
      }
      if (data && data['client_id']) {
        this.getClientProfile(data['client_id']);
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  closeModal() {
    if (this.modalService.getModalsCount() > 0) {
      this.modalRef.hide();
    }
  }

  logout() {
    const dmail = localStorage.getItem('user_email');
    const dpassword = localStorage.getItem('user_password');
    const isRemember: any = localStorage.getItem('isRemember');
    localStorage.clear();
    if (isRemember === true || isRemember === 'true') {
      localStorage.setItem('user_email', dmail);
      localStorage.setItem('user_password', dpassword);
      localStorage.setItem('isRemember', isRemember);
    }
    this.router.navigate(['/login']);
  }

  getApplicationName() {
    this.apiService.get('application').subscribe((data) => {
      if (data['status'] === 200 && data['application'] && data['application']['name']) {
        localStorage.setItem('applicationName', data['application']['name']);
        this.title.setTitle(data['application']['name']);
      }
    });
  }

  getProfile(staffId) {
    this.apiService.get('staff/view/' + staffId).subscribe((staff) => {
      if (staff['status'] === 200) {
        this.userInfo = staff['staff'];
        localStorage.setItem('userGroup', this.userInfo['group']);
        this.userName = this.userInfo['staff_surname']
          ? this.userInfo['staff_firstname'] + ' ' + this.userInfo['staff_surname']
          : this.userInfo['staff_firstname'];
        if (this.userInfo && this.userInfo['is_active'] === 2) {
          this.modalRef = this.modalService.show(this.blockAccount, {
            class: 'modal-md',
            backdrop: 'static',
            keyboard: false,
          });
        }
      } else {
        this.userInfo = {};
        this.userName = '';
      }
    });
  }

  getClientProfile(clientId) {
    this.apiService.get('client/view/' + clientId).subscribe((client) => {
      if (client['status'] === 200) {
        this.userInfo = client['client'];
        this.userName = this.userInfo['name'];
        localStorage.setItem('userGroup', 'Client');
        if (this.userInfo && this.userInfo['is_active'] === 2) {
          this.modalRef = this.modalService.show(this.blockAccount, {
            class: 'modal-md',
            backdrop: 'static',
            keyboard: false,
          });
        }
      } else {
        this.userInfo = {};
        this.userName = '';
      }
    });
  }
  sidebarToggle() {
    this.sidemenuToggle.emit((this.shouldShow = !this.shouldShow));
  }
}
