import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
})
export class IncidentDetailComponent implements OnInit {
  incidentId;
  incidentInfo: any = {};
  staffList = [];
  eventList = [];
  incidentStatus;
  isLoding = false;
  public loading = false;
  userGroup = localStorage.getItem('userGroup');

  constructor(
    private _location: Location,
    private apiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.incidentId = this.route.snapshot.params.id;
    this.getIncidentInfo();
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 11) {
        this.getIncidentInfo();
      }
    });
  }

  getIncidentInfo() {
    this.loading = true;
    this.apiService.get('/incident/report/' + this.incidentId).subscribe(
      (incident) => {
        this.isLoding = false;
        this.incidentInfo = incident['incident'];
        this.staffList = this.incidentInfo['staffs'];
        this.incidentStatus = this.incidentInfo['status'];
        this.eventList = this.incidentInfo['events'];
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  changeStatus(status) {
    status = status ? 1 : 0;
    this.isLoding = true;
    this.loading = true;
    this.apiService.post('incident/report/' + this.incidentId + '/status', { status }).subscribe(
      (incidentStatus) => {
        this.toastr.clear();
        if (incidentStatus['status'] === 200) {
          this.toastr.success(incidentStatus['message']);
        } else {
          this.toastr.error(incidentStatus['message']);
        }
        this.getIncidentInfo();
        this.loading = false;
      },
      (err) => {
        this.isLoding = false;
        this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }

  backClicked() {
    this._location.back();
  }
}
