import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService, GeolocationService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-about',
  templateUrl: './location-about.component.html',
})
export class LocationAboutComponent implements OnInit {
  @Input() locationInfo;
  @Input() clientId;
  @Input() locationId;
  modalRef: BsModalRef;
  isSubmitted = false;
  loading = false;
  locationForm: FormGroup;
  availableTime: any = ['Anytime', '30 minutes before', '15 minutes before', '5 minutes before'];

  public latitude: number;
  public longitude: number;
  public zoom: number;
  marklatitude: any = '';
  marklongitude: any = '';

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private geolocationService: GeolocationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
  ) {}
  ngOnInit() {
    this.createForm();
    this.setCurrentLocation();
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
        },
        (err) => {
          this.zoom = 15;
          this.latitude = -41.2935403;
          this.longitude = 174.7760212;
        },
      );
    } else {
      this.zoom = 15;
      this.latitude = -41.2935403;
      this.longitude = 174.7760212;
    }
  }

  createForm() {
    this.locationForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: [''],
      is_clockin: [0],
      is_clock_at_location: [0],
      is_exclude_time_limit: [0],
      clock_available: [''],
      default_brief: [''],
      latitude: [''],
      longitude: [''],
    });
  }

  redirectUrl(id) {
    this.router.navigate(['/client-details/' + id]);
  }

  editClientModal(template: TemplateRef<any>) {
    this.setLocationInfo();
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  openDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  get f() {
    return this.locationForm.controls;
  }

  createLocation() {
    this.isSubmitted = true;
    if (this.locationForm.invalid) {
      return;
    }

    const datavalue = this.locationForm.value;
    datavalue['is_clockin'] = datavalue['is_clockin'] === true || datavalue['is_clockin'] === 1 ? 1 : 0;
    datavalue['is_clock_at_location'] =
      datavalue['is_clock_at_location'] === true || datavalue['is_clock_at_location'] === 1 ? 1 : 0;
    datavalue['is_exclude_time_limit'] =
      datavalue['is_exclude_time_limit'] === true || datavalue['is_exclude_time_limit'] === 1 ? 1 : 0;
    datavalue['longitude'] = this.marklongitude;
    datavalue['latitude'] = this.marklatitude;
    this.loading = true;
    this.apiService.put('client/' + this.clientId + '/location/' + this.locationId, datavalue).subscribe((location) => {
      this.loading = false;
      if (location['status'] === 200) {
        this.toastr.success(location['message']);
        this.clearForm();
      }
    });
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  async addMarker(lat: number, lng: number) {
    this.marklatitude = lat;
    this.marklongitude = lng;
    const address = await this.geolocationService.getMarkedAddress({ lat, lng });
    this.locationForm.patchValue({ address: address['address'] });
  }

  async checkLatLong(value) {
    if (!value) {
      this.marklatitude = '';
      this.marklongitude = '';
      this.latitude = null;
      this.longitude = null;
      this.setCurrentLocation();
    } else {
      const address = await this.geolocationService.getLocationAddress(value);
      if (address && address['lat'] && address['long']) {
        this.marklatitude = address['lat'];
        this.marklongitude = address['long'];
        this.latitude = address['lat'];
        this.longitude = address['long'];
      }
    }
  }

  setLocationInfo() {
    this.locationForm.patchValue({
      name: this.locationInfo['name'],
      address: this.locationInfo['address'],
      is_clockin: this.locationInfo['is_clockin'],
      is_clock_at_location: this.locationInfo['is_clock_at_location'],
      is_exclude_time_limit: this.locationInfo['is_exclude_time_limit'],
      clock_available: this.locationInfo['clock_available'] ? this.locationInfo['clock_available'] : '',
      default_brief: this.locationInfo['default_brief'],
      latitude: this.locationInfo['latitude'],
      longitude: this.locationInfo['longitude'],
    });

    if (this.locationInfo['latitude'] && this.locationInfo['longitude']) {
      this.marklatitude = parseFloat(this.locationInfo['latitude']);
      this.marklongitude = parseFloat(this.locationInfo['longitude']);
    }
    if (this.locationInfo['address']) {
      this.checkLatLong(this.locationInfo['address']);
    }
  }

  removeLocation() {
    this.loading = true;
    this.apiService.delete('client/' + this.clientId + '/location/' + this.locationId).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.toastr.success('Location Removed Successfully');
          this.clearForm();
          this.loading = false;
          this.router.navigate(['/client-details/' + this.clientId]);
        } else {
          this.loading = false;
          this.toastr.error(data['message']);
        }
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  clearForm() {
    this.modalRef.hide();
    this.locationForm.reset({ clock_available: '' });
    this.isSubmitted = false;
    this.loading = false;
    this.apiService.closeClientModel.emit(14);
    //this.apiService.closeClientModel.emit(10);
    this.marklongitude = '';
    this.marklatitude = '';
  }

  closeModel() {
    this.modalRef.hide();
  }

  revertLocation() {
    this.loading = true;
    this.apiService.get('client/' + this.clientId + '/location/' + this.locationId + '/revert').subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.toastr.success('Location Restored Successfully');
          this.clearForm();
          this.loading = false;
          //this.router.navigate(['/client-details/'+this.clientId]);
        } else {
          this.loading = false;
          this.toastr.error(data['message']);
        }
      },
      (err) => {
        this.loading = false;
      },
    );
  }
}
