import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
var IncidentTypeComponent = /** @class */ (function () {
    function IncidentTypeComponent(modalService, apiService, toastr, formBuilder) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.incidentList = [];
        this.submitted = false;
        // tslint:disable-next-line: max-line-length
        this.title = 'Add';
        this.page = 1;
        this.pagesize = 10;
        this.search = '';
        this.isLoading = false;
        this.sortType = '';
        this.isSubmitEnable = false;
        this.loading = false;
    }
    //Add Incident Type
    IncidentTypeComponent.prototype.addIncidentModal = function (template, mode) {
        // tslint:disable-next-line: max-line-length
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg modal-dialog-centered roaster-modal',
        });
        if (mode === 'A') {
            this.incidentForm.reset();
            this.submitted = false;
        }
    };
    IncidentTypeComponent.prototype.onValueChange = function (data) {
        var _this = this;
        this.incidentForm.valueChanges.subscribe(function (val) {
            var changesExist = false;
            if (val.incident_name !== data.incident_name) {
                changesExist = true;
            }
            if (changesExist) {
                _this.isSubmitEnable = true;
            }
            else {
                _this.isSubmitEnable = false;
            }
        });
    };
    IncidentTypeComponent.prototype.editIncidentModal = function (template, value) {
        this.isSubmitEnable = false;
        this.incidentForm = this.formBuilder.group({
            id: value.id,
            incident_name: value.incident_name,
        });
        this.title = 'Edit';
        this.onValueChange(value);
        this.addIncidentModal(template, 'E');
    };
    IncidentTypeComponent.prototype.openDeleteModal = function (deleteCertificate, value) {
        this.selectedIncident = value;
        this.modalRef = this.modalService.show(deleteCertificate, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-dialog-centered roaster-modal',
        });
    };
    IncidentTypeComponent.prototype.getIncidentList = function () {
        var _this = this;
        this.loading = true;
        var params = { page: this.page, pagesize: this.pagesize };
        if (this.search && this.search.length > 0)
            params['search'] = this.search;
        if (this.sortType != '' && this.sortFiled != '') {
            params['sort'] = this.sortFiled + ':' + this.sortType;
        }
        this.apiService.get('incident/list', params).subscribe(function (data) {
            if (data['status'] === 200) {
                _this.incidentList = data.incidents;
                var pageDetails = data.pageDetails;
                _this.totalCount = pageDetails['total'];
            }
            else {
                _this.incidentList = [];
                _this.totalCount = 0;
            }
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
        });
    };
    IncidentTypeComponent.prototype.pageChange = function (newPage) {
        this.page = newPage;
        this.getIncidentList();
    };
    IncidentTypeComponent.prototype.upside = function (format, field) {
        this.sortType = format;
        this.sortFiled = field;
        this.getIncidentList();
    };
    IncidentTypeComponent.prototype.downSide = function (format, field) {
        this.sortType = format;
        this.sortFiled = field;
        this.getIncidentList();
    };
    IncidentTypeComponent.prototype.onReset = function () {
        this.submitted = false;
        this.incidentForm.reset();
    };
    IncidentTypeComponent.prototype.closeForm = function () {
        this.incidentForm.reset();
        this.submitted = false;
        this.modalRef.hide();
        this.selectedIncident = {};
        this.title = 'Add';
        this.isLoading = false;
    };
    IncidentTypeComponent.prototype.addEditIncident = function () {
        var _this = this;
        this.submitted = true;
        if (this.incidentForm.invalid) {
            return;
        }
        var incidentData = this.incidentForm.value;
        if (!this.incidentForm.value.id) {
            this.isLoading = true;
            this.apiService.post('incident', incidentData).subscribe(function (data) {
                if (data.status === 200) {
                    _this.toastr.success(data.message);
                    _this.closeForm();
                    _this.getIncidentList();
                }
                _this.isLoading = false;
            }, function (err) {
                if (err.error.message) {
                    _this.toastr.error(err.error.message);
                }
                _this.isLoading = false;
            });
        }
        else {
            this.isLoading = true;
            this.apiService.put('incident/update/' + incidentData.id, incidentData).subscribe(function (editData) {
                if (editData.status === 200) {
                    _this.toastr.success(editData.message);
                    _this.closeForm();
                    _this.getIncidentList();
                }
                _this.isLoading = false;
            }, function (errMsg) {
                if (errMsg.error.message) {
                    _this.toastr.error(errMsg.error.message);
                }
                _this.isLoading = false;
            });
        }
    };
    IncidentTypeComponent.prototype.searchText = function (value) {
        this.search = value;
        this.page = 1;
        this.getIncidentList();
    };
    IncidentTypeComponent.prototype.omit_special_char = function (event) {
        var k = event.charCode;
        var value = event.target.value;
        if (value.length === 0) {
            if (k === 32) {
                return false;
            }
        }
        else {
            return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k >= 96 && k <= 105) || k == 32 || k == 13);
        }
    };
    IncidentTypeComponent.prototype.deleteIncidentType = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.delete('incident/delete/' + this.selectedIncident.id).subscribe(function (deletefile) {
            _this.toastr.clear();
            if (deletefile.status === 200) {
                _this.toastr.success(deletefile.message);
                _this.closeForm();
                _this.getIncidentList();
            }
            else {
                _this.toastr.error(deletefile.message);
            }
            _this.isLoading = false;
        }, function (err) {
            _this.isLoading = false;
            _this.toastr.error(err.message);
        });
    };
    IncidentTypeComponent.prototype.createForm = function () {
        this.incidentForm = this.formBuilder.group({
            id: [''],
            incident_name: ['', Validators.required],
        });
    };
    Object.defineProperty(IncidentTypeComponent.prototype, "f", {
        get: function () {
            return this.incidentForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    IncidentTypeComponent.prototype.ngOnInit = function () {
        this.getIncidentList();
        this.createForm();
    };
    return IncidentTypeComponent;
}());
export { IncidentTypeComponent };
