<div class="container-fluid p-4">
  <div class="mt-5 c-pointer" (click)="backiconclick()">
    <img src="assets/images/backicon.svg" alt="backicon" />
    <span class="fs14 pl-2 color-dark-blue">Back</span>
  </div>
  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border" *ngIf="!isEdit">Add Client</h1>
  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border" *ngIf="isEdit">Edit Client</h1>
  <p class="color-grey-text">
    Below you can Manage Organization related information like Domain Name,Time Format and TimeZone.
  </p>
  <div class="row px-3 pt-3">
    <div class="col-lg-8 bg-white">
      <form [formGroup]="customercreationform" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Name</label>
              <input
                type="text"
                formControlName="Name"
                class="form-control customercreateform"
                placeholder="Enter Name"
                [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
              />
              <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                <div *ngIf="f.Name.errors.required">Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Staff Count</label>
              <input
                type="text"
                formControlName="staff_count"
                class="form-control customercreateform"
                placeholder="Enter Staff Count"
                [ngClass]="{ 'is-invalid': submitted && f.staff_count.errors }"
              />
              <div *ngIf="submitted && f.staff_count.errors" class="invalid-feedback">
                <div *ngIf="f.staff_count.errors.required">Staff Count is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Company Name</label>
              <input
                type="text"
                formControlName="CompanyName"
                class="form-control customercreateform"
                placeholder="Enter Company Name"
                [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }"
              />
              <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
                <div *ngIf="f.CompanyName.errors.required">Company Name is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Company Website</label>
              <input
                type="text"
                formControlName="CompanyWebsite"
                class="form-control customercreateform"
                placeholder="Enter Company Website"
                [ngClass]="{ 'is-invalid': submitted && f.CompanyWebsite.errors }"
              />
              <div *ngIf="submitted && f.CompanyWebsite.errors" class="invalid-feedback">
                <div *ngIf="f.CompanyWebsite.errors.required">Company Website is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label class="fs16 color-black">Mobile Number</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="btn-group about-more number-flag" dropdown>
                  <button
                    id="button-basic"
                    dropdownToggle
                    type="button"
                    class="btn btn-primary custombtninputgrp dropdown-toggle border bg-transparent fs14 text-dark"
                    aria-controls="dropdown-basic"
                  >
                    <img src="assets/images/country-flags/{{ countryFlag }}.png" alt="country" /> {{ countryCode }}
                    <em class="fas fa-caret-down ml-1 op-05"></em>
                  </button>
                  <ul
                    id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="button-basic"
                    formControlName="countryCode"
                    [ngClass]="{ 'is-invalid': submitted && f.countryCode.errors }"
                  >
                    <li role="menuitem" *ngFor="let code of phoneCode">
                      <span (click)="setCountryInfo(code)">
                        <img src="assets/images/country-flags/{{ code.countries_iso_code }}.png" alt="country" />
                        {{ code.countries_isd_code }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="number"
                (keypress)="isNumberKey($event.keyCode, $event.target.value)"
                formControlName="MobileNumber"
                class="form-control custominputgroup customercreateform"
                placeholder="Mobile Number"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Email</label>
              <input
                type="text"
                formControlName="Email"
                class="form-control customercreateform"
                placeholder="Enter Email"
                [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
              />
              <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                <div *ngIf="f.Email.errors.required">Email is required</div>
                <div *ngIf="f.Email.errors.pattern">Email is not valid</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Domain</label>
              <input
                type="text"
                formControlName="Domain"
                class="form-control customercreateform"
                placeholder="Enter Domain"
                [ngClass]="{ 'is-invalid': submitted && f.Domain.errors }"
              />
              <div *ngIf="submitted && f.Domain.errors" class="invalid-feedback">
                <div *ngIf="f.Domain.errors.required">Domain is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Time Format</label>
              <input
                type="text"
                (click)="timeformatModal(Timeformat)"
                formControlName="TimeFormat"
                class="form-control customercreateform"
                placeholder="Enter Time Format"
                [ngClass]="{ 'is-invalid': submitted && f.TimeFormat.errors }"
              />
              <div *ngIf="submitted && f.TimeFormat.errors" class="invalid-feedback">
                <div *ngIf="f.TimeFormat.errors.required">Time Format is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Time Zone</label>
              <input
                type="text"
                (click)="timezoneModal(Timezone)"
                formControlName="TimeZone"
                class="form-control customercreateform"
                placeholder="Enter Time Zone"
                [ngClass]="{ 'is-invalid': submitted && f.TimeZone.errors }"
              />
              <div *ngIf="submitted && f.TimeZone.errors" class="invalid-feedback">
                <div *ngIf="f.TimeZone.errors.required">Time Zone is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mb-4">
              <label class="fs16 color-black">Date Format</label>
              <input
                type="text"
                (click)="dateformatModal(Dateformat)"
                formControlName="DateFormat"
                class="form-control customercreateform"
                placeholder="Enter Date Format"
                [ngClass]="{ 'is-invalid': submitted && f.DateFormat.errors }"
              />
              <div *ngIf="submitted && f.DateFormat.errors" class="invalid-feedback">
                <div *ngIf="f.DateFormat.errors.required">Date Format is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-4 d-flex justify-content-start">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-140px mr-3"
            tooltip="Cancel"
            (click)="closeModel()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-secondary btn-create border-0 w-140px fs14" tooltip="Submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #Timeformat>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Time Format</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timeformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timeformat
        (messageEvent)="receivetimeformat($event)"
        (timedformat)="receivetime($event)"
        [updatetimeformat]="timeformatvalue"
      ></app-timeformat>
    </div>
  </ng-template>
  <ng-template #Dateformat>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Date Format</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="dateformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-dateformat
        (messageEvent)="receivedateformat($event)"
        (datesformat)="receivedate($event)"
        [updatedateformat]="dateformatvalue"
      ></app-dateformat>
    </div>
  </ng-template>
  <ng-template #Timezone>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Time Zone</h4>
      <button
        type="button"
        class="close text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timezonepopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timezone (messageEvent)="receivetimezonevalue($event)" [timezonevalue]="message3"></app-timezone>
    </div>
  </ng-template>
</div>
