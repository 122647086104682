<div class="card mb-3 border-0">
  <div class="card-header bg-white px-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="text-dark font-weight-bold fs16 mb-0">ABOUT</h6>
      <div class="position-relative about-more" dropdown>
        <button
          type="button"
          class="btn btn-primary btn-more"
          id="button-basic"
          dropdownToggle
          aria-controls="dropdown-basic"
          tooltip="More"
        >
          More <em class="fas fa-caret-down ml-1 op-05"></em>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" (click)="editClientModal(editClient)">
            <a class="dropdown-item"><em class="fas fa-suitcase mr-2"></em>Edit Client Details</a>
          </li>
          <li role="menuitem" (click)="changeLoginInfo(loginInfo)">
            <a class="dropdown-item"><em class="fas fa-user mr-2"></em>Change Login Info</a>
          </li>

          <li role="menuitem" (click)="openDeleteModal(deleteModal)" *ngIf="!clientInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-trash mr-2"></em>Remove</a>
          </li>
          <li role="menuitem" (click)="openDeleteModal(revertModel)" *ngIf="clientInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-undo mr-2"></em>Restore</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-body text-dark px-md-5 px-3">
    <div class="row">
      <div class="col-md-6 p-1">
        <div class="bg-user">
          <h2 class="text-dark fs16 m-0">{{ clientInfo?.contact_person ? clientInfo.contact_person : '-' }}</h2>
          <span class="text-secondary fs14">Contact</span>
        </div>
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs16 m-0">
            {{ clientInfo?.mobilecode?.countries_isd_code }} {{ clientInfo?.mobile ? clientInfo?.mobile : '-' }}
          </h2>
          <span class="text-secondary fs14">Mobile</span>
        </div>
      </div>
      <div class="col-md-6 p-1">
        <div class="bg-icon-call">
          <h2 class="text-dark fs16 m-0">
            {{ clientInfo?.phonecode?.countries_isd_code }} {{ clientInfo?.phone ? clientInfo?.phone : '-' }}
          </h2>
          <span class="text-secondary fs14">Phone</span>
        </div>
        <div class="bg-icon-envelope mt-4">
          <h2 class="text-dark fs16 m-0">{{ clientInfo?.email || '-' }}</h2>
          <span class="text-secondary fs14">Email</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loginInfo>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Change Login Info</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-change-login-info [editClientInfo]="clientInfo"></app-change-login-info>
  </div>
</ng-template>

<ng-template #editClient>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Edit Client</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-client [editClientInfo]="clientInfo"></app-add-client>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Delete user</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete(hiding) user?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeModel()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="removeClient()"
    >
      Yes
    </button>
  </div>
</ng-template>

<ng-template #revertModel>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Restore user</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to restore user?</p>
    <button type="button" class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" (click)="closeModel()">
      No
    </button>
    <button type="submit" class="btn btn-secondary btn-create border-0 w-100px fs14" (click)="revertClient()">
      Yes
    </button>
  </div>
</ng-template>
