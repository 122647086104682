<div class="card border-0 mb-3 layout-card-2">
  <div class="card-header bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="text-dark font-weight-bold fs16 mb-0">ROLE</h6>
      <div>
        <ul class="list-unstyled d-inline-flex float-right m-0">
          <li class="mr-2">
            <button type="button" class="btn btn-primary btn-more" tooltip="Add" (click)="openModal(template)">
              <em class="fas fa-plus mr-2"></em>Add
            </button>
            <ng-template #template class="modal-content">
              <div class="modal-header bg-primary">
                <h4 class="modal-title fs16 text-center text-white">Add Role</h4>
                <button type="button" class="close pull-right text-white" aria-label="Close" (click)="clearForm()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body w-100 modal-lg">
                <form [formGroup]="locationForm" (ngSubmit)="createLocation()">
                  <div class="form-group">
                    <label
                      for="exampleInputEmail1"
                      class="font-weight-bold"
                      [ngClass]="{ 'text-danger': isSubmitted && f.name.errors }"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder=""
                      formControlName="name"
                      [ngClass]="{ 'invalid-field': isSubmitted && f.name.errors }"
                      (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                    />
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-6">
                        <label for="exampleFormControlTextarea1" class="font-weight-bold">Address</label>
                      </div>
                      <div class="col-md-6 float-right">
                        <small id="emailHelp" class="form-text text-primary float-right text-right fs10"
                          >* The map location will be updated on change. Please ensure it is correct.</small
                        >
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputPassword1"
                      placeholder=""
                      formControlName="address"
                      (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                      (input)="checkLatLong($event.target.value)"
                    />
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <label for="exampleFormControlTextarea1" class="font-weight-bold">Exact Location</label>
                        <div class="float-right">
                          <small id="emailHelp" class="form-text text-primary float-right text-right fs10"
                            >* The map location will be updated on change. Please ensure it is correct.</small
                          >
                        </div>
                        <agm-map
                          class="agm-map"
                          [latitude]="latitude"
                          [longitude]="longitude"
                          [mapDraggable]="true"
                          [scrollwheel]="true"
                          [zoom]="zoom"
                          [mapTypeControl]="true"
                          [zoomControl]="true"
                          [streetViewControl]="true"
                          (mapClick)="addMarker($event.coords.lat, $event.coords.lng)"
                        >
                          <agm-marker [latitude]="marklatitude" [longitude]="marklongitude"></agm-marker>
                        </agm-map>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="font-weight-bold">Default Breif</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      formControlName="default_brief"
                      (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                    ></textarea>
                  </div>
                  <div class="float-left">
                    <p class="text-secondary">
                      The default brief gives you the option to provide a regular briefing for this role. It will be
                      shared with the staff using the mobile app as well as in notifications about new shifts.
                    </p>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="is_clockin" />
                    <label class="form-check-label font-weight-bold" for="exampleCheck1">Clock In</label>
                    If ticked, reqiures the staff member to clock in using the mobile app to start and end the shift.
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck2"
                      formControlName="is_clock_at_location"
                    />
                    <label class="form-check-label font-weight-bold" for="exampleCheck2">Clock In</label>
                    Only At Location.
                  </div>
                  <div class="clearfix"></div>
                  <div>
                    <ul class="d-inline-flex list-unstyled">
                      <li class="font-weight-bold mr-4">Clock in Available From</li>
                      <li class="border border-secondary">
                        <select
                          id="inputState"
                          class="form-control text-dark font-weight-bold"
                          formControlName="clock_available"
                        >
                          <option value="" selected disabled>--Select Available Time--</option>
                          <option [ngValue]="time" *ngFor="let time of availableTime">{{ time }}</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck3"
                      formControlName="is_exclude_time_limit"
                    />
                    <label class="form-check-label" for="exampleCheck3"
                      ><span class="font-weight-bold mr-2"> Exclude From Time Limits</span
                      ><span class="text-secondary"
                        >Time limits are legal or contractual limitations of maximum working hours for staff. If you
                        tick this box, this role will not be considered for any time limitations. For example can be
                        applied for "Leave" or "Training".</span
                      ></label
                    >
                  </div>
                  <div class="clearfix"></div>
                  <div class="float-right">
                    <ul class="d-inline-flex list-unstyled">
                      <li class="mr-2">
                        <button
                          type="button"
                          class="btn btn-primary bg-secondary text-white"
                          tooltip="Cancel"
                          (click)="clearForm()"
                        >
                          Cancel
                        </button>
                      </li>
                      <li>
                        <button type="submit" [disabled]="loading" class="btn btn-primary" tooltip="Submit">
                          Submit
                        </button>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </ng-template>
          </li>
          <li><em class="fas fa-compress circle-icon compress-icon"></em></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-header bg-light pb-0" *ngIf="locationList?.length > 0">
    <div class="row">
      <div class="col-md-6">
        <p class="float-left">Name</p>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
  <div class="card-body text-dark" *ngFor="let location of locationList">
    <div class="row align-items-center c-pointer" (click)="viewLocation(location.id)">
      <div class="col-md-6">
        <div class="d-flex align-items-center">
          <em class="fas fa-map-marker circle-icon map-icon mr-3"></em>
          <ul class="list-unstyled m-0">
            <li class="tel">
              <a href="javascript:void(0)" class="text-dark text-decoration-none">{{ location?.name }}</a>
            </li>
            <li class="text-secondary fs10">{{ location?.address }}</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 text-right">
        <a href="javascript:void" class="text-success fs12 c-pointer">
          <em class="fas fa-eye" tooltip="View"></em> VIEW MORE
        </a>
      </div>
    </div>
    <h6 class="text-primary ml-5 mt-2 mb-2" *ngIf="location?.locations?.length > 0">
      <em class="fas fa-arrow-circle-right mr-2"></em>REQUIREMENTS
    </h6>
    <div class="row" *ngIf="location?.locations?.length > 0">
      <ng-container *ngFor="let sublocation of location.locations; let odd = odd; let even = even">
        <div
          class="col-md-6 c-pointer"
          [ngClass]="{ 'float-right mb-2': even == even, 'float-left mb-2': odd == odd }"
          (click)="viewSubLocation(sublocation.id)"
        >
          <div class="row ml-4 bg-light mr-2 p-3">
            <div class="d-inline-flex">
              <div class="col-md-2 col-3 mr-2">
                <em class="fas fa-map-marker circle-icon map-icon"></em>
              </div>
              <div class="col-md-10 pr-0 col-9">
                <ul class="list-unstyled">
                  <li class="tel">
                    <a href="javascript:void(0)" class="text-dark text-decoration-none">{{ sublocation?.name }}</a>
                  </li>
                  <li class="text-secondary fs10">{{ sublocation?.address }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="locationList?.length === 0">
    <p>No roles added so far.</p>
  </div>
</div>
