<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="backUrl()"><a>Location Detail</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-user mr-1"></em>Role Detail</li>
  </ol>
</nav>
<div class="roaster-body client-layout">
  <p class="color-red" *ngIf="locationInfo.is_deleted">
    This role is hidden and won't be shown as an option to select.
  </p>
  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">{{ locationInfo?.name }}</h1>
  <p class="color-grey-text">
    Any specific information related to this role is managed below. You can also manage required certifications and
    blacklistings for this role here.
  </p>
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <app-location-about
        [locationInfo]="locationInfo"
        [clientId]="clientId"
        [locationId]="locationId"
      ></app-location-about>
      <app-location-sublocation
        [locationList]="locationList"
        [clientId]="clientId"
        [locationId]="locationId"
        [locationInfo]="locationInfo"
      ></app-location-sublocation>
      <app-location-incidents
        [clientId]="clientId"
        [locationId]="locationId"
        [incidentsList]="incidentsList"
        [clientInfo]="clientInfo"
      ></app-location-incidents>
    </div>
    <div class="col-md-4 col-12">
      <app-location-certification
        [certificateList]="certificateList"
        [clientId]="clientId"
        [locationCertificateList]="locationCertificateList"
        [locationId]="locationId"
      ></app-location-certification>
      <app-location-files
        [fileList]="fileList"
        [clientId]="clientId"
        [locationFileList]="locationFileList"
        [locationId]="locationId"
      ></app-location-files>
      <app-location-blacklisting
        [blackList]="blackList"
        [clientId]="clientId"
        [locationBlackList]="locationBlackList"
        [locationId]="locationId"
      ></app-location-blacklisting>
    </div>
  </div>
</div>
