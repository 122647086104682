import { OnInit, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, S3UploadService } from '../services';
import { environment } from '../../environments/environment';
import { humanizeBytes } from 'ngx-uploader';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { validFormat } from '../constants/defaultValues';
var AddIncidentComponent = /** @class */ (function () {
    function AddIncidentComponent(apiService, s3upload, router, toastr, modalService, formBuilder) {
        this.apiService = apiService;
        this.s3upload = s3upload;
        this.router = router;
        this.toastr = toastr;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.isSubmitted = false;
        this.incidentTypeList = [];
        this.clientList = [];
        this.locationList = [];
        this.staffList = [];
        this.fileIds = [];
        this.loading = false;
        this.minDate = new Date();
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = '';
        this.fileUrl = environment.fileUrl;
        this.uploadfileList = [];
        this.imageObject = {};
        this.progress = [{ progress: 0 }];
        this.multiFileIds = [];
        this.userGroup = localStorage.getItem('userGroup');
        this.userId = localStorage.getItem('userid');
        this.cancelModel = new EventEmitter();
        //this.minDate.setDate(this.minDate.getDate());
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    AddIncidentComponent.prototype.closeModal = function () {
        this.apiService.clearList();
    };
    AddIncidentComponent.prototype.ngOnInit = function () {
        var _this = this;
        var format = validFormat.toString();
        this.supportFormat = format.replace(/,/g, ', ');
        this.createForm();
        this.getClientList();
        this.getIncidentType();
        this.getStaffList();
        if (this.userGroup === 'Client') {
            this.clientId = parseInt(this.userId);
            this.incidentsForm.controls['client_id'].setValue(this.clientId);
            this.getLocationList(this.clientId);
        }
        this.s3upload.progress.subscribe(function (progress) {
            if (progress['type'] === 3) {
                _this.progress[progress['index']] = progress;
                if (_this.progress && _this.progress.length > 0) {
                    var completeUpload_1 = [];
                    _this.progress.forEach(function (key) {
                        if (key && key['progress']) {
                            if (key['progress'] === 100) {
                                completeUpload_1.push(key);
                                if (_this.progress && _this.progress.length === completeUpload_1.length) {
                                    _this.loading = false;
                                }
                            }
                        }
                    });
                }
            }
        });
    };
    AddIncidentComponent.prototype.getIncidentType = function () {
        var _this = this;
        this.apiService.get('incident').subscribe(function (incidenttype) {
            if (incidenttype['status'] === 200) {
                _this.incidentTypeList = incidenttype['incidents'];
            }
        });
    };
    AddIncidentComponent.prototype.getClientList = function () {
        var _this = this;
        this.apiService.get('client/list').subscribe(function (client) {
            if (client['status'] === 200) {
                _this.clientList = client['clients'];
            }
        });
    };
    AddIncidentComponent.prototype.getLocationList = function (clientId) {
        var _this = this;
        if (clientId) {
            this.apiService.get('/client/' + clientId + '/location', { isParent: true }).subscribe(function (location) {
                if (location['status'] === 200) {
                    _this.locationList = location['location'];
                    _this.incidentsForm.controls['location_id'].setValue('');
                }
                else {
                    _this.locationList = [];
                    _this.incidentsForm.controls['location_id'].setValue('');
                }
            });
        }
    };
    AddIncidentComponent.prototype.getStaffList = function () {
        var _this = this;
        this.apiService.get('/staff/list').subscribe(function (staff) {
            if (staff['status'] === 200) {
                _this.staffList = staff['staffs'];
            }
        });
    };
    AddIncidentComponent.prototype.changeClient = function () {
        this.getLocationList(this.incidentsForm.value['client_id']);
    };
    AddIncidentComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    AddIncidentComponent.prototype.createForm = function () {
        this.incidentsForm = this.formBuilder.group({
            incident_date: ['', Validators.required],
            staff_id: ['', Validators.required],
            location_id: ['', [Validators.required]],
            client_id: ['', [Validators.required]],
            incident_type_id: ['', [Validators.required]],
            description: [''],
        });
    };
    Object.defineProperty(AddIncidentComponent.prototype, "f", {
        get: function () {
            return this.incidentsForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddIncidentComponent.prototype.clearForm = function () {
        this.files = [];
        this.incidentsForm.reset({ client_id: '', location_id: '', staff_id: '', incident_type_id: '' });
        this.isSubmitted = false;
        this.fileError = false;
        this.fileIds = [];
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.uploadfileList = [];
        this.imageObject = {};
        this.multiFileIds = [];
        this.progress = [];
        this.apiService.shiftModal.emit();
    };
    AddIncidentComponent.prototype.isLinkActive = function () {
        var activeUrl = this.router.url.split('/')[1];
        var clientIndex = activeUrl.indexOf('client');
        var staffIndex = activeUrl.indexOf('staff');
        if (clientIndex > -1) {
            this.apiService.closeClientModel.emit(4);
        }
        if (staffIndex > -1) {
            this.apiService.closeClientModel.emit(5);
        }
    };
    AddIncidentComponent.prototype.createIncidents = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.incidentsForm.invalid) {
            return;
        }
        var datavalue = this.incidentsForm.value;
        if (this.uploadfileList && this.uploadfileList.length > 0) {
            this.fileIds = [];
            this.uploadfileList.forEach(function (item) {
                var data = { file_path: item.file_path, name: item.name, type: item.type };
                _this.fileIds.push(data);
            });
            datavalue['file_ids'] = this.fileIds;
        }
        if (datavalue['incident_date']) {
            /*let date = datavalue['incident_date'].getDate();
            date = date > 9 ? date : '0' + date;
            let month = datavalue['incident_date'].getMonth() + 1;
            month = month > 9 ? month : '0' + month;
            datavalue['incident_date'] = datavalue['incident_date'].getFullYear() + '-' + month + '-' + date;*/
            datavalue['incident_date'] = formatDate(datavalue['incident_date'], 'yyyy-MM-dd', 'en');
        }
        this.loading = true;
        this.apiService.post('incident/report', datavalue).subscribe(function (incident) {
            _this.loading = false;
            if (incident['status'] === 200) {
                _this.closeModal();
                _this.toastr.success(incident['message']);
                _this.isLinkActive();
                _this.clearForm();
            }
        });
    };
    AddIncidentComponent.prototype.uploadS3Bucket = function (file, index) {
        var _this = this;
        var fileType = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (fileType === 'application')
            fileType = 'document';
        var type = 3;
        this.s3upload
            .uploadfile(file, index, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: file['name'], type: fileType, format: fileExtension };
            _this.uploadfileList[index] = fileData;
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    AddIncidentComponent.prototype.readURL = function (file, index) {
        var _this = this;
        var type = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (type === 'application')
            type = 'document';
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList[index] = { imageSrc: reader.result, name: file['name'], type: type, format: fileExtension };
        };
        reader.readAsDataURL(file);
    };
    AddIncidentComponent.prototype.onUploadOutput = function (output) {
        var _this = this;
        if (output.type === 'allAddedToQueue') {
            if (this.files && this.files.length > 0) {
                var formatErrors_1 = [];
                var sizeErros_1 = [];
                this.files.forEach(function (key, index) {
                    var fileSize = key.size / 1024 / 1024;
                    var fileExtension = key.name.replace(/^.*\./, '');
                    if (!validFormat.includes(fileExtension)) {
                        _this.fileError = true;
                        _this.fileFormatError = true;
                        formatErrors_1.push(true);
                        _this.removeFile(key['id']);
                    }
                    else {
                        if (fileSize < 5) {
                            var fileIndex = _this.multiFileIds.findIndex(function (obj) { return obj === key['id']; });
                            if (sizeErros_1.length > 0 || formatErrors_1.length > 0) {
                                _this.fileError = true;
                            }
                            else {
                                _this.fileError = false;
                            }
                            if (sizeErros_1.length > 0) {
                                _this.fileSizeError = true;
                            }
                            else {
                                _this.fileSizeError = false;
                            }
                            if (formatErrors_1.length > 0) {
                                _this.fileFormatError = true;
                            }
                            else {
                                _this.fileFormatError = false;
                            }
                            if (fileIndex === -1) {
                                _this.multiFileIds.push(key['id']);
                                _this.readURL(key['nativeFile'], index);
                                _this.uploadS3Bucket(key['nativeFile'], index);
                            }
                        }
                        else {
                            _this.fileError = true;
                            _this.fileSizeError = true;
                            sizeErros_1.push(true);
                            _this.removeFile(key['id']);
                        }
                    }
                });
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            // add file to array when added
            this.files.push(output.file);
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            var index = this.files.findIndex(function (file) { return typeof output.file !== 'undefined' && file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    AddIncidentComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    AddIncidentComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    AddIncidentComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    AddIncidentComponent.prototype.deleteUploadFile = function (fileIds, index, fileindex) {
        var _this = this;
        this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe(function (deleteFiles) {
            if (fileIds && fileIds.length === 1) {
                _this.uploadfileList.splice(index, 1);
                _this.fileIds.splice(index, 1);
            }
        });
    };
    AddIncidentComponent.prototype.uploadCancel = function () {
        this.clearForm();
        this.cancelModel.next();
    };
    AddIncidentComponent.prototype.removeUploadFile = function (file, index) {
        this.removeFile(this.files[index]['id']);
        this.uploadfileList.splice(index, 1);
    };
    return AddIncidentComponent;
}());
export { AddIncidentComponent };
