<!-- Start New Shift -->
<div class="p-4">
  <div class="d-flex flex-md-row flex-column justify-content-md-between align-self-center mb-4">
    <div class="mb-3 mb-md-0">
      <h1 class="color-black op-09 fs16 mb-0">{{ 'New Shift' }}</h1>
      <p class="color-black op-05 fs14 mb-0">
        The following form allows you to add one-off as well as permanent shifts.
      </p>
    </div>
    <button type="button" class="btn btn-outline-info btn-outline-blue fs14" (click)="pastShift()">
      {{ buttonLabel }}
    </button>
  </div>
  <form [formGroup]="shiftForm" (ngSubmit)="onSubmit()" class="modal-form mt-3">
    <div class="form-row">
      <div class="form-group col-md-4">
        <label [ngClass]="{ 'text-danger': submitted && f.client_id.errors }" for="client">Location</label>
        <select
          id="client"
          class="form-control"
          [ngClass]="{ 'invalid-field': submitted && f.client_id.errors }"
          formControlName="client_id"
          (change)="clientChange($event.target.value)"
        >
          <option value="">Select a Location</option>
          <ng-container *ngFor="let client of clientList">
            <option [value]="client.id">{{ client.name }}</option>
          </ng-container>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label [ngClass]="{ 'text-danger': submitted && f.location_id.errors }" for="location">Role</label>
        <select
          id="location"
          class="form-control"
          formControlName="location_id"
          [ngClass]="{ 'invalid-field': submitted && f.location_id.errors }"
          (change)="locationChange($event.target.value)"
        >
          <option value="">Select a Role</option>
          <ng-container *ngFor="let location of clientLocationList">
            <option [value]="location.id">{{ location.name }}</option>
          </ng-container>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="sublocation">Requirement</label>
        <select id="sublocation" class="form-control" formControlName="sub_location_id">
          <option value="">Select a Requirement</option>
          <ng-container *ngFor="let location of subLocation">
            <option [value]="location.id">{{ location.name }}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="event"
          >Event
          <span class="font-weight-normal">(Optional)</span>
        </label>
        <input
          *ngIf="eventName === ''"
          type="text"
          class="form-control"
          placeholder="Enter Event Name"
          formControlName="event"
          id="event"
        />
        <input *ngIf="eventName !== ''" type="text" class="form-control" formControlName="event" id="event" />
      </div>
      <div class="form-group col-md-6" *ngIf="!isPast">
        <label for="code">Code</label>
        <input type="text" class="form-control" placeholder="Enter Code" formControlName="code" id="shift_code" />
      </div>
      <div class="form-group col-md-6" *ngIf="isPast">
        <label [ngClass]="{ 'text-danger': submitted && f.staff_id.errors }" for="client">Staff</label>
        <select
          id="client"
          class="form-control"
          [ngClass]="{ 'invalid-field': submitted && f.staff_id.errors }"
          formControlName="staff_id"
        >
          <option value="" selected disabled>Select a Staff</option>
          <ng-container *ngFor="let staff of staffList">
            <option [value]="staff.id">{{ staff.firstName }} {{ staff.surName }}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="form-row" *ngIf="!isPast">
      <div class="form-group col-md-4 mb-md-2">
        <label for="event">Additional Notes</label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter Additional Notes"
          formControlName="additional_notes"
          id="additional_notes"
        />
      </div>

      <div class="form-group col-md-5 col-md-2 mb-md-2 drop-toggle" dropdown [insideClick]="true" dropdownToggle>
        <label for="sublocation" [ngClass]="{ 'text-danger': submitted && f.weekdays.errors }">Select Days</label>
        <input
          readonly
          placeholder="Select Days"
          type="text"
          class="form-control bg-white"
          [(ngModel)]="dayNames"
          [ngModelOptions]="{ standalone: true }"
          [ngClass]="{ 'invalid-field': submitted && f.weekdays.errors }"
          id="select-days"
        />
        <ul *dropdownMenu class="dropdown-menu w-100 p-3" role="menu">
          <li role="menuitem" *ngFor="let chekedCon of weekDays; let i = index">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                (change)="checkingDays(chekedCon, $event.target.checked)"
                class="custom-control-input"
                id="customCheck{{ i }}"
              />
              <label class="custom-control-label" for="customCheck{{ i }}">{{ chekedCon.value }}</label>
            </div>
          </li>
        </ul>
      </div>

      <div class="form-group col-md-2 col-md-3 mb-md-0">
        <label for="code" [ngClass]="{ 'text-danger': submitted && f.number_of_staffs.errors }"
          >Number of Staff (Max 50)</label
        >
        <input
          type="text"
          class="form-control"
          placeholder="Enter Number of Staff"
          formControlName="number_of_staffs"
          (keypress)="isValueCheck($event)"
          [ngClass]="{ 'invalid-field': submitted && f.number_of_staffs.errors }"
          id="number_of_staffs"
          mask="99"
        />
      </div>
    </div>
    <hr class="dashed-border my-md-4 mb-4" />
    <div class="form-row">
      <div class="form-group" [ngClass]="isPast ? 'col-md-4' : 'col-md-6'">
        <label for="st" [ngClass]="{ 'text-danger': submitted && f.startHr.errors }">Work - Start Time</label>
        <div class="row">
          <div class="" [ngClass]="isPast ? 'col-md-6 mb-4 mb-md-0' : 'col-md-5 mb-4 mb-md-0'">
            <select
              id="startHr"
              class="form-control"
              formControlName="startHr"
              (change)="selectStartTime($event.target.value)"
              [ngClass]="{ 'invalid-field': submitted && f.startHr.errors }"
            >
              <ng-container *ngFor="let startHr of hourList">
                <option *ngIf="+startHr > +showHour">{{ startHr }}</option>
              </ng-container>
            </select>
          </div>
          <div class="" [ngClass]="isPast ? 'col-md-6' : 'col-md-5 '">
            <select
              id="startMin"
              class="form-control"
              formControlName="startMin"
              (change)="selectStartMin($event.target.value)"
              [ngClass]="{ 'invalid-field': submitted && f.startHr.errors }"
            >
              <ng-container *ngFor="let startMin of minitsList">
                <option>{{ startMin }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group" [ngClass]="isPast ? 'col-md-4' : 'col-md-6'">
        <label for="et" [ngClass]="{ 'text-danger': submitted && f.endHr.errors }">Work - End Time</label>
        <div class="row">
          <div class="" [ngClass]="isPast ? 'col-md-6 mb-4 mb-md-0' : 'col-md-5 mb-4 mb-md-0'">
            <select
              id="endHr"
              class="form-control"
              [ngClass]="{ 'invalid-field': submitted && f.endHr.errors }"
              formControlName="endHr"
              (change)="selectendHr($event.target.value)"
            >
              <ng-container *ngFor="let endHr of endHourList">
                <option *ngIf="+endHr > +showHour">{{ endHr }}</option>
              </ng-container>
            </select>
          </div>
          <div class="" [ngClass]="isPast ? 'col-md-6' : 'col-md-5'">
            <select
              id="endMin"
              class="form-control"
              [ngClass]="{ 'invalid-field': submitted && f.endHr.errors }"
              formControlName="endMin"
            >
              <ng-container *ngFor="let endMin of endMinitsList">
                <option>{{ endMin }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div *ngIf="submitted && f.endHr.errors" class="invalid-feedback my-2">
          <div *ngIf="f.endHr.errors.toTimeError">Start Time Hour and End Time Hour Must Differ</div>
          <div *ngIf="f.startHr && f.startHr.errors && f.startHr.errors.startHrerrors">
            Start Time should be lesser than current time
          </div>
          <div *ngIf="f.endHr.errors.endHrerrors">End Time should be lesser than current time</div>
        </div>
      </div>

      <div class="form-group col-md-4" *ngIf="isPast">
        <label for="client" [ngClass]="{ 'text-danger': submitted && f.work_date.errors }">Date</label>
        <div class="input-group mb-0">
          <input
            type="text"
            class="form-control border-right-0 bg-white"
            #dp="bsDatepicker"
            bsDatepicker
            formControlName="work_date"
            [ngClass]="{ 'invalid-field': submitted && f.work_date.errors }"
            readonly
            [maxDate]="minDate"
            placeholder="Select From Date"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              showWeekNumbers: false,
              useUtc: true,
              selectFromOtherMonth: true
            }"
            placement="auto"
          />
          <div class="input-group-append">
            <span
              class="input-group-text bg-transparent border-left-0"
              [ngClass]="{ 'invalid-field': submitted && f.work_date.errors }"
              (click)="dp.toggle()"
              [attr.aria-expanded]="dp.isOpen"
            >
              <img src="assets/images/calendar.svg" alt="calendar" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="!isPast">
      <div class="form-group col-md-6">
        <label for="client" [ngClass]="{ 'text-danger': submitted && f.startDate.errors }">
          Duration - Start Date
        </label>
        <div class="input-group mb-0">
          <input
            type="text"
            class="form-control border-right-0 bg-white"
            #dp="bsDatepicker"
            bsDatepicker
            formControlName="startDate"
            [ngClass]="{ 'invalid-field': submitted && f.startDate.errors }"
            readonly
            [minDate]="minDate"
            [maxDate]="maxDate"
            placeholder="Select From Date"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              showWeekNumbers: false,
              selectFromOtherMonth: true
            }"
            placement="auto"
            (bsValueChange)="onDateSelect($event)"
          />
          <div class="input-group-append">
            <span
              class="input-group-text bg-transparent border-left-0"
              [ngClass]="{ 'invalid-field': submitted && f.startDate.errors }"
              (click)="dp.toggle()"
              [attr.aria-expanded]="dp.isOpen"
            >
              <img src="assets/images/calendar.svg" alt="calendar" />
            </span>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="client" [ngClass]="{ 'text-danger': submitted && f.endDate.errors }"> Duration - End Date </label>
        <div class="input-group mb-0">
          <input
            type="text"
            class="form-control border-right-0 bg-white"
            #dp1="bsDatepicker"
            bsDatepicker
            formControlName="endDate"
            [ngClass]="{ 'invalid-field': submitted && f.endDate.errors }"
            readonly
            [minDate]="eminDate"
            [maxDate]="maxDate"
            placeholder="Select End Date"
            [bsConfig]="{
              dateInputFormat: 'DD/MM/YYYY',
              showWeekNumbers: false,
              selectFromOtherMonth: true
            }"
            placement="auto"
          />
          <div class="input-group-append">
            <span
              class="input-group-text bg-transparent border-left-0"
              [ngClass]="{ 'invalid-field': submitted && f.endDate.errors }"
              (click)="action()"
              [attr.aria-expanded]="dp1.isOpen"
            >
              <img src="assets/images/calendar.svg" alt="calendar" />
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="submitted && f.endDate.errors" class="invalid-feedback my-2">
        <div *ngIf="f.endDate.errors.endDateError">End Date Must Greater Than Start Date</div>
      </div>
    </div>
    <div class="form-row btn-week" *ngIf="!isPast">
      <div class="form-group col-md-6">
        <button type="button" (click)="startWeek()" class="btn btn-primary btn-cancel border-0 fs14 mr-3 mb-4 mb-md-2">
          Current Week
        </button>
        <button type="button" (click)="clearStartDate()" class="btn btn-primary bg-green-light border-0 fs14 px-4 mb-2">
          Start Week
        </button>
      </div>
      <div class="form-group col-md-6">
        <button
          type="button"
          (click)="clearEndDate()"
          class="btn btn-primary bg-red border-0 fs14 px-4 mr-3 mb-4 mb-md-2"
        >
          Define Week
        </button>
        <button
          type="button"
          (click)="setPermanent()"
          [ngClass]="this.shiftForm.get('permanent').value === 0 ? 'btn-cancel' : 'btn-primary'"
          class="btn border-0 fs14 mb-2"
        >
          Permanent
        </button>
      </div>
    </div>
    <hr class="dashed-border my-3" />
    <div class="d-flex justify-content-end mt-3">
      <button
        type="button"
        (click)="closeModal()"
        class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
        tooltip="Cancel"
      >
        Cancel
      </button>
      <button
        type="submit"
        tooltip="Add"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 w-100px fs14"
        *ngIf="!isPast"
      >
        Create
      </button>
      <button
        type="submit"
        tooltip="Add"
        [disabled]="loading"
        class="btn btn-secondary btn-create border-0 w-100px fs14"
        *ngIf="isPast"
      >
        Create Past
      </button>
    </div>
  </form>
</div>
<!-- End New Shift -->
