import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-incident-type',
  templateUrl: './incident-type.component.html',
})
export class IncidentTypeComponent implements OnInit {
  modalRef: BsModalRef;
  incidentList = [];
  incidentForm: FormGroup;
  submitted = false;
  selectedIncident: any;
  // tslint:disable-next-line: max-line-length
  title = 'Add';
  page = 1;
  pagesize = 10;
  totalCount;
  search = '';
  isLoading = false;
  sortType: any = '';
  sortFiled;
  isSubmitEnable = false;
  public loading = false;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  //Add Incident Type
  addIncidentModal(template: TemplateRef<any>, mode) {
    // tslint:disable-next-line: max-line-length
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
    if (mode === 'A') {
      this.incidentForm.reset();
      this.submitted = false;
    }
  }
  onValueChange(data) {
    this.incidentForm.valueChanges.subscribe((val) => {
      let changesExist = false;
      if (val.incident_name !== data.incident_name) {
        changesExist = true;
      }

      if (changesExist) {
        this.isSubmitEnable = true;
      } else {
        this.isSubmitEnable = false;
      }
    });
  }
  editIncidentModal(template, value) {
    this.isSubmitEnable = false;
    this.incidentForm = this.formBuilder.group({
      id: value.id,
      incident_name: value.incident_name,
    });
    this.title = 'Edit';
    this.onValueChange(value);
    this.addIncidentModal(template, 'E');
  }

  openDeleteModal(deleteCertificate: TemplateRef<any>, value) {
    this.selectedIncident = value;
    this.modalRef = this.modalService.show(deleteCertificate, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered roaster-modal',
    });
  }

  getIncidentList() {
    this.loading = true;
    const params = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) params['search'] = this.search;
    if (this.sortType != '' && this.sortFiled != '') {
      params['sort'] = this.sortFiled + ':' + this.sortType;
    }

    this.apiService.get('incident/list', params).subscribe(
      (data: any) => {
        if (data['status'] === 200) {
          this.incidentList = data.incidents;
          const pageDetails = data.pageDetails;
          this.totalCount = pageDetails['total'];
        } else {
          this.incidentList = [];
          this.totalCount = 0;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getIncidentList();
  }

  upside(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getIncidentList();
  }

  downSide(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getIncidentList();
  }

  onReset() {
    this.submitted = false;
    this.incidentForm.reset();
  }

  closeForm() {
    this.incidentForm.reset();
    this.submitted = false;
    this.modalRef.hide();
    this.selectedIncident = {};
    this.title = 'Add';
    this.isLoading = false;
  }

  addEditIncident() {
    this.submitted = true;
    if (this.incidentForm.invalid) {
      return;
    }
    const incidentData = this.incidentForm.value;
    if (!this.incidentForm.value.id) {
      this.isLoading = true;
      this.apiService.post('incident', incidentData).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastr.success(data.message);
            this.closeForm();
            this.getIncidentList();
          }
          this.isLoading = false;
        },
        (err) => {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
          this.isLoading = false;
        },
      );
    } else {
      this.isLoading = true;
      this.apiService.put('incident/update/' + incidentData.id, incidentData).subscribe(
        (editData: any) => {
          if (editData.status === 200) {
            this.toastr.success(editData.message);
            this.closeForm();
            this.getIncidentList();
          }
          this.isLoading = false;
        },
        (errMsg) => {
          if (errMsg.error.message) {
            this.toastr.error(errMsg.error.message);
          }
          this.isLoading = false;
        },
      );
    }
  }

  searchText(value) {
    this.search = value;
    this.page = 1;
    this.getIncidentList();
  }

  omit_special_char(event) {
    const k: any = event.charCode;
    const value: any = event.target.value;
    if (value.length === 0) {
      if (k === 32) {
        return false;
      }
    } else {
      return (
        (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k >= 96 && k <= 105) || k == 32 || k == 13
      );
    }
  }

  deleteIncidentType() {
    this.isLoading = true;
    this.apiService.delete('incident/delete/' + this.selectedIncident.id).subscribe(
      (deletefile: any) => {
        this.toastr.clear();
        if (deletefile.status === 200) {
          this.toastr.success(deletefile.message);
          this.closeForm();
          this.getIncidentList();
        } else {
          this.toastr.error(deletefile.message);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(err.message);
      },
    );
  }

  createForm() {
    this.incidentForm = this.formBuilder.group({
      id: [''],
      incident_name: ['', Validators.required],
    });
  }

  get f() {
    return this.incidentForm.controls;
  }

  ngOnInit() {
    this.getIncidentList();
    this.createForm();
  }
}
