<section class="mbr-section content4 cid-rTobe8ZpbN" id="content4-4">
  <div class="container">
    <div class="media-container-row">
      <div class="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
        <h4>HELP & SUPPORT</h4>
      </div>
    </div>
  </div>
</section>
<section class="mbr-section article content1 cid-rToc317gOj" id="content1-8">
  <div class="container">
    <div class="media-container-row">
      <div class="mbr-text col-12 mbr-fonts-style display-7 col-md-12">
        <p><strong>Privacy Policy</strong></p>
        <p>
          Please read our privacy policy to learn more how we treat personally identifiable information collected from
          our visitors and users.
        </p>
        <p><a href="http://r-101.com/privacy-policy" target="_blank">Read Privacy Policy</a></p>

        <p><strong>Contact Us</strong></p>
        <p>
          <span class="fs16">For any support please contact us at</span><strong> darryl@firstcontact.co.nz</strong
          ><span class="fs16">.</span><br />
        </p>
      </div>
    </div>
  </div>
</section>
