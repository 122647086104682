import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as io from 'socket.io-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  reportValue: any;
  private subject = new Subject<any>();

  public shiftModal: EventEmitter<any> = new EventEmitter<any>();
  public searchValue: EventEmitter<any> = new EventEmitter<any>();
  public closeClientModel: EventEmitter<any> = new EventEmitter<any>();
  public headerModel: EventEmitter<any> = new EventEmitter<any>();
  public profileUpdates: EventEmitter<any> = new EventEmitter<any>();
  public profilechangemodal: EventEmitter<any> = new EventEmitter<any>();
  public domainModel: EventEmitter<any> = new EventEmitter<any>();
  scheduleSubject = new BehaviorSubject<any>(null);
  schedule = this.scheduleSubject.asObservable().pipe(distinctUntilChanged());

  url = environment.url;
  private socket;
  userId = localStorage.getItem('userid');
  userLogId = localStorage.getItem('userLogId');

  constructor(public http: HttpClient) {
    this.socket = io.connect(environment.socketUrl);

    this.socket.on('connect', (data) => {
      console.info('Socket Connected');
      this.socket.emit('register', this.userLogId);
      console.info('Socket User Registered');
    });
  }

  schedulerList(): Observable<any> {
    return this.subject.asObservable();
  }

  clearList() {
    this.subject.next();
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }
    if (params) {
      reqOpts.params = new HttpParams();
      for (const k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]); // corrected here
      }
    }
    return this.http.get(this.url + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.url + '/' + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  getReportDetails(value) {
    return this.http.post(this.url + `report/download`, value);
  }

  filterReportDetails(value) {
    return this.http.post(this.url + `report/filter_report`, value);
  }
  public socketReturn(event) {
    return Observable.create((observer) => {
      this.socket.on(event, (message) => {
        observer.next(message);
      });
    });
  }
  public getShiftMessage = (event) => {
    return this.socketReturn(event);
  };

  public getProfileChanges = (event) => {
    return Observable.create((observer) => {
      this.socket.on(event + this.userLogId, (message) => {
        observer.next(message);
      });
    });
  };

  public getDashboardChanges = (event) => {
    return this.socketReturn(event);
  };

  public reloadSchedule = (message?) => {
    this.scheduleSubject.next(message);
  };
  ngOnDestroy() {
    this.socket.disconnect();
  }
}
