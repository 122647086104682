<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<div class="availability-card-layout mb-3">
  <div class="row">
    <div class="col-md-12 mx-auto">
      <div class="row justify-content-sm-end ff-roboto color-black fs13 text-uppercase mb-2 py-3 bg-gray">
        <div class="col-md-3 col-3 col-sm-3 offset-md-3 offset-md-3 text-md-center">All Day</div>
        <div class="col-md-3 col-4 col-sm-3">From</div>
        <div class="col-md-3 col-3 col-sm-3">Until</div>
      </div>
      <div class="card mb-1 border-0">
        <div class="card-body bg-white px-md-4 px-1">
          <!--Day Start-->
          <ng-container *ngFor="let day of weekDays">
            <div class="row align-items-center ff-roboto color-black fs13 mb-3">
              <div class="col-md-3 col-sm-3 col-12">
                {{ day.weekDay }}
              </div>
              <div class="col-md-3 col-sm-3 col-2 text-md-center">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="{{ day.weekDay }}"
                    (change)="checkAllDay($event.target.checked, day.weekDay)"
                    [checked]="day.isAllDay"
                  />
                  <label class="custom-control-label font-weight-bold" [for]="day.weekDay"></label>
                </div>
              </div>
              <div class="col-md-3 col-sm-3 col-5 custom-time-picker text-center">
                <input
                  type="text"
                  class="form-control"
                  value="{{ day.fromTime }}"
                  (click)="showFromTime(day.weekDay)"
                  placeholder="00:00"
                  [disabled]="day.isAllDay"
                  readonly
                />
                <timepicker
                  class="time-picker-container"
                  [(ngModel)]="day.actFromTime"
                  *ngIf="day.fromTimePicker"
                  [showMeridian]="isMeridian"
                  [disabled]="day.isAllDay"
                  (ngModelChange)="formatChange(day.actFromTime, day, 'fromTime')"
                >
                </timepicker>
              </div>
              <div class="col-md-3 col-sm-3 col-5 custom-time-picker text-center">
                <input
                  type="text"
                  class="form-control"
                  value="{{ day.toTime }}"
                  (click)="showToTime(day.weekDay)"
                  placeholder="00:00"
                  [disabled]="day.isAllDay"
                  readonly
                />
                <timepicker
                  class="time-picker-container"
                  [(ngModel)]="day.actToTime"
                  *ngIf="day.toTimePicker"
                  [showMeridian]="isMeridian"
                  [disabled]="day.isAllDay"
                  (ngModelChange)="formatChange(day.actToTime, day, 'toTime')"
                >
                </timepicker>
              </div>
            </div>
            <!--Day End-->
          </ng-container>
        </div>
      </div>
      <div class="mt-4 mb-3 d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-primary bg-secondary text-white mr-2"
          tooltip="Cancel"
          (click)="closeModel()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-primary px-3" tooltip="Save" (click)="addStaffUnavailability()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
