/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./sidebarsuperadmin.component";
var styles_SidebarsuperadminComponent = [];
var RenderType_SidebarsuperadminComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarsuperadminComponent, data: {} });
export { RenderType_SidebarsuperadminComponent as RenderType_SidebarsuperadminComponent };
export function View_SidebarsuperadminComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "aside", [["class", "roaster-sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "li", [["class", "list-group-item"], ["routerLink", "/client-information"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, [[1, 4]], 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(4, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵpod(7, { exact: 0 }), (_l()(), i0.ɵeld(8, 0, null, null, 0, "img", [["alt", "client-information"], ["src", "assets/images/menu-icons/dashboard.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Client Information "]))], function (_ck, _v) { var currVal_0 = "/client-information"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, true); var currVal_2 = "active"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_SidebarsuperadminComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebarsuperadmin", [], null, null, null, View_SidebarsuperadminComponent_0, RenderType_SidebarsuperadminComponent)), i0.ɵdid(1, 114688, null, 0, i2.SidebarsuperadminComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarsuperadminComponentNgFactory = i0.ɵccf("app-sidebarsuperadmin", i2.SidebarsuperadminComponent, View_SidebarsuperadminComponent_Host_0, {}, {}, []);
export { SidebarsuperadminComponentNgFactory as SidebarsuperadminComponentNgFactory };
