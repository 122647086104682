<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/settings"><a>Settings</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-alert mr-1"></em>Incident Type</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Incident Type</h1>
    <p class="color-grey-text">
      Incident types are options to select when you or your employees report incidents. The types can be positive as
      well as negative.
    </p>
    <p class="color-grey-text">Examples could be "Client Feedback", "Health and Safety Issue" or "Performance".</p>
    <div class="clearfix"></div>
    <div class="row mt-30">
      <div class="col-md-12 col-xl-10 col-12">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div class="search-box">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text pr-0" id="basic-addon1">
                  <em class="fas fa-search color-light-grey fs12"></em>
                </span>
              </div>
              <input
                type="text"
                class="form-control fs14"
                placeholder="search"
                (input)="searchText($event.target.value)"
                [(ngModel)]="search"
              />
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-create border-0 fs12 mt-1"
            tooltip="Add"
            (click)="addIncidentModal(addIncident, 'A')"
          >
            + ADD INCIDENT TYPE
          </button>
        </div>
        <div class="table-responsive list-table">
          <table class="table bg-white shadow-none mb-0">
            <caption class="d-none">
              Incident Type
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  S.No
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'id' }"
                      (click)="upside('asc', 'id')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'id' }"
                      (click)="downSide('desc', 'id')"
                    ></em>
                  </span>
                </th>

                <th scope="col">
                  Name
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'incident_name' }"
                      (click)="upside('asc', 'incident_name')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'incident_name' }"
                      (click)="downSide('desc', 'incident_name')"
                    ></em>
                  </span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let data of incidentList
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                  let i = index
                "
              >
                <td>{{ page !== 1 ? i + 1 + pagesize * (page - 1) : i + 1 }}</td>
                <td class="incident-text-overflow">{{ data?.incident_name }}</td>
                <td>
                  <span
                    class="mr-3 color-dark-blue c-pointer"
                    tooltip="Edit"
                    (click)="editIncidentModal(addIncident, data)"
                    ><em class="fas fa-pen pen-icon"></em
                  ></span>
                  <span
                    class="color-dark-cred c-pointer"
                    tooltip="Delete"
                    (click)="openDeleteModal(deleteIncident, data)"
                    ><em class="fas fa-trash delete-icon"></em
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="bg-white text-center py-4 roaster-pagination" *ngIf="incidentList.length > 0">
            <pagination-controls
              (pageChange)="pageChange($event)"
              maxSize="5"
              previousLabel=""
              nextLabel=""
              autoHide="true"
            ></pagination-controls>
          </div>
          <div class="bg-white text-center py-4" *ngIf="incidentList.length === 0">
            <p class="text-danger">No Data Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Start Add/Edit Incident Type -->
<ng-template #addIncident>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">{{ title }} Incident Type</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <h1 class="color-black op-09 fs16">New Incident Type</h1>
      <p class="color-black op-05 fs14 mb-0">Please click on "Save" to finalize your changes.</p>
      <form class="modal-form mt-3" [formGroup]="incidentForm" (ngSubmit)="addEditIncident()">
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter Incident Name"
            formControlName="incident_name"
            (keypress)="omit_special_char($event)"
            [ngClass]="{ 'is-invalid': submitted && f.incident_name.errors }"
          />
          <div *ngIf="submitted && f.incident_name.errors" class="invalid-feedback">
            <div *ngIf="f.incident_name.errors.required">Incident Name is required</div>
          </div>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="closeForm()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Save"
            [disabled]="isLoading"
            *ngIf="title === 'Add'"
          >
            Save
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Save"
            [disabled]="!isSubmitEnable"
            *ngIf="title != 'Add'"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- End Add/Edit Incident Type -->

<!-- Start Delete Incident Type -->
<ng-template #deleteIncident>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Incident Type</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete Incident Type?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeForm()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteIncidentType()"
      [disabled]="isLoading"
    >
      Yes
    </button>
  </div>
</ng-template>
<!-- End Delete Incident Type -->
