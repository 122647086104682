import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { ApiService } from '../services';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-timeformat',
  templateUrl: './timeformat.component.html',
})
export class TimeformatComponent implements OnInit {
  message = '';
  time = '';
  timeformat = 'hh:mm:ss @';
  fd1 = '';
  fd2 = '';
  fd3 = '';
  fd4 = '';
  @Input() updatetimeformat;
  @Output() messageEvent = new EventEmitter<string>();
  @Output() timedformat = new EventEmitter<string>();
  domainInfo: any = {};

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.fd1 = moment().format('hh:mm A');
    this.fd2 = moment().format('hh:mm:ss A');
    this.fd3 = moment().format('HH:mm');
    this.fd4 = moment().format('HH:mm:ss');
    if (this.updatetimeformat) {
      if (this.updatetimeformat === 'hh:mm @') {
        this.ftime1();
      } else if (this.updatetimeformat === 'HH:mm') {
        this.ftime3();
      } else if (this.updatetimeformat === 'HH:mm:ss') {
        this.ftime4();
      } else {
        this.ftime2();
      }
    } else {
      this.ftime2();
    }
  }
  ftime1() {
    this.timeformat = 'hh:mm @';
    this.time = this.fd1;
  }
  ftime2() {
    this.timeformat = 'hh:mm:ss @';
    this.time = this.fd2;
  }
  ftime3() {
    this.timeformat = 'HH:mm';
    this.time = this.fd3;
  }
  ftime4() {
    this.timeformat = 'HH:mm:ss';
    this.time = this.fd4;
  }
  timezonesubmit() {
    // var timeformat = "14:55:00";
    // alert(this.time)
    if (this.time == '') {
      this.time = this.fd2;
      this.timeformat = 'hh:mm:ss @';
    }
    this.messageEvent.emit(this.time);
    this.timedformat.emit(this.timeformat);
    this.apiService.closeClientModel.emit(11);
  }
  closeModel() {
    this.apiService.closeClientModel.emit(11);
  }
}
