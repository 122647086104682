<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500">
      <a>Staff</a>
    </li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-users mr-1"></em>Staff List</li>
  </ol>
</nav>
<div class="roaster-body staff-layout">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Staff</h1>
    <p class="color-grey-text" *ngIf="showHidden === 0">
      This list gives you access to all employee related information. You can also
      <a href="javascript:void(0)" (click)="viewHidestaff(1)">view hidden staff members.</a>
    </p>
    <p class="color-grey-text" *ngIf="showHidden === 1">
      This list gives you access to all employee related information. You can also
      <a href="javascript:void(0)" (click)="viewHidestaff(0)">hide hidden staff members.</a>
    </p>
    <div class="row mt-3">
      <div class="col-md-4 col-xl-3 search-box">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text pr-0" id="basic-addon1">
              <em class="fas fa-search color-light-grey fs12"></em>
            </span>
          </div>
          <input
            type="text"
            class="form-control fs14"
            placeholder="Search here"
            aria-label="Search here"
            aria-describedby="basic-addon1"
            (input)="searchText($event.target.value)"
            [(ngModel)]="search"
          />
        </div>
      </div>
      <div class="col-md-4 offset-md-4 col-xl-3 offset-xl-6">
        <em
          class="fas fa-info-circle color-light-black fs20 float-md-right"
          tooltip="Staff Limit Exceeds for your account. Please contact Administrator"
          *ngIf="staffDis == true"
        ></em>
        <button
          type="button"
          class="btn btn-secondary btn-create border-0 fs12 float-md-right"
          tooltip="ADD STAFF"
          (click)="addStaffModal(addStaff)"
          *ngIf="(userGroup === 'Advanced' || userGroup === 'Full access') && staffDis == false"
        >
          + ADD STAFF
        </button>
      </div>
    </div>
    <div class="mt-1">
      <app-app-list-data-staff></app-app-list-data-staff>
    </div>
  </div>

  <ng-template #addStaff>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Add Staff</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-staff></app-add-staff>
    </div>
  </ng-template>
</div>
