/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./blacklisting-staff.component";
import * as i3 from "../../services/api.service";
import * as i4 from "ngx-toastr";
import * as i5 from "ngx-bootstrap/modal";
var styles_BlacklistingStaffComponent = [];
var RenderType_BlacklistingStaffComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BlacklistingStaffComponent, data: {} });
export { RenderType_BlacklistingStaffComponent as RenderType_BlacklistingStaffComponent };
function View_BlacklistingStaffComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "th", [["class", "border-0"], ["scope", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "em", [["class", "fas fa-ban circle-icon ban-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [["class", "border-0 fs12 pl-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.client == null) ? null : _v.context.$implicit.client.name)); _ck(_v, 4, 0, currVal_0); }); }
function View_BlacklistingStaffComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No blacklistings recorded. Please use the related client or location to blacklist staff members."]))], null, null); }
export function View_BlacklistingStaffComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "card mb-3 border-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "card-header bg-white px-md-4 px-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "card-title mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-dark font-weight-bold fs16 mb-0 fs16"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["BLACKLISTING"])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "card-body table-responsive text-dark"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "table", [["aria-describedby", "tab"], ["class", "table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BlacklistingStaffComponent_1)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BlacklistingStaffComponent_2)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blackList; _ck(_v, 9, 0, currVal_0); var currVal_1 = (_co.blackList.length === 0); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_BlacklistingStaffComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-blacklisting-staff", [], null, null, null, View_BlacklistingStaffComponent_0, RenderType_BlacklistingStaffComponent)), i0.ɵdid(1, 114688, null, 0, i2.BlacklistingStaffComponent, [i3.ApiService, i4.ToastrService, i5.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlacklistingStaffComponentNgFactory = i0.ɵccf("app-blacklisting-staff", i2.BlacklistingStaffComponent, View_BlacklistingStaffComponent_Host_0, { blackList: "blackList" }, {}, []);
export { BlacklistingStaffComponentNgFactory as BlacklistingStaffComponentNgFactory };
