import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService, S3UploadService } from '../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { environment } from '../../environments/environment';
import { passwordRegEx } from '../constants/defaultValues';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
  userInfo;
  profileForm: FormGroup;
  userType = localStorage.getItem('userType');
  userId = localStorage.getItem('userid');
  isSubmitted = false;
  loading = false;
  fileUrl: any = environment.fileUrl;
  profileImageInfo;
  @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  fileError = false;
  fileSizeError = false;
  fileFormatError = false;
  supportFormat = 'png,jpg,gif,jpeg';
  uploadfileList = [];
  progress = [{ progress: 0 }];
  removeFileIds = [];
  enableChange = false;
  isPass = false;
  isCurrent = false;
  isDisable = false;
  profileImage = '';
  passwordRegEx: string = passwordRegEx;

  constructor(
    private s3upload: S3UploadService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    if (this.userType === '2') {
      this.getProfile(this.userId);
      this.createForm();
    } else {
      this.getClientProfile(this.userId);
      this.createClientForm();
    }
  }

  changeAction() {
    this.enableChange = !this.enableChange;
  }

  getProfile(staffId) {
    this.loading = true;
    this.apiService.get('staff/view/' + staffId).subscribe(
      (staff) => {
        this.loading = false;
        if (staff['status'] === 200) {
          this.userInfo = staff['staff'];
          if (this.userInfo && this.userInfo['profile'] && this.userInfo['profile']['file_path']) {
            this.profileImage = this.userInfo['profile']['file_path'];
          } else {
            this.profileImage = '';
          }
          this.setStaffData();
        } else {
          this.userInfo = {};
        }
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  getClientProfile(clientId) {
    this.loading = true;
    this.apiService.get('client/view/' + clientId).subscribe(
      (client) => {
        this.loading = false;
        if (client['status'] === 200) {
          this.userInfo = client['client'];
          this.setClientData();
        } else {
          this.userInfo = {};
        }
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  setStaffData() {
    this.profileForm.patchValue({
      email: this.userInfo['email'],
      firstName: this.userInfo['staff_firstname'],
      surName: this.userInfo['staff_surname'],
      current_password: '',
      new_password: '',
      confirm_password: '',
    });
  }

  setClientData() {
    this.profileForm.patchValue({
      email: this.userInfo['email'],
      name: this.userInfo['name'],
      current_password: '',
      new_password: '',
      confirm_password: '',
    });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('new_password').value;
    const confirmPass = group.get('confirm_password').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  createForm() {
    this.profileForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,}$/)]],
        firstName: ['', Validators.required],
        surName: ['', Validators.required],
        current_password: [''],
        new_password: [''],
        confirm_password: [''],
      },
      { validator: this.checkPasswords },
    );
  }

  createClientForm() {
    this.profileForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,}$/)]],
        name: [''],
        current_password: [''],
        new_password: [''],
        confirm_password: [''],
      },
      { validator: this.checkPasswords },
    );
  }

  get f() {
    return this.profileForm.controls;
  }

  valuechange(value, type) {
    if (type === 'New') {
      if (value.length > 0) {
        this.isPass = false;
      } else {
        this.isPass = true;
      }
    }
    if (type === 'Current') {
      if (value.length > 0) {
        this.isCurrent = false;
      } else {
        this.isCurrent = true;
      }
    }
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  closeModel() {
    this.files = [];
    this.fileError = false;
    this.removeFileIds = [];
    this.profileImageInfo = null;
    this.isDisable = false;
    this.isCurrent = false;
    this.isPass = false;
    this.enableChange = false;
    this.isSubmitted = false;
    this.profileForm.reset();
    if (this.userType === '2') {
      this.getProfile(this.userId);
    } else {
      this.getClientProfile(this.userId);
    }
  }

  uploadS3Bucket() {
    this.fileError = false;
    this.fileSizeError = false;
    this.fileFormatError = false;
    const type = 4;
    this.isDisable = true;
    this.s3upload
      .uploadfile(this.files[0]['nativeFile'], 0, type)
      .then((data: any) => {
        const fileData = { file_path: data['Key'], name: this.files[0]['nativeFile']['name'] };
        this.uploadfileList = [fileData];
        if (data && data['Key']) {
          this.apiService.post('staff/profile-upload', fileData).subscribe(
            (fileInfo) => {
              if (fileInfo['status'] === 200) {
                this.isDisable = false;
                this.profileImage = data['Key'];
                this.profileImageInfo = fileInfo['file']['id'];
              }
            },
            (err) => {
              this.isDisable = true;
            },
          );
        }
      })
      .catch((err) => {
        this.isDisable = false;
      });
  }

  readURL() {
    const file = this.files[0]['nativeFile'];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadfileList = [{ imageSrc: reader.result, name: this.files[0]['nativeFile']['name'] }];
    };
    reader.readAsDataURL(file);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      const fileType = this.files[0]['type'];
      const fileSize = this.files[0].size / 1024 / 1024;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(fileType)) {
        this.fileError = true;
        this.fileFormatError = true;
        this.removeFile(this.files[0]['id']);
      } else {
        if (fileSize < 5) {
          this.readURL();
          this.uploadS3Bucket();
        } else {
          this.fileError = true;
          this.fileSizeError = true;
          this.removeFile(this.files[0]['id']);
        }
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added
      this.files = [output.file];
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.files = [output.file];
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  updateProfile() {
    this.isSubmitted = true;
    this.isCurrent = false;
    this.isPass = false;
    if (this.profileForm.invalid) {
      return;
    }
    const profileValue = this.profileForm.value;
    if (this.enableChange) {
      if (profileValue && this.profileForm.value['current_password'] === '') {
        this.isCurrent = true;
        return;
      } else if (profileValue['current_password'].length > 0) {
        if (profileValue['new_password'] === '') {
          this.isPass = true;
        } else {
          this.isPass = false;
        }
        if (this.isPass) {
          return;
        }
      }
    }

    const params = { userLogId: this.userInfo['userLogId'] };
    params['email'] = profileValue.email;
    if (this.userType == '2') {
      params['firstName'] = profileValue.firstName;
      params['surName'] = profileValue.surName;
      if (this.profileImageInfo) params['profile_image'] = this.profileImageInfo;
    } else {
      params['name'] = profileValue.name;
    }

    if (this.enableChange) {
      params['current_password'] = profileValue.current_password;
      params['new_password'] = profileValue.new_password;
      params['confirm_password'] = profileValue.confirm_password;
    }

    const apiUrl =
      this.userType === '2' ? 'auth/update-staffprofile/' + this.userId : 'auth/update-clientprofile/' + this.userId;
    this.isDisable = true;
    this.loading = true;
    this.apiService.post(apiUrl, params).subscribe(
      (data) => {
        if (data['status']['status'] === 200) {
          this.closeModel();
          this.toastr.success(data['status']['message']);
          this.apiService.profileUpdates.emit(1);
        } else {
          this.loading = false;
          this.isDisable = false;
          this.toastr.error(data['status']['message']);
        }
      },
      (err) => {
        this.isDisable = false;
        this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }
}
