<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div class="container">
  <div class="row">
    <div class="col-xl-5 col-md-8 offset-md-2 col-lg-6 offset-lg-3 offset-xl-4 my-auto">
      <div class="mt-160">
        <div class="card auth-card col-md-11">
          <div class="card-header text-center text-white auth-header">
            <img src="assets/images/logo-w.png" alt="logo" class="logo" />
          </div>
          <div class="card-body bg-white">
            <h1 class="card-title text-dark text-left fs20">RESET PASSWORD</h1>
            <div class="border-primary w-25 login-bdr mb-3"></div>
            <form [formGroup]="changeLoginForm" (ngSubmit)="onSubmit()" novalidate>
              <div class="form-group bg-white">
                <label for="Password" class="fs14 text-dark font-weight-bold">Password</label>
                <input
                  type="password"
                  class="form-control bg-white fs14"
                  placeholder="Enter your Password"
                  id="Password"
                  pattern="{{ passwordRegEx }}"
                  formControlName="new_password"
                  [ngClass]="{ 'invalid-field': isSubmitted && f.new_password.errors }"
                  (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                />
                <div *ngIf="f.new_password.errors" class="text-danger mt-1">
                  <div *ngIf="f.new_password.errors.pattern">
                    Password requires at least 8 characters, one must be a number and one special character
                  </div>
                </div>
                <div *ngIf="isSubmitted && f.new_password.errors" class="text-danger mt-1">
                  <div *ngIf="f.new_password.errors.required">Please enter Password</div>
                </div>
              </div>
              <div class="form-group bg-white">
                <label for="confirm_password fs14" class="text-dark font-weight-bold">Confirm Password</label>
                <input
                  type="password"
                  class="form-control bg-white fs14"
                  placeholder="Enter your Confirm Password"
                  id="confirm_password"
                  formControlName="confirm_password"
                  [ngClass]="{ 'invalid-field': changeLoginForm.hasError('notSame') && f.new_password.errors }"
                  (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                />
                <div
                  class="text-danger mt-1"
                  *ngIf="isSubmitted && f.confirm_password.errors && f.confirm_password.errors.required"
                >
                  Please enter Confirm Password
                </div>
                <div
                  class="text-danger mt-1"
                  *ngIf="
                    changeLoginForm.hasError('notSame') &&
                    changeLoginForm.value &&
                    changeLoginForm.value.confirm_password &&
                    changeLoginForm.value.confirm_password.length > 0
                  "
                >
                  Password Mismatch
                </div>
              </div>

              <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
