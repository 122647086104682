<div class="card mb-3 border-0">
  <div class="card-header bg-white pl-4 pr-2 pb-2">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="text-dark font-weight-bold fs16 mb-0">CERTIFICATES</h6>
      <div>
        <button type="button" class="btn btn-primary btn-more mr-3" tooltip="Add" (click)="openModal(template1)">
          <em class="fas fa-plus mr-2"></em>Add
        </button>
        <ng-template #template1 class="modal-content template-3">
          <div class="modal-header bg-primary">
            <h4 class="modal-title w-100 text-center fs18 font-weight-normal text-white">Add Required Certification</h4>
            <button
              aria-label="Close"
              class="close text-white font-weight-normal op1"
              type="button"
              aria-label="Close"
              (click)="clearForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h1 class="fs14 font-weight-bold">Client Certification</h1>
            <p class="text-secondary">
              Please select the certification which is required to schedule an employee in any new shifts.
            </p>
            <form>
              <h1 class="fs14 font-weight-bold">Certification</h1>
              <div class="form-group">
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="certificateInfo"
                  name="certificateInfo"
                  (change)="changeAction()"
                  [ngClass]="{ 'invalid-field': showError && isSubmit }"
                >
                  <option value="" selected disabled>--Select Certificate--</option>
                  <option [ngValue]="certificate.id" *ngFor="let certificate of certificateData">
                    {{ certificate?.name }}
                  </option>
                </select>
              </div>
              <div class="mb-4 mt-3 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-primary bg-secondary text-white mr-2"
                  tooltip="Cancel"
                  (click)="clearForm()"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="isSubmit"
                  tooltip="Add"
                  (click)="addCerticate()"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </ng-template>
        <em class="fas fa-compress circle-icon compress-icon"></em>
      </div>
    </div>
  </div>
  <div class="card-body text-dark">
    <ul class="list-unstyled">
      <li class="mb-5">
        <div class="row d-flex align-items-center mb-4" *ngFor="let certificates of certificateList">
          <div class="col-md-10 col-10 d-flex align-items-center">
            <div class="bg-medal">
              <h2 class="text-dark fs14 mt-2">{{ certificates?.certificate?.name }}</h2>
            </div>
          </div>
          <div class="col-md-2 col-1">
            <em
              class="fas fa-trash delete-icon c-pointer"
              tooltip="Delete"
              (click)="deletePopup(delete, certificates)"
            ></em>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="certificateList?.length === 0">
      <p>Currently no certifications are required to roster staff for this client.</p>
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Delete Certificate</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="clearForm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete certificate?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="clearForm()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteCertificate()"
    >
      Yes
    </button>
  </div>
</ng-template>
