<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500"><a>Settings</a></li>
  </ol>
</nav>
<div class="roaster-body">
  <h1 class="color-black fs20 text-uppercase pl-2 my-4 heading-border">Settings</h1>
  <div class="row">
    <div class="col-lg-4 my-2">
      <div class="card border-0 h-100 c-pointer" routerLink="/setting-certification">
        <div class="card-body px-2 roaster-shadow">
          <div class="bg-icon-file">
            <h2 class="color-light-black fs18 font-weight-normal">Certifications</h2>
            <p class="color-light-black mb-0 fs16 op-32">Manages the available certification types.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 my-2">
      <div class="card border-0 h-100 c-pointer" routerLink="/setting-incident">
        <div class="card-body px-2 roaster-shadow">
          <div class="bg-icon-alert">
            <h2 class="color-light-black fs18 font-weight-normal">Incident Types</h2>
            <p class="color-light-black mb-0 fs16 op-32">Manages the available types of incidents.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 my-2">
      <div class="card border-0 h-100 c-pointer" routerLink="/setting-equipment">
        <div class="card-body px-2 roaster-shadow">
          <div class="bg-icon-equipment">
            <h2 class="color-light-black fs18 font-weight-normal">Equipment items</h2>
            <p class="color-light-black mb-0 fs16 op-32">Manages the available equipment as well as the inventory.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-3">
      <div class="card border-0">
        <div class="card-body roaster-shadow">
          <h3 class="color-light-black fs18 font-weight-bold">Application Name</h3>
          <p class="color-light-black fs16 op-32">
            The following option allows you to define the name of this application. Usually displayed in the title of
            your browser window.
          </p>
          <form [formGroup]="applicationForm" (ngSubmit)="CreateApplication()">
            <div class="form-group">
              <label class="font-weight-bold">Title</label>
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
              />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Title is required</div>
              </div>
            </div>
            <div class="float-right">
              <button
                type="reset"
                class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
                tooltip="Cancel"
                (click)="onReset()"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-secondary btn-create border-0 w-100px fs14"
                tooltip="Update"
                *ngIf="!isEdit"
              >
                Save
              </button>
              <button
                type="submit"
                class="btn btn-secondary btn-create border-0 w-100px fs14"
                tooltip="Update"
                *ngIf="isEdit"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
