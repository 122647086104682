<aside class="roaster-sidebar">
  <ul class="list-group">
    <li
      class="list-group-item"
      routerLink="/dashboard"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'"
    >
      <img src="assets/images/menu-icons/dashboard.svg" alt="Dashboard" />Dashboard
    </li>
    <li
      class="list-group-item"
      routerLink="/clients"
      [class.active]="isLinkActive('client')"
      *ngIf="userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor'"
    >
      <img src="assets/images/menu-icons/clients.svg" alt="Clients" />Locations
    </li>
    <li
      class="list-group-item"
      routerLink="/staff"
      [class.active]="isLinkActive('staff')"
      *ngIf="userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor'"
    >
      <img src="assets/images/menu-icons/staff.svg" alt="Staff" />Staff
    </li>
    <li
      class="list-group-item"
      routerLink="/staff-details/{{ userId }}"
      [class.active]="isLinkActive('staff')"
      *ngIf="userGroup === 'Staff'"
    >
      <img src="assets/images/menu-icons/staff.svg" alt="Staff" />Staff
    </li>
    <li
      class="list-group-item"
      routerLink="/scheduler"
      [class.active]="isLinkActive('scheduler')"
      (click)="redirectTo('/scheduler')"
    >
      <img src="assets/images/menu-icons/scheduler.svg" alt="Scheduler" />Scheduler
    </li>
    <li class="list-group-item" routerLink="/reports" [class.active]="isLinkActive('report')" *ngIf="false">
      <img src="assets/images/menu-icons/reports.svg" alt="Reports" />Reports
    </li>
    <li
      class="list-group-item"
      routerLink="/report"
      [class.active]="isLinkActive('report')"
      *ngIf="userGroup === 'Full access'"
    >
      <img src="assets/images/menu-icons/reports.svg" alt="Reports" />Reports
    </li>
    <li
      class="list-group-item"
      routerLink="/settings"
      [class.active]="isLinkActive('setting')"
      *ngIf="userGroup === 'Full access'"
    >
      <img src="assets/images/menu-icons/settings.svg" alt="Settings" />Settings
    </li>
  </ul>
</aside>
