import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services';
@Component({
  selector: 'app-headersuperadmin',
  templateUrl: './headersuperadmin.component.html',
})
export class HeadersuperadminComponent implements OnInit {
  @Output() sidemenuToggle = new EventEmitter<boolean>();
  shouldShow: boolean;
  userInfo: any = {};
  fileUrl: any = environment.fileUrl;
  // fileUrl = environment.fileUrl;

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit() {
    this.getuserInfo();
    this.apiService.profilechangemodal.subscribe((data) => {
      if (data === 'profilechanged') {
        this.getuserInfo();
      }
    });
    //  this.userName = details['firstName'] +''+ details['surName'];
  }
  getuserInfo() {
    this.userInfo = JSON.parse(localStorage.getItem('user'));
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  sidebarToggle() {
    this.sidemenuToggle.emit((this.shouldShow = !this.shouldShow));
  }
}
