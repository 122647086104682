<div class="card mb-3 border-0">
  <div class="card-header bg-white px-md-4 px-1">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h6 class="text-dark font-weight-bold fs16 mb-0">FILES</h6>
      <ul class="list-unstyled d-inline-flex mb-0">
        <li class="mr-2">
          <button
            type="button"
            class="btn btn-primary btn-more"
            tooltip="Add"
            (click)="openModal(template1)"
            *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'"
          >
            <em class="fas fa-plus mr-2"></em>Add
          </button>
          <ng-template #template1 class="modal-content template-10">
            <div class="modal-header bg-primary">
              <h4 class="modal-title fs16 text-center text-white">Upload Files to Staff Profile</h4>
              <button type="button" class="close pull-right text-white" aria-label="Close" (click)="closeForm()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body w-100 modal-lg">
              <p class="font-weight-bold">Files</p>
              <p class="text-secondary">
                You can attach files by uploading the files using the form below. Files will be attached to the client
                after clicking the "Upload" button. The maximal size per file is 5 MB.
              </p>
              <div class="border-dashed border-secondary">
                <form class="text-center p-5">
                  <div
                    class="drop-container"
                    ngFileDrop
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    [ngClass]="{ 'is-drop-over': dragOver }"
                  >
                    <p class="text-secondary">Drag and Drop your Files here</p>
                    <p class="text-secondary"><span>Or</span></p>
                    <button type="button" class="btn btn-primary" (click)="uploader1.click()">Upload Your Files</button>
                    <input
                      type="file"
                      ngFileSelect
                      [options]="options"
                      (uploadOutput)="onUploadOutput($event)"
                      [uploadInput]="uploadInput"
                      hidden
                      #uploader1
                      multiple
                    />
                  </div>
                  <!-- Start File list -->
                  <ul class="list-group mt-4">
                    <li class="list-group-item my-2 py-1 px-2" *ngFor="let fileInfo of uploadfileList; let i = index">
                      <div class="media">
                        <img
                          src="{{ fileUrl }}{{ fileInfo?.file_path }}"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo?.file_path && fileInfo.type === 'image'"
                        />
                        <img
                          [src]="fileInfo.imageSrc"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="!fileInfo?.file_path && fileInfo.type === 'image'"
                        />

                        <img
                          src="./assets/images/file-icons/audio.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo.type === 'audio'"
                        />
                        <img
                          src="./assets/images/file-icons/video.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="fileInfo.type === 'video'"
                        />

                        <ng-container *ngIf="fileInfo.type === 'document'">
                          <img
                            src="./assets/images/file-icons/pdf.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'pdf'"
                          />
                          <img
                            src="./assets/images/file-icons/excel.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'xlsx' || fileInfo.format === 'xls' || fileInfo.format === 'csv'"
                          />
                          <img
                            src="./assets/images/file-icons/word.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'doc' || fileInfo.format === 'docx'"
                          />
                          <img
                            src="./assets/images/file-icons/ppt.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="fileInfo.format === 'ppt' || fileInfo.format === 'pptx'"
                          />

                          <img
                            src="./assets/images/file-icons/document.png"
                            alt="Profile"
                            class="align-self-center rounded-circle mr-3"
                            width="64"
                            height="64"
                            *ngIf="
                              fileInfo.format != 'pdf' &&
                              fileInfo.format != 'xlsx' &&
                              fileInfo.format != 'xls' &&
                              fileInfo.format != 'csv' &&
                              fileInfo.format != 'doc' &&
                              fileInfo.format != 'docx' &&
                              fileInfo.format != 'ppt' &&
                              fileInfo.format != 'pptx'
                            "
                          />
                        </ng-container>

                        <img
                          src="./assets/images/file-icons/document.png"
                          alt="Profile"
                          class="align-self-center rounded-circle mr-3"
                          width="64"
                          height="64"
                          *ngIf="
                            fileInfo.type !== 'video' &&
                            fileInfo.type !== 'image' &&
                            fileInfo.type !== 'document' &&
                            fileInfo.type !== 'audio'
                          "
                        />

                        <div class="media-body align-self-center text-left">
                          <h1 class="fs18 color-light-black mt-0">{{ fileInfo?.name }}</h1>

                          <progressbar [value]="progress[i]?.progress" [striped]="true" class="w-50"
                            >{{ progress[i]?.progress }}%</progressbar
                          >
                        </div>
                        <div class="align-self-center">
                          <em class="far fa-times-circle c-pointer fs20" (click)="removeUploadFile(i)"></em>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- End File list -->
                </form>
              </div>
              <div *ngIf="fileError">
                <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
                <p class="text-danger" *ngIf="fileFormatError">Valid extenstions {{ supportFormat }}</p>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-4 mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
                    tooltip="Cancel"
                    (click)="closeForm()"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    [disabled]="uploadfileList.length === 0"
                    class="btn btn-secondary btn-create border-0 w-100px fs14"
                    tooltip="Save"
                    (click)="saveFiles()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li><em class="fas fa-compress circle-icon compress-icon"></em></li>
      </ul>
    </div>
  </div>
  <div class="card-body table-responsive text-dark">
    <table class="table border-0" aria-describedby="tab">
      <tbody>
        <tr *ngFor="let file of fileList">
          <th scope="row" class="border-0"><em class="far fa-file"></em></th>
          <td class="border-0 fs12 pl-0 c-pointer" (click)="viewFile(file?.file?.file_path)">
            {{ file?.file?.name }}
          </td>
          <td class="border-0">
            <em
              class="fas fa-arrow-alt-circle-down c-pointer"
              tooltip="Download"
              (click)="downloadfile(file?.file?.file_path)"
            ></em>
          </td>
          <td class="border-0" *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'">
            <em class="fas fa-trash delete-icon c-pointer" tooltip="Delete" (click)="deletePopup(delete, file)"></em>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="fileList.length === 0">
      <p>No files uploaded yet.</p>
    </div>
  </div>
</div>
<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete File</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete file?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeForm()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteFile()"
    >
      Yes
    </button>
  </div>
</ng-template>
