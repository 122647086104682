<aside class="roaster-sidebar">
  <ul class="list-group">
    <li
      class="list-group-item"
      routerLink="/client-information"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img src="assets/images/menu-icons/dashboard.svg" alt="client-information" />Client Information
    </li>
  </ul>
</aside>
