import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services';
import { passwordRegEx } from '../constants/defaultValues';
@Component({
  selector: 'app-change-login-info',
  templateUrl: './change-login-info.component.html',
})
export class ChangeLoginInfoComponent implements OnInit {
  @Input() editClientInfo;
  @Input() editStaffInfo;
  showStatus = false;
  changeLoginForm: FormGroup;
  isSubmitted = false;
  isLoading = false;
  isPass = false;
  groupInfo = ['Staff', 'Supervisor', 'Advanced', 'Full access'];
  passwordRegEx: string = passwordRegEx;

  constructor(private apiService: ApiService, private toastr: ToastrService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.createForm();
    if (this.editClientInfo && this.editClientInfo['id']) {
      this.setClientInfo(this.editClientInfo);
    }
    if (this.editStaffInfo && this.editStaffInfo['id']) {
      this.setStaffInfo(this.editStaffInfo);
    }
  }

  setClientInfo(data) {
    this.changeLoginForm.patchValue({
      is_active: data['is_active'] === '1' || data['is_active'] === 1 ? '1' : '2',
      password: '',
      confirm_password: '',
    });
    if (data['is_active'] === '1' || data['is_active'] === 1) {
      this.showStatus = true;
    } else {
      this.showStatus = false;
    }
    //this.isLoading = (data['is_active'] === '0' || data['is_active'] === 0) ? true : false;
  }

  setStaffInfo(data) {
    this.changeLoginForm.patchValue({
      is_active: data['is_active'] === '1' || data['is_active'] === 1 ? '1' : '2',
      group: data['group'],
      password: '',
      confirm_password: '',
    });
    if (data['is_active'] === '1' || data['is_active'] === 1) {
      this.showStatus = true;
    } else {
      this.showStatus = false;
    }
    //this.isLoading = (data['is_active'] === '0' || data['is_active'] === 0) ? true : false;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirm_password').value;

    return pass === confirmPass ? null : { notSame: true };
  }

  createForm() {
    this.changeLoginForm = this.formBuilder.group(
      {
        is_active: ['2', Validators.required],
        group: ['Staff'],
        password: [''],
        confirm_password: [''],
      },
      { validator: this.checkPasswords },
    );
  }

  get f() {
    return this.changeLoginForm.controls;
  }

  valuechange(value) {
    if (value.length > 0) {
      this.isPass = false;
    } else {
      this.isPass = true;
    }
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  updateLogin() {
    this.isSubmitted = true;
    if (this.changeLoginForm.invalid) {
      return;
    }

    let dataValue = this.changeLoginForm.value;

    if (dataValue['is_active'] === '1' || dataValue['is_active'] === 1) {
      if (dataValue['password'] === '') {
        this.isPass = true;
      } else {
        this.isPass = false;
      }
      if (this.isPass) {
        return;
      }
    }

    this.isLoading = true;
    dataValue = this.changeLoginForm.value;
    dataValue['is_active'] = parseInt(dataValue['is_active']);

    if (this.editClientInfo && this.editClientInfo['id']) {
      delete dataValue['group'];
      this.apiService.put('client/access-change/' + this.editClientInfo['id'], dataValue).subscribe(
        (client: any) => {
          if (client.status === 200) {
            this.closeModel();
            this.toastr.success(client.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    }

    if (this.editStaffInfo && this.editStaffInfo['id']) {
      if (dataValue['is_active'] === '2' || dataValue['is_active'] === 2) {
        delete dataValue['group'];
      }

      this.apiService.put('staff/access-change/' + this.editStaffInfo['id'], dataValue).subscribe(
        (staff: any) => {
          if (staff.status === 200) {
            this.closeModel();
            this.toastr.success(staff.message);
          } else {
            this.toastr.error(staff.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    }
  }

  errorMessage(err) {
    this.closeModel();
    if (err.error.message) {
      this.toastr.error(err.error.message);
    }
  }
  closeModel() {
    this.isPass = false;
    this.isLoading = false;
    this.isSubmitted = false;
    this.showStatus = false;
    if (this.editClientInfo && this.editClientInfo['id']) {
      this.apiService.closeClientModel.emit(7);
    }

    if (this.editStaffInfo && this.editStaffInfo['id']) {
      this.apiService.closeClientModel.emit(8);
    }
  }

  handleChange() {
    const isActive = this.changeLoginForm.value['is_active'];
    if (isActive === '1' || isActive === 1) {
      this.showStatus = true;
    } else {
      this.showStatus = false;
    }
    if (isActive === '0' || isActive === 0) {
      this.isLoading = true;
    } else {
      this.isLoading = false;
    }
  }
}
