<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="backSchedule()"><a>Scheduler</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-clock mr-1"></em>History</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Shift Log</h1>
    <p class="color-grey-text">Here you can View the full log report of the user.</p>
    <div class="table-responsive list-table">
      <table class="table bg-white shadow mb-0">
        <caption class="d-none">
          Report Data
        </caption>
        <thead class="thead-light">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Interface</th>
            <th scope="col">Staff</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let history of historyInfo">
            <td>{{ history.created_at | date: dateFormat }}</td>
            <td>{{ history.created_at | date: 'HH:mm' }}</td>
            <td>{{ history.interface }}</td>
            <td>{{ history?.actionBy?.firstName }} {{ history?.actionBy?.surName }}</td>
            <td class="w-25">{{ history.reason }}</td>
          </tr>
        </tbody>
      </table>
      <p class="text-danger text-center" *ngIf="historyInfo.length === 0">No Data Found</p>
    </div>
  </div>
</div>
