import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
var AddClientinfoComponent = /** @class */ (function () {
    function AddClientinfoComponent(formBuilder, modalService, apiService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.apiService = apiService;
        this.submitted = false;
        this.isEdit = false;
    }
    AddClientinfoComponent.prototype.ngOnInit = function () {
        this.customercreationform = this.formBuilder.group({
            Name: ['', Validators.required],
            CompanyName: ['', Validators.required],
            CompanyWebsite: [''],
            MobileNumber: ['', Validators.required],
            Email: ['', Validators.required],
            Domain: ['', Validators.required],
            TimeFormat: ['', Validators.required],
            TimeZone: ['', Validators.required],
            DateFormat: ['', Validators.required],
        });
    };
    Object.defineProperty(AddClientinfoComponent.prototype, "f", {
        get: function () {
            return this.customercreationform.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddClientinfoComponent.prototype.onSubmit = function () {
        this.submitted = true;
        // stop here if form is invalid
        if (this.customercreationform.invalid) {
            return;
        }
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.customercreationform.value));
    };
    AddClientinfoComponent.prototype.timeformatModal = function (template) {
        this.modalRef1 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    AddClientinfoComponent.prototype.timezoneModal = function (template) {
        this.modalRef2 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    AddClientinfoComponent.prototype.dateformatModal = function (template) {
        this.modalRef3 = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-md modal-dialog-centered roaster-modal',
        });
    };
    AddClientinfoComponent.prototype.closeModel = function () {
        if (this.isEdit) {
            this.apiService.closeClientModel.emit(7);
        }
        else {
            this.apiService.closeClientModel.emit(1);
        }
        this.isEdit = false;
    };
    return AddClientinfoComponent;
}());
export { AddClientinfoComponent };
