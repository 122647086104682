import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor(private mapsAPILoader: MapsAPILoader) {}

  async getCurrentCountry() {
    return await new Promise((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.mapsAPILoader.load().then(() => {
              const geocoder = new google.maps.Geocoder();
              const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
              const request = { latLng: latlng };

              geocoder.geocode(request, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  const address_components = results[0].address_components;
                  const address = address_components
                    .filter((r) => {
                      if (r.types[0] == 'country') {
                        return r;
                      }
                    })
                    .map((r) => {
                      return r.short_name;
                    });
                  resolve(address[0]);
                }
              });
            });
          },
          (error) => {
            console.error(error);
            resolve('NZ');
          },
        );
      } else {
        resolve('NZ');
      }
    });
  }

  async getLocationAddress(address) {
    return await new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        const request = { address: address };
        geocoder.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve({ lat: results[0].geometry.location.lat(), long: results[0].geometry.location.lng() });
          }
        });
      });
    });
  }

  async getMarkedAddress(address) {
    return await new Promise((resolve, reject) => {
      this.mapsAPILoader.load().then(() => {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(address.lat, address.lng);
        const request = { latLng: latlng };
        geocoder.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const address_components = results[0].formatted_address;
            resolve({ address: address_components });
          }
        });
      });
    });
  }
}
