import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, S3UploadService } from '../services';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { passwordRegEx } from '../constants/defaultValues';
@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
})
export class ViewprofileComponent implements OnInit {
  viewprofileform: FormGroup;
  submitted = false;
  isPass = false;
  isCurrent = false;
  @Input() info: any;
  fileUrl: any = environment.fileUrl;
  profileImageInfo;
  @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  isDisable = false;
  profileImage = '';
  fileError = false;
  fileSizeError = false;
  fileFormatError = false;
  supportFormat = 'png,jpg,gif,jpeg';
  uploadfileList = [];
  progress = [{ progress: 0 }];
  removeFileIds = [];
  userId: any = localStorage.getItem('userid');
  passwordRegEx: string = passwordRegEx;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private s3upload: S3UploadService,
    private apiService: ApiService,
  ) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.viewprofileform = this.formBuilder.group(
      {
        Name: ['', Validators.required],
        Email: [
          '',
          [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\\.]+)@([a-zA-Z0-9_\-\\.]+)\.([a-zA-Z]{2,})$/)],
        ],
        currentpassword: ['', Validators.required],
        newpassword: ['', Validators.required],
        confirmpassword: ['', Validators.required],
      },
      { validator: this.checkPasswords },
    );
    this.viewprofileform.patchValue({
      Name: this.info['staff_firstname'],
      Email: this.info['email'],
    });
    this.profileImage = this.info.profile.file_path;
  }
  checkPasswords(group: FormGroup) {
    const pass = group.get('newpassword').value;
    const confirmPass = group.get('confirmpassword').value;

    return pass === confirmPass ? null : { notSame: true };
  }
  get f() {
    return this.viewprofileform.controls;
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }
  valuechange(value, type) {
    if (type === 'New') {
      if (value.length > 0) {
        this.isPass = false;
      } else {
        this.isPass = true;
      }
    }
    if (type === 'Current') {
      if (value.length > 0) {
        this.isCurrent = false;
      } else {
        this.isCurrent = true;
      }
    }
  }

  uploadS3Bucket() {
    this.fileError = false;
    this.fileSizeError = false;
    this.fileFormatError = false;
    const type = 4;
    this.isDisable = true;
    this.s3upload
      .uploadfile(this.files[0]['nativeFile'], 0, type)
      .then((data: any) => {
        const fileData = { file_path: data['Key'], name: this.files[0]['nativeFile']['name'] };
        this.uploadfileList = [fileData];
        if (data && data['Key']) {
          this.apiService.post('staff/profile-upload', fileData).subscribe(
            (fileInfo) => {
              if (fileInfo['status'] === 200) {
                this.isDisable = false;
                this.profileImage = data['Key'];
                this.profileImageInfo = fileInfo['file']['id'];
              }
            },
            (err) => {
              this.isDisable = true;
            },
          );
        }
      })
      .catch((err) => {
        this.isDisable = false;
      });
  }
  readURL() {
    const file = this.files[0]['nativeFile'];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadfileList = [{ imageSrc: reader.result, name: this.files[0]['nativeFile']['name'] }];
    };
    reader.readAsDataURL(file);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      const fileType = this.files[0]['type'];
      const fileSize = this.files[0].size / 1024 / 1024;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(fileType)) {
        this.fileError = true;
        this.fileFormatError = true;
        this.removeFile(this.files[0]['id']);
      } else {
        if (fileSize < 5) {
          this.readURL();
          this.uploadS3Bucket();
        } else {
          this.fileError = true;
          this.fileSizeError = true;
          this.removeFile(this.files[0]['id']);
        }
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added
      this.files = [output.file];
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.files = [output.file];
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.viewprofileform.invalid) {
      return;
    }
    const params = {};
    if (this.profileImageInfo) params['profile_image'] = this.profileImageInfo;
    params['firstName'] = this.viewprofileform.value.Name;
    params['surName'] = this.viewprofileform.value.Name;
    params['email'] = this.viewprofileform.value.Email;
    params['userLogId'] = this.info['userLogId'];
    params['current_password'] = this.viewprofileform.value.currentpassword;
    params['new_password'] = this.viewprofileform.value.newpassword;
    params['confirm_password'] = this.viewprofileform.value.confirmpassword;
    this.apiService.post('auth/update-staffprofile/' + this.userId, params).subscribe(
      (client: any) => {
        if (client.status.status === 200) {
          // this.apiService.profilechangemodal.emit("profilechanged");
          // var s =  JSON.parse(localStorage.getItem('user'));
          // localStorage.setItem('user', JSON.stringify(client.userinfo));
          this.toastr.success(client.status.message);
          this.closeModel();
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }
  closeModel() {
    this.apiService.closeClientModel.emit(15);
  }
}
