import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
var EquipmentsStaffComponent = /** @class */ (function () {
    function EquipmentsStaffComponent(apiService, toastr, formBuilder, modalService) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.equipmentData = [];
        this.isSubmitted = false;
        this.loading = false;
        this.selectEquipment = {};
        this.inputText = '';
        this.userGroup = localStorage.getItem('userGroup');
        this.userLogId = localStorage.getItem('userLogId');
    }
    EquipmentsStaffComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getEquipmentlist();
        this.createForm();
        this.apiService.getProfileChanges('profile:').subscribe(function (data) {
            if (data && data['staff_id'] && data['group']) {
                _this.userGroup = data['group'];
                localStorage.setItem('userGroup', data['group']);
            }
        });
        this.apiService.closeClientModel.subscribe(function (data) {
            if (data === 16) {
                _this.getEquipmentlist();
            }
        });
    };
    EquipmentsStaffComponent.prototype.createForm = function () {
        this.equipmentForm = this.formBuilder.group({
            equipment_id: ['', Validators.required],
            tags: ['', Validators.required],
        });
    };
    EquipmentsStaffComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    };
    EquipmentsStaffComponent.prototype.deletePopup = function (template, equipment) {
        this.selectEquipment = equipment;
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
    };
    Object.defineProperty(EquipmentsStaffComponent.prototype, "f", {
        get: function () {
            return this.equipmentForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    EquipmentsStaffComponent.prototype.getEquipmentlist = function () {
        var _this = this;
        this.apiService.get('equipment').subscribe(function (equipment) {
            if (equipment['status'] === 200) {
                _this.equipmentData = equipment['equipments'];
            }
        });
    };
    EquipmentsStaffComponent.prototype.clearForm = function () {
        this.equipmentForm.reset({ equipment_id: '' });
        this.modalRef.hide();
        this.isSubmitted = false;
        this.loading = false;
        this.selectEquipment = {};
        this.apiService.closeClientModel.emit(5);
    };
    EquipmentsStaffComponent.prototype.createStaffEquipment = function () {
        var _this = this;
        this.isSubmitted = true;
        var tagValue = this.equipmentForm.controls['tags'].value ? this.equipmentForm.controls['tags'].value : [];
        if (this.inputText != '') {
            tagValue.push({ display: this.inputText, value: this.inputText });
            this.inputText = '';
            tagValue = _.uniqBy(tagValue, 'value');
            this.equipmentForm.controls['tags'].setValue(tagValue);
        }
        if (this.equipmentForm.invalid) {
            return;
        }
        var dataValue = this.equipmentForm.value;
        dataValue['staff_id'] = this.staffId;
        this.loading = true;
        var tags = dataValue['tags'];
        if (tags && tags.length > 0) {
            var arrayTag_1 = [];
            tags.forEach(function (item) { return arrayTag_1.push(item['value']); });
            dataValue['tags'] = arrayTag_1.toString();
        }
        this.apiService.post('staff/equipment', dataValue).subscribe(function (equipment) {
            _this.loading = false;
            if (equipment['status'] === 200) {
                _this.clearForm();
                _this.toastr.success(equipment['message']);
            }
        });
    };
    EquipmentsStaffComponent.prototype.deleteEquipments = function () {
        var _this = this;
        this.apiService.delete('staff/equipment/' + this.selectEquipment['id']).subscribe(function (deleteequipment) {
            if (deleteequipment['status'] === 200) {
                _this.toastr.success(deleteequipment['message']);
                _this.clearForm();
            }
            else {
                _this.toastr.error(deleteequipment['message']);
                _this.clearForm();
            }
        });
    };
    EquipmentsStaffComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    return EquipmentsStaffComponent;
}());
export { EquipmentsStaffComponent };
