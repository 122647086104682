import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, S3UploadService } from '../services';
import { Ng2Highcharts } from 'ng2-highcharts';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  missingEquipment = [];
  expiryCertificate = [];
  unResolvedIncidents = [];
  page = 1;
  incidentPage = 1;
  equipmentPage = 1;
  pagesize = 5;
  totalCount;
  incidentCount;
  equipmentCount;
  dashboardInfo: any = {};
  userStatistics: object;
  lineChart: object;
  selectCertificate = {};
  modalRef: BsModalRef;
  domainInfo: any = {};
  dateFormat;
  public loading = false;
  userGroup = localStorage.getItem('userGroup');

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.getDashboard(true);
    this.getMissingEquipment();
    this.getExpiryCertificate();
    this.getUnresolvedIncidents();
    this.apiService.getDashboardChanges('dashboard:').subscribe((data) => {
      if (data && this.userGroup === 'Full access') {
        this.getDashboard(false);
        this.getMissingEquipment();
        this.getExpiryCertificate();
        this.getUnresolvedIncidents();
      }
    });
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }

  getMissingEquipment() {
    const params = { page: this.equipmentPage, pagesize: this.pagesize, isMissing: true };
    this.apiService.get('staff/equipment/missing-list', params).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.missingEquipment = data['equipment'];
          const pageDetails = data['pageDetails'];
          this.equipmentCount = pageDetails['total'];
        } else {
          this.missingEquipment = [];
          this.equipmentCount = 0;
        }
      },
      (err) => {
        this.missingEquipment = [];
        this.equipmentCount = 0;
      },
    );
  }

  getExpiryCertificate() {
    const params = { page: this.page, pagesize: this.pagesize, isExpire: true };
    this.apiService.get('staff-certificate/list', params).subscribe(
      (certificate) => {
        if (certificate['status'] === 200) {
          this.expiryCertificate = certificate['certificates'];
          const pageDetails = certificate['pageDetails'];
          this.totalCount = pageDetails['total'];
        } else {
          this.expiryCertificate = [];
          this.totalCount = 0;
        }
      },
      (err) => {
        this.expiryCertificate = [];
        this.totalCount = 0;
      },
    );
  }

  getUnresolvedIncidents() {
    const params = { page: this.incidentPage, pagesize: this.pagesize, status: 0 };
    this.apiService.get('incident/report', params).subscribe(
      (incident) => {
        if (incident['status'] === 200) {
          this.unResolvedIncidents = incident['incidents'];
          const pageDetails = incident['pageDetails'];
          this.incidentCount = pageDetails['total'];
        } else {
          this.unResolvedIncidents = [];
          this.incidentCount = 0;
        }
      },
      (err) => {
        this.unResolvedIncidents = [];
        this.incidentCount = 0;
      },
    );
  }

  pageChange(newPage) {
    this.page = newPage;
    this.getExpiryCertificate();
  }

  pageChanges(newPage) {
    this.equipmentPage = newPage;
    this.getMissingEquipment();
  }

  pageChanged(newPage) {
    this.incidentPage = newPage;
    this.getUnresolvedIncidents();
  }

  getDashboard(type) {
    this.loading = type;
    this.apiService.get('application/view/report/dashboard').subscribe(
      (data) => {
        this.dashboardInfo = data['count'];
        this.getChartDetails(this.dashboardInfo['staff'], this.dashboardInfo['client']);
        this.getLineChart(data['startYear'], data['clientData'], data['staffData']);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  getChartDetails(staff, client) {
    this.userStatistics = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        style: {
          // color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
          height: '270px',
        },
      },
      title: {
        text: 'Active User Statistics',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            // style: {
            //   color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            // }
          },
        },
      },
      series: [
        {
          name: 'Members',
          colorByPoint: true,
          data: [
            {
              name: 'Client',
              y: client,
            },
            {
              name: 'Staff',
              y: staff,
            },
          ],
          point: {
            events: {
              click: function (e) {
                if (e.point.name === 'Client') {
                  this.router.navigate(['/clients']);
                }

                if (e.point.name === 'Staff') {
                  this.router.navigate(['/staff']);
                }
              }.bind(this),
            },
          },
        },
      ],
    };
  }

  getLineChart(startYear, clientData, staffData) {
    this.lineChart = {
      title: {
        text: 'Over All User Statistics Report',
      },
      xAxis: {
        allowDecimals: false,
      },
      yAxis: {
        title: {
          text: 'Number of Users',
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: startYear,
        },
      },

      series: [
        {
          name: 'Client',
          data: clientData,
        },
        {
          name: 'Staff',
          data: staffData,
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  deletePopup(template: TemplateRef<any>, certificate) {
    this.selectCertificate = certificate;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
  }

  deleteCertificate() {
    this.apiService.delete('staff-certificate/' + this.selectCertificate['id']).subscribe(
      (deletecertificate) => {
        if (deletecertificate['status'] === 200) {
          this.toastr.success(deletecertificate['message']);
          this.getExpiryCertificate();
        } else {
          this.toastr.error(deletecertificate['message']);
          this.getExpiryCertificate();
        }
        this.modalRef.hide();
      },
      (err) => {
        this.modalRef.hide();
        this.getExpiryCertificate();
      },
    );
  }
}
