/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-layoutsuperadmin',
  templateUrl: './app-layoutsuperadmin.component.html',
})
export class AppLayoutsuperadminComponent implements OnInit {
  toggleClass = false;
  constructor() {}

  ngOnInit() {}
  sideMenuChange(val) {
    this.toggleClass = val;
  }
}
