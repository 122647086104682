<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/settings"><a>Settings</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page">
      <em class="fas fa-file circle-icon file-icon mr-1"></em>Certifications
    </li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Certifications</h1>
    <p class="color-grey-text">
      Certifications are the basis for defining requirements for employees to be suitable for certain types of shifts.
      Any certification can be made a requirement for clients and/or location in the "Clients" section. In each
      individual staff profile a certification can be added and adjusted.
    </p>
    <p class="color-grey-text">
      Examples could be a Delivery Driver requires a valid Driver license or a Duty Manager requires a Duty Manager
      license.
    </p>
    <div class="clearfix"></div>
    <div class="row mt-30">
      <div class="col-md-12 col-xl-10 col-12">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div class="search-box">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text pr-0" id="basic-addon1">
                  <em class="fas fa-search color-light-grey fs12"></em>
                </span>
              </div>
              <input
                type="text"
                class="form-control fs14"
                placeholder="search"
                (input)="searchText($event.target.value)"
                [(ngModel)]="search"
              />
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-create border-0 fs12 mt-1"
            tooltip="Add"
            (click)="addCertificationModal(certificate, 'A')"
          >
            + ADD CERTIFICATION
          </button>
        </div>
        <div class="table-responsive list-table">
          <table class="table bg-white shadow-none mb-0">
            <caption class="d-none">
              Certifications Data
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  S.No
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'id' }"
                      (click)="upside('asc', 'id')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'id' }"
                      (click)="downSide('desc', 'id')"
                    ></em>
                  </span>
                </th>

                <th scope="col">
                  Name
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'name' }"
                      (click)="upside('asc', 'name')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'name' }"
                      (click)="downSide('desc', 'name')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Notes
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'notes' }"
                      (click)="upside('asc', 'notes')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'notes' }"
                      (click)="downSide('desc', 'notes')"
                    ></em>
                  </span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let data of certificateList
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                  let i = index
                "
              >
                <td>{{ page !== 1 ? i + 1 + pagesize * (page - 1) : i + 1 }}</td>
                <td>{{ data.name }}</td>
                <td class="text_overflow">{{ data.notes }}</td>
                <td>
                  <span
                    class="mr-3 color-dark-blue c-pointer"
                    tooltip="Edit"
                    (click)="editCertificationModal(certificate, data)"
                    ><em class="fas fa-pen pen-icon"></em
                  ></span>
                  <span
                    class="color-dark-cred c-pointer"
                    tooltip="Delete"
                    (click)="openDeleteModal(deleteCertificate, data)"
                    ><em class="fas fa-trash delete-icon"></em
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="bg-white text-center py-4 roaster-pagination" *ngIf="certificateList.length > 0">
            <pagination-controls
              (pageChange)="pageChange($event)"
              maxSize="5"
              previousLabel=""
              nextLabel=""
              autoHide="true"
            ></pagination-controls>
          </div>
          <div class="bg-white text-center py-4" *ngIf="certificateList.length === 0">
            <p class="text-danger">No Data Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Start Add/Edit Certification -->
<ng-template #certificate>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">{{ title }} Certification</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <h1 class="color-black op-09 fs16">New certification</h1>
      <p class="color-black op-05 fs14 mb-0">
        Please fill in the form below to add a new certification and click on "Save".
      </p>
      <form class="modal-form mt-3" [formGroup]="certificateForm" (ngSubmit)="addEditCertificate()">
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter Certification Name"
            formControlName="name"
            (keypress)="omit_special_char($event)"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea
            rows="4"
            class="form-control"
            placeholder="Enter Certification Notes"
            formControlName="notes"
            [ngClass]="{ 'is-invalid': submitted && f.notes.errors }"
          ></textarea>
          <div *ngIf="submitted && f.notes.errors" class="invalid-feedback">
            <div *ngIf="f.notes.errors.required">Notes is required</div>
          </div>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="closeForm()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Update"
            [disabled]=""
            *ngIf="title === 'Add'"
          >
            Save
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Update"
            [disabled]="!isSubmitEnable"
            *ngIf="title != 'Add'"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- End Add/Edit Certification -->

<!-- Start Delete Certification -->
<ng-template #deleteCertificate>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Certification</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete Certification?</p>
    <button class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" tooltip="No" (click)="closeForm()">No</button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteCertification()"
      [disabled]="isLoading"
    >
      Yes
    </button>
  </div>
</ng-template>
<!-- End Delete Certification -->
