import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService } from '../../services';
import { Router } from '@angular/router';
var LocationComponent = /** @class */ (function () {
    function LocationComponent(apiService, geolocationService, toastr, formBuilder, modalService, router) {
        this.apiService = apiService;
        this.geolocationService = geolocationService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.router = router;
        this.isSubmitted = false;
        this.loading = false;
        this.availableTime = ['Anytime', '30 minutes before', '15 minutes before', '5 minutes before'];
        this.marklatitude = '';
        this.marklongitude = '';
    }
    LocationComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.setCurrentLocation();
    };
    LocationComponent.prototype.setCurrentLocation = function () {
        var _this = this;
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.latitude = position.coords.latitude;
                _this.longitude = position.coords.longitude;
                _this.zoom = 15;
            }, function (err) {
                _this.zoom = 15;
                _this.latitude = -41.2935403;
                _this.longitude = 174.7760212;
            });
        }
        else {
            this.zoom = 15;
            this.latitude = -41.2935403;
            this.longitude = 174.7760212;
        }
    };
    LocationComponent.prototype.createForm = function () {
        this.locationForm = this.formBuilder.group({
            name: ['', Validators.required],
            address: [''],
            is_clockin: [0],
            is_clock_at_location: [0],
            is_exclude_time_limit: [0],
            clock_available: [''],
            default_brief: [''],
            latitude: [''],
            longitude: [''],
        });
    };
    LocationComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    };
    Object.defineProperty(LocationComponent.prototype, "f", {
        get: function () {
            return this.locationForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LocationComponent.prototype.createLocation = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.locationForm.invalid) {
            return;
        }
        var datavalue = this.locationForm.value;
        datavalue['is_clockin'] = datavalue['is_clockin'] === true ? 1 : 0;
        datavalue['is_clock_at_location'] = datavalue['is_clock_at_location'] === true ? 1 : 0;
        datavalue['is_exclude_time_limit'] = datavalue['is_exclude_time_limit'] === true ? 1 : 0;
        datavalue['longitude'] = this.marklongitude;
        datavalue['latitude'] = this.marklatitude;
        this.loading = true;
        this.apiService.post('client/' + this.clientId + '/location', datavalue).subscribe(function (location) {
            _this.loading = false;
            if (location['status'] === 200) {
                _this.toastr.success(location['message']);
                _this.clearForm();
                _this.viewLocation(location['location']['id']);
            }
        });
    };
    LocationComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    LocationComponent.prototype.checkLatLong = function (value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!value) return [3 /*break*/, 1];
                        this.marklatitude = '';
                        this.marklongitude = '';
                        this.latitude = null;
                        this.longitude = null;
                        this.setCurrentLocation();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.geolocationService.getLocationAddress(value)];
                    case 2:
                        address = _a.sent();
                        if (address && address['lat'] && address['long']) {
                            this.marklatitude = address['lat'];
                            this.marklongitude = address['long'];
                            this.latitude = address['lat'];
                            this.longitude = address['long'];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationComponent.prototype.clearForm = function () {
        this.modalRef.hide();
        this.locationForm.reset({ clock_available: '' });
        this.isSubmitted = false;
        this.loading = false;
        this.apiService.closeClientModel.emit(4);
        //this.apiService.closeClientModel.emit(10);
        this.marklongitude = '';
        this.marklatitude = '';
    };
    LocationComponent.prototype.addMarker = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.marklatitude = lat;
                        this.marklongitude = lng;
                        return [4 /*yield*/, this.geolocationService.getMarkedAddress({ lat: lat, lng: lng })];
                    case 1:
                        address = _a.sent();
                        this.locationForm.patchValue({ address: address['address'] });
                        return [2 /*return*/];
                }
            });
        });
    };
    LocationComponent.prototype.viewLocation = function (id) {
        this.router.navigate(['client/location-details/' + id], { queryParams: { clientId: this.clientId } });
    };
    LocationComponent.prototype.viewSubLocation = function (id) {
        this.router.navigate(['client/sublocation-details/' + id], { queryParams: { clientId: this.clientId } });
    };
    return LocationComponent;
}());
export { LocationComponent };
