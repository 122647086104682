<div class="roaster-body custommargin">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Client Information</h1>
    <p class="color-grey-text">
      Below you can Manage Organization related information like Domain Name,Time Format and TimeZone.
    </p>
    <div class="row">
      <div class="col-md-3 search-box">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text pr-0" id="basic-addon1">
              <em class="fas fa-search color-light-grey fs12"></em>
            </span>
          </div>
          <input
            type="text"
            class="form-control fs14"
            placeholder="search"
            aria-label="Username"
            aria-describedby="basic-addon1"
            (input)="searchText($event.target.value)"
            [(ngModel)]="search"
          />
        </div>
      </div>
      <div class="col-md-6 p-0 d-flex align-items-top">
        <div class="text-left" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn selectedfilterbtn color-white py-2 bg-yellow border-0 w-120px fs14"
            aria-controls="dropdown-basic"
          >
            <em class="fa fa-filter color-white pr-2" aria-hidden="true"></em>
            <span>Filter</span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="filterdropdown dropdown-menu"
            role="menu"
            aria-labelledby="button-basic"
          >
            <li role="menuitem">
              <a class="fs14 color-black dropdown-item" (click)="filterby(0)">Activate</a>
            </li>
            <li role="menuitem">
              <a class="fs14 color-black dropdown-item" (click)="filterby(1)">Deactivate</a>
            </li>
          </ul>
        </div>
        <div class="pl-3">
          <button
            *ngIf="isSelectedfilter"
            class="btn selectedfilterbtn color-white pl-3 py-2 bg-lightgreen2 border-0 w-120px borderrad-50 fs14"
          >
            <span>{{ selectedfilter }}</span>
            <em class="fas fa-times-circle bg-lightgreen2 pl-2 color-white" (click)="removefilter()"></em>
          </button>
        </div>
      </div>
      <div class="col-md-3 text-right">
        <button
          type="submit"
          class="btn btn-secondary py-2 btn-create border-0 w-120px fs14"
          (click)="addclientModal(addClient)"
        >
          Add Client
        </button>
      </div>
    </div>
    <div class="mt-1">
      <div class="table-responsive list-table">
        <table class="table bg-white shadow mb-0">
          <caption class="d-none">
            List Data
          </caption>
          <thead class="thead-light">
            <tr>
              <th scope="col">
                Name
                <span class="font-icon ml-2">
                  <em
                    class="fas fa-sort-up up-arrow test"
                    [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'name' }"
                    (click)="upside('asc', 'name')"
                  ></em>

                  <em
                    class="fas fa-sort-up down-arrow"
                    [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'name' }"
                    (click)="down('desc', 'name')"
                  ></em>
                </span>
              </th>
              <th scope="col">
                Contact
                <span class="font-icon ml-2">
                  <em
                    class="fas fa-sort-up up-arrow"
                    [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'contact_person' }"
                    (click)="upside('asc', 'clientname')"
                  ></em>
                  <em
                    class="fas fa-sort-up down-arrow"
                    [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'contact_person' }"
                    (click)="down('desc', 'clientname')"
                  ></em>
                </span>
              </th>

              <th scope="col">
                Mobile
                <span class="font-icon ml-2">
                  <em
                    class="fas fa-sort-up up-arrow"
                    [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'mobile' }"
                    (click)="upside('asc', 'mobileNumber')"
                  ></em>
                  <em
                    class="fas fa-sort-up down-arrow"
                    [ngClass]="{ 'text-dark': sortType == 'desc' && sortFiled === 'mobile' }"
                    (click)="down('desc', 'mobileNumber')"
                  ></em>
                </span>
              </th>
              <th scope="col">
                Email
                <span class="font-icon ml-2">
                  <em
                    class="fas fa-sort-up up-arrow"
                    [ngClass]="{ 'text-dark': sortType == 'asc' && sortFiled === 'email' }"
                    (click)="up('asc', 'email')"
                  ></em>
                  <em
                    class="fas fa-sort-up down-arrow"
                    [ngClass]="{ 'text-dark': sortType == 'desc' && sortFiled === 'email' }"
                    (click)="down('desc', 'email')"
                  ></em>
                </span>
              </th>
              <th scope="col">Total Staff</th>
              <th scope="col">Active Staff</th>
              <th scope="col">Inactive Staff</th>
              <th scope="col" class="text-center">Status</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let client of clientList
                  | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                let i = index
              "
            >
              <tr class="c-pointer" (click)="viewClientModal(viewClient, client)">
                <td>{{ client.name }}</td>
                <td>{{ client.clientname }}</td>
                <td>{{ client.mobileNumber }}</td>
                <td>{{ client.email }}</td>
                <td>{{ client.staff_count }}</td>
                <td>{{ client.activeStaff?.length }}</td>
                <td>{{ client.inActiveStaff?.length }}</td>
                <td class="text-center" (click)="$event.stopPropagation()">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitches_{{ i }}"
                      [checked]="client.status"
                      (change)="statuschange(client)"
                    />
                    <label class="custom-control-label" for="customSwitches_{{ i }}"></label>
                  </div>
                </td>
                <td class="op-1 text-center">
                  <img
                    src="assets/images/edityellowicon.svg"
                    alt="Edit"
                    class=""
                    tooltip="Edit"
                    (click)="editclient(client); $event.stopPropagation()"
                  />
                  <img
                    src="assets/images/eyeicon.svg"
                    alt="View"
                    class="pl-2"
                    tooltip="QuickView"
                    (click)="viewClientModal(viewClient, client); $event.stopPropagation()"
                  />
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="bg-white text-center py-4 roaster-pagination" *ngIf="clientList.length > 0">
        <pagination-controls
          (pageChange)="pageChange($event)"
          maxSize="7"
          previousLabel=""
          nextLabel=""
          autoHide="true"
        ></pagination-controls>
      </div>

      <div class="bg-white text-center py-4" *ngIf="clientList.length === 0">
        <p class="text-danger">No Data Found</p>
      </div>
    </div>
  </div>
  <ng-template #addClient>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Add Client</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="closePopup1()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-add-clientinfo></app-add-clientinfo>
    </div>
  </ng-template>
  <ng-template #viewClient>
    <div class="modal-header">
      <h4 class="modal-title w-100 text-center fs18 font-weight-normal">View Client</h4>
      <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closePopup2()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-view-clientinfo [id]="domainid"></app-view-clientinfo>
    </div>
  </ng-template>
</div>
