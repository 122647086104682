import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CertificationsComponent implements OnInit {
  modalRef: BsModalRef;
  certificateList = [];
  certificateForm: FormGroup;
  submitted = false;
  selectedCertificate: any;
  title = 'Add';
  page = 1;
  pagesize = 10;
  totalCount;
  search = '';
  isLoading = false;
  sortType: any = '';
  sortFiled;
  isSubmitEnable = false;
  public loading = false;

  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  get f() {
    return this.certificateForm.controls;
  }

  // Add Certification
  addCertificationModal(template: TemplateRef<any>, mode) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
    if (mode === 'A') {
      this.certificateForm.reset();
      this.submitted = false;
    }
  }
  onValueChange(data) {
    this.certificateForm.valueChanges.subscribe((val) => {
      let changesExist = false;
      if (val.name !== data.name) {
        changesExist = true;
      }
      if (val.notes !== data.notes) {
        changesExist = true;
      }
      if (changesExist) {
        this.isSubmitEnable = true;
      } else {
        this.isSubmitEnable = false;
      }
    });
  }
  editCertificationModal(template, value) {
    this.isSubmitEnable = false;
    this.certificateForm = this.formBuilder.group({
      id: value.id,
      name: value.name,
      notes: value.notes,
    });
    this.title = 'Edit';
    this.onValueChange(value);
    this.addCertificationModal(template, 'E');
  }

  openDeleteModal(deleteCertificate: TemplateRef<any>, value) {
    this.selectedCertificate = value;
    this.modalRef = this.modalService.show(deleteCertificate, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered roaster-modal',
    });
  }

  // Certificate List
  getCertificateList() {
    this.loading = true;
    const params = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) params['search'] = this.search;
    if (this.sortType != '' && this.sortFiled != '') {
      params['sort'] = this.sortFiled + ':' + this.sortType;
    }
    this.apiService.get('certificate/list', params).subscribe(
      (data: any) => {
        if (data['status'] === 200) {
          this.certificateList = data.certificates;
          const pageDetails = data.pageDetails;
          this.totalCount = pageDetails['total'];
        } else {
          this.certificateList = [];
          this.totalCount = 0;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getCertificateList();
  }

  searchText(value) {
    this.search = value;
    this.page = 1;
    this.getCertificateList();
  }

  upside(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getCertificateList();
  }

  downSide(format, field) {
    this.sortType = format;
    this.sortFiled = field;
    this.getCertificateList();
  }

  omit_special_char(event) {
    const k: any = event.charCode;
    const value: any = event.target.value;
    if (value.length === 0) {
      if (k === 32) {
        return false;
      }
    } else {
      return (
        (k > 64 && k < 91) ||
        (k > 96 && k < 123) ||
        (k >= 44 && k <= 57) ||
        (k >= 96 && k <= 105) ||
        [13, 39, 32, 63].includes(k)
      );
    }
  }

  // Add and Edit certificate Form
  addEditCertificate() {
    this.submitted = true;
    if (this.certificateForm.invalid) {
      return;
    }
    const certificateData = this.certificateForm.value;
    if (!this.certificateForm.value.id) {
      this.isLoading = true;
      this.apiService.post('certificate', certificateData).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastr.success(data.message);
            this.closeForm();
            this.getCertificateList();
          }
          this.isLoading = false;
        },
        (err) => {
          if (err.error.message) {
            this.toastr.error(err.error.message);
          }
          this.isLoading = false;
        },
      );
    } else {
      this.isLoading = true;
      this.apiService.put('certificate/update/' + certificateData.id, certificateData).subscribe(
        (editData: any) => {
          if (editData.status === 200) {
            this.toastr.success(editData.message);
            this.closeForm();
            this.getCertificateList();
          }
          this.isLoading = false;
        },
        (errMsg) => {
          if (errMsg.error.message) {
            this.toastr.error(errMsg.error.message);
          }
          this.isLoading = false;
        },
      );
    }
  }

  // Delete certificate
  deleteCertification() {
    this.isLoading = true;
    this.apiService.delete('certificate/delete/' + this.selectedCertificate.id).subscribe(
      (deletefile: any) => {
        this.toastr.clear();
        if (deletefile.status === 200) {
          this.toastr.success(deletefile.message);
          this.closeForm();
          this.getCertificateList();
        } else {
          this.toastr.error(deletefile.message);
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(err.message);
      },
    );
  }

  closeForm() {
    this.submitted = false;
    this.selectedCertificate = {};
    this.certificateForm.reset(this.createForm());
    this.modalRef.hide();
    this.isLoading = false;
    this.title = 'Add';
  }

  createForm() {
    this.certificateForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      notes: [''],
    });
  }

  ngOnInit() {
    this.getCertificateList();
    this.createForm();
  }
}
