<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500"><a>Reports</a></li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Reports</h1>
    <p class="color-grey-text">
      Reports allow to view and export certain sets of information for further work. Custom reports can be created using
      the "plus" symbol at the top right end of the table header.
    </p>
    <div class="row mt-3">
      <div class="col-xl-8 mt-1">
        <div class="row">
          <div class="col-xl-4 col-md-5 search-box">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text pr-0" id="basic-addon1">
                  <em class="fas fa-search color-light-grey fs12"></em>
                </span>
              </div>
              <input
                type="text"
                class="form-control fs14"
                placeholder="search"
                name="search"
                [(ngModel)]="search"
                (ngModelChange)="pageChange(1)"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="col-xl-3 col-md-5 offset-md-2 offset-xl-5">
            <button
              type="button"
              class="btn btn-secondary btn-create border-0 fs12 float-md-right"
              tooltip="Add"
              (click)="openAddReportModal(addReport)"
            >
              + ADD REPORT
            </button>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="table-responsive list-table">
          <table class="table bg-white shadow mb-0">
            <caption class="d-none">
              Report Data
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  S.No
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'id' }"
                      (click)="upside('asc', 'id')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'id' }"
                      (click)="upside('desc', 'id')"
                    ></em>
                  </span>
                </th>

                <th scope="col">
                  Title
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'title' }"
                      (click)="upside('asc', 'title')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'title' }"
                      (click)="downSide('desc', 'title')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Owner
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'owner_id' }"
                      (click)="upside('asc', 'owner_id')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'owner_id' }"
                      (click)="downSide('desc', 'owner_id')"
                    ></em>
                  </span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let report of reportList
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                  let i = index
                "
              >
                <tr class="c-pointer" (click)="reportDetail(report)">
                  <td>{{ page !== 1 ? i + 1 + pagesize * (page - 1) : i + 1 }}</td>
                  <td>{{ report.title }}</td>
                  <td>{{ report.user_name }}</td>
                  <td class="op-1" tooltip="View"><em class="fas fa-eye color-blue c-pointer"></em></td>
                </tr>
                <tr *ngIf="reportList.length === 0">
                  <td colspan="3">No reports found</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="bg-white text-center py-4 roaster-pagination" *ngIf="reportList.length > 0">
          <pagination-controls
            (pageChange)="pageChange($event)"
            maxSize="7"
            previousLabel=""
            nextLabel=""
            autoHide="true"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Start Add Report-->
<ng-template #addReport>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Add Report</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <h1 class="color-black op-09 fs16">New Report</h1>
      <p class="color-black op-05 fs14 mb-0">Please fill in the form below to create a custom report.</p>
      <form class="modal-form mt-3" [formGroup]="reportForm" (ngSubmit)="addReportData()">
        <div class="form-group">
          <label>Title</label>
          <input
            type="text"
            class="form-control"
            placeholder="Title"
            formControlName="title"
            [ngClass]="{ 'is-invalid': submitted && f.title.errors }"
            (keypress)="omit_special_char($event)"
          />
          <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
            <div *ngIf="f.title.errors.required">Title is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Time Frame</label>
          <select
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.time_frame.errors }"
            formControlName="time_frame"
            (change)="selectTimeFrame($event)"
          >
            <option [value]="null" selected disabled>Select</option>
            <option [value]="timeframe" *ngFor="let timeframe of timeframeList">{{ timeframe }}</option>
          </select>
          <div *ngIf="submitted && f.time_frame.errors" class="invalid-feedback">
            <div *ngIf="f.time_frame.errors.required">Time Frame is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Source</label>
          <select
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.source.errors }"
            formControlName="source"
            (change)="selectSource($event)"
          >
            <option [value]="null" selected disabled>Select</option>
            <option [value]="sourceData" *ngFor="let sourceData of sourceList">{{ sourceData }}</option>
          </select>
          <div *ngIf="submitted && f.source.errors" class="invalid-feedback">
            <div *ngIf="f.source.errors.required">Source is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Start</label>
          <input
            type="text"
            placeholder="Date"
            class="form-control"
            bsDatepicker
            formControlName="start_date"
            [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false, adaptivePosition: true }"
          />
          <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
            <div *ngIf="f.start_date.errors.required">Start Date is required</div>
          </div>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" formControlName="is_public" id="is_public" />
          <label class="custom-control-label font-weight-bold" for="is_public">Public</label>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="modalRef.hide()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Save"
            [disabled]="isLoading"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- End Add Report -->
