<div class="px-3">
  <h1 class="color-black op-09 fs16">Change Login Info</h1>
  <p class="color-black op-05 fs14 mb-0">
    You grant others access to the application by setting a password below. Be careful as this exposes sensitive
    information. Generally, it is advisable to only create accounts when there are actually needed and used!
  </p>
  <form class="modal-form mt-3" [formGroup]="changeLoginForm" (ngSubmit)="updateLogin()">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="Status">Status</label>
          <select class="custom-select" formControlName="is_active"
            [ngClass]="{ 'invalid-field': isSubmitted && f.is_active.errors }" (change)="handleChange()">
            <option value="0" selected disabled>Select</option>
            <option value="1">Active</option>
            <option value="2">Blocked</option>
          </select>
        </div>
      </div>
      <div class="col-md-6" *ngIf="showStatus && editStaffInfo && editStaffInfo.id">
        <div class="form-group">
          <label for="Status">Group</label>
          <select class="custom-select" formControlName="group"
            [ngClass]="{ 'invalid-field': isSubmitted && f.group.errors }">
            <option value="" selected disabled>Select</option>
            <option [ngValue]="groupname" *ngFor="let groupname of groupInfo">{{ groupname }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="showStatus && editStaffInfo && editStaffInfo.id">
      <label for="Password" [ngClass]="{ 'text-danger': isSubmitted && isPass }">Password</label>
      <input autocomplete="new-password" type="password" class="form-control" placeholder="Enter your Password"
        id="Password" formControlName="password" pattern="{{ passwordRegEx }}"
        [ngClass]="{ 'invalid-field': isSubmitted && isPass }" (input)="valuechange($event.target.value)"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)" />
      <div *ngIf="f.password.errors" class="text-danger mt-1">
        <p class="mb-0" *ngIf="f.password.errors.pattern">
          Password requires at least 8 characters, one must be a number and one special character
        </p>
      </div>
    </div>
    <div class="form-group" *ngIf="showStatus && editClientInfo && editClientInfo.id">
      <label for="Password" [ngClass]="{ 'text-danger': isSubmitted && isPass }">Password</label>

      <input type="password" autocomplete="new-password" class="form-control" placeholder="Enter your Password"
        id="Password" formControlName="password" pattern="{{ passwordRegEx }}"
        [ngClass]="{ 'invalid-field': isSubmitted && isPass }" (input)="valuechange($event.target.value)"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)" />
      <div *ngIf="f.password.errors" class="text-danger mt-1">
        <p class="mb-0" *ngIf="f.password.errors.pattern">
          Password requires at least 8 characters, one must be a number and one special character
        </p>
      </div>
    </div>
    <div class="form-group" *ngIf="showStatus">
      <label for="Confirm Password" [ngClass]="{ 'text-danger': changeLoginForm.hasError('notSame') }">Confirm
        Password</label>
      <input type="password" class="form-control" placeholder="Enter your Confirm Password" id="Confirm Password"
        formControlName="confirm_password" [ngClass]="{ 'invalid-field': changeLoginForm.hasError('notSame') }"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)" />
      <p class="text-danger" *ngIf="
          changeLoginForm.hasError('notSame') &&
          changeLoginForm.value &&
          changeLoginForm.value.confirm_password &&
          changeLoginForm.value.confirm_password.length > 0
        ">
        Password Mismatch
      </p>
    </div>
    <div class="my-4 d-flex justify-content-end">
      <button type="button" class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" tooltip="Cancel"
        (click)="closeModel()">
        Cancel
      </button>
      <button type="submit" class="btn btn-secondary btn-create border-0 w-100px fs14" tooltip="Save"
        [disabled]="isLoading">
        Save
      </button>
    </div>
  </form>
</div>