import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';

@Component({
  selector: 'app-add-clientinfo',
  templateUrl: './add-clientinfo.component.html',
})
export class AddClientinfoComponent implements OnInit {
  customercreationform: FormGroup;
  submitted = false;
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  isEdit = false;

  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private apiService: ApiService) {}

  ngOnInit() {
    this.customercreationform = this.formBuilder.group({
      Name: ['', Validators.required],
      CompanyName: ['', Validators.required],
      CompanyWebsite: [''],
      MobileNumber: ['', Validators.required],
      Email: ['', Validators.required],
      Domain: ['', Validators.required],
      TimeFormat: ['', Validators.required],
      TimeZone: ['', Validators.required],
      DateFormat: ['', Validators.required],
    });
  }
  get f() {
    return this.customercreationform.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customercreationform.invalid) {
      return;
    }

    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.customercreationform.value));
  }
  timeformatModal(template: TemplateRef<any>) {
    this.modalRef1 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
  timezoneModal(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }
  dateformatModal(template: TemplateRef<any>) {
    this.modalRef3 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-md modal-dialog-centered roaster-modal',
    });
  }

  closeModel() {
    if (this.isEdit) {
      this.apiService.closeClientModel.emit(7);
    } else {
      this.apiService.closeClientModel.emit(1);
    }
    this.isEdit = false;
  }
}
