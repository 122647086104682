<div class="card mb-3 border-0">
  <div class="card-header bg-white px-4">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-self-center">
        <h6 class="text-dark font-weight-bold fs16 mb-0">Details</h6>
      </div>
      <div class="position-relative about-more" dropdown>
        <button
          type="button"
          class="btn btn-primary btn-more"
          tooltip="More"
          id="button-basic"
          dropdownToggle
          aria-controls="dropdown-basic"
        >
          More <em class="fas fa-caret-down ml-1 op-05"></em>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" (click)="editClientModal(editClient)">
            <a class="dropdown-item"><em class="fas fa-suitcase mr-2"></em>Edit Role Details</a>
          </li>
          <li role="menuitem" (click)="openDeleteModal(deleteModal)" *ngIf="!locationInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-trash mr-2"></em>Remove</a>
          </li>
          <li role="menuitem" (click)="openDeleteModal(revertModel)" *ngIf="locationInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-undo mr-2"></em>Restore</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-body text-dark p-md-5">
    <div class="row">
      <div class="col-md-6 p-1">
        <div class="bg-user">
          <h2 class="text-dark fs16 m-0 c-pointer" routerLink="/client-details/{{ clientId }}">
            {{ locationInfo?.client?.name ? locationInfo.client.name : '-' }}
          </h2>
          <span class="text-secondary fs14">Location Name</span>
        </div>
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs16 m-0">{{ locationInfo?.address ? locationInfo?.address : '-' }}</h2>
          <span class="text-secondary fs14">Address</span>
        </div>
      </div>
      <div class="col-md-6 p-1">
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs16 m-0">{{ locationInfo?.name ? locationInfo?.name : '-' }}</h2>
          <span class="text-secondary fs14">Role Name</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editClient class="modal-content">
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-center text-white">Edit Role</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="clearForm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body w-100 modal-lg">
    <form [formGroup]="locationForm" (ngSubmit)="createLocation()">
      <div class="form-group">
        <label
          for="exampleInputEmail1"
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': isSubmitted && f.name.errors }"
          >Name</label
        >
        <input
          type="text"
          class="form-control"
          id="exampleInputEmail1"
          placeholder=""
          formControlName="name"
          [ngClass]="{ 'invalid-field': isSubmitted && f.name.errors }"
          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
        />
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-6">
            <label for="exampleFormControlTextarea1" class="font-weight-bold">Address</label>
          </div>
          <div class="col-md-6 float-right">
            <small id="emailHelp" class="form-text text-primary float-right text-right fs10"
              >* The map location will be updated on change. Please ensure it is correct.</small
            >
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          id="exampleInputPassword1"
          placeholder=""
          formControlName="address"
          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
          (input)="checkLatLong($event.target.value)"
        />
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-12">
            <label for="exampleFormControlTextarea1" class="font-weight-bold">Exact Location</label>
            <div class="float-right">
              <small id="emailHelp" class="form-text text-primary float-right text-right fs10"
                >* The map location will be updated on change. Please ensure it is correct.</small
              >
            </div>
            <agm-map
              class="agm-map"
              [latitude]="latitude"
              [longitude]="longitude"
              [mapDraggable]="true"
              [scrollwheel]="true"
              [zoom]="zoom"
              [mapTypeControl]="true"
              [zoomControl]="true"
              [streetViewControl]="true"
              (mapClick)="addMarker($event.coords.lat, $event.coords.lng)"
            >
              <agm-marker [latitude]="marklatitude" [longitude]="marklongitude"></agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1" class="font-weight-bold">Default Brief</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          formControlName="default_brief"
          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
        ></textarea>
      </div>
      <div class="float-left">
        <p class="text-secondary">
          The default brief gives you the option to provide a regular briefing for this role. It will be shared with
          the staff using the mobile app as well as in notifications about new shifts.
        </p>
      </div>

      <div class="clearfix"></div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" formControlName="is_clockin" />
        <label class="form-check-label font-weight-bold" for="exampleCheck1">Clock In</label>
        If ticked, requires the staff member to clock in using the mobile app to start and end the shift.
      </div>

      <div class="clearfix"></div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck2" formControlName="is_clock_at_location" />
        <label class="form-check-label font-weight-bold" for="exampleCheck2">Clock In</label>
        Only At Location.
      </div>
      <div class="clearfix"></div>

      <div>
        <ul class="d-inline-flex list-unstyled">
          <li class="font-weight-bold mr-4">Clock in Available From</li>
          <li class="border border-secondary">
            <select id="inputState" class="form-control text-dark font-weight-bold" formControlName="clock_available">
              <option value="" selected disabled>--Select Available Time--</option>
              <option [ngValue]="time" *ngFor="let time of availableTime">{{ time }}</option>
            </select>
          </li>
        </ul>
      </div>
      <div class="clearfix"></div>

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck3" formControlName="is_exclude_time_limit" />
        <label class="form-check-label" for="exampleCheck3"
          ><span class="font-weight-bold mr-2"> Exclude From Time Limits</span
          ><span class="text-secondary"
            >Time limits are legal or contractual limitations of maximum working hours for staff. If you tick this box,
            this role will not be considered for any time limitations. For example can be applied for "Leave" or
            "Training".</span
          ></label
        >
      </div>
      <div class="float-right">
        <ul class="d-inline-flex list-unstyled">
          <li class="mr-2">
            <button
              type="button"
              class="btn btn-primary bg-secondary text-white"
              tooltip="Cancel"
              (click)="clearForm()"
            >
              Cancel
            </button>
          </li>
          <li><button type="submit" [disabled]="loading" class="btn btn-primary" tooltip="Submit">Submit</button></li>
        </ul>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Delete Location</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete(hiding) Location?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeModel()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="removeLocation()"
    >
      Yes
    </button>
  </div>
</ng-template>

<ng-template #revertModel>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Revert Location</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to restore Location?</p>
    <button type="button" class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" (click)="closeModel()">
      No
    </button>
    <button type="submit" class="btn btn-secondary btn-create border-0 w-100px fs14" (click)="revertLocation()">
      Yes
    </button>
  </div>
</ng-template>
