/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebarsuperadmin',
  templateUrl: './sidebarsuperadmin.component.html',
})
export class SidebarsuperadminComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
