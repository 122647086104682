import { Component, OnInit, TemplateRef } from '@angular/core';
import { ApiService } from '../services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
})
export class StaffComponent implements OnInit {
  search;
  modalRef: BsModalRef;
  // userGroup = 'Advanced';
  userGroup = localStorage.getItem('userGroup');
  userId: any = localStorage.getItem('userid');
  showHidden = 0;

  constructor(private apiService: ApiService, private modalService: BsModalService) {}
  totalStaffCount = 0;
  currentCount = 0;
  staffDis = false;
  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 3) {
        this.closePopup();
      }
      if (data === 5) {
        this.search = '';
        this.closePopup();
      }
    });
    this.apiService.get('staff/allStaffList').subscribe((staff: any) => {
      this.apiService.get('staff/getStaffCount').subscribe((data: any) => {
        this.totalStaffCount = data.domain.staff_count;
        this.currentCount = staff.staffs.length;
        // if (this.totalStaffCount <= this.currentCount) {
        //   this.staffDis = true;
        // } else {
        // this.staffDis = false;
        // }
      });
    });
  }

  closePopup() {
    if (this.modalRef) this.modalRef.hide();
    this.apiService.searchValue.emit({ id: 2, value: this.search, showHidden: this.showHidden });
  }

  searchText(value) {
    this.search = value;
    this.apiService.searchValue.emit({ id: 2, value: value, showHidden: this.showHidden });
  }

  addStaffModal(template: TemplateRef<any>) {
    // if (this.totalStaffCount >= this.currentCount && this.totalStaffCount > 0) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
    // }
  }

  viewHidestaff(id) {
    this.showHidden = id;
    this.searchText(this.search);
  }
}
