import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'reportListPipe' })
export class ReportListPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const values = [];
    for (const key of Object.keys(value)) {
      values.push(value[key]);
    }
    return values;
  }
}
