import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment-timezone';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-availability-staff',
  templateUrl: './availability-staff.component.html',
})
export class AvailabilityStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() editStaffInfo;
  loading = false;

  public staffUnavailability;
  private dayStart: Date = new Date(new Date().setHours(0, 0, 0, 0));

  public weekDays = [
    {
      day: 1,
      weekDay: 'Monday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 2,
      weekDay: 'Tuesday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 3,
      weekDay: 'Wednesday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 4,
      weekDay: 'Thursday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 5,
      weekDay: 'Friday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 6,
      weekDay: 'Saturday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
    {
      day: 7,
      weekDay: 'Sunday',
      isAllDay: false,
      fromTime: '00:00',
      toTime: '00:00',
      fromTimePicker: false,
      toTimePicker: false,
      actFromTime: this.dayStart,
      actToTime: this.dayStart,
    },
  ];

  constructor(private apiService: ApiService, private toastr: ToastrService, private modalService: BsModalService) {}

  ngOnInit() {
    this.getStaffUnavailabilityList();
  }

  closeModel() {
    this.apiService.closeClientModel.emit(8);
  }

  getStaffUnavailabilityList() {
    this.apiService.get('staff/staff-unavailability-list/' + this.editStaffInfo.id).subscribe(
      (staff: any) => {
        if (staff.status === 200) {
          const staffList = staff.staff;
          this.weekDays = this.weekDays.map((sa) => {
            const currSA = staffList.find((wd) => sa.day === wd.weekday);
            if (currSA) {
              sa.isAllDay = currSA.is_allday;
              sa.fromTime = currSA.from_time;
              sa.toTime = currSA.end_time;
              const [hrFrom, minFrom] = currSA.from_time.split(':');
              sa.actFromTime = new Date(new Date().setHours(hrFrom, minFrom));
              const [hrTo, minTo] = currSA.from_time.split(':');
              sa.actToTime = new Date(new Date().setHours(hrTo, minTo));
            }
            return sa;
          });
        }
      },
      (err) => {
        this.errorMessage(err);
      },
    );
  }

  checkAllDay(check, weekDay) {
    this.weekDays = this.weekDays.map((wd) => {
      if (wd.weekDay === weekDay) {
        wd.fromTime = '00:00';
        wd.toTime = '00:00';
        wd.isAllDay = check;
        wd.fromTimePicker = false;
        wd.toTimePicker = false;
      }
      return wd;
    });
  }

  showFromTime(weekDay) {
    this.weekDays = this.weekDays.map((wd) => {
      if (wd.weekDay === weekDay) {
        wd.fromTimePicker = true;
        wd.toTimePicker = false;
      } else {
        wd.fromTimePicker = false;
        wd.toTimePicker = false;
      }
      return wd;
    });
  }

  showToTime(weekDay) {
    this.weekDays = this.weekDays.map((wd) => {
      if (wd.weekDay === weekDay) {
        wd.fromTimePicker = false;
        wd.toTimePicker = true;
      } else {
        wd.fromTimePicker = false;
        wd.toTimePicker = false;
      }
      return wd;
    });
  }

  formatChange(time, weekDay, fromOrTo) {
    const newTime = new Date(time).toLocaleString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
    weekDay[fromOrTo] = newTime;
  }

  // Time validation for from and end time
  timeChecking(fromTime, toTime) {
    const startTime = moment(fromTime, 'HH:mm');
    const endTime = moment(toTime, 'HH:mm');
    if (startTime.isSame(endTime)) {
      return true;
    } else {
      return false;
    }
  }

  errorMessage(err) {
    this.loading = false;
    if (err.error.message) {
      this.toastr.error(err.error.message);
    }
  }

  async addStaffUnavailability() {
    this.loading = true;
    const dataValue = {
      staff_unavailability: this.weekDays.map((wd) => {
        return {
          is_allday: wd.isAllDay,
          weekday: wd.day,
          from_time: wd.fromTime,
          end_time: wd.toTime,
        };
      }),
    };

    this.apiService.post('staff/staff-unavailability/' + this.editStaffInfo.id, dataValue).subscribe(
      (staff: any) => {
        if (staff.status === 200) {
          this.loading = false;
          this.toastr.success(staff.message);
          this.closeModel();
        }
      },
      (err) => {
        this.errorMessage(err);
      },
    );
  }
}
