import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;
  email: FormControl;
  usertype: FormControl;
  submitted = false;
  loading = false;
  constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) {}

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/),
    ]);

    this.usertype = new FormControl('1');
  }

  createForm() {
    this.loginForm = new FormGroup({
      usertype: this.usertype,
      email: this.email,
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }

    const paramsValue = this.loginForm.value;
    if (paramsValue && paramsValue.usertype && parseInt(paramsValue.usertype) > 0) {
      paramsValue.type = parseInt(paramsValue.usertype);
      delete paramsValue.usertype;
    } else {
      paramsValue.type = 1;
    }

    this.loading = true;
    this.apiService.post('auth/fpassword', paramsValue).subscribe(
      (data) => {
        this.loading = false;
        this.submitted = false;
        if (data['status'] === 200) {
          this.toastr.success(data['message']);
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(data['message']);
        }
      },
      (err) => {
        this.loading = false;
        this.submitted = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }
}
