import { Component, OnInit, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService, S3UploadService } from '../services';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-add-staff',
  templateUrl: './add-staff.component.html',
})
export class AddStaffComponent implements OnInit {
  @Input() editStaffInfo;
  isEdit = false;
  staffForm: FormGroup;
  isSubmitted = false;
  loading = false;
  fileUrl: any = environment.fileUrl;
  profileImageInfo;
  phoneCode: any = [];
  defaultCountryCode: any = 'NZ';
  countryCode;
  nzcode;
  countryFlag;
  @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  fileError = false;
  fileSizeError = false;
  fileFormatError = false;
  supportFormat = 'png,jpg,gif,jpeg';
  uploadfileList = [];
  progress = [{ progress: 0 }];
  removeFileIds = [];
  isPhone = false;
  isEmail = false;

  constructor(
    private geolocationService: GeolocationService,
    private s3upload: S3UploadService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.createForm();
    this.getCurrentLocation();
    this.getPhoneCode();
    this.fileInput.nativeElement.onclick = function () {
      this.value = null;
    };

    if (this.editStaffInfo && this.editStaffInfo['id']) {
      this.setEditForm(this.editStaffInfo);
    }

    this.s3upload.progress.subscribe((progress: any) => {
      if (progress['type'] === 1) {
        this.progress[progress['index']] = progress;
      }
    });
  }

  async getCurrentLocation() {
    this.defaultCountryCode = await this.geolocationService.getCurrentCountry();
    this.setcountryFlag();
  }

  setcountrycodeflag(intphoneData) {
    this.countryCode = intphoneData.countries_isd_code;
    this.countryFlag = intphoneData.countries_iso_code;
    this.nzcode = intphoneData.id;

    this.staffForm.patchValue({
      phone_code: this.nzcode,
    });
  }

  setcountryFlag() {
    const intphoneData = this.phoneCode.filter((obj) => obj.countries_iso_code === this.defaultCountryCode);
    if (!this.editStaffInfo) {
      if (intphoneData && intphoneData.length > 0) {
        this.setcountrycodeflag(intphoneData[0]);
      }
    } else {
      if (this.editStaffInfo) {
        const phoneData = this.phoneCode.filter((obj) => obj.id === this.editStaffInfo.phone_id);
        if (phoneData && phoneData.length > 0) {
          this.setcountrycodeflag(phoneData[0]);
        } else {
          this.setcountrycodeflag(intphoneData[0]);
        }
      }
    }
  }

  getPhoneCode() {
    this.apiService.get('phone-code').subscribe((phoneCode: any) => {
      if (phoneCode.status === 200) {
        this.phoneCode = phoneCode['phonecodes'];
        if (this.phoneCode && this.phoneCode.length > 0) {
          this.setcountryFlag();
        }
      }
    });
  }

  createForm() {
    this.staffForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,}$/)]],
      firstName: ['', Validators.required],
      surName: ['', Validators.required],
      role: [''],
      //profile_image: [''],
      phone: [''],
      phone_code: [''],
      is_newsletter_subscribed: [0],
      send_message: [0],
      receive_message: [0],
    });
  }

  get f() {
    return this.staffForm.controls;
  }

  setEditForm(data) {
    this.isEdit = true;
    this.staffForm.patchValue({
      email: data.email,
      firstName: data.staff_firstname,
      surName: data.staff_surname,
      phone: data.phone,
      phone_code: data.phone_id ? data.phone_id : this.nzcode,
      is_newsletter_subscribed: data.is_newsletter_subscribed,
      role: data.role,
      send_message: data.send_message,
      receive_message: data.receive_message,
    });

    if (data && data['profile'] && data['profile']['id']) {
      this.uploadfileList.push(data['profile']);
    }
  }

  setCountryInfo(code) {
    this.setcountrycodeflag(code);
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  isNumberKey(evt, value) {
    //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
    if (evt > 31 && (evt < 48 || evt > 57)) {
      return false;
    } else if (value.length > 15) {
      return false;
    }
    return true;
  }

  checkError(err) {
    if (err.error.message === 'PhoneNumber already exists. Please try another') {
      this.isPhone = true;
    }
    if (err.error.message === 'Email Id already exists') {
      this.isEmail = true;
    }
  }

  createStaff() {
    this.isSubmitted = true;
    if (this.staffForm.invalid) {
      return;
    }
    const datavalue = this.staffForm.value;
    datavalue['send_message'] = datavalue['send_message'] ? 1 : 0;
    datavalue['receive_message'] = datavalue['receive_message'] ? 1 : 0;
    datavalue['is_newsletter_subscribed'] = datavalue['is_newsletter_subscribed'] ? 1 : 0;
    if (this.profileImageInfo) datavalue['profile_image'] = this.profileImageInfo;
    if (datavalue['phone_code'] === '') delete datavalue['phone_code'];
    this.loading = true;
    if (this.isEdit) {
      if (this.editStaffInfo && this.editStaffInfo['userLogId'])
        datavalue['userLogId'] = this.editStaffInfo['userLogId'];

      this.apiService.put('staff/update/' + this.editStaffInfo['id'], datavalue).subscribe(
        (staff: any) => {
          if (staff.status === 200) {
            this.profileImageInfo = null;
            if (this.removeFileIds && this.removeFileIds.length > 0) {
              this.deleteUploadFile(this.removeFileIds);
            }
            this.closeModel();
            this.loading = false;
            this.toastr.success(staff.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    } else {
      this.apiService.post('staff', datavalue).subscribe(
        (staff: any) => {
          if (staff.status === 200) {
            this.profileImageInfo = null;
            if (this.removeFileIds && this.removeFileIds.length > 0) {
              this.deleteUploadFile(this.removeFileIds);
            }
            this.closeModel();
            this.loading = false;
            this.router.navigate(['/staff-details/' + staff.staff['id']]);
            this.toastr.success(staff.message);
          }
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    }
  }

  errorMessage(err) {
    this.loading = false;
    if (err.error.message) {
      this.toastr.error(err.error.message);
      this.checkError(err);
    }
  }

  closeModel() {
    if (this.profileImageInfo) {
      this.deleteUploadFile([this.profileImageInfo]);
    }
    this.files = [];
    this.fileError = false;
    if (this.isEdit) {
      this.apiService.closeClientModel.emit(8);
    } else {
      this.apiService.closeClientModel.emit(3);
    }
    this.isEdit = false;
    this.removeFileIds = [];
    this.profileImageInfo = null;
  }

  uploadS3Bucket() {
    this.fileError = false;
    this.fileSizeError = false;
    this.fileFormatError = false;
    const type = 1;
    this.s3upload
      .uploadfile(this.files[0]['nativeFile'], 0, type)
      .then((data: any) => {
        const fileData = { file_path: data['Key'], name: this.files[0]['nativeFile']['name'] };
        this.uploadfileList = [fileData];
        if (data && data['Key']) {
          this.apiService.post('staff/profile-upload', fileData).subscribe((fileInfo) => {
            if (fileInfo['status'] === 200) {
              this.profileImageInfo = fileInfo['file']['id'];
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  readURL() {
    const file = this.files[0]['nativeFile'];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadfileList = [{ imageSrc: reader.result, name: this.files[0]['nativeFile']['name'] }];
    };
    reader.readAsDataURL(file);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      const fileType = this.files[0]['type'];
      const fileSize = this.files[0].size / 1024 / 1024;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(fileType)) {
        this.fileError = true;
        this.fileFormatError = true;
        this.removeFile(this.files[0]['id']);
      } else {
        if (fileSize < 5) {
          this.readURL();
          this.uploadS3Bucket();
        } else {
          this.fileError = true;
          this.fileSizeError = true;
          this.removeFile(this.files[0]['id']);
        }
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added
      this.files = [output.file];
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.files = [output.file];
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  deleteUploadFile(fileIds) {
    this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe((deleteFiles) => {
      // Do Nothing...
    });
  }

  removeUploadFile(file) {
    this.uploadfileList = [];
    this.profileImageInfo = null;
    this.removeFileIds.push(file.id);
  }
}
