import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService, S3UploadService } from '../services';
import { humanizeBytes } from 'ngx-uploader';
import { environment } from '../../environments/environment';
var AddStaffComponent = /** @class */ (function () {
    function AddStaffComponent(geolocationService, s3upload, apiService, toastr, formBuilder, router) {
        this.geolocationService = geolocationService;
        this.s3upload = s3upload;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.router = router;
        this.isEdit = false;
        this.isSubmitted = false;
        this.loading = false;
        this.fileUrl = environment.fileUrl;
        this.phoneCode = [];
        this.defaultCountryCode = 'NZ';
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = 'png,jpg,gif,jpeg';
        this.uploadfileList = [];
        this.progress = [{ progress: 0 }];
        this.removeFileIds = [];
        this.isPhone = false;
        this.isEmail = false;
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    AddStaffComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.getCurrentLocation();
        this.getPhoneCode();
        this.fileInput.nativeElement.onclick = function () {
            this.value = null;
        };
        if (this.editStaffInfo && this.editStaffInfo['id']) {
            this.setEditForm(this.editStaffInfo);
        }
        this.s3upload.progress.subscribe(function (progress) {
            if (progress['type'] === 1) {
                _this.progress[progress['index']] = progress;
            }
        });
    };
    AddStaffComponent.prototype.getCurrentLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.geolocationService.getCurrentCountry()];
                    case 1:
                        _a.defaultCountryCode = _b.sent();
                        this.setcountryFlag();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddStaffComponent.prototype.setcountrycodeflag = function (intphoneData) {
        this.countryCode = intphoneData.countries_isd_code;
        this.countryFlag = intphoneData.countries_iso_code;
        this.nzcode = intphoneData.id;
        this.staffForm.patchValue({
            phone_code: this.nzcode,
        });
    };
    AddStaffComponent.prototype.setcountryFlag = function () {
        var _this = this;
        var intphoneData = this.phoneCode.filter(function (obj) { return obj.countries_iso_code === _this.defaultCountryCode; });
        if (!this.editStaffInfo) {
            if (intphoneData && intphoneData.length > 0) {
                this.setcountrycodeflag(intphoneData[0]);
            }
        }
        else {
            if (this.editStaffInfo) {
                var phoneData = this.phoneCode.filter(function (obj) { return obj.id === _this.editStaffInfo.phone_id; });
                if (phoneData && phoneData.length > 0) {
                    this.setcountrycodeflag(phoneData[0]);
                }
                else {
                    this.setcountrycodeflag(intphoneData[0]);
                }
            }
        }
    };
    AddStaffComponent.prototype.getPhoneCode = function () {
        var _this = this;
        this.apiService.get('phone-code').subscribe(function (phoneCode) {
            if (phoneCode.status === 200) {
                _this.phoneCode = phoneCode['phonecodes'];
                if (_this.phoneCode && _this.phoneCode.length > 0) {
                    _this.setcountryFlag();
                }
            }
        });
    };
    AddStaffComponent.prototype.createForm = function () {
        this.staffForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,}$/)]],
            firstName: ['', Validators.required],
            surName: ['', Validators.required],
            role: [''],
            //profile_image: [''],
            phone: [''],
            phone_code: [''],
            is_newsletter_subscribed: [0],
            send_message: [0],
            receive_message: [0],
        });
    };
    Object.defineProperty(AddStaffComponent.prototype, "f", {
        get: function () {
            return this.staffForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddStaffComponent.prototype.setEditForm = function (data) {
        this.isEdit = true;
        this.staffForm.patchValue({
            email: data.email,
            firstName: data.staff_firstname,
            surName: data.staff_surname,
            phone: data.phone,
            phone_code: data.phone_id ? data.phone_id : this.nzcode,
            is_newsletter_subscribed: data.is_newsletter_subscribed,
            role: data.role,
            send_message: data.send_message,
            receive_message: data.receive_message,
        });
        if (data && data['profile'] && data['profile']['id']) {
            this.uploadfileList.push(data['profile']);
        }
    };
    AddStaffComponent.prototype.setCountryInfo = function (code) {
        this.setcountrycodeflag(code);
    };
    AddStaffComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    AddStaffComponent.prototype.isNumberKey = function (evt, value) {
        //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
        if (evt > 31 && (evt < 48 || evt > 57)) {
            return false;
        }
        else if (value.length > 15) {
            return false;
        }
        return true;
    };
    AddStaffComponent.prototype.checkError = function (err) {
        if (err.error.message === 'PhoneNumber already exists. Please try another') {
            this.isPhone = true;
        }
        if (err.error.message === 'Email Id already exists') {
            this.isEmail = true;
        }
    };
    AddStaffComponent.prototype.createStaff = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.staffForm.invalid) {
            return;
        }
        var datavalue = this.staffForm.value;
        datavalue['send_message'] = datavalue['send_message'] ? 1 : 0;
        datavalue['receive_message'] = datavalue['receive_message'] ? 1 : 0;
        datavalue['is_newsletter_subscribed'] = datavalue['is_newsletter_subscribed'] ? 1 : 0;
        if (this.profileImageInfo)
            datavalue['profile_image'] = this.profileImageInfo;
        if (datavalue['phone_code'] === '')
            delete datavalue['phone_code'];
        this.loading = true;
        if (this.isEdit) {
            if (this.editStaffInfo && this.editStaffInfo['userLogId'])
                datavalue['userLogId'] = this.editStaffInfo['userLogId'];
            this.apiService.put('staff/update/' + this.editStaffInfo['id'], datavalue).subscribe(function (staff) {
                if (staff.status === 200) {
                    _this.profileImageInfo = null;
                    if (_this.removeFileIds && _this.removeFileIds.length > 0) {
                        _this.deleteUploadFile(_this.removeFileIds);
                    }
                    _this.closeModel();
                    _this.loading = false;
                    _this.toastr.success(staff.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
        else {
            this.apiService.post('staff', datavalue).subscribe(function (staff) {
                if (staff.status === 200) {
                    _this.profileImageInfo = null;
                    if (_this.removeFileIds && _this.removeFileIds.length > 0) {
                        _this.deleteUploadFile(_this.removeFileIds);
                    }
                    _this.closeModel();
                    _this.loading = false;
                    _this.router.navigate(['/staff-details/' + staff.staff['id']]);
                    _this.toastr.success(staff.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
    };
    AddStaffComponent.prototype.errorMessage = function (err) {
        this.loading = false;
        if (err.error.message) {
            this.toastr.error(err.error.message);
            this.checkError(err);
        }
    };
    AddStaffComponent.prototype.closeModel = function () {
        if (this.profileImageInfo) {
            this.deleteUploadFile([this.profileImageInfo]);
        }
        this.files = [];
        this.fileError = false;
        if (this.isEdit) {
            this.apiService.closeClientModel.emit(8);
        }
        else {
            this.apiService.closeClientModel.emit(3);
        }
        this.isEdit = false;
        this.removeFileIds = [];
        this.profileImageInfo = null;
    };
    AddStaffComponent.prototype.uploadS3Bucket = function () {
        var _this = this;
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        var type = 1;
        this.s3upload
            .uploadfile(this.files[0]['nativeFile'], 0, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: _this.files[0]['nativeFile']['name'] };
            _this.uploadfileList = [fileData];
            if (data && data['Key']) {
                _this.apiService.post('staff/profile-upload', fileData).subscribe(function (fileInfo) {
                    if (fileInfo['status'] === 200) {
                        _this.profileImageInfo = fileInfo['file']['id'];
                    }
                });
            }
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    AddStaffComponent.prototype.readURL = function () {
        var _this = this;
        var file = this.files[0]['nativeFile'];
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList = [{ imageSrc: reader.result, name: _this.files[0]['nativeFile']['name'] }];
        };
        reader.readAsDataURL(file);
    };
    AddStaffComponent.prototype.onUploadOutput = function (output) {
        if (output.type === 'allAddedToQueue') {
            var fileType = this.files[0]['type'];
            var fileSize = this.files[0].size / 1024 / 1024;
            var validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
            if (!validImageTypes.includes(fileType)) {
                this.fileError = true;
                this.fileFormatError = true;
                this.removeFile(this.files[0]['id']);
            }
            else {
                if (fileSize < 5) {
                    this.readURL();
                    this.uploadS3Bucket();
                }
                else {
                    this.fileError = true;
                    this.fileSizeError = true;
                    this.removeFile(this.files[0]['id']);
                }
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            // add file to array when added
            this.files = [output.file];
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            this.files = [output.file];
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    AddStaffComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    AddStaffComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    AddStaffComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    AddStaffComponent.prototype.deleteUploadFile = function (fileIds) {
        this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe(function (deleteFiles) {
            // Do Nothing...
        });
    };
    AddStaffComponent.prototype.removeUploadFile = function (file) {
        this.uploadfileList = [];
        this.profileImageInfo = null;
        this.removeFileIds.push(file.id);
    };
    return AddStaffComponent;
}());
export { AddStaffComponent };
