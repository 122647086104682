import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-location-blacklisting',
  templateUrl: './location-blacklisting.component.html',
})
export class LocationBlackListingComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() blackList;
  @Input() clientId;
  @Input() locationBlackList;
  @Input() locationId;
  staffList;
  selectBlackList: any = {};
  blackStaff = '';
  isSubmit = false;
  showError = false;

  constructor(private apiService: ApiService, private toastr: ToastrService, private modalService: BsModalService) {}

  ngOnInit() {
    this.getStaffList();
  }

  openModal(template: TemplateRef<any>) {
    this.selectBlackList = {};
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  getStaffList() {
    this.apiService.get('staff/list').subscribe((staff) => {
      if (staff['status'] === 200) {
        this.staffList = staff['staffs'];
      }
    });
  }

  changeAction() {
    this.isSubmit = false;
    this.showError = false;
  }

  createBlackList() {
    this.isSubmit = true;
    if (this.blackStaff === '') {
      this.showError = true;
      return;
    }

    this.apiService
      .post('client/' + this.clientId + '/blacklist', { staff_id: this.blackStaff, location: this.locationId })
      .subscribe((blackStaff) => {
        if (blackStaff['status'] === 200) {
          this.toastr.success(blackStaff['message']);
          this.clearForm();
        }
      });
  }

  deleteList(template: TemplateRef<any>, list) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
    this.selectBlackList = list;
  }

  deleteBlackList() {
    this.apiService
      .delete('client/' + this.clientId + '/blacklist/' + this.selectBlackList['id'])
      .subscribe((deleteblacklist) => {
        if (deleteblacklist['status'] === 200) {
          this.toastr.success(deleteblacklist['message']);
          this.clearForm();
        } else {
          this.toastr.error(deleteblacklist['message']);
          this.clearForm();
        }
      });
  }

  clearForm() {
    this.modalRef.hide();
    this.isSubmit = false;
    this.showError = false;
    this.blackStaff = '';
    this.selectBlackList = {};
    this.apiService.closeClientModel.emit(14);
  }
}
