import { OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../../environments/environment';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, S3UploadService } from '../../../services';
import { humanizeBytes } from 'ngx-uploader';
import { validFormat } from '../../../constants/defaultValues';
var IncidentEventsComponent = /** @class */ (function () {
    function IncidentEventsComponent(apiService, s3upload, toastr, modalService, formBuilder) {
        this.apiService = apiService;
        this.s3upload = s3upload;
        this.toastr = toastr;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.fileUrl = environment.fileUrl;
        this.isSubmitted = false;
        this.fileIds = [];
        this.loading = false;
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = '';
        this.uploadfileList = [];
        this.imageObject = {};
        this.progress = [{ progress: 0 }];
        this.multiFileIds = [];
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    IncidentEventsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var format = validFormat.toString();
        this.supportFormat = format.replace(/,/g, ', ');
        this.createForm();
        this.s3upload.progress.subscribe(function (progress) {
            if (progress['type'] === 4) {
                _this.progress[progress['index']] = progress;
                if (_this.progress && _this.progress.length > 0) {
                    var completeUpload_1 = [];
                    _this.progress.forEach(function (key) {
                        if (key && key['progress']) {
                            if (key['progress'] === 100) {
                                completeUpload_1.push(key);
                                if (_this.progress && _this.progress.length === completeUpload_1.length) {
                                    _this.loading = false;
                                }
                            }
                        }
                    });
                }
            }
        });
    };
    IncidentEventsComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    IncidentEventsComponent.prototype.createForm = function () {
        this.incidentsForm = this.formBuilder.group({
            subject: ['', Validators.required],
            description: [''],
        });
    };
    Object.defineProperty(IncidentEventsComponent.prototype, "f", {
        get: function () {
            return this.incidentsForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    IncidentEventsComponent.prototype.openModal = function (template) {
        this.files = [];
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    };
    IncidentEventsComponent.prototype.createIncidents = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.incidentsForm.invalid) {
            return;
        }
        var datavalue = this.incidentsForm.value;
        if (this.uploadfileList && this.uploadfileList.length > 0) {
            this.fileIds = [];
            this.uploadfileList.forEach(function (item) {
                var data = { file_path: item.file_path, name: item.name, type: item.type };
                _this.fileIds.push(data);
            });
            datavalue['file_ids'] = this.fileIds;
        }
        this.loading = true;
        this.apiService.post('incident/report/' + this.incidentId + '/event', datavalue).subscribe(function (incident) {
            _this.loading = false;
            if (incident['status'] === 200) {
                _this.toastr.success(incident['message']);
                _this.clearForm();
            }
        });
    };
    IncidentEventsComponent.prototype.clearForm = function () {
        this.modalRef.hide();
        this.incidentsForm.reset();
        this.isSubmitted = false;
        this.fileError = false;
        this.fileIds = [];
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.uploadfileList = [];
        this.imageObject = {};
        this.multiFileIds = [];
        this.progress = [];
        this.apiService.closeClientModel.emit(11);
    };
    IncidentEventsComponent.prototype.uploadS3Bucket = function (file, index) {
        var _this = this;
        var fileType = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (fileType === 'application')
            fileType = 'document';
        var type = 4;
        this.s3upload
            .uploadfile(file, index, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: file['name'], type: fileType, format: fileExtension };
            _this.uploadfileList[index] = fileData;
            /*this.apiService.post('incident/report-upload', {file_path:data['Key'], name: file['name']}).subscribe(fileInfo => {
            if(fileInfo['status'] === 200) {
              let fileResult = fileInfo['file'];
              this.fileIds.push(fileResult['id']);
              this.uploadfileList.forEach(item => {
                if(item['file_path'] === fileResult['file_path']) {
                  item['id'] = fileResult['id'];
                }
              })
            }
          })*/
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    IncidentEventsComponent.prototype.readURL = function (file, index) {
        var _this = this;
        var type = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (type === 'application')
            type = 'document';
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList[index] = { imageSrc: reader.result, name: file['name'], type: type, format: fileExtension };
        };
        reader.readAsDataURL(file);
    };
    IncidentEventsComponent.prototype.onUploadOutput = function (output) {
        var _this = this;
        if (output.type === 'allAddedToQueue') {
            if (this.files && this.files.length > 0) {
                var formatErrors_1 = [];
                var sizeErros_1 = [];
                this.files.forEach(function (key, index) {
                    var fileSize = key.size / 1024 / 1024;
                    var fileExtension = key.name.replace(/^.*\./, '');
                    if (!validFormat.includes(fileExtension)) {
                        _this.fileError = true;
                        _this.fileFormatError = true;
                        formatErrors_1.push(true);
                        _this.removeFile(key['id']);
                    }
                    else {
                        if (fileSize < 5) {
                            var fileIndex = _this.multiFileIds.findIndex(function (obj) { return obj === key['id']; });
                            if (sizeErros_1.length > 0 || formatErrors_1.length > 0) {
                                _this.fileError = true;
                            }
                            else {
                                _this.fileError = false;
                            }
                            if (sizeErros_1.length > 0) {
                                _this.fileSizeError = true;
                            }
                            else {
                                _this.fileSizeError = false;
                            }
                            if (formatErrors_1.length > 0) {
                                _this.fileFormatError = true;
                            }
                            else {
                                _this.fileFormatError = false;
                            }
                            if (fileIndex === -1) {
                                _this.multiFileIds.push(key['id']);
                                _this.readURL(key['nativeFile'], index);
                                _this.uploadS3Bucket(key['nativeFile'], index);
                            }
                        }
                        else {
                            _this.fileError = true;
                            _this.fileSizeError = true;
                            sizeErros_1.push(true);
                            _this.removeFile(key['id']);
                        }
                    }
                });
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            // add file to array when added
            this.files.push(output.file);
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            var index = this.files.findIndex(function (file) { return typeof output.file !== 'undefined' && file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    IncidentEventsComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    IncidentEventsComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    IncidentEventsComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    IncidentEventsComponent.prototype.deleteUploadFile = function (fileIds, index, fileindex) {
        var _this = this;
        this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe(function (deleteFiles) {
            if (fileIds && fileIds.length === 1) {
                _this.uploadfileList.splice(index, 1);
                _this.fileIds.splice(index, 1);
            }
        });
    };
    IncidentEventsComponent.prototype.uploadCancel = function () {
        this.clearForm();
        /*if(this.uploadfileList && this.uploadfileList.length > 0) {
          let fileIds = [];
          this.uploadfileList.forEach(item => fileIds.push(item['id']));
          this.deleteUploadFile(this.fileIds, -1, -1)
          this.clearForm();
        } else {
          this.clearForm();
        }*/
    };
    IncidentEventsComponent.prototype.removeUploadFile = function (file, index) {
        // let fileindex = this.fileIds.findIndex(obj => obj === file.id);
        this.removeFile(this.files[index]['id']);
        this.uploadfileList.splice(index, 1);
        // this.deleteUploadFile([file['id']], index, fileindex);
    };
    IncidentEventsComponent.prototype.viewFile = function (url) {
        window.open(this.fileUrl + url);
    };
    return IncidentEventsComponent;
}());
export { IncidentEventsComponent };
