import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blacklisting-staff',
  templateUrl: './blacklisting-staff.component.html',
})
export class BlacklistingStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() blackList;

  constructor(private apiService: ApiService, private toastr: ToastrService, private modalService: BsModalService) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }
}
