<div class="row px-2 pt-3">
  <div class="col-12">
    <div class="row">
      <div class="col-md-12">
        <div class="search-box">
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0" id="basic-addon1">
                <em class="fas fa-search color-light-grey fs12"></em>
              </span>
            </div>
            <input
              type="text"
              class="form-control fs14"
              placeholder="search"
              aria-label="zone"
              aria-describedby="basic-addon1"
              (input)="searchzone($event.target.value)"
            />
          </div>
        </div>
      </div>
    </div>
    <ul class="timezonestyle">
      <li *ngFor="let timezone of timezones">
        <label class="radiocontainer">
          <p class="color-black fs16 pl-3 mb-4">{{ timezone }}</p>
          <input type="radio" name="radio" (change)="selecttimezone(timezone)" [checked]="timezonevalue === timezone" />
          <span class="customradiobtn"></span>
        </label>
      </li>
    </ul>

    <div class="form-row mt-2">
      <div class="col-12 mb-4 d-flex justify-content-end">
        <button type="button" class="btn btn-primary btn-cancel border-0 py-2 fs14 w-140px mr-3" (click)="closeModel()">
          Cancel
        </button>
        <button type="submit" class="btn btn-secondary py-2 btn-create border-0 w-140px fs14" (click)="submitfn()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
