import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService, GeolocationService } from '../../../../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
var SublocationAboutComponent = /** @class */ (function () {
    function SublocationAboutComponent(apiService, toastr, geolocationService, formBuilder, router, modalService) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.geolocationService = geolocationService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.modalService = modalService;
        this.isSubmitted = false;
        this.loading = false;
        this.availableTime = ['Anytime', '30 minutes before', '15 minutes before', '5 minutes before'];
        this.marklatitude = '';
        this.marklongitude = '';
    }
    SublocationAboutComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.setCurrentLocation();
    };
    SublocationAboutComponent.prototype.setCurrentLocation = function () {
        var _this = this;
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                _this.latitude = position.coords.latitude;
                _this.longitude = position.coords.longitude;
                _this.zoom = 15;
            }, function (err) {
                _this.zoom = 15;
                _this.latitude = -41.2935403;
                _this.longitude = 174.7760212;
            });
        }
        else {
            this.zoom = 15;
            this.latitude = -41.2935403;
            this.longitude = 174.7760212;
        }
    };
    SublocationAboutComponent.prototype.createForm = function () {
        this.locationForm = this.formBuilder.group({
            name: ['', Validators.required],
            address: [''],
            is_clockin: [0],
            is_clock_at_location: [0],
            is_exclude_time_limit: [0],
            clock_available: [''],
            default_brief: [''],
            latitude: [''],
            longitude: [''],
        });
    };
    SublocationAboutComponent.prototype.redirectUrl = function (id) {
        this.router.navigate(['/client-details/' + id]);
    };
    SublocationAboutComponent.prototype.editClientModal = function (template) {
        this.setLocationInfo();
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg modal-dialog-centered roaster-modal',
        });
    };
    SublocationAboutComponent.prototype.openDeleteModal = function (template) {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg modal-dialog-centered roaster-modal',
        });
    };
    Object.defineProperty(SublocationAboutComponent.prototype, "f", {
        get: function () {
            return this.locationForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    SublocationAboutComponent.prototype.createLocation = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.locationForm.invalid) {
            return;
        }
        var datavalue = this.locationForm.value;
        datavalue['is_clockin'] = datavalue['is_clockin'] === true || datavalue['is_clockin'] === 1 ? 1 : 0;
        datavalue['is_clock_at_location'] =
            datavalue['is_clock_at_location'] === true || datavalue['is_clock_at_location'] === 1 ? 1 : 0;
        datavalue['is_exclude_time_limit'] =
            datavalue['is_exclude_time_limit'] === true || datavalue['is_exclude_time_limit'] === 1 ? 1 : 0;
        datavalue['longitude'] = this.marklongitude;
        datavalue['latitude'] = this.marklatitude;
        this.loading = true;
        this.apiService.put('client/' + this.clientId + '/location/' + this.locationId, datavalue).subscribe(function (location) {
            _this.loading = false;
            if (location['status'] === 200) {
                _this.toastr.success(location['message']);
                _this.clearForm();
            }
        });
    };
    SublocationAboutComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    SublocationAboutComponent.prototype.addMarker = function (lat, lng) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.marklatitude = lat;
                        this.marklongitude = lng;
                        return [4 /*yield*/, this.geolocationService.getMarkedAddress({ lat: lat, lng: lng })];
                    case 1:
                        address = _a.sent();
                        this.locationForm.patchValue({ address: address['address'] });
                        return [2 /*return*/];
                }
            });
        });
    };
    SublocationAboutComponent.prototype.checkLatLong = function (value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var address;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!value) return [3 /*break*/, 1];
                        this.marklatitude = '';
                        this.marklongitude = '';
                        this.latitude = null;
                        this.longitude = null;
                        this.setCurrentLocation();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.geolocationService.getLocationAddress(value)];
                    case 2:
                        address = _a.sent();
                        if (address && address['lat'] && address['long']) {
                            this.marklatitude = address['lat'];
                            this.marklongitude = address['long'];
                            this.latitude = address['lat'];
                            this.longitude = address['long'];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SublocationAboutComponent.prototype.setLocationInfo = function () {
        this.locationForm.patchValue({
            name: this.locationInfo['name'],
            address: this.locationInfo['address'],
            is_clockin: this.locationInfo['is_clockin'],
            is_clock_at_location: this.locationInfo['is_clock_at_location'],
            is_exclude_time_limit: this.locationInfo['is_exclude_time_limit'],
            clock_available: this.locationInfo['clock_available'] ? this.locationInfo['clock_available'] : '',
            default_brief: this.locationInfo['default_brief'],
            latitude: this.locationInfo['latitude'],
            longitude: this.locationInfo['longitude'],
        });
        if (this.locationInfo['latitude'] && this.locationInfo['longitude']) {
            this.marklatitude = parseFloat(this.locationInfo['latitude']);
            this.marklongitude = parseFloat(this.locationInfo['longitude']);
        }
        if (this.locationInfo['address']) {
            this.checkLatLong(this.locationInfo['address']);
        }
    };
    SublocationAboutComponent.prototype.removeLocation = function () {
        var _this = this;
        this.loading = true;
        this.apiService.delete('client/' + this.clientId + '/location/' + this.locationId).subscribe(function (data) {
            if (data['status'] === 200) {
                _this.toastr.success('Location Removed Successfully');
                _this.clearForm();
                _this.loading = false;
                _this.router.navigate(['/client/location-details/' + _this.parentLocationId], {
                    queryParams: { clientId: _this.clientId },
                });
            }
            else {
                _this.loading = false;
                _this.toastr.error(data['message']);
            }
        }, function (err) {
            _this.loading = false;
        });
    };
    SublocationAboutComponent.prototype.clearForm = function () {
        this.modalRef.hide();
        this.locationForm.reset({ clock_available: '' });
        this.isSubmitted = false;
        this.loading = false;
        this.apiService.closeClientModel.emit(15);
        //this.apiService.closeClientModel.emit(10);
        this.marklongitude = '';
        this.marklatitude = '';
    };
    SublocationAboutComponent.prototype.closeModel = function () {
        this.modalRef.hide();
    };
    SublocationAboutComponent.prototype.revertLocation = function () {
        var _this = this;
        this.loading = true;
        this.apiService.get('client/' + this.clientId + '/location/' + this.locationId + '/revert').subscribe(function (data) {
            if (data['status'] === 200) {
                _this.toastr.success('Location Restored Successfully');
                _this.clearForm();
                _this.loading = false;
                //this.router.navigate(['/client-details/'+this.clientId]);
            }
            else {
                _this.loading = false;
                _this.toastr.error(data['message']);
            }
        }, function (err) {
            _this.loading = false;
        });
    };
    SublocationAboutComponent.prototype.backUrl = function () {
        this.router.navigate(['/client/location-details/' + this.parentLocationId], {
            queryParams: { clientId: this.clientId },
        });
    };
    return SublocationAboutComponent;
}());
export { SublocationAboutComponent };
