<div class="row px-3 py-3">
  <div class="col-12">
    <form [formGroup]="customercreationform" (ngSubmit)="onSubmit()">
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Name</label>
            <input
              type="text"
              formControlName="Name"
              class="form-control customercreateform"
              placeholder="Enter Name"
              [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
            />
            <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
              <div *ngIf="f.Name.errors.required">Name is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Company Name</label>
            <input
              type="text"
              formControlName="CompanyName"
              class="form-control customercreateform"
              placeholder="Enter Company Name"
              [ngClass]="{ 'is-invalid': submitted && f.CompanyName.errors }"
            />
            <div *ngIf="submitted && f.CompanyName.errors" class="invalid-feedback">
              <div *ngIf="f.CompanyName.errors.required">Company Name is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Company Website</label>
            <input
              type="text"
              formControlName="CompanyWebsite"
              class="form-control customercreateform"
              placeholder="Enter Company Website"
              [ngClass]="{ 'is-invalid': submitted && f.CompanyWebsite.errors }"
            />
            <div *ngIf="submitted && f.CompanyWebsite.errors" class="invalid-feedback">
              <div *ngIf="f.CompanyWebsite.errors.required">Company Website is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Mobile Number</label>
            <div class="inputwithicon position-relative">
              <img src="./assets/images/indiaflag.png" class="position-absolute img1" alt="indiaflag" />
              <img src="./assets/images/arrow1.png" class="position-absolute img2" alt="arrow" />
              <input
                type="text"
                formControlName="MobileNumber"
                class="form-control customercreateform"
                placeholder="Mobile Number"
                [ngClass]="{ 'is-invalid': submitted && f.MobileNumber.errors }"
              />
            </div>
            <div *ngIf="submitted && f.MobileNumber.errors" class="invalid-feedback">
              <div *ngIf="f.MobileNumber.errors.required">Mobile Number is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Email</label>
            <input
              type="text"
              formControlName="Email"
              class="form-control customercreateform"
              placeholder="Enter Email"
              [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
            />
            <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
              <div *ngIf="f.Email.errors.required">Email is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Domain</label>
            <input
              type="text"
              formControlName="Domain"
              class="form-control customercreateform"
              placeholder="Enter Domain"
              [ngClass]="{ 'is-invalid': submitted && f.Domain.errors }"
            />
            <div *ngIf="submitted && f.Domain.errors" class="invalid-feedback">
              <div *ngIf="f.Domain.errors.required">Domain is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Time Format</label>
            <input
              type="text"
              (click)="timeformatModal(Timeformat)"
              formControlName="TimeFormat"
              class="form-control customercreateform"
              placeholder="Enter Time Format"
              [ngClass]="{ 'is-invalid': submitted && f.TimeFormat.errors }"
            />
            <div *ngIf="submitted && f.TimeFormat.errors" class="invalid-feedback">
              <div *ngIf="f.TimeFormat.errors.required">Time Format is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Time Zone</label>
            <input
              type="text"
              (click)="timezoneModal(Timezone)"
              formControlName="TimeZone"
              class="form-control customercreateform"
              placeholder="Enter Time Zone"
              [ngClass]="{ 'is-invalid': submitted && f.TimeZone.errors }"
            />
            <div *ngIf="submitted && f.TimeZone.errors" class="invalid-feedback">
              <div *ngIf="f.TimeZone.errors.required">Time Zone is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Date Format</label>
            <input
              type="text"
              (click)="dateformatModal(Dateformat)"
              formControlName="DateFormat"
              class="form-control customercreateform"
              placeholder="Enter Date Format"
              [ngClass]="{ 'is-invalid': submitted && f.DateFormat.errors }"
            />
            <div *ngIf="submitted && f.DateFormat.errors" class="invalid-feedback">
              <div *ngIf="f.DateFormat.errors.required">Date Format is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mt-2">
        <div class="col-12 mb-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-140px mr-3"
            tooltip="Cancel"
            (click)="closeModel()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-secondary btn-create border-0 w-140px fs14" tooltip="New Staff">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
  <ng-template #Timeformat>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Time Format</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timeformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timeformat
        (messageEvent)="receivetimeformat($event)"
        (timedformat)="receivetime($event)"
        [updatetimeformat]="timeformatvalue"
      ></app-timeformat>
    </div>
  </ng-template>
  <ng-template #Dateformat>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Date Format</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="dateformatpopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-dateformat
        (messageEvent)="receivedateformat($event)"
        (datesformat)="receivedate($event)"
        [updatedateformat]="dateformatvalue"
      ></app-dateformat>
    </div>
  </ng-template>
  <ng-template #Timezone>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Time Zone</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="timezonepopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-timezone (messageEvent)="receivetimezonevalue($event)" [timezonevalue]="message3"></app-timezone>
    </div>
  </ng-template>
</div>
