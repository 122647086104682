<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="cancleDeleteAllShfit()"><a>Scheduler</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-trash mr-1"></em>Delete Shift</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border" *ngIf="!deleteType">Delete Shift</h1>
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border" *ngIf="deleteType">Delete All Shift</h1>
    <p class="color-grey-text" *ngIf="!deleteType">Are you sure you want to delete this shifts ?</p>
    <p class="color-grey-text" *ngIf="deleteType">Are you sure you want to delete all these shifts ?</p>
    <div class="row mt-2">
      <div class="col-md-12 col-lg-6 col-12">
        <div class="table-responsive list-table">
          <table class="table bg-white shadow-none mb-0">
            <caption class="d-none">
              Delete tracking Data
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let shift of shiftList
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                  let i = index
                "
                ><tr>
                  <td>{{ shift.work_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ shift.startTime }}</td>
                  <td>{{ shift.endTime }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="bg-white text-center py-4 roaster-pagination" *ngIf="type && shiftList.length > 0">
          <pagination-controls
            class="delete-all-shifts"
            (pageChange)="pageChange($event)"
            maxSize="7"
            previousLabel=""
            nextLabel=""
            autoHide="true"
          ></pagination-controls>
        </div>

        <div class="bg-white text-center py-4" *ngIf="shiftList.length === 0">
          <p class="text-danger">No Data Found</p>
        </div>

        <div class="float-right my-3" *ngIf="shiftList.length > 0">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="cancleDeleteAllShfit()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Delete"
            (click)="openDeleteModal(deleteModal)"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal" *ngIf="!deleteType">Delete Shift</h4>
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal" *ngIf="deleteType">Delete All Shift</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p *ngIf="!deleteType">Are you sure you want to delete this shift?</p>
    <p *ngIf="deleteType">Are you sure you want to delete all these shifts?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeModel()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteAllShift()"
      [disabled]="isLoading"
    >
      Yes
    </button>
  </div>
</ng-template>
