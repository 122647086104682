import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment-timezone';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
})
export class TimezoneComponent implements OnInit {
  timezones = [];
  //timezonevalue = "";
  @Input() timezonevalue;
  @Output() messageEvent = new EventEmitter<string>();
  constructor(private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit() {
    this.timezones = moment.tz.names();
  }

  searchzone(data) {
    if (data != undefined && data != '') {
      this.timezones = this.timezones.filter((i) => i.toLowerCase().startsWith(data.toString().toLowerCase()));
    } else {
      this.timezones = moment.tz.names();
    }
  }
  selecttimezone(timezone) {
    this.timezonevalue = timezone;
  }
  submitfn() {
    if (this.timezonevalue) {
      this.messageEvent.emit(this.timezonevalue);
      this.apiService.closeClientModel.emit(12);
    } else {
      this.toastr.error('Choose timezone value');
    }
  }
  closeModel() {
    this.apiService.closeClientModel.emit(12);
  }
}
