<header class="header">
  <nav class="navbar navbar-light fixed-top bg-white p-0" aria-labelledby="R101">
    <div class="navbar-brand">
      <img src="assets/images/logo-w.png" alt="user icon" class="logo" />
    </div>
    <a href="javascript:void(0)" class="toggle-btn" [ngClass]="{ sidemenuopened: shouldShow }" (click)="sidebarToggle()"
      ><em class="fas fa-bars fs30"></em
    ></a>

    <div class="ml-auto d-block d-md-none mr-2" dropdown placement="bottom right">
      <div
        class="user-profile bg-transparent d-flex align-items-center c-pointer dropdown-toggle"
        dropdownToggle
        aria-controls="dropdown-animated"
      >
        <img
          src="{{ fileUrl }}{{ userInfo?.profile?.file_path }}"
          alt="user icon"
          class="mr-2"
          *ngIf="userInfo && userInfo?.profile && userInfo?.profile?.file_path"
        />
        <img src="assets/images/profile.png" alt="user icon" class="mr-2" *ngIf="!userInfo?.profile?.file_path" />
        <p class="font-weight-bold fs14 text-truncate widm-140 mb-0 mr-1 profile-name" routerLink="/profile">
          {{ userName }}
        </p>
        <em class="fa fa-angle-down text-white"></em>
      </div>
      <ul
        id="dropdown-animated"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right fs14"
        role="menu"
        aria-labelledby="button-animated"
      >
        <li role="menuitem">
          <a
            class="dropdown-item"
            href="javascript:void(0);"
            (click)="openAddShift(addShift)"
            *ngIf="userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor'"
            >Add Shift</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" href="javascript:void(0);" (click)="openAddShift(addIncident)">Report Incident</a>
        </li>
        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
      </ul>
    </div>
    <div class="ml-auto nav-right d-none d-md-block mr-3">
      <ul class="list-group list-group-horizontal align-items-center">
        <li class="list-inline border-0 p-0 mr-md-3">
          <button
            class="btn btn-success button-light-green border-0 fs12 ff-roboto fw-500"
            (click)="openAddShift(addShift)"
            *ngIf="userGroup === 'Full access' || userGroup === 'Advanced' || userGroup === 'Supervisor'"
          >
            + ADD SHIFT
          </button>
        </li>
        <li class="list-inline border-0 p-0 mr-md-3">
          <button
            type="button"
            class="btn btn-danger border-0 fs12 ff-roboto fw-500"
            (click)="openAddShift(addIncident)"
          >
            <em class="fas fa-exclamation-circle"></em> REPORT INCIDENT
          </button>
        </li>
        <li class="list-inline border-0 p-0 mr-md-3">
          <div class="user-profile d-flex align-items-center c-pointer">
            <img
              src="{{ fileUrl }}{{ userInfo?.profile?.file_path }}"
              alt="user icon"
              class="mr-2"
              *ngIf="userInfo && userInfo?.profile && userInfo?.profile?.file_path"
            />
            <img src="assets/images/profile.png" alt="user icon" class="mr-2" *ngIf="!userInfo?.profile?.file_path" />
            <p class="font-weight-bold fs14 text-truncate widm-140 mb-0" routerLink="/profile">
              {{ userName }}
            </p>
          </div>
        </li>
        <li class="list-inline border-0 p-0">
          <button type="button" (click)="logout()" class="btn btn-danger border-0 fs12 ff-roboto fw-500">logout</button>
        </li>
      </ul>
    </div>
  </nav>
</header>
<ng-template #addShift>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">{{ headerTitle }}</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <app-add-shift></app-add-shift>
  </div>
</ng-template>

<ng-template #addIncident>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Report Incident</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0">
    <app-add-incident (cancelModel)="closeModal()"></app-add-incident>
  </div>
</ng-template>

<ng-template #blockAccount>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Block Account</h4>
  </div>
  <div class="modal-body text-center">
    <p>Your Account has been blocked. Please Contact Admin.</p>
    <button type="submit" class="btn btn-secondary btn-create border-0 w-100px fs14" (click)="logout()">Ok</button>
  </div>
</ng-template>
