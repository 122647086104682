import { Component, OnInit, TemplateRef, Input, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ApiService, S3UploadService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { validFormat } from '../../constants/defaultValues';
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
})
export class FilesComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() fileList;
  @Input() clientId;
  fileUrl: any = environment.fileUrl;
  selectedFile = {};

  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  fileError = false;
  fileSizeError = false;
  fileFormatError = false;
  supportFormat = '';
  currentUploadfileId = [];
  uploadfileList = [];
  imageObject = {};
  progress = [{ progress: 0 }];
  multiFileIds = [];

  constructor(
    private apiService: ApiService,
    private s3upload: S3UploadService,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    const format = validFormat.toString();
    this.supportFormat = format.replace(/,/g, ', ');
    this.s3upload.progress.subscribe((progress: any) => {
      if (progress['type'] === 2) {
        this.progress[progress['index']] = progress;
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  downloadfile(url) {
    const headers = new Headers();
    const requestOptions: any = { method: 'GET', headers: { headers }, mode: 'cors', cache: 'default' };
    fetch(this.fileUrl + url, requestOptions)
      .then((response) => {
        return response.blob();
      })
      .then((myBlob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(myBlob);
        link.setAttribute('visibility', 'hidden');
        link.download = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        window.open(this.fileUrl + url);
      });
  }

  viewFile(url) {
    window.open(this.fileUrl + url);
  }

  closeForm() {
    this.modalRef.hide();
    this.files = [];
    this.fileError = false;
    this.fileSizeError = false;
    this.fileFormatError = false;
    this.selectedFile = {};
    this.uploadfileList = [];
    this.imageObject = {};
    this.multiFileIds = [];
    this.progress = [];
    this.apiService.closeClientModel.emit(4);
  }

  deletePopup(template: TemplateRef<any>, file) {
    this.selectedFile = file;
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-md' });
  }

  uploadS3Bucket(file, index) {
    let fileType = file.type.slice(0, file.type.indexOf('/'));
    const fileExtension = file.name.replace(/^.*\./, '');
    if (fileType === 'application') fileType = 'document';
    const type = 2;
    this.s3upload
      .uploadfile(file, index, type)
      .then((data: any) => {
        const fileData = { file_path: data['Key'], name: file['name'], type: fileType, format: fileExtension };
        this.uploadfileList[index] = fileData;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  readURL(file, index) {
    let type = file.type.slice(0, file.type.indexOf('/'));
    const fileExtension = file.name.replace(/^.*\./, '');
    if (type === 'application') type = 'document';
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadfileList[index] = { imageSrc: reader.result, name: file['name'], type: type, format: fileExtension };
    };
    reader.readAsDataURL(file);
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') {
      if (this.files && this.files.length > 0) {
        const formatErrors = [];
        const sizeErros = [];
        this.files.forEach((key, index) => {
          const fileSize = key.size / 1024 / 1024;
          const fileExtension = key.name.replace(/^.*\./, '');
          if (!validFormat.includes(fileExtension)) {
            this.fileError = true;
            this.fileFormatError = true;
            formatErrors.push(true);
            this.removeFile(key['id']);
          } else {
            if (fileSize < 5) {
              const fileIndex = this.multiFileIds.findIndex((obj) => obj === key['id']);
              if (sizeErros.length > 0 || formatErrors.length > 0) {
                this.fileError = true;
              } else {
                this.fileError = false;
              }
              if (sizeErros.length > 0) {
                this.fileSizeError = true;
              } else {
                this.fileSizeError = false;
              }
              if (formatErrors.length > 0) {
                this.fileFormatError = true;
              } else {
                this.fileFormatError = false;
              }
              if (fileIndex === -1) {
                this.multiFileIds.push(key['id']);
                this.readURL(key['nativeFile'], index);
                this.uploadS3Bucket(key['nativeFile'], index);
              }
            } else {
              this.fileError = true;
              this.fileSizeError = true;
              sizeErros.push(true);
              this.removeFile(key['id']);
            }
          }
        });
      }
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  deleteFile() {
    this.apiService.delete('client/deletefile/' + this.selectedFile['id']).subscribe((deletefile) => {
      this.toastr.clear();
      if (deletefile['status'] === 200) {
        this.toastr.success(deletefile['message']);
        this.closeForm();
      } else {
        this.toastr.error(deletefile['message']);
        this.closeForm();
      }
    });
  }

  deleteUploadFile(fileIds, index) {
    this.apiService.post('client/delete-multiplefile', { file_ref_id: fileIds }).subscribe((deleteFiles) => {
      if (fileIds && fileIds.length === 1) {
        this.uploadfileList.splice(index, 1);
      }
    });
  }

  uploadCancel() {
    if (this.uploadfileList && this.uploadfileList.length > 0) {
      const fileIds = [];
      this.uploadfileList.forEach((item) => fileIds.push(item['id']));
      this.deleteUploadFile(fileIds, -1);
      this.closeForm();
    } else {
      this.closeForm();
    }
  }

  removeUploadFile(index) {
    this.uploadfileList.splice(index, 1);
    const file = this.files[index];
    this.removeFile(file['id']);
    /*let index = this.uploadfileList.findIndex(obj => obj.id === file.id);
    this.deleteUploadFile([file['id']], index);*/
  }

  saveFiles() {
    if (this.uploadfileList && this.uploadfileList.length > 0) {
      const fileInfo = [];
      this.uploadfileList.forEach((item) => {
        const data = { file_path: item['file_path'], name: item['name'] };
        fileInfo.push(data);
      });
      this.apiService.post('client/upload/' + this.clientId, { fileInfo }).subscribe((uploadfile) => {
        if (uploadfile['status'] === 200) {
          this.toastr.success('File Added Successfully');
          this.closeForm();
        } else {
          this.toastr.error('File not found');
        }
      });
    }
  }
}
