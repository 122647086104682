import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  email: FormControl;
  password: FormControl;
  submitted = false;
  loading = false;
  isRemember: any = false;
  domainInfo: any = {};
  constructor(private apiService: ApiService, private toastr: ToastrService, private router: Router) {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    if (this.domainInfo) moment.tz.setDefault(this.domainInfo.timezone);
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/),
    ]);
    this.password = new FormControl('', [Validators.required]);
  }

  createForm() {
    this.loginForm = new FormGroup({
      email: this.email,
      password: this.password,
    });
    if (localStorage.getItem('isRemember')) {
      this.isRemember = true;
      const dmail = window.atob(localStorage.getItem('user_email'));
      this.loginForm.controls['email'].setValue(dmail);
      const dpassword = window.atob(localStorage.getItem('user_password'));
      this.loginForm.controls['password'].setValue(dpassword);
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  async getDateAndTimeFormat(organizationId): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiService.get('domain/getTimeAndDateFormat/' + organizationId).subscribe((dateAndTimeInfo) => {
        if (dateAndTimeInfo['status'] === 200) {
          this.domainInfo = dateAndTimeInfo && dateAndTimeInfo['viewDateAndTimeFormat'];
        } else {
          this.domainInfo = { timeformat: 'hh:mm:ss @', dateformat: 'MMM DD, YYYY' };
        }
        if (this.domainInfo && this.domainInfo.timezone) moment.tz.setDefault(this.domainInfo.timezone);
        localStorage.setItem('domainInfo', JSON.stringify(this.domainInfo));
        resolve(true);
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const data: any = {};
    data.email = this.loginForm.value.email;
    data.password = this.loginForm.value.password;
    // data.companyName = 'optisol';
    // data.is_admin = 1;
    this.loading = true;

    this.apiService.post('auth/login', data).subscribe(
      async (user: any) => {
        this.loading = false;
        if (user.status === 200) {
          localStorage.setItem('user', JSON.stringify(user.userinfo));
          localStorage.setItem('userid', user.userinfo.id);
          localStorage.setItem('token', user.token);
          localStorage.setItem('userType', user.userinfo.userType);
          localStorage.setItem('userGroup', user.userinfo.group);
          localStorage.setItem('userLogId', user.userinfo.userLogId);
          localStorage.setItem('organization_id', user.userinfo.organization_id);
          await this.getDateAndTimeFormat(user.userinfo.organization_id);

          this.toastr.success(user.message);
          this.checkIsRemember(data);
          if (user.userinfo && (user.userinfo.group === 'Full access' || user.userinfo.group === 'Advanced')) {
            this.router.navigate(['/dashboard']);
          } else {
            if (user.userinfo.group_id == 5) {
              const queryParams = { page: 1, pagesize: 10 };
              this.apiService.get('domain/domainList', queryParams).subscribe((client: any) => {
                if (client.status === 200) {
                  const list = client.organizationdetails;
                  localStorage.removeItem('Editarray');
                  if (list.length > 0) {
                    this.router.navigate(['/client-information']);
                  } else {
                    this.router.navigate(['/customer-creation']);
                  }
                }
              });
            } else {
              this.router.navigate(['/scheduler']);
            }
          }
        }
      },
      (err) => {
        this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }

  checkIsRemember(data) {
    if (this.isRemember === true || this.isRemember === 'true') {
      const email = window.btoa(data.email);
      localStorage.setItem('user_email', email.toString());
      const password = window.btoa(data.password.toString());
      localStorage.setItem('user_password', password);
      localStorage.setItem('isRemember', this.isRemember.toString());
    } else {
      localStorage.removeItem('user_email');
      localStorage.removeItem('user_password');
      localStorage.removeItem('isRemember');
    }
  }

  checkValue(value: boolean) {
    if (value) {
      this.isRemember = true;
    } else {
      this.isRemember = false;
    }
  }
}
