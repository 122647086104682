<div class="card mb-3 border-0">
  <div class="card-header bg-white px-md-4 px-1">
    <div class="d-flex justify-content-between align-items-center">
      <div class="align-self-center">
        <h6 class="text-dark font-weight-bold fs16 mb-0">ABOUT</h6>
      </div>
      <div class="position-relative about-more" dropdown>
        <button
          type="button"
          class="btn btn-primary btn-more"
          id="button-basic"
          dropdownToggle
          aria-controls="dropdown-basic"
          tooltip="More"
          *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'"
        >
          More <em class="fas fa-caret-down ml-1 op-05"></em>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" (click)="editClientModal(editClient)">
            <a class="dropdown-item"><em class="fas fa-suitcase mr-2"></em>Edit Staff Details</a>
          </li>
          <li role="menuitem" (click)="changeLoginInfo(loginInfo)">
            <a class="dropdown-item"><em class="fas fa-user mr-2"></em>Change Login Info</a>
          </li>
          <li role="menuitem" (click)="staffAvailabilityModal(staffAvailabilityInfo)">
            <a class="dropdown-item"><em class="fas fa-user mr-2"></em>Staff Unavailability</a>
          </li>
          <li role="menuitem" (click)="openDeleteModal(deleteModal)" *ngIf="!staffInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-trash mr-2"></em>Remove</a>
          </li>
          <li role="menuitem" (click)="openDeleteModal(revertModal)" *ngIf="staffInfo.is_deleted">
            <a class="dropdown-item"><em class="fas fa-undo mr-2"></em>Restore</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-body text-dark">
    <div class="row">
      <div class="col-md-6 col-sm-6 p-1">
        <div class="media m-3">
          <img
            src="assets/images/profile-big.png"
            alt="Profile"
            class="roaster-profile-img align-self-center rounded-circle mr-3"
            *ngIf="!staffInfo?.profile?.file_path"
          />
          <img
            src="{{ fileUrl }}{{ staffInfo?.profile?.file_path }}"
            alt="Profile"
            class="roaster-profile-img align-self-center rounded-circle mr-3"
            *ngIf="staffInfo?.profile?.file_path"
          />
          <div class="media-body align-self-center">
            <h1 class="fs18 color-light-black mt-3">{{ staffInfo?.staff_firstname }} {{ staffInfo?.staff_surname }}</h1>
            <p class="fs14 color-light-black op-32">{{ staffInfo?.role }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 py-3">
        <div class="bg-icon-call">
          <h2 class="text-dark fs16 m-0">
            {{ staffInfo?.phonecode?.countries_isd_code }} {{ staffInfo.phone ? staffInfo.phone : '-' }}
          </h2>
          <span class="color-light-black op-32 fs14">Phone</span>
        </div>
        <div class="bg-icon-envelope mt-5">
          <h2 class="text-dark fs16 m-0">{{ staffInfo?.email }}</h2>
          <span class="color-light-black op-32 fs14">Email</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loginInfo>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Change Login Info</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-change-login-info [editStaffInfo]="staffInfo"></app-change-login-info>
  </div>
</ng-template>

<ng-template #editClient>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Edit Staff</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-add-staff [editStaffInfo]="staffInfo"></app-add-staff>
  </div>
</ng-template>

<ng-template #deleteModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Staff</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete(hiding) Staff?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="closeModel()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="removeStaff()"
    >
      Yes
    </button>
  </div>
</ng-template>

<ng-template #revertModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Restore Staff</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="closeModel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to restore Staff?</p>
    <button type="button" class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" (click)="closeModel()">
      No
    </button>
    <button type="submit" class="btn btn-secondary btn-create border-0 w-100px fs14" (click)="revertStaff()">
      Yes
    </button>
  </div>
</ng-template>

<ng-template #staffAvailabilityInfo>
  <div class="modal-header">
    <h4 class="modal-title w-100 text-center fs18 font-weight-normal">Staff Unavailability</h4>
    <button type="button" class="close text-white font-weight-normal op1" aria-label="Close" (click)="closeModel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body staffAvailInfo">
    <app-availability-staff [editStaffInfo]="staffInfo"></app-availability-staff>
  </div>
</ng-template>
