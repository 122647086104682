<!-- Start New Shift -->
<div class="p-4">
  <h1 class="color-black op-09 fs16">Incident</h1>
  <p class="color-black op-05 fs14 mb-0">
    Any type of incident can be reported using the form below. Please fill in as many details as needed to process the
    incident. You can also attach files as needed.
  </p>
  <form [formGroup]="incidentsForm" (ngSubmit)="createIncidents()">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label
          for="inputEmail4"
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': isSubmitted && f.incident_date.errors }"
          >Date</label
        >
        <input
          type="text"
          placeholder="Date"
          class="form-control"
          formControlName="incident_date"
          [ngClass]="{ 'invalid-field': isSubmitted && f.incident_date.errors }"
          bsDatepicker
          [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', showWeekNumbers: false }"
        />
      </div>
      <div class="form-group col-md-6">
        <label
          for="inputPassword4"
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': isSubmitted && f.incident_type_id.errors }"
          >Incident Type</label
        >
        <select
          id="inputState"
          class="form-control"
          formControlName="incident_type_id"
          [ngClass]="{ 'invalid-field': isSubmitted && f.incident_type_id.errors }"
        >
          <option value="" selected disabled>--Select Incident Type--</option>
          <option [ngValue]="incident.id" *ngFor="let incident of incidentTypeList">
            {{ incident.incident_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4" *ngIf="userGroup != 'Client'">
        <label for="inputCity" class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && f.client_id.errors }"
          >Location</label
        >
        <select
          id="inputState1"
          class="form-control"
          formControlName="client_id"
          [ngClass]="{ 'invalid-field': isSubmitted && f.client_id.errors }"
          (change)="changeClient()"
        >
          <option value="" selected disabled>--Select Location--</option>
          <option [ngValue]="client.id" *ngFor="let client of clientList">{{ client.name }}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label
          for="inputState2"
          class="font-weight-bold"
          [ngClass]="{ 'text-danger': isSubmitted && f.location_id.errors }"
          >Role</label
        >
        <select
          id="inputState3"
          class="form-control"
          formControlName="location_id"
          [ngClass]="{ 'invalid-field': isSubmitted && f.location_id.errors }"
        >
          <option value="" selected disabled>--Select Role--</option>
          <option [ngValue]="location.id" *ngFor="let location of locationList">{{ location.name }}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="inputZip" class="font-weight-bold" [ngClass]="{ 'text-danger': isSubmitted && f.staff_id.errors }"
          >Staff</label
        >
        <select
          id="inputState4"
          class="form-control"
          formControlName="staff_id"
          [ngClass]="{ 'invalid-field': isSubmitted && f.staff_id.errors }"
        >
          <option value="" disabled selected>--Select Staff--</option>
          <option [ngValue]="staff.id" *ngFor="let staff of staffList">
            {{ staff?.firstName }} {{ staff?.surName }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label for="exampleFormControlTextarea1" class="font-weight-bold">Description</label>
      <textarea
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
        formControlName="description"
        placeholder="Type here"
        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
      ></textarea>
    </div>

    <div class="clearfix"></div>
    <div class="border-dashed border-secondary image-upload-wrap">
      <form class="text-center p-4">
        <div
          class="drop-container"
          ngFileDrop
          [options]="options"
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
          [ngClass]="{ 'is-drop-over': dragOver }"
        >
          <input
            class="file-upload-input"
            type="file"
            ngFileSelect
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            hidden
            #uploader1
            multiple
          />
          <div class="drag-text text-center color-dark-blue">
            <h3 class="fs18 mb-0">
              <span class="op-25">Drag and Drop your Files here<span class="drag-or">Or</span> </span
              ><button class="btn btn-secondary btn-create border-0 fs14 my-2" (click)="uploader1.click()">
                Upload your Files
              </button>
            </h3>
          </div>
        </div>
        <!-- Start File list -->
        <ul class="list-group mt-4">
          <li class="list-group-item my-2 py-1 px-2" *ngFor="let fileInfo of uploadfileList; let i = index">
            <div class="media">
              <img
                src="{{ fileUrl }}{{ fileInfo?.file_path }}"
                alt="Profile"
                class="align-self-center rounded-circle mr-3"
                width="64"
                height="64"
                *ngIf="fileInfo?.file_path && fileInfo.type === 'image'"
              />
              <img
                [src]="fileInfo.imageSrc"
                alt="Profile"
                class="align-self-center rounded-circle mr-3"
                width="64"
                height="64"
                *ngIf="!fileInfo?.file_path && fileInfo.type === 'image'"
              />

              <img
                src="./assets/images/file-icons/audio.png"
                alt="Profile"
                class="align-self-center rounded-circle mr-3"
                width="64"
                height="64"
                *ngIf="fileInfo.type === 'audio'"
              />
              <img
                src="./assets/images/file-icons/video.png"
                alt="Profile"
                class="align-self-center rounded-circle mr-3"
                width="64"
                height="64"
                *ngIf="fileInfo.type === 'video'"
              />

              <ng-container *ngIf="fileInfo.type === 'document'">
                <img
                  src="./assets/images/file-icons/pdf.png"
                  alt="Profile"
                  class="align-self-center rounded-circle mr-3"
                  width="64"
                  height="64"
                  *ngIf="fileInfo.format === 'pdf'"
                />
                <img
                  src="./assets/images/file-icons/excel.png"
                  alt="Profile"
                  class="align-self-center rounded-circle mr-3"
                  width="64"
                  height="64"
                  *ngIf="fileInfo.format === 'xlsx' || fileInfo.format === 'xls' || fileInfo.format === 'csv'"
                />
                <img
                  src="./assets/images/file-icons/word.png"
                  alt="Profile"
                  class="align-self-center rounded-circle mr-3"
                  width="64"
                  height="64"
                  *ngIf="fileInfo.format === 'doc' || fileInfo.format === 'docx'"
                />
                <img
                  src="./assets/images/file-icons/ppt.png"
                  alt="Profile"
                  class="align-self-center rounded-circle mr-3"
                  width="64"
                  height="64"
                  *ngIf="fileInfo.format === 'ppt' || fileInfo.format === 'pptx'"
                />

                <img
                  src="./assets/images/file-icons/document.png"
                  alt="Profile"
                  class="align-self-center rounded-circle mr-3"
                  width="64"
                  height="64"
                  *ngIf="
                    fileInfo.format != 'pdf' &&
                    fileInfo.format != 'xlsx' &&
                    fileInfo.format != 'xls' &&
                    fileInfo.format != 'csv' &&
                    fileInfo.format != 'doc' &&
                    fileInfo.format != 'docx' &&
                    fileInfo.format != 'ppt' &&
                    fileInfo.format != 'pptx'
                  "
                />
              </ng-container>

              <img
                src="./assets/images/file-icons/document.png"
                alt="Profile"
                class="align-self-center rounded-circle mr-3"
                width="64"
                height="64"
                *ngIf="
                  fileInfo.type !== 'video' &&
                  fileInfo.type !== 'image' &&
                  fileInfo.type !== 'document' &&
                  fileInfo.type !== 'audio'
                "
              />

              <div class="media-body align-self-center">
                <h1 class="fs18 color-light-black mt-0">{{ fileInfo?.name }}</h1>
                <progressbar [value]="progress[i]?.progress" [striped]="true" class="w-50"
                  >{{ progress[i]?.progress }}%</progressbar
                >
              </div>
              <div class="align-self-center">
                <em class="far fa-times-circle c-pointer fs20" (click)="removeUploadFile(fileInfo, i)"></em>
              </div>
            </div>
          </li>
        </ul>
        <!-- End File list -->
      </form>
      <div *ngIf="fileError">
        <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
        <p class="text-danger" *ngIf="fileFormatError">Valid extensions {{ supportFormat }}</p>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button
        type="button"
        class="btn btn-primary bg-secondary text-white mr-2"
        tooltip="Cancel"
        (click)="uploadCancel()"
      >
        Cancel
      </button>
      <button type="submit" [disabled]="loading" tooltip="Save" class="btn btn-primary">Save</button>
    </div>
  </form>
</div>
<!-- End New Shift -->
