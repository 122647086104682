<div class="card border-0 mb-3 layout-card-4">
  <div class="card-header bg-white">
    <div class="row">
      <div class="col-md-9 col-sm-6">
        <h6 class="text-dark font-weight-bold fs16 mb-0">REQUIREMENT</h6>
      </div>
      <div class="col-md-3 col-sm-6">
        <div class="row">
          <div class="col-md-12">
            <ul class="list-unstyled d-inline-flex float-right mt-3 mt-sm-0">
              <li class="mr-2 ml-sm-4">
                <button type="button" class="btn btn-primary btn-more" tooltip="Add" (click)="openModal(template)">
                  <em class="fas fa-plus mr-2"></em>Add requirement
                </button>
                <ng-template #template class="modal-content">
                  <div class="modal-header bg-primary">
                    <h4 class="modal-title text-center text-white">Add Requirement</h4>
                    <button type="button" class="close text-white" aria-label="Close" (click)="clearForm()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body w-100 modal-lg">
                    <form [formGroup]="locationForm" (ngSubmit)="createLocation()">
                      <div class="form-group">
                        <label for="exampleInputEmail1" class="font-weight-bold"
                          [ngClass]="{ 'text-danger': isSubmitted && f.name.errors }">Name</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" placeholder=""
                          formControlName="name" [ngClass]="{ 'invalid-field': isSubmitted && f.name.errors }"
                          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)" />
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-6">
                            <label for="exampleFormControlTextarea1" class="font-weight-bold">Address</label>
                          </div>
                          <div class="col-md-6 float-right">
                            <small id="emailHelp" class="form-text text-primary float-right text-right fs10">* The map
                              location will be updated on change. Please ensure it is correct.</small>
                          </div>
                        </div>
                        <input type="text" class="form-control" id="exampleInputPassword1" placeholder=""
                          formControlName="address" (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                          (input)="checkLatLong($event.target.value)" />
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-md-12">
                            <label for="exampleFormControlTextarea1" class="font-weight-bold">Exact Location</label>
                            <div class="float-right">
                              <small id="emailHelp" class="form-text text-primary float-right text-right fs10">* The map
                                location will be updated on change. Please ensure it is correct.</small>
                            </div>
                            <agm-map class="agm-map" [latitude]="latitude" [longitude]="longitude" [mapDraggable]="true"
                              [scrollwheel]="true" [zoom]="zoom" [mapTypeControl]="true" [zoomControl]="true"
                              [streetViewControl]="true" (mapClick)="addMarker($event.coords.lat, $event.coords.lng)">
                              <agm-marker [latitude]="marklatitude" [longitude]="marklongitude"></agm-marker>
                            </agm-map>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1" class="font-weight-bold">Default Brief</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                          formControlName="default_brief"
                          (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"></textarea>
                      </div>
                      <div class="float-left">
                        <p class="text-secondary">
                          The default brief gives you the option to provide a regular briefing for this requirement. It
                          will be shared with the staff using the mobile app as well as in notifications about new
                          shifts.
                        </p>
                      </div>
                      <div class="clearfix"></div>
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1"
                          formControlName="is_clockin" />
                        <label class="form-check-label font-weight-bold" for="exampleCheck1">Clock In</label>
                        If ticked, requires the staff member to clock in using the mobile app to start and end the
                        shift.
                      </div>
                      <div class="clearfix"></div>
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck2"
                          formControlName="is_clock_at_location" />
                        <label class="form-check-label font-weight-bold" for="exampleCheck2">Clock In</label>
                        Only At Location.
                      </div>
                      <div class="clearfix"></div>
                      <div>
                        <ul class="d-inline-flex list-unstyled">
                          <li class="font-weight-bold mr-4">Clock in Available From</li>
                          <li class="border border-secondary">
                            <select id="inputState" class="form-control text-dark font-weight-bold"
                              formControlName="clock_available">
                              <option value="" selected disabled>--Select Available Time--</option>
                              <option [ngValue]="time" *ngFor="let time of availableTime">{{ time }}</option>
                            </select>
                          </li>
                        </ul>
                        <div class="clearfix"></div>
                      </div>
                      <div class="form-group form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck3"
                          formControlName="is_exclude_time_limit" />
                        <label class="form-check-label" for="exampleCheck3"><span class="font-weight-bold mr-2"> Exclude
                            From Time Limits</span><span class="text-secondary">Time limits are legal or contractual
                            limitations of maximum working hours for staff. If you
                            tick this box, this requirement will not be considered for any time limitations. For example
                            can be applied for "Leave" or "Training".</span></label>
                      </div>
                      <div class="float-right">
                        <ul class="d-inline-flex list-unstyled">
                          <li class="mr-2">
                            <button type="button" class="btn btn-primary bg-secondary text-white" tooltip="Cancel"
                              (click)="clearForm()">
                              Cancel
                            </button>
                          </li>
                          <li>
                            <button type="submit" [disabled]="loading" tooltip="Submit" class="btn btn-primary">
                              Submit
                            </button>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </li>
              <li><em class="fas fa-compress circle-icon compress-icon"></em></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-header bg-light pb-0" *ngIf="locationList?.length > 0">
    <div class="row">
      <div class="col-md-6">
        <p class="float-left">Name</p>
      </div>
      <div class="col-md-6"></div>
    </div>
  </div>
  <div class="card-body text-dark" *ngIf="locationList?.length > 0">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-2 pr-0">
            <em class="fas fa-map-marker circle-icon map-icon"></em>
          </div>
          <div class="col-md-10 pl-0">
            <ul class="list-unstyled">
              <li class="tel">
                <a href="javascript:void(0)" class="text-dark text-decoration-none">{{ locationInfo?.name }}</a>
              </li>
              <li class="text-secondary fs10">{{ locationInfo?.address }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row float-right">
          <ul class="d-inline-flex list-unstyled"></ul>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body text-dark" *ngFor="let location of locationList">
    <div class="row c-pointer" (click)="viewLocation(location.id)">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-2 pr-0">
            <em class="fas fa-map-marker circle-icon map-icon"></em>
          </div>
          <div class="col-md-10 pl-0">
            <ul class="list-unstyled">
              <li class="tel">
                <a href="javascript:void(0)" class="text-dark text-decoration-none">{{ location?.name }}</a>
              </li>
              <li class="text-secondary fs10">{{ location?.address }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row float-right">
          <ul class="d-inline-flex list-unstyled">
            <li class="mr-2" tooltip="View"><em class="fas fa-eye eye-icon"></em></li>
            <li class="text-success fs12 c-pointer">VIEW MORE</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="locationList?.length === 0">
    <p>No requirement added so far.</p>
  </div>
</div>
