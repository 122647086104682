import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services';
import { passwordRegEx } from '../constants/defaultValues';
var ChangeLoginInfoComponent = /** @class */ (function () {
    function ChangeLoginInfoComponent(apiService, toastr, formBuilder) {
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.showStatus = false;
        this.isSubmitted = false;
        this.isLoading = false;
        this.isPass = false;
        this.groupInfo = ['Staff', 'Supervisor', 'Advanced', 'Full access'];
        this.passwordRegEx = passwordRegEx;
    }
    ChangeLoginInfoComponent.prototype.ngOnInit = function () {
        this.createForm();
        if (this.editClientInfo && this.editClientInfo['id']) {
            this.setClientInfo(this.editClientInfo);
        }
        if (this.editStaffInfo && this.editStaffInfo['id']) {
            this.setStaffInfo(this.editStaffInfo);
        }
    };
    ChangeLoginInfoComponent.prototype.setClientInfo = function (data) {
        this.changeLoginForm.patchValue({
            is_active: data['is_active'] === '1' || data['is_active'] === 1 ? '1' : '2',
            password: '',
            confirm_password: '',
        });
        if (data['is_active'] === '1' || data['is_active'] === 1) {
            this.showStatus = true;
        }
        else {
            this.showStatus = false;
        }
        //this.isLoading = (data['is_active'] === '0' || data['is_active'] === 0) ? true : false;
    };
    ChangeLoginInfoComponent.prototype.setStaffInfo = function (data) {
        this.changeLoginForm.patchValue({
            is_active: data['is_active'] === '1' || data['is_active'] === 1 ? '1' : '2',
            group: data['group'],
            password: '',
            confirm_password: '',
        });
        if (data['is_active'] === '1' || data['is_active'] === 1) {
            this.showStatus = true;
        }
        else {
            this.showStatus = false;
        }
        //this.isLoading = (data['is_active'] === '0' || data['is_active'] === 0) ? true : false;
    };
    ChangeLoginInfoComponent.prototype.checkPasswords = function (group) {
        var pass = group.get('password').value;
        var confirmPass = group.get('confirm_password').value;
        return pass === confirmPass ? null : { notSame: true };
    };
    ChangeLoginInfoComponent.prototype.createForm = function () {
        this.changeLoginForm = this.formBuilder.group({
            is_active: ['2', Validators.required],
            group: ['Staff'],
            password: [''],
            confirm_password: [''],
        }, { validator: this.checkPasswords });
    };
    Object.defineProperty(ChangeLoginInfoComponent.prototype, "f", {
        get: function () {
            return this.changeLoginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ChangeLoginInfoComponent.prototype.valuechange = function (value) {
        if (value.length > 0) {
            this.isPass = false;
        }
        else {
            this.isPass = true;
        }
    };
    ChangeLoginInfoComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    ChangeLoginInfoComponent.prototype.updateLogin = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.changeLoginForm.invalid) {
            return;
        }
        var dataValue = this.changeLoginForm.value;
        if (dataValue['is_active'] === '1' || dataValue['is_active'] === 1) {
            if (dataValue['password'] === '') {
                this.isPass = true;
            }
            else {
                this.isPass = false;
            }
            if (this.isPass) {
                return;
            }
        }
        this.isLoading = true;
        dataValue = this.changeLoginForm.value;
        dataValue['is_active'] = parseInt(dataValue['is_active']);
        if (this.editClientInfo && this.editClientInfo['id']) {
            delete dataValue['group'];
            this.apiService.put('client/access-change/' + this.editClientInfo['id'], dataValue).subscribe(function (client) {
                if (client.status === 200) {
                    _this.closeModel();
                    _this.toastr.success(client.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
        if (this.editStaffInfo && this.editStaffInfo['id']) {
            if (dataValue['is_active'] === '2' || dataValue['is_active'] === 2) {
                delete dataValue['group'];
            }
            this.apiService.put('staff/access-change/' + this.editStaffInfo['id'], dataValue).subscribe(function (staff) {
                if (staff.status === 200) {
                    _this.closeModel();
                    _this.toastr.success(staff.message);
                }
                else {
                    _this.toastr.error(staff.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
    };
    ChangeLoginInfoComponent.prototype.errorMessage = function (err) {
        this.closeModel();
        if (err.error.message) {
            this.toastr.error(err.error.message);
        }
    };
    ChangeLoginInfoComponent.prototype.closeModel = function () {
        this.isPass = false;
        this.isLoading = false;
        this.isSubmitted = false;
        this.showStatus = false;
        if (this.editClientInfo && this.editClientInfo['id']) {
            this.apiService.closeClientModel.emit(7);
        }
        if (this.editStaffInfo && this.editStaffInfo['id']) {
            this.apiService.closeClientModel.emit(8);
        }
    };
    ChangeLoginInfoComponent.prototype.handleChange = function () {
        var isActive = this.changeLoginForm.value['is_active'];
        if (isActive === '1' || isActive === 1) {
            this.showStatus = true;
        }
        else {
            this.showStatus = false;
        }
        if (isActive === '0' || isActive === 0) {
            this.isLoading = true;
        }
        else {
            this.isLoading = false;
        }
    };
    return ChangeLoginInfoComponent;
}());
export { ChangeLoginInfoComponent };
