import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, S3UploadService } from '../../../services';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sublocation-detail',
  templateUrl: './sublocation-detail.component.html',
})
export class SublocationDetailComponent implements OnInit {
  clientId;
  locationId;
  locationInfo: any = {};
  clientInfo: any = {};
  incidentsList: any = [];
  fileList: any = [];
  certificateList: any = [];
  blackList: any = [];
  locationIncidentsList: any = [];
  locationFileList: any = [];
  locationCertificateList: any = [];
  locationBlackList: any = [];
  parentLocationId;
  public loading = false;

  constructor(
    private apiService: ApiService,
    private S3UploadService: S3UploadService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.locationId = this.route.snapshot.params.id;
    this.clientId = this.route.snapshot.queryParamMap.get('clientId');
    this.getLocationInfo();
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 15) {
        this.getLocationInfo();
      }
    });
  }

  cancelLocationDetails(type) {
    if (type === 1) {
      this.locationIncidentsList = [];
      this.locationFileList = [];
      this.locationCertificateList = [];
      this.locationBlackList = [];
      this.incidentsList = [];
      this.fileList = [];
      this.certificateList = [];
      this.blackList = [];
    }
    if (type === 2) {
      this.incidentsList = [];
      this.fileList = [];
      this.certificateList = [];
      this.blackList = [];
    }
  }

  getLocationInfo() {
    this.loading = true;
    this.apiService.get('client/' + this.clientId + '/location/' + this.locationId).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.locationInfo = data['location'];
          this.clientInfo = data['location']['client'];
          if (this.locationInfo && this.locationInfo['id']) {
            this.parentLocationId = this.locationInfo['parent_id'];
            this.locationFileList = this.locationInfo['files'];
            this.locationCertificateList = this.locationInfo['certificates'];
            this.locationBlackList = this.locationInfo['blacklists'];
            this.incidentsList = this.locationInfo['incidents'];
          } else {
            this.locationInfo = {};
            this.cancelLocationDetails(1);
          }

          if (this.clientInfo && this.clientInfo['id']) {
            this.fileList = this.clientInfo['files'];
            this.certificateList = this.clientInfo['certificates'];
            this.blackList = this.clientInfo['blacklists'];
          } else {
            this.clientInfo = {};
            this.cancelLocationDetails(2);
          }
        } else {
          this.clientInfo = {};
          this.locationInfo = {};
          this.cancelLocationDetails(1);
        }
        this.loading = false;
        this.apiService.closeClientModel.emit(10);
      },
      (err) => {
        this.loading = false;
        this.cancelLocationDetails(1);
      },
    );
  }

  backUrl() {
    this.router.navigate(['/client/location-details/' + this.parentLocationId], {
      queryParams: { clientId: this.clientId },
    });
  }
}
