<div class="card mb-3 border-0">
  <div class="card-header bg-white pl-4 pr-2">
    <div class="d-flex bd-highlight">
      <div class="flex-grow-1 bd-highlight">
        <h6 class="text-dark font-weight-bold fs16 mb-0">CERTIFICATES</h6>
      </div>
      <div class="bd-highlight mr-3">
        <button type="button" class="btn btn-primary btn-more" tooltip="Add" (click)="openModal(template1)">
          <em class="fas fa-plus mr-2"></em>Add
        </button>
        <ng-template #template1 class="modal-content template-7">
          <div class="modal-header bg-primary">
            <h4 class="modal-title w-100 text-center fs18 font-weight-normal text-white">Add Required Certification</h4>
            <button
              aria-label="Close"
              class="close text-white font-weight-normal op1"
              type="button"
              aria-label="Close"
              (click)="clearForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="font-weight-bold">Client Certification</p>
            <p class="text-secondary">
              Please select the certification which is required to schedule an employee in any new shifts.
            </p>
            <form>
              <p class="font-weight-bold">Certification</p>
              <div class="form-group">
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="certificateInfo"
                  name="certificateInfo"
                  (change)="changeAction()"
                  [ngClass]="{ 'invalid-field': showError && isSubmit }"
                >
                  <option value="" selected disabled>--Select Certificate--</option>
                  <option [ngValue]="certificate.id" *ngFor="let certificate of certificateData">
                    {{ certificate?.name }}
                  </option>
                </select>
              </div>
              <div class="form-row">
                <div class="col-md-12 mb-4 mt-3 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary bg-secondary text-white"
                    tooltip="Cancel"
                    (click)="clearForm()"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="isSubmit"
                    tooltip="Add"
                    (click)="addCerticate()"
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ng-template>
      </div>
      <div class="bd-highlight ml-1"><em class="fas fa-compress circle-icon compress-icon"></em></div>
    </div>
  </div>
  <div class="card-body text-dark">
    <ul class="list-unstyled">
      <li class="mb-5">
        <div class="row d-flex align-items-center mb-4" *ngFor="let certificates of certificateList">
          <div class="col-md-10 col-10 d-flex align-items-center">
            <div class="bg-medal">
              <h2 class="text-dark fs14 mt-2">{{ certificates?.certificate?.name }}</h2>
            </div>
          </div>
          <div class="col-md-2 col-1"></div>
        </div>
        <div class="row d-flex align-items-center mb-4" *ngFor="let certificates of locationCertificateList">
          <div class="col-md-10 col-10 d-flex align-items-center">
            <div class="bg-medal">
              <h2 class="text-dark fs14 mt-2">{{ certificates?.certificate?.name }}</h2>
            </div>
          </div>
          <div class="col-md-2 col-1">
            <em class="fas fa-trash delete-icon" (click)="deletePopup(delete, certificates)"></em>
          </div>
        </div>
      </li>
    </ul>
    <div *ngIf="certificateList?.length === 0 && locationCertificateList?.length === 0">
      <p>Currently no certifications are required to roster staff for this client.</p>
    </div>
  </div>
</div>

<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title">Delete Certificate</h4>
    <button type="button" class="close" aria-label="Close" (click)="clearForm()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete the certificate?</p>
    <button type="button" class="button-value mx-2" (click)="clearForm()">No</button>
    <button type="button" class="button-danger mx-2" (click)="deleteCertificate()">Yes</button>
  </div>
</ng-template>
