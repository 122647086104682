import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
  applicationForm: FormGroup;
  submitted = false;
  name: string;
  applicationTitle;
  applicationData = {};
  isEdit = false;
  public loading = false;

  constructor(
    private apiService: ApiService,
    private title: Title,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  get f() {
    return this.applicationForm.controls;
  }

  CreateApplication() {
    this.submitted = true;
    if (this.applicationForm.invalid) {
      return;
    }
    const applicationName = this.applicationForm.value;
    if (this.applicationData && this.applicationData['id']) {
      this.apiService.put('application/update/' + this.applicationData['id'], applicationName).subscribe(
        (data: any) => {
          this.sucessMessage(data, applicationName);
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    } else {
      this.apiService.post('application', applicationName).subscribe(
        (data: any) => {
          this.sucessMessage(data, applicationName);
        },
        (err) => {
          this.errorMessage(err);
        },
      );
    }
  }

  sucessMessage(data, applicationName) {
    this.toastr.clear();
    if (data.status === 200) {
      this.closeForm(data, applicationName);
    }
  }

  errorMessage(err) {
    this.toastr.clear();
    if (err.error.message) {
      this.toastr.error(err.error.message);
    }
  }

  closeForm(data, applicationName) {
    this.toastr.success(data.message);
    this.applicationForm.reset();
    this.submitted = false;
    this.title.setTitle(applicationName.name);
    localStorage.setItem('applicationName', data['application']['name']);
    this.getApplicationName();
  }

  onReset() {
    this.submitted = false;
    this.getApplicationName();
  }

  getApplicationName() {
    this.loading = true;
    this.apiService.get('application').subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.applicationData = data['application'];
          this.applicationForm.patchValue({ name: this.applicationData['name'] });
          this.isEdit = true;
        } else {
          this.isEdit = false;
          this.applicationForm.patchValue({ name: '' });
          this.applicationData = {};
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.isEdit = false;
        this.applicationForm.patchValue({ name: '' });
        this.applicationData = {};
      },
    );
  }

  ngOnInit() {
    this.getApplicationName();
    this.applicationForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
}
