<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" routerLink="/settings"><a>Settings</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-laptop mr-1"></em>Equipments</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Equipments</h1>
    <p class="color-grey-text">
      Equipment are any items which are handed to employees and usually returned at the end of the employment.
    </p>
    <p class="color-grey-text">Examples could be a work uniform, a laptop or a car.</p>
    <div class="clearfix"></div>
    <div class="row mt-30">
      <div class="col-md-12 col-xl-10 col-12">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
          <div class="search-box">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text pr-0" id="basic-addon1">
                  <em class="fas fa-search color-light-grey fs12"></em>
                </span>
              </div>
              <input
                type="text"
                class="form-control fs14"
                placeholder="search"
                name="search"
                [(ngModel)]="search"
                (ngModelChange)="pageChange(1)"
                autocomplete="off"
              />
            </div>
          </div>
          <button
            type="button"
            class="btn btn-secondary btn-create border-0 fs12 mt-1"
            tooltip="Add"
            (click)="addEquipmentModal(addEquipment, 'A')"
          >
            + ADD EQUIPMENT
          </button>
        </div>
        <div class="table-responsive list-table">
          <table class="table bg-white shadow-none mb-0">
            <caption class="d-none">
              Equipments Data
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  S.No
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'id' }"
                      (click)="upside('asc', 'id')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'id' }"
                      (click)="downSide('desc', 'id')"
                    ></em>
                  </span>
                </th>

                <th scope="col">
                  Name
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'name' }"
                      (click)="upside('asc', 'name')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'name' }"
                      (click)="downSide('desc', 'name')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Size
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'size' }"
                      (click)="upside('asc', 'size')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'size' }"
                      (click)="downSide('desc', 'size')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Inventory
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'inventory' }"
                      (click)="upside('asc', 'inventory')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'inventory' }"
                      (click)="downSide('desc', 'inventory')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Available
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'available' }"
                      (click)="upside('asc', 'available')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'available' }"
                      (click)="downSide('desc', 'available')"
                    ></em>
                  </span>
                </th>
                <th scope="col">
                  Last update
                  <span class="font-icon ml-2">
                    <em
                      class="fas fa-sort-up up-arrow test"
                      [ngClass]="{ 'text-dark': sortType === 'asc' && sortFiled === 'updated_at' }"
                      (click)="upside('asc', 'updated_at')"
                    ></em>
                    <em
                      class="fas fa-sort-up down-arrow"
                      [ngClass]="{ 'text-dark': sortType === 'desc' && sortFiled === 'updated_at' }"
                      (click)="downSide('desc', 'updated_at')"
                    ></em>
                  </span>
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let equipment of equipmentList
                    | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount };
                  let i = index
                "
              >
                <td>{{ page !== 1 ? i + 1 + pagesize * (page - 1) : i + 1 }}</td>
                <td class="equipment-text-overflow">{{ equipment.name }}</td>
                <td>{{ equipment.size }}</td>
                <td>{{ equipment.inventory }}</td>
                <td>{{ equipment.available }}</td>
                <td>{{ equipment.updated_at | date: dateFormat }}</td>
                <td>
                  <span
                    class="mr-3 color-dark-blue c-pointer"
                    tooltip="Edit"
                    (click)="editEquipmentModal(addEquipment, equipment)"
                    ><em class="fas fa-pen pen-icon"></em
                  ></span>
                  <span
                    class="color-dark-cred c-pointer"
                    tooltip="Delete"
                    (click)="openDeleteModal(deleteEquipment, equipment)"
                    ><em class="fas fa-trash delete-icon"></em
                  ></span>
                </td>
              </tr>
              <tr *ngIf="equipmentList.length === 0">
                <td colspan="6">No equipments found</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bg-white text-center py-4 roaster-pagination" *ngIf="equipmentList.length > 0">
          <pagination-controls
            (pageChange)="pageChange($event)"
            maxSize="7"
            previousLabel=""
            nextLabel=""
            autoHide="true"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Start Add/Edit Equipment -->
<ng-template #addEquipment>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">{{ title }} Equipment</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="clearForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="px-3">
      <h1 class="color-black op-09 fs16">New Equipment</h1>
      <p class="color-black op-05 fs14 mb-0">
        Please fill in the form to add a new type of equipment. If you are adding an existing type of equipment the
        inventory gets added up.
      </p>
      <form class="modal-form mt-3" [formGroup]="equipmentForm" (ngSubmit)="addEditEquipment()">
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            formControlName="name"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
            (keypress)="omit_special_char($event)"
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Size</label>
          <input
            type="text"
            class="form-control"
            placeholder="Size"
            formControlName="equipment_size"
            [ngClass]="{ 'is-invalid': submitted && f.equipment_size.errors }"
          />
          <div *ngIf="submitted && f.equipment_size.errors" class="invalid-feedback">
            <div *ngIf="f.equipment_size.errors.required">Size is required</div>
          </div>
        </div>
        <div class="form-group">
          <label>Inventory</label>
          <input
            type="text"
            class="form-control"
            placeholder="Inventory"
            OnlyNumber="true"
            formControlName="inventory"
            [ngClass]="{ 'is-invalid': submitted && f.inventory.errors }"
          />
          <div *ngIf="submitted && f.inventory.errors" class="invalid-feedback">
            <div *ngIf="f.inventory.errors.required">Inventory is required</div>
          </div>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
            tooltip="Cancel"
            (click)="clearForm()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Save"
            [disabled]="isLoading"
            *ngIf="title === 'Add'"
          >
            Save
          </button>
          <button
            type="submit"
            class="btn btn-secondary btn-create border-0 w-100px fs14"
            tooltip="Update"
            [disabled]="!isSubmitEnable"
            *ngIf="title != 'Add'"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- End Add/Edit Equipment -->

<!-- Start Delete Equipment -->
<ng-template #deleteEquipment>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Equipment</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete Equipment?</p>
    <button class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3" tooltip="No" (click)="clearForm()">No</button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteEquipmentData()"
      [disabled]="isLoading"
    >
      Yes
    </button>
  </div>
</ng-template>
<!-- End Delete Equipment -->
