<ng-container *ngTemplateOutlet="certificationForStaff"></ng-container>

<ng-template #certificationForStaff>
  <div class="card mb-3 border-0">
    <div class="card-header bg-white px-md-4 px-1">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h1 class="text-dark font-weight-bold fs16 mb-0">CERTIFICATES</h1>
        <ul class="list-unstyled d-inline-flex float-right mb-0">
          <li class="mr-2">
            <button
              type="button"
              class="btn btn-primary btn-more"
              tooltip="Add"
              (click)="openModal(template1, 'Add New')"
              *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'"
            >
              <em class="fas fa-plus mr-2" tooltip="Add"></em>Add
            </button>
            <ng-template #template1 class="modal-content template-9">
              <div class="modal-header bg-primary">
                <h4 class="modal-title fs16 text-center text-white">{{ type }} Certification to Staff Member</h4>
                <button type="button" class="close pull-right text-white" aria-label="Close" (click)="clearForm()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-secondary">
                  Please fill in the form below to add a new or renewed certification of an employee. The certification
                  number is optional.
                </p>
                <form [formGroup]="certificateForm" (ngSubmit)="createStaffCertificate()">
                  <div class="form-row">
                    <div class="form-group">
                      <h2
                        class="font-weight-bold fs14"
                        [ngClass]="{ 'text-danger': isSubmitted && f.certificate_id.errors }"
                      >
                        Certification
                      </h2>
                      <select
                        id="inputState"
                        class="form-control"
                        formControlName="certificate_id"
                        [ngClass]="{ 'invalid-field': isSubmitted && f.certificate_id.errors }"
                      >
                        <option value="" disabled selected>-- Select Certificate--</option>
                        <option [ngValue]="certificate.id" *ngFor="let certificate of certificateData">
                          {{ certificate.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <h2 class="font-weight-bold fs14">Number</h2>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail4"
                        placeholder=""
                        formControlName="certification_number"
                        (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <h2
                        class="font-weight-bold fs14"
                        [ngClass]="{ 'text-danger': isSubmitted && f.expire_date.errors }"
                      >
                        Expiry Date
                      </h2>
                      <input
                        type="text"
                        placeholder="Datepicker"
                        class="form-control"
                        bsDatepicker
                        formControlName="expire_date"
                        [minDate]="today"
                        [bsConfig]="{
                          dateInputFormat: 'DD/MM/YYYY',
                          showWeekNumbers: false,
                          adaptivePosition: true
                        }"
                        [ngClass]="{ 'invalid-field': isSubmitted && f.expire_date.errors }"
                      />
                    </div>
                  </div>
                  <div class="float-right">
                    <ul class="d-inline-flex list-unstyled">
                      <li class="mr-2">
                        <button
                          type="button"
                          class="btn btn-primary bg-secondary text-white"
                          tooltip="Cancel"
                          (click)="clearForm()"
                        >
                          Cancel
                        </button>
                      </li>
                      <li>
                        <button type="submit" class="btn btn-primary" tooltip="Add" [disabled]="loading">
                          {{ type === 'Add New' ? 'Add' : 'Update' }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </ng-template>
          </li>
          <li><em class="fas fa-compress circle-icon compress-icon"></em></li>
        </ul>
      </div>
    </div>
    <div class="card-body table-responsive text-dark">
      <table class="table border-0" aria-describedby="tab">
        <tbody>
          <tr class="border-0" *ngFor="let certificates of certificateList">
            <th scope="row" class="border-0"><em class="fas fa-medal circle-icon medal-icon"></em></th>
            <td class="border-0 fs12 pl-0">{{ certificates?.certificate?.name }}</td>
            <td class="border-0 fs12 pl-0" *ngIf="certificates.expire_date">
              {{ certificates?.expire_date | date: dateFormat }}
            </td>
            <td class="border-0 fs12 pl-0" *ngIf="!certificates.expire_date"></td>
            <td class="border-0" *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'">
              <em
                class="fas fa-pen pen-icon c-pointer"
                tooltip="Edit"
                (click)="openUpdateModal(template1, certificates, 'Update')"
              ></em>
            </td>
            <td class="border-0" *ngIf="userGroup === 'Full access' || userGroup === 'Advanced'">
              <em
                class="fas fa-trash delete-icon c-pointer"
                tooltip="Delete"
                (click)="deletePopup(delete, certificates)"
              ></em>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="certificateList.length === 0">
        <p>No certifications recorded.</p>
      </div>
    </div>
  </div>
</ng-template>

<div class="sidebar-overlay" *ngIf="showCertificate">
  <div class="sidebar-container" (click)="hideCertificate()">
    X
    <ng-container *ngTemplateOutlet="certificationForStaff"></ng-container>
  </div>
</div>

<ng-template #delete>
  <div class="modal-header">
    <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Delete Certificate</h4>
    <button
      type="button"
      class="close pull-right text-white font-weight-normal op1"
      aria-label="Close"
      (click)="clearForm()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete certificate?</p>
    <button
      type="button"
      class="btn btn-primary btn-cancel border-0 fs14 w-100px mr-3"
      tooltip="No"
      (click)="clearForm()"
    >
      No
    </button>
    <button
      type="submit"
      class="btn btn-secondary btn-create border-0 w-100px fs14"
      tooltip="Yes"
      (click)="deleteCertificate()"
    >
      Yes
    </button>
  </div>
</ng-template>
