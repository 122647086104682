import { OnInit, TemplateRef, EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, S3UploadService } from '../../services';
import { environment } from '../../../environments/environment';
import { humanizeBytes } from 'ngx-uploader';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { validFormat } from '../../constants/defaultValues';
var IncidentsStaffComponent = /** @class */ (function () {
    function IncidentsStaffComponent(modalService, router, s3upload, toastr, formBuilder, apiService) {
        this.modalService = modalService;
        this.router = router;
        this.s3upload = s3upload;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.apiService = apiService;
        this.isSubmitted = false;
        this.incidentTypeList = [];
        this.clientList = [];
        this.locationList = [];
        this.staffList = [];
        this.fileIds = [];
        this.fileError = false;
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.supportFormat = '';
        this.loading = false;
        this.fileUrl = environment.fileUrl;
        this.uploadfileList = [];
        this.imageObject = {};
        this.progress = [{ progress: 0 }];
        this.multiFileIds = [];
        this.minDate = new Date();
        this.userGroup = localStorage.getItem('userGroup');
        this.userLogId = localStorage.getItem('userLogId');
        this.domainInfo = {};
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.files = [];
        this.uploadInput = new EventEmitter();
        this.humanizeBytes = humanizeBytes;
    }
    IncidentsStaffComponent.prototype.ngOnInit = function () {
        var _this = this;
        var format = validFormat.toString();
        this.supportFormat = format.replace(/,/g, ', ');
        this.createForm();
        this.getIncidentType();
        this.getClientList();
        this.getStaffList();
        this.getLocationList(this.clientId);
        this.apiService.closeClientModel.subscribe(function (data) {
            if (data === 16) {
                _this.getIncidentType();
                _this.getClientList();
                _this.getStaffList();
                _this.getLocationList(_this.clientId);
            }
        });
        this.s3upload.progress.subscribe(function (progress) {
            if (progress['type'] === 6) {
                _this.progress[progress['index']] = progress;
                if (_this.progress && _this.progress.length > 0) {
                    var completeUpload_1 = [];
                    _this.progress.forEach(function (key) {
                        if (key && key['progress']) {
                            if (key['progress'] === 100) {
                                completeUpload_1.push(key);
                                if (_this.progress && _this.progress.length === completeUpload_1.length) {
                                    _this.loading = false;
                                }
                            }
                        }
                    });
                }
            }
        });
        this.apiService.getProfileChanges('profile:').subscribe(function (data) {
            if (data && data['staff_id'] && data['group']) {
                _this.userGroup = data['group'];
                localStorage.setItem('userGroup', data['group']);
            }
        });
        this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
        this.dateFormat =
            this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
    };
    IncidentsStaffComponent.prototype.getIncidentType = function () {
        var _this = this;
        this.apiService.get('incident').subscribe(function (staffincident) {
            if (staffincident['status'] === 200) {
                _this.incidentTypeList = staffincident['incidents'];
            }
        });
    };
    IncidentsStaffComponent.prototype.getClientList = function () {
        var _this = this;
        this.apiService.get('client/list').subscribe(function (client) {
            if (client['status'] === 200) {
                _this.clientList = client['clients'];
            }
        });
    };
    IncidentsStaffComponent.prototype.getLocationList = function (clientId) {
        var _this = this;
        if (clientId) {
            this.apiService.get('/client/' + clientId + '/location', { isParent: true }).subscribe(function (location) {
                if (location['status'] === 200) {
                    _this.locationList = location['location'];
                    if (_this.locationList && _this.locationList.length > 0) {
                        _this.incidentsStaffForm.controls['location_id'].setValue(_this.locationList[0] && _this.locationList[0]['id']);
                    }
                    else {
                        _this.locationList = [];
                        _this.incidentsStaffForm.controls['location_id'].setValue('');
                    }
                }
                else {
                    _this.locationList = [];
                    _this.incidentsStaffForm.controls['location_id'].setValue('');
                }
            });
        }
    };
    IncidentsStaffComponent.prototype.getStaffList = function () {
        var _this = this;
        this.apiService.get('staff/list').subscribe(function (staff) {
            if (staff['status'] === 200) {
                _this.staffList = staff['staffs'];
            }
            if (_this.staffInfo && _this.staffInfo['is_deleted'] === 0) {
                _this.incidentsStaffForm.controls['staff_id'].setValue(parseInt(_this.staffId));
            }
            else {
                _this.incidentsStaffForm.controls['staff_id'].setValue('');
            }
        });
    };
    IncidentsStaffComponent.prototype.changeClient = function () {
        this.getLocationList(this.incidentsStaffForm.value['client_id']);
    };
    Object.defineProperty(IncidentsStaffComponent.prototype, "f", {
        get: function () {
            return this.incidentsStaffForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    IncidentsStaffComponent.prototype.openModal = function (template) {
        this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
    };
    IncidentsStaffComponent.prototype.createForm = function () {
        this.incidentsStaffForm = this.formBuilder.group({
            incident_date: ['', Validators.required],
            staff_id: ['', [Validators.required]],
            location_id: ['', [Validators.required]],
            client_id: ['', [Validators.required]],
            incident_type_id: ['', [Validators.required]],
            description: [''],
        });
    };
    IncidentsStaffComponent.prototype.clearForm = function () {
        this.modalRef.hide();
        this.isSubmitted = false;
        this.fileError = false;
        this.fileIds = [];
        this.incidentsStaffForm.reset({
            staff_id: parseInt(this.staffId),
            location_id: '',
            client_id: '',
            incident_type_id: '',
        });
        this.fileSizeError = false;
        this.fileFormatError = false;
        this.uploadfileList = [];
        this.imageObject = {};
        this.multiFileIds = [];
        this.progress = [];
        this.apiService.closeClientModel.emit(5);
    };
    IncidentsStaffComponent.prototype.createStaffIncidents = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.incidentsStaffForm.invalid) {
            return;
        }
        var datavalue = this.incidentsStaffForm.value;
        if (this.uploadfileList && this.uploadfileList.length > 0) {
            this.fileIds = [];
            this.uploadfileList.forEach(function (item) {
                var data = { file_path: item.file_path, name: item.name, type: item.type };
                _this.fileIds.push(data);
            });
            datavalue['file_ids'] = this.fileIds;
        }
        if (datavalue['incident_date']) {
            datavalue['incident_date'] = formatDate(datavalue['incident_date'], 'yyyy-MM-dd', 'en');
        }
        this.loading = true;
        this.apiService.post('incident/report', datavalue).subscribe(function (incident) {
            _this.loading = false;
            if (incident['status'] === 200) {
                _this.toastr.success(incident['message']);
                _this.clearForm();
                _this.viewStaffIncident(incident['incident']['id']);
            }
        });
    };
    IncidentsStaffComponent.prototype.uploadS3Bucket = function (file, index) {
        var _this = this;
        this.loading = true;
        var fileType = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (fileType === 'application')
            fileType = 'document';
        var type = 6;
        this.s3upload
            .uploadfile(file, index, type)
            .then(function (data) {
            var fileData = { file_path: data['Key'], name: file['name'], type: fileType, format: fileExtension };
            _this.uploadfileList[index] = fileData;
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    IncidentsStaffComponent.prototype.readURL = function (file, index) {
        var _this = this;
        var type = file.type.slice(0, file.type.indexOf('/'));
        var fileExtension = file.name.replace(/^.*\./, '');
        if (type === 'application')
            type = 'document';
        var reader = new FileReader();
        reader.onload = function (e) {
            _this.uploadfileList[index] = { imageSrc: reader.result, name: file['name'], type: type, format: fileExtension };
        };
        reader.readAsDataURL(file);
    };
    IncidentsStaffComponent.prototype.onUploadOutput = function (output) {
        var _this = this;
        if (output.type === 'allAddedToQueue') {
            if (this.files && this.files.length > 0) {
                var formatErrors_1 = [];
                var sizeErros_1 = [];
                this.files.forEach(function (key, index) {
                    var fileSize = key.size / 1024 / 1024;
                    var fileExtension = key.name.replace(/^.*\./, '');
                    if (!validFormat.includes(fileExtension)) {
                        _this.fileError = true;
                        _this.fileFormatError = true;
                        formatErrors_1.push(true);
                        _this.removeFile(key['id']);
                    }
                    else {
                        if (fileSize < 5) {
                            var fileIndex = _this.multiFileIds.findIndex(function (obj) { return obj === key['id']; });
                            if (sizeErros_1.length > 0 || formatErrors_1.length > 0) {
                                _this.fileError = true;
                            }
                            else {
                                _this.fileError = false;
                            }
                            if (sizeErros_1.length > 0) {
                                _this.fileSizeError = true;
                            }
                            else {
                                _this.fileSizeError = false;
                            }
                            if (formatErrors_1.length > 0) {
                                _this.fileFormatError = true;
                            }
                            else {
                                _this.fileFormatError = false;
                            }
                            if (fileIndex === -1) {
                                _this.multiFileIds.push(key['id']);
                                _this.readURL(key['nativeFile'], index);
                                _this.uploadS3Bucket(key['nativeFile'], index);
                            }
                        }
                        else {
                            _this.fileError = true;
                            _this.fileSizeError = true;
                            sizeErros_1.push(true);
                            _this.removeFile(key['id']);
                        }
                    }
                });
            }
        }
        else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            this.files.push(output.file);
        }
        else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            var index = this.files.findIndex(function (file) { return typeof output.file !== 'undefined' && file.id === output.file.id; });
            this.files[index] = output.file;
        }
        else if (output.type === 'removed') {
            this.files = this.files.filter(function (file) { return file !== output.file; });
        }
        else if (output.type === 'dragOver') {
            this.dragOver = true;
        }
        else if (output.type === 'dragOut') {
            this.dragOver = false;
        }
        else if (output.type === 'drop') {
            this.dragOver = false;
        }
    };
    IncidentsStaffComponent.prototype.cancelUpload = function (id) {
        this.uploadInput.emit({ type: 'cancel', id: id });
    };
    IncidentsStaffComponent.prototype.removeFile = function (id) {
        this.uploadInput.emit({ type: 'remove', id: id });
    };
    IncidentsStaffComponent.prototype.removeAllFiles = function () {
        this.uploadInput.emit({ type: 'removeAll' });
    };
    IncidentsStaffComponent.prototype.deleteUploadFile = function (fileIds, index, fileindex) {
        var _this = this;
        this.apiService.post('client/delete-files', { file_ref_id: fileIds }).subscribe(function (deleteFiles) {
            if (fileIds && fileIds.length === 1) {
                _this.uploadfileList.splice(index, 1);
                _this.fileIds.splice(index, 1);
            }
        });
    };
    IncidentsStaffComponent.prototype.uploadCancel = function () {
        this.clearForm();
    };
    IncidentsStaffComponent.prototype.removeUploadFile = function (file, index) {
        this.uploadfileList.splice(index, 1);
        this.removeFile(this.files[index]['id']);
    };
    IncidentsStaffComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    IncidentsStaffComponent.prototype.viewStaffIncident = function (incidentId) {
        if (this.userGroup != 'Staff' && this.userGroup != 'Supervisor') {
            this.router.navigate(['/incident-staff-detail/' + incidentId]);
        }
    };
    return IncidentsStaffComponent;
}());
export { IncidentsStaffComponent };
