import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, S3UploadService } from '../services';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
})
export class ClientDetailsComponent implements OnInit {
  clientId;
  clientInfo: any = {};
  incidentsList: any = [];
  fileList: any = [];
  certificateList: any = [];
  blackList: any = [];
  locationList: any = [];
  public loading = false;

  constructor(
    private apiService: ApiService,
    private S3UploadService: S3UploadService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.params.id;
    this.getClientInfo();
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 4) {
        this.getClientInfo();
      }
    });
  }

  getClientInfo() {
    this.loading = true;
    this.apiService.get('client/view/' + this.clientId).subscribe(
      (client) => {
        this.loading = false;
        if (client['status'] === 200) {
          this.clientInfo = client['client'];
          if (this.clientInfo && this.clientInfo['id']) {
            this.incidentsList = this.clientInfo['incidents'];
            this.fileList = this.clientInfo['files'];
            this.certificateList = this.clientInfo['certificates'];
            this.blackList = this.clientInfo['blacklists'];
            this.locationList = this.clientInfo['locations'];
          }
        } else {
          this.incidentsList = [];
          this.fileList = [];
          this.certificateList = [];
          this.blackList = [];
          this.locationList = [];
          this.clientInfo = {};
        }
        this.apiService.closeClientModel.emit(10);
      },
      (err) => {
        this.loading = false;
      },
    );
  }
}
