import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services';
import { Router, ActivatedRoute } from '@angular/router';
import { passwordRegEx } from '../constants/defaultValues';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  changeLoginForm: FormGroup;
  isSubmitted = false;
  loading = false;
  otp_code = '';
  passwordRegEx: string = passwordRegEx;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.otp_code = this.route.snapshot.queryParamMap.get('otp_code');
    this.createForm();
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('new_password').value;
    const confirmPass = group.get('confirm_password').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  createForm() {
    this.changeLoginForm = this.formBuilder.group(
      {
        otp_code: [this.otp_code, Validators.required],
        new_password: ['', Validators.required],
        confirm_password: ['', Validators.required],
      },
      { validator: this.checkPasswords },
    );
  }

  get f() {
    return this.changeLoginForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.changeLoginForm.valid) {
      return;
    }
    const queryValues = this.changeLoginForm.value;
    this.loading = true;
    this.apiService.post('auth/reset', queryValues).subscribe(
      (data) => {
        this.loading = false;
        this.isSubmitted = false;
        if (data['status'] === 200) {
          this.toastr.success(data['message']);
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(data['message']);
        }
      },
      (err) => {
        this.loading = false;
        this.isSubmitted = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }
}
