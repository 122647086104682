<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="redirectSchedule(true)"><a>Scheduler</a></li>
    <li *ngIf="eventName" class="breadcrumb-item fs14 fw500 c-pointer" (click)="redirectSchedule()">
      <a>{{ eventName }}</a>
    </li>
    <li class="breadcrumb-item active fs14" aria-current="page">
      <em class="fas fa-user-plus mr-1"></em>Roster Staff on Shift
    </li>
  </ol>
</nav>
<div class="roaster-body staff-layout">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Roster staff on shift</h1>
    <p class="color-grey-text">
      Any staff highlighted green are available and suitable to fill this shift. If an employee is unsuitable you will
      find more information to identify the issue under the employee name
    </p>
    <p class="color-grey-text mb-4" *ngIf="isAll != 'true'">
      Roster staff for the shift on {{ shiftInfo?.work_date | date: 'EEEE' }},
      {{ shiftInfo?.work_date | date: dateFormat }}
      {{ timeFormat === '12HoursFormat' ? tConvert(shiftInfo?.start_time) : shiftInfo?.start_time }} -
      {{ timeFormat === '12HoursFormat' ? tConvert(shiftInfo?.end_time) : shiftInfo?.end_time }} at
      {{ shiftInfo?.clients?.name }}
      ({{ shiftInfo.sublocation != null ? shiftInfo.sublocation_name : shiftInfo.location_name }}).To cancel the action
      click <a href="javascript:void(0)" (click)="redirectSchedule()">here.</a>
    </p>
    <p class="color-grey-text mb-4" *ngIf="isAll === 'true'">
      Roster staff for the shift on {{ shiftInfo?.work_date | date: 'EEEE' }},
      {{ shiftInfo?.work_date | date: dateFormat }}
      {{ timeFormat === '12HoursFormat' ? tConvert(shiftInfo?.start_time) : shiftInfo?.start_time }} -
      {{ timeFormat === '12HoursFormat' ? tConvert(shiftInfo?.end_time) : shiftInfo?.end_time }} at
      {{ shiftInfo?.clients?.name }}
      ({{ shiftInfo.sublocation != null ? shiftInfo.sublocation_name : shiftInfo.location_name }}) as well as all
      following weeks with the same shift.To cancel the action click
      <a href="javascript:void(0)" (click)="redirectSchedule()">here.</a>
    </p>
    <div class="clearfix"></div>
    <tabset type="pills" class="roaster-tab">
      <tab heading="Available">
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-3" *ngIf="isShow">
            <div class="card border-0 available-data">
              <div class="card-body">
                <div class="media">
                  <img src="assets/images/profile-big.png" class="align-self-center mr-3" alt="profile image" />
                  <div
                    class="media-body align-self-center"
                    [ngClass]="isLoding ? 'c-dispointer' : 'c-pointer'"
                    (click)="shiftUnschedule()"
                  >
                    <h1 class="fs18 color-light-black mt-3">Unscheduled</h1>
                    <p class="fs14 color-light-black op-32">Reset the shift to unscheduled</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-3" *ngFor="let staff of availableStaffList">
            <div class="card border-0 available-data">
              <div class="card-body">
                <div class="media">
                  <img
                    src="assets/images/profile-big.png"
                    class="align-self-center mr-3"
                    alt="profile image"
                    *ngIf="!staff.profileImage"
                  />
                  <img
                    src="{{ fileUrl }}{{ staff.profileImage }}"
                    class="align-self-center mr-3"
                    alt="profile image"
                    *ngIf="staff.profileImage"
                  />
                  <div
                    class="media-body align-self-center"
                    [ngClass]="isLoding ? 'c-dispointer' : 'c-pointer'"
                    (click)="shiftAllAction(staff.id)"
                  >
                    <h1 class="fs18 color-light-black mt-3">{{ staff?.staff_name }}</h1>
                    <p class="fs14 color-light-black op-32">
                      {{ staff.message === '' ? 'Available' : staff?.message }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Unavailable">
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-3" *ngFor="let staff of notAvailableStaffList">
            <div class="card border-0 not-available-data">
              <div class="card-body">
                <div class="media">
                  <img
                    src="assets/images/profile-big.png"
                    class="align-self-center mr-3"
                    alt="profile image"
                    *ngIf="!staff.profileImage"
                  />
                  <img
                    src="{{ fileUrl }}{{ staff.profileImage }}"
                    class="align-self-center mr-3"
                    alt="profile image"
                    *ngIf="staff.profileImage"
                  />
                  <div class="media-body align-self-center">
                    <h1 class="fs18 color-light-black mt-3">{{ staff?.staff_name }}</h1>
                    <p class="fs14 color-light-black op-32">{{ staff?.message }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-danger text-center" *ngIf="notAvailableStaffList.length === 0">No Record Found.</p>
        </div>
      </tab>
    </tabset>
  </div>
</div>
