<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="backSchedule()"><a>Scheduler</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page"><em class="fas fa-clock mr-1"></em>Time tracking</li>
  </ol>
</nav>
<div class="roaster-body">
  <div class="container-fluid px-0">
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">Time tracking</h1>
    <p class="color-grey-text">Here you can View the full log time tracking report of the user.</p>
    <div class="row mt-2">
      <div class="col-md-12 col-lg-5 col-12">
        <div class="table-responsive list-table">
          <table class="table bg-white shadow-none mb-0">
            <caption class="d-none">
              Time tracking Data
            </caption>
            <thead class="thead-light">
              <tr>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let time of clockArray">
                <td>{{ time?.webClockIn }}</td>
                <td>{{ time?.webClockOut }}</td>
              </tr>
            </tbody>
          </table>
          <p class="text-danger text-center" *ngIf="clockArray.length === 0">No Data Found</p>
        </div>
      </div>
    </div>
  </div>
</div>
