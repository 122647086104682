import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-superadminprofile',
  templateUrl: './superadminprofile.component.html',
})
export class SuperadminprofileComponent implements OnInit {
  modalRef: BsModalRef;
  userId: any = localStorage.getItem('userid');
  profileinformation: any;
  fileUrl: any = environment.fileUrl;
  profileImage = '';
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private apiService: ApiService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 15) {
        this.closePopup();
      }
    });
    this.profileinfo();
  }
  backiconclick() {
    this.router.navigate(['/client-information']);
  }
  profileinfo() {
    this.apiService.get('staff/view/' + this.userId).subscribe(
      (client: any) => {
        if (client.status === 200) {
          this.profileinformation = client['staff'];
          this.profileImage = this.profileinformation.profile.file_path;
          const s = JSON.parse(localStorage.getItem('user'));
          s.email = this.profileinformation['email'];
          s.profile_path = this.profileinformation['profile']['file_path'];
          s.firstName = this.profileinformation['staff_firstname'];
          s.lastName = this.profileinformation['staff_firstname'];
          localStorage.setItem('user', JSON.stringify(s));
          this.apiService.profilechangemodal.emit('profilechanged');
        }
      },
      (err) => {
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }
  viewClientModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }
  closePopup() {
    this.modalRef.hide();
    this.profileinfo();
  }
}
