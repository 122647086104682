/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-bootstrap/dropdown";
import * as i3 from "ngx-bootstrap/component-loader";
import * as i4 from "@angular/animations";
import * as i5 from "@angular/router";
import * as i6 from "./headersuperadmin.component";
import * as i7 from "../../services/api.service";
var styles_HeadersuperadminComponent = [];
var RenderType_HeadersuperadminComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeadersuperadminComponent, data: {} });
export { RenderType_HeadersuperadminComponent as RenderType_HeadersuperadminComponent };
function View_HeadersuperadminComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "mr-2"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "", _co.fileUrl, "", ((_co.userInfo == null) ? null : _co.userInfo.profile_path), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_HeadersuperadminComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "mr-2"], ["src", "assets/images/profile.png"]], null, null, null, null, null))], null, null); }
function View_HeadersuperadminComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ul", [["aria-labelledby", "button-animated"], ["class", "dropdown-menu dropdown-menu-right fs14"], ["id", "dropdown-animated"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "dropdown-item"], ["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Logout"]))], null, null); }
function View_HeadersuperadminComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "mr-2"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "", _co.fileUrl, "", ((_co.userInfo == null) ? null : _co.userInfo.profile_path), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_HeadersuperadminComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "mr-2"], ["src", "assets/images/profile.png"]], null, null, null, null, null))], null, null); }
export function View_HeadersuperadminComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 36, "header", [["class", "header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 35, "nav", [["aria-labelledby", "R101"], ["class", "navbar navbar-light fixed-top bg-white p-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", "user icon"], ["class", "logo"], ["src", "assets/images/logo-w.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "a", [["class", "toggle-btn"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sidebarToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(6, { sidemenuopened: 0 }), (_l()(), i0.ɵeld(7, 0, null, null, 0, "em", [["class", "fas fa-bars fs30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 16777216, null, null, 14, "div", [["class", "ml-auto d-block d-md-none mr-2"], ["dropdown", ""], ["placement", "bottom right"]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i0.ɵprd(512, null, i2.BsDropdownState, i2.BsDropdownState, []), i0.ɵdid(10, 212992, null, 0, i2.BsDropdownDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i3.ComponentLoaderFactory, i2.BsDropdownState, i2.BsDropdownConfig, i4.AnimationBuilder], { placement: [0, "placement"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 9, "div", [["aria-controls", "dropdown-animated"], ["class", "user-profile bg-transparent d-flex align-items-center c-pointer dropdown-toggle"], ["dropdownToggle", ""]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 147456, null, 0, i2.BsDropdownToggleDirective, [i0.ChangeDetectorRef, i2.BsDropdownDirective, i0.ElementRef, i0.Renderer2, i2.BsDropdownState], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeadersuperadminComponent_1)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeadersuperadminComponent_2)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 2, "p", [["class", "font-weight-bold fs16 text-truncate widm-140 profile-name mb-0"], ["routerLink", "/profile-superadmin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 18).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(18, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(19, null, [" ", " "])), (_l()(), i0.ɵeld(20, 0, null, null, 0, "em", [["class", "fa fa-angle-down text-white"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeadersuperadminComponent_3)), i0.ɵdid(22, 16384, null, 0, i2.BsDropdownMenuDirective, [i2.BsDropdownState, i0.ViewContainerRef, i0.TemplateRef], null, null), (_l()(), i0.ɵeld(23, 0, null, null, 13, "div", [["class", "ml-auto nav-right d-none d-md-block mr-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 12, "ul", [["class", "list-group list-group-horizontal align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 8, "li", [["class", "list-inline border-0 p-0 mr-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 7, "div", [["class", "user-profile d-flex align-items-center c-pointer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeadersuperadminComponent_4)), i0.ɵdid(28, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeadersuperadminComponent_5)), i0.ɵdid(30, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(31, 0, null, null, 2, "p", [["class", "font-weight-bold fs16 text-truncate widm-140 mb-0"], ["routerLink", "/profile-superadmin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 32).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(32, 16384, null, 0, i5.RouterLink, [i5.Router, i5.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(33, null, [" ", " "])), (_l()(), i0.ɵeld(34, 0, null, null, 2, "li", [["class", "list-inline border-0 p-0 mr-md-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 1, "button", [["class", "btn btn-secondary py-2 btn-logout fs14 w-100px"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Logout "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "toggle-btn"; var currVal_1 = _ck(_v, 6, 0, _co.shouldShow); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_5 = "bottom right"; _ck(_v, 10, 0, currVal_5); var currVal_9 = (_co.userInfo && ((_co.userInfo == null) ? null : _co.userInfo.profile_path)); _ck(_v, 14, 0, currVal_9); var currVal_10 = !((_co.userInfo == null) ? null : _co.userInfo.profile_path); _ck(_v, 16, 0, currVal_10); var currVal_11 = "/profile-superadmin"; _ck(_v, 18, 0, currVal_11); var currVal_13 = (_co.userInfo && ((_co.userInfo == null) ? null : _co.userInfo.profile_path)); _ck(_v, 28, 0, currVal_13); var currVal_14 = !((_co.userInfo == null) ? null : _co.userInfo.profile_path); _ck(_v, 30, 0, currVal_14); var currVal_15 = "/profile-superadmin"; _ck(_v, 32, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵnov(_v, 10).dropup; var currVal_3 = i0.ɵnov(_v, 10).isOpen; var currVal_4 = (i0.ɵnov(_v, 10).isOpen && i0.ɵnov(_v, 10).isBs4); _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = true; var currVal_7 = i0.ɵnov(_v, 12).isDisabled; var currVal_8 = i0.ɵnov(_v, 12).isOpen; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8); var currVal_12 = ((_co.userInfo == null) ? null : _co.userInfo.firstName); _ck(_v, 19, 0, currVal_12); var currVal_16 = ((_co.userInfo == null) ? null : _co.userInfo.firstName); _ck(_v, 33, 0, currVal_16); }); }
export function View_HeadersuperadminComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-headersuperadmin", [], null, null, null, View_HeadersuperadminComponent_0, RenderType_HeadersuperadminComponent)), i0.ɵdid(1, 114688, null, 0, i6.HeadersuperadminComponent, [i5.Router, i7.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeadersuperadminComponentNgFactory = i0.ɵccf("app-headersuperadmin", i6.HeadersuperadminComponent, View_HeadersuperadminComponent_Host_0, {}, { sidemenuToggle: "sidemenuToggle" }, []);
export { HeadersuperadminComponentNgFactory as HeadersuperadminComponentNgFactory };
