<div class="row px-5 pt-3">
  <div class="col-12">
    <label class="radiocontainer" (click)="ftime1()">
      <p class="color-black fs18 pl-3 mb-5">{{ fd1 }}</p>
      <input type="radio" [checked]="timeformat == 'hh:mm @'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="ftime3()">
      <p class="color-black fs18 pl-3 mb-5">{{ fd3 }}</p>
      <input type="radio" [checked]="timeformat == 'HH:mm'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="ftime2()">
      <p class="color-black fs18 pl-3 mb-5">
        {{ fd2 }}
        <span class="color-light-grey pl-2">(Default)</span>
      </p>
      <input type="radio" [checked]="timeformat == 'hh:mm:ss @'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="ftime4()">
      <p class="color-black fs18 pl-3 mb-5">{{ fd4 }}</p>
      <input type="radio" [checked]="timeformat == 'HH:mm:ss'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <div class="form-row mt-2">
      <div class="col-12 mb-4 d-flex justify-content-start">
        <button
          type="button"
          class="btn btn-primary btn-cancel border-0 py-2 fs14 w-140px mr-3"
          tooltip="Cancel"
          (click)="closeModel()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-secondary py-2 btn-create border-0 w-140px fs14"
          (click)="timezonesubmit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
