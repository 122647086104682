import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent implements OnInit {
  modalRef: BsModalRef;
  reportList = [];
  reportForm: FormGroup;
  submitted = false;
  selectedReport: any;
  page = 1;
  pagesize = 10;
  totalCount = 0;
  search: string;
  sortType: any = '';
  sortFiled;
  timeframeList = [];
  timeFrameData;
  sourceList = [];
  sourceListData;
  isLoading = false;
  public loading = false;

  // tslint:disable-next-line:max-line-length
  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {}

  // Report List
  getreportList() {
    this.loading = true;
    const queryParams = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) {
      queryParams['search'] = this.search;
    }
    if (this.sortType != '' && this.sortFiled != '') {
      queryParams['sort'] = this.sortFiled + ':' + this.sortType;
    }
    this.apiService.get('report', queryParams).subscribe(
      (data: any) => {
        this.reportList = data.reports;
        this.totalCount = data.pageDetails.total;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      },
    );
  }

  pageChange(newPage: number) {
    this.page = newPage;
    this.getreportList();
  }
  upside(format, field) {
    if (this.sortType == 'asc') {
      this.sortType = 'desc';
      this.sortFiled = field;
      this.getreportList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getreportList();
    }
  }

  downSide(format, field) {
    if (this.sortType == 'desc') {
      this.sortType = 'asc';
      this.sortFiled = field;
      this.getreportList();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getreportList();
    }
  }

  get f() {
    return this.reportForm.controls;
  }

  openAddReportModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
    this.reportForm.reset();
    this.submitted = false;
  }

  addReportData() {
    this.submitted = true;
    if (this.reportForm.invalid) {
      return;
    }
    const reportItem = {
      source: this.reportForm.value.source,
      start_date: formatDate(this.reportForm.value.start_date, 'yyyy-MM-dd', 'en'),
      time_frame: this.reportForm.value.time_frame,
      title: this.reportForm.value.title,
      is_public: this.reportForm.value.is_public,
    };
    reportItem['is_public'] = reportItem['is_public'] ? 1 : 0;
    this.isLoading = true;
    this.apiService.post('report', reportItem).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastr.success(data.message);
          this.reportForm.reset();
          this.submitted = false;
          this.modalRef.hide();
          this.getreportList();
        }
        this.isLoading = false;
      },
      (err) => {
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
        this.isLoading = false;
      },
    );
  }

  selectTimeFrame(event) {
    this.timeFrameData = event.target.value;
  }

  selectSource(event) {
    this.sourceListData = event.target.value;
  }

  //Report Detail
  reportDetail(value) {
    if (value) {
      this.apiService.reportValue = value;
      this.router.navigate(['/report-detail/' + value.id]);
    }
  }

  omit_special_char(event) {
    const k: any = event.charCode;
    const value: any = event.target.value;
    if (value.length === 0) {
      if (k === 32) {
        return false;
      }
    } else {
      return (
        (k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k >= 96 && k <= 105) || k == 32 || k == 13
      );
    }
  }

  ngOnInit() {
    this.getreportList();
    this.reportForm = this.formBuilder.group({
      title: ['', Validators.required],
      time_frame: ['', Validators.required],
      source: ['', Validators.required],
      start_date: ['', Validators.required],
      is_public: [0],
    });
    this.page = 1;
    this.timeframeList = ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Quaterly', 'Half-yearly', 'Annually'];
    this.sourceList = [
      'Hours per Staff',
      'Hours per Staff (detailed)',
      'Hours per Client & Location',
      'Number of incident types by client & location',
    ];
  }
}
