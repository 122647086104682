<header class="header">
  <nav class="navbar navbar-light fixed-top bg-white p-0" aria-labelledby="R101">
    <div class="navbar-brand">
      <img src="assets/images/logo-w.png" alt="user icon" class="logo" />
    </div>
    <a
      href="javascript:void(0)"
      class="toggle-btn"
      [ngClass]="{ sidemenuopened: shouldShow }"
      (click)="sidebarToggle()"
    >
      <em class="fas fa-bars fs30"></em>
    </a>
    <div class="ml-auto d-block d-md-none mr-2" dropdown placement="bottom right">
      <div
        class="user-profile bg-transparent d-flex align-items-center c-pointer dropdown-toggle"
        dropdownToggle
        aria-controls="dropdown-animated"
      >
        <img
          src="{{ fileUrl }}{{ userInfo?.profile_path }}"
          alt="user icon"
          class="mr-2"
          *ngIf="userInfo && userInfo?.profile_path"
        />
        <img src="assets/images/profile.png" alt="user icon" class="mr-2" *ngIf="!userInfo?.profile_path" />
        <p class="font-weight-bold fs16 text-truncate widm-140 profile-name mb-0" routerLink="/profile-superadmin">
          {{ userInfo?.firstName }}
        </p>
        <em class="fa fa-angle-down text-white"></em>
      </div>
      <ul
        id="dropdown-animated"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right fs14"
        role="menu"
        aria-labelledby="button-animated"
      >
        <li role="menuitem"><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
      </ul>
    </div>
    <div class="ml-auto nav-right d-none d-md-block mr-3">
      <ul class="list-group list-group-horizontal align-items-center">
        <li class="list-inline border-0 p-0 mr-md-3">
          <div class="user-profile d-flex align-items-center c-pointer">
            <img
              src="{{ fileUrl }}{{ userInfo?.profile_path }}"
              alt="user icon"
              class="mr-2"
              *ngIf="userInfo && userInfo?.profile_path"
            />
            <img src="assets/images/profile.png" alt="user icon" class="mr-2" *ngIf="!userInfo?.profile_path" />
            <p class="font-weight-bold fs16 text-truncate widm-140 mb-0" routerLink="/profile-superadmin">
              {{ userInfo?.firstName }}
            </p>
          </div>
        </li>
        <li class="list-inline border-0 p-0 mr-md-3">
          <button type="submit" class="btn btn-secondary py-2 btn-logout fs14 w-100px" (click)="logout()">
            Logout
          </button>
        </li>
      </ul>
    </div>
  </nav>
</header>
