import { OnInit, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
var ReportsComponent = /** @class */ (function () {
    // tslint:disable-next-line:max-line-length
    function ReportsComponent(modalService, apiService, toastr, formBuilder, router) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.router = router;
        this.reportList = [];
        this.submitted = false;
        this.page = 1;
        this.pagesize = 10;
        this.totalCount = 0;
        this.sortType = '';
        this.timeframeList = [];
        this.sourceList = [];
        this.isLoading = false;
        this.loading = false;
    }
    // Report List
    ReportsComponent.prototype.getreportList = function () {
        var _this = this;
        this.loading = true;
        var queryParams = { page: this.page, pagesize: this.pagesize };
        if (this.search && this.search.length > 0) {
            queryParams['search'] = this.search;
        }
        if (this.sortType != '' && this.sortFiled != '') {
            queryParams['sort'] = this.sortFiled + ':' + this.sortType;
        }
        this.apiService.get('report', queryParams).subscribe(function (data) {
            _this.reportList = data.reports;
            _this.totalCount = data.pageDetails.total;
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
        });
    };
    ReportsComponent.prototype.pageChange = function (newPage) {
        this.page = newPage;
        this.getreportList();
    };
    ReportsComponent.prototype.upside = function (format, field) {
        if (this.sortType == 'asc') {
            this.sortType = 'desc';
            this.sortFiled = field;
            this.getreportList();
        }
        else {
            this.sortType = format;
            this.sortFiled = field;
            this.getreportList();
        }
    };
    ReportsComponent.prototype.downSide = function (format, field) {
        if (this.sortType == 'desc') {
            this.sortType = 'asc';
            this.sortFiled = field;
            this.getreportList();
        }
        else {
            this.sortType = format;
            this.sortFiled = field;
            this.getreportList();
        }
    };
    Object.defineProperty(ReportsComponent.prototype, "f", {
        get: function () {
            return this.reportForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ReportsComponent.prototype.openAddReportModal = function (template) {
        this.modalRef = this.modalService.show(template, {
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg modal-dialog-centered roaster-modal',
        });
        this.reportForm.reset();
        this.submitted = false;
    };
    ReportsComponent.prototype.addReportData = function () {
        var _this = this;
        this.submitted = true;
        if (this.reportForm.invalid) {
            return;
        }
        var reportItem = {
            source: this.reportForm.value.source,
            start_date: formatDate(this.reportForm.value.start_date, 'yyyy-MM-dd', 'en'),
            time_frame: this.reportForm.value.time_frame,
            title: this.reportForm.value.title,
            is_public: this.reportForm.value.is_public,
        };
        reportItem['is_public'] = reportItem['is_public'] ? 1 : 0;
        this.isLoading = true;
        this.apiService.post('report', reportItem).subscribe(function (data) {
            if (data.status === 200) {
                _this.toastr.success(data.message);
                _this.reportForm.reset();
                _this.submitted = false;
                _this.modalRef.hide();
                _this.getreportList();
            }
            _this.isLoading = false;
        }, function (err) {
            if (err.error.message) {
                _this.toastr.error(err.error.message);
            }
            _this.isLoading = false;
        });
    };
    ReportsComponent.prototype.selectTimeFrame = function (event) {
        this.timeFrameData = event.target.value;
    };
    ReportsComponent.prototype.selectSource = function (event) {
        this.sourceListData = event.target.value;
    };
    //Report Detail
    ReportsComponent.prototype.reportDetail = function (value) {
        if (value) {
            this.apiService.reportValue = value;
            this.router.navigate(['/report-detail/' + value.id]);
        }
    };
    ReportsComponent.prototype.omit_special_char = function (event) {
        var k = event.charCode;
        var value = event.target.value;
        if (value.length === 0) {
            if (k === 32) {
                return false;
            }
        }
        else {
            return ((k > 64 && k < 91) || (k > 96 && k < 123) || (k >= 48 && k <= 57) || (k >= 96 && k <= 105) || k == 32 || k == 13);
        }
    };
    ReportsComponent.prototype.ngOnInit = function () {
        this.getreportList();
        this.reportForm = this.formBuilder.group({
            title: ['', Validators.required],
            time_frame: ['', Validators.required],
            source: ['', Validators.required],
            start_date: ['', Validators.required],
            is_public: [0],
        });
        this.page = 1;
        this.timeframeList = ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Quaterly', 'Half-yearly', 'Annually'];
        this.sourceList = [
            'Hours per Staff',
            'Hours per Staff (detailed)',
            'Hours per Client & Location',
            'Number of incident types by client & location',
        ];
    };
    return ReportsComponent;
}());
export { ReportsComponent };
