/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./sidebar.component";
import * as i4 from "../../services/api.service";
var styles_SidebarComponent = [];
var RenderType_SidebarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
function View_SidebarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "list-group-item"], ["routerLink", "/dashboard"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, [[1, 4]], 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵpod(5, { exact: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [["alt", "Dashboard"], ["src", "assets/images/menu-icons/dashboard.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Dashboard "]))], function (_ck, _v) { var currVal_0 = "/dashboard"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, true); var currVal_2 = "active"; _ck(_v, 2, 0, currVal_1, currVal_2); }, null); }
function View_SidebarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/clients"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Clients"], ["src", "assets/images/menu-icons/clients.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Locations "]))], function (_ck, _v) { var currVal_1 = "/clients"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("client"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/staff"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Staff"], ["src", "assets/images/menu-icons/staff.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Staff "]))], function (_ck, _v) { var currVal_1 = "/staff"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("staff"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Staff"], ["src", "assets/images/menu-icons/staff.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Staff "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "/staff-details/", _co.userId, ""); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("staff"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/reports"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Reports"], ["src", "assets/images/menu-icons/reports.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reports "]))], function (_ck, _v) { var currVal_1 = "/reports"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("report"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/report"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Reports"], ["src", "assets/images/menu-icons/reports.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Reports "]))], function (_ck, _v) { var currVal_1 = "/report"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("report"); _ck(_v, 0, 0, currVal_0); }); }
function View_SidebarComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/settings"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Settings"], ["src", "assets/images/menu-icons/settings.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Settings "]))], function (_ck, _v) { var currVal_1 = "/settings"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLinkActive("setting"); _ck(_v, 0, 0, currVal_0); }); }
export function View_SidebarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "aside", [["class", "roaster-sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 18, "ul", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_3)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_4)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "li", [["class", "list-group-item"], ["routerLink", "/scheduler"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.redirectTo("/scheduler") !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 0, "img", [["alt", "Scheduler"], ["src", "assets/images/menu-icons/scheduler.svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Scheduler "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_5)), i0.ɵdid(15, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_6)), i0.ɵdid(17, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarComponent_7)), i0.ɵdid(19, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userGroup === "Full access") || (_co.userGroup === "Advanced")); _ck(_v, 3, 0, currVal_0); var currVal_1 = (((_co.userGroup === "Full access") || (_co.userGroup === "Advanced")) || (_co.userGroup === "Supervisor")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.userGroup === "Full access") || (_co.userGroup === "Advanced")) || (_co.userGroup === "Supervisor")); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.userGroup === "Staff"); _ck(_v, 9, 0, currVal_3); var currVal_5 = "/scheduler"; _ck(_v, 11, 0, currVal_5); var currVal_6 = false; _ck(_v, 15, 0, currVal_6); var currVal_7 = (_co.userGroup === "Full access"); _ck(_v, 17, 0, currVal_7); var currVal_8 = (_co.userGroup === "Full access"); _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isLinkActive("scheduler"); _ck(_v, 10, 0, currVal_4); }); }
export function View_SidebarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i0.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [i1.Router, i4.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i0.ɵccf("app-sidebar", i3.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
