import { Component, OnInit, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import _ from 'lodash';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { dateFormats } from '../constants/defaultValues';
import { shareReplay } from 'rxjs/operators';
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
})
export class SchedulerComponent implements OnInit, OnDestroy {
  offset = 0;
  weekDays = [];
  startOfWeek;
  endOfWeek;
  scheduleList: any = [];
  staffList = [];
  clientId: any = '';
  locationId: any = '';
  subLocationId: any = '';
  staffId: any = '';
  domainInfo: any = {};
  getShiftScheduleSubscription;

  today = moment().format('YYYY-MM-DD');
  permission: any = {};
  @ViewChild('bsDatepicker') datepicker1: BsDaterangepickerDirective;
  @ViewChild('datepickerFull') datePickerObj;
  modalRef: BsModalRef;
  editShift: any = {};
  minDate: Date;
  showHour = '-1';
  interval = 5;
  times = [];
  tt = 0;
  timeList = [];
  hourTimeList = [];
  miniuteTimeList = [];
  hourList = [];
  endHourList = [];
  minitsList = [];
  endMinitsList = [];
  loading = false;
  shiftForm: FormGroup;
  submitted = false;
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');
  userId: any = localStorage.getItem('userid');
  weekMomentDays = [];
  public loader = false;
  lastEditDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
  top = 0;
  miminumStatusValue;
  statusList: any = [];
  bsValue: Date = new Date(moment().format('YYYY, MM, DD'));
  currentTime = moment().format('HH:mm');
  nextDaycurrentTime = moment().toISOString();
  scrollx = window.pageXOffset;
  scrolly = window.pageYOffset;
  timeFormat;
  subscription: Subscription;
  maxDate = new Date(moment().add(180, 'days').format('YYYY, MM, DD'));
  timezone = '';
  jumpDateFormat: string;
  restrictNextButton = false;
  cancelSub;
  eventName = '';
  socketSubscribe;
  profileSubscribe;
  clickDatePicker = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    const date = moment().format('YYYY, MM, DD');
    this.minDate = new Date(date);
    this.bsValue = new Date(moment().format('YYYY, MM, DD'));
    this.jumpDateFormat = dateFormats[this.domainInfo.dateformat];
    for (let i = 0; this.tt < 24 * 60; i++) {
      const hh = Math.floor(this.tt / 60);
      const index = this.hourList.findIndex((hour) => hour === ('0' + (hh % 24)).slice(-2));
      if (index === -1) {
        this.hourList.push(('0' + (hh % 24)).slice(-2));
        this.endHourList.push(('0' + (hh % 24)).slice(-2));
        this.hourTimeList.push(('0' + (hh % 24)).slice(-2));
      }
      const mm = this.tt % 60;
      const indexm = this.minitsList.findIndex((minits) => minits === ('0' + mm).slice(-2));
      if (indexm === -1) {
        this.minitsList.push(('0' + mm).slice(-2));
        this.endMinitsList.push(('0' + mm).slice(-2));
        this.miniuteTimeList.push(('0' + mm).slice(-2));
      }

      // this.timeList.push(('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2));
      this.tt = this.tt + this.interval;
    }
    this.minitsList.push('59');
    this.endMinitsList.push('59');
    this.miniuteTimeList.push('59');
  }

  ngOnInit() {
    this.today = moment().format('YYYY-MM-DD');
    this.lastEditDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    this.currentTime = moment().format('HH:mm');
    this.nextDaycurrentTime = moment().toISOString();
    this.formInitialize();
    this.getStaffList();
    this.userId = parseInt(this.userId);
    this.clientId = this.route.snapshot.queryParamMap.get('clientId')
      ? parseInt(this.route.snapshot.queryParamMap.get('clientId'))
      : '';
    this.staffId =
      this.route.snapshot.queryParamMap.get('staffId') === 'null'
        ? 'null'
        : this.route.snapshot.queryParamMap.get('staffId')
        ? parseInt(this.route.snapshot.queryParamMap.get('staffId'))
        : '';
    this.top = this.route.snapshot.queryParamMap.get('top')
      ? parseInt(this.route.snapshot.queryParamMap.get('top'))
      : 0;
    this.offset = this.route.snapshot.queryParamMap.get('offset')
      ? parseInt(this.route.snapshot.queryParamMap.get('offset'))
      : 0;
    this.eventName = this.route.snapshot.queryParamMap.get('eventName')
      ? this.route.snapshot.queryParamMap.get('eventName')
      : '';
    if (this.userGroup === 'Client') {
      this.clientId = this.userId;
    }
    if (this.userGroup === 'Staff') {
      this.staffId = this.userId;
    }

    this.socketSubscribe = this.apiService.getShiftMessage('update_shift').subscribe((data) => {
      if (data['data'] === true) {
        this.getShiftSchedule(false);
      }
    });

    this.profileSubscribe = this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data['staff_id'] && data['group']) {
        this.userGroup = data['group'];
        localStorage.setItem('userGroup', data['group']);
        this.getShiftSchedule(false);
      }
    });

    this.cancelSub = this.apiService.schedule.subscribe((res) => {
      if (res) {
        this.getShiftSchedule(false);
        this.apiService.reloadSchedule(null);
      }
    });
    let splittedTime;

    if (this.domainInfo.timeformat !== null) {
      splittedTime = this.domainInfo.timeformat.split(':');
    } else {
      this.timeFormat = '24HoursFormat';
    }
    if (splittedTime && splittedTime[0] === 'hh') {
      this.timeFormat = '12HoursFormat';
    }
    this.getWeekDays('New');
  }

  unsubscribeSubscriptions() {
    this.socketSubscribe.unsubscribe();
    this.profileSubscribe.unsubscribe();
    this.cancelSub.unsubscribe();
    this.getShiftScheduleSubscription.unsubscribe();
    this.socketSubscribe = null;
    this.profileSubscribe = null;
    this.cancelSub = null;
    this.getShiftScheduleSubscription = null;
  }

  tConvert(time) {
    if (!time) return time;
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  isEvent(shift) {
    return shift.event && this.eventName === '';
  }

  formInitialize() {
    this.shiftForm = this.formBuilder.group({
      shiftId: [''],
      startHr: ['00', [Validators.required]],
      endHr: ['00', [Validators.required]],
      startMin: ['00', [Validators.required]],
      endMin: ['00', [Validators.required]],
      brief: [''],
      code: [''],
      additional_notes: [''],
      workDate: [''],
      index: [''],
    });
  }

  get f() {
    return this.shiftForm.controls;
  }

  setPageScroll() {
    const doc = document.scrollingElement || document.body;
    doc.scrollTop = this.top;
  }

  validateEndHour() {
    const startHour = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
    const endHour = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
    const resultedStartHour = moment(startHour, 'h:mm');
    const resultedEndHour = moment(endHour, 'h:mm');
    if (resultedEndHour.isBefore(resultedStartHour)) {
      this.shiftForm.get('endHr').setErrors({ endHrerrors: true });
    } else {
      this.shiftForm.get('endHr').clearValidators();
    }
  }
  checkTimeandDate() {
    this.showHour = '-1';

    const startHr = this.shiftForm.get('startHr').value;
    const startMin = this.shiftForm.get('startMin').value;
    const endHr = this.shiftForm.get('endHr').value;
    const endMin = this.shiftForm.get('endMin').value;

    const fromTime = `${startHr}:${startMin}:00`;
    const toTime = `${endHr}:${endMin}:00`;

    const startTime = moment(fromTime, 'HH:mm:ss');
    const endTime = moment(toTime, 'HH:mm:ss');

    if (startTime.isSame(endTime) || startTime.isAfter(endTime)) {
      this.shiftForm.get('endHr').setErrors({ toTimeError: true });
    } else {
      this.shiftForm.get('endHr').clearValidators();
    }
  }

  selectStartTime(value) {
    const index = this.hourList.findIndex((obj) => obj === value);
    const endTime = this.shiftForm.get('endHr').value;
    const minvalue = this.shiftForm.get('startMin').value;
    const data = [];
    const minData = [];
    for (let i = index; i <= this.hourTimeList.length; i++) {
      if (this.hourTimeList[i]) {
        data.push(this.hourTimeList[i].toString());
      }
    }
    if (endTime <= value) {
      const minindex = this.minitsList.findIndex((obj) => obj === minvalue);
      for (let i = minindex; i <= this.miniuteTimeList.length; i++) {
        minData.push(this.miniuteTimeList[i]);
      }
    } else {
      this.shiftForm.get('endHr').clearValidators();
    }
  }

  createEndTimeAndMins(hr, min) {
    const date = new Date();
    const mindate = new Date();
    const interval = 1;
    const mininterval = 5;
    date.setHours(hr);
    date.setMinutes(min);
    mindate.setHours(hr);
    mindate.setMinutes(min);
    const mindata = [];
    let finalMindata = [];
    const stTime = this.shiftForm.get('startHr').value;
    const endTime =
      this.shiftForm.get('endHr').value && this.shiftForm.get('endHr').value.indexOf(': ') > -1
        ? this.shiftForm.get('endHr').value.split(': ')[1]
        : this.shiftForm.get('endHr').value;

    const data: any = [date.getHours() > 9 ? date.getHours() : '0' + date.getHours()];
    for (let i = 0; i < 23; i++) {
      date.setHours(date.getHours() + interval);
      const dateString = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
      if (dateString) {
        data.push(dateString.toString());
      }
    }

    for (let i = 0; i < 12; i++) {
      mindate.setMinutes(mindate.getMinutes() + mininterval);
      if (i != 11) {
        mindata.push(mindate.getMinutes());
      } else {
        mindata.push(mindata[mindata.length - 1] + 4);
      }
    }
    if (stTime === endTime) {
      mindata.forEach((item) => {
        item = item > 9 ? item : '0' + item;
        finalMindata.push(item);
      });
    } else {
      finalMindata = this.minitsList;
    }

    //this.endHourList = data;
    //this.endMinitsList = finalMindata;
  }

  selectStartMin(value) {
    const minvalue = this.shiftForm.get('endMin').value;
    //this.endMinitsList = [];

    this.createEndTimeAndMins(value, minvalue);
  }

  selectendHr(value) {
    //this.shiftForm.get('endHr').clearValidators();
    const stMin = this.shiftForm.get('startMin').value;
    this.selectStartMin(stMin);
    //this.validateEndHour();
  }

  onEndMinSelection(value) {
    this.shiftForm.get('endHr').clearValidators();
    this.shiftForm.get('endHr').setErrors(null);
  }

  async openEditShiftModal(template: TemplateRef<any>, shift, type, workDate) {
    this.editShift = shift;
    this.editShift.work_date = workDate;
    this.editShift['isAll'] = type;
    this.setShiftform(shift);
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  async getEditShiftDetails(shift) {
    const data = await this.apiService.get('shift/edit-shift-details', { shiftId: shift.id }).toPromise();

    return data && data['data']['brief'];
  }

  async setShiftform(shift) {
    const stHr = shift['start_time'].split(':')[0];
    const stMin = shift['start_time'].split(':')[1];
    const etHr = shift['end_time'].split(':')[0];
    const etMin = shift['end_time'].split(':')[1];
    const brief = await this.getEditShiftDetails(shift);

    this.shiftForm.patchValue({
      shiftId: shift['id'],
      startHr: stHr.toString(),
      endHr: etHr.toString(),
      startMin: stMin.toString(),
      endMin: etMin.toString(),
      code: shift['code'] || '',
      additional_notes: shift['additional_notes'] || '',
      brief,
      workDate: shift['work_date'],
      index: shift['index'],
    });
  }

  getWeekDays(type) {
    if (['Add', 'Sub'].includes(type)) {
      this.clickDatePicker = false;
    }
    if (this.restrictNextButton && type === 'Add') {
      return;
    }
    this.offset = type === 'New' ? this.offset : type === 'Add' ? this.offset + 1 : this.offset - 1;
    this.startOfWeek = moment().add(this.offset, 'weeks').startOf('isoWeek');
    this.endOfWeek = moment().add(this.offset, 'weeks').endOf('isoWeek');
    this.weekDays = [];
    this.weekMomentDays = [];
    let day = this.startOfWeek;
    let changedDate;
    while (day <= this.endOfWeek) {
      this.weekDays.push(moment(day).format('YYYY-MM-DD'));
      if (
        (this.weekDays.length === 1 && moment(day).isAfter(moment(this.bsValue))) ||
        (this.weekDays.length === 7 && moment(day).isBefore(moment(this.bsValue)))
      ) {
        changedDate = day;
      }
      this.weekMomentDays.push(moment(day).format('YYYY-MM-DD'));
      day = day.clone().add(1, 'd');
    }
    if (changedDate) {
      this.bsValue = new Date(moment(changedDate).format('YYYY, MM, DD'));
    }
    const sixMonthsFromToday = moment().add('180', 'days');
    if (
      moment(sixMonthsFromToday).isSameOrAfter(this.startOfWeek) &&
      moment(sixMonthsFromToday).isSameOrBefore(this.endOfWeek)
    ) {
      this.restrictNextButton = true;
    } else {
      this.restrictNextButton = false;
    }
    this.startOfWeek = moment(this.startOfWeek).format(this.jumpDateFormat);
    this.endOfWeek = moment(this.endOfWeek).format(this.jumpDateFormat);

    this.getShiftSchedule(true);
  }

  shwWarn(dp) {
    this.clickDatePicker = dp;
  }

  onDateSelect(e) {
    if (e && this.clickDatePicker) {
      this.bsValue = new Date(moment(e).format('YYYY, MM, DD'));
      const currentWeek = moment().add(0, 'weeks').startOf('isoWeek');
      let selectedWeek = moment(e).add(0, 'weeks').endOf('isoWeek');
      if (e) {
        if (currentWeek > selectedWeek) {
          selectedWeek = moment(e).add(0, 'weeks').startOf('isoWeek');
        } else {
          selectedWeek = moment(e).add(0, 'weeks').endOf('isoWeek');
        }
      } else {
        selectedWeek = moment().add(0, 'weeks').endOf('isoWeek');
      }

      const diff = selectedWeek.diff(currentWeek, 'week');
      this.offset = this.route.snapshot.queryParamMap.get('offset')
        ? parseInt(this.route.snapshot.queryParamMap.get('offset'), 10)
        : diff;
      this.router.navigate(['/scheduler']);
      this.getWeekDays('New');
    }
  }

  getClassNameByEventStatus(value) {
    const status = [];
    let className = 'card-blue p-2 rounded c-pointer';

    _.each(value, function (statusValue) {
      status.push(statusValue.status);
    });

    const minimumStatusValue = Math.min(...status);

    if (minimumStatusValue === 0) {
      className = 'card-blue p-2 rounded c-pointer';
    }
    if (minimumStatusValue === 1) {
      className = 'card-red p-2 rounded c-pointer';
    }
    if (minimumStatusValue === 2) {
      className = 'card-yello p-2 rounded c-pointer';
    }
    if (minimumStatusValue === 3) {
      className = 'card-green p-2 rounded c-pointer';
    }
    if (minimumStatusValue === 4) {
      className = 'card-blue p-2 rounded c-pointer';
    }
    if (minimumStatusValue === 5) {
      className = 'card-grey p-2 rounded c-pointer';
    }

    return className;
  }

  isEventExists(key, modifiedShiftList) {
    let isExist = false;

    _.each(modifiedShiftList, (modifiedShift) => {
      if (modifiedShift.event === key && this.eventName === '') {
        isExist = true;
      }
    });

    return isExist;
  }

  removeDuplicateEventsAndSetClass(key, shiftList, groupedEvents) {
    const modifiedShiftList = [];
    _.each(shiftList, (shiftObj) => {
      if (shiftObj.event === key) {
        shiftObj.className = this.getClassNameByEventStatus(groupedEvents);

        if (!this.isEventExists(key, modifiedShiftList)) {
          modifiedShiftList.push(shiftObj);
        }
      } else {
        modifiedShiftList.push(shiftObj);
      }
    });

    return modifiedShiftList;
  }

  updateWorkDate(shiftInfo) {
    return shiftInfo.data.map((shift) => {
      shift.work_date = shiftInfo.keyDate;
      shift.permanent = shift.permanent ? shift.permanent : 0;
      if (!shift.status) shift.status = null;
      return shift;
    });
  }

  getShiftScheduleList(type) {
    this.loader = type;
    const from_date = moment(this.startOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
    const to_date = moment(this.endOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
    const current_date = moment().format('YYYY-MM-DD');
    const query = { from_date: from_date, to_date: to_date, current_date: current_date };
    //if (this.clientId != '') query['clientId'] = this.clientId;
    if (this.staffId != '' && this.staffId != 'null') query['staffId'] = this.staffId;
    if (this.staffId == 'null') query['isUnschedule'] = true;

    if (localStorage.getItem('userType').toString() === '1') {
      query['clientId'] = this.userId;
    }
    this.getShiftScheduleSubscription = this.apiService.get('shift/schedule-list', query).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.scheduleList = data['schedule'];
          this.scheduleList.forEach((clientSchedule) => {
            clientSchedule &&
              clientSchedule.data.forEach((clientLocation) => {
                clientLocation &&
                  clientLocation.data.forEach((shiftInfo) => {
                    shiftInfo.data = this.updateWorkDate(shiftInfo);
                    const groupedByEvent = _.groupBy(shiftInfo && shiftInfo.data, 'event');
                    _.forOwn(groupedByEvent, (value, key) => {
                      if (key !== 'null') {
                        shiftInfo.data = this.removeDuplicateEventsAndSetClass(key, shiftInfo.data, value);
                      }
                    });
                  });
              });
          });

          this.permission = data['permission'];
        } else {
          this.scheduleList = [];
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
      },
    );
  }

  getShiftSchedule(type) {
    this.loader = type;
    const from_date = moment(this.startOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
    const to_date = moment(this.endOfWeek, this.jumpDateFormat).format('YYYY-MM-DD');
    const current_date = moment().format('YYYY-MM-DD');
    const query = { from_date: from_date, to_date: to_date, current_date: current_date };
    if (this.staffId != '' && this.staffId != 'null') query['staffId'] = this.staffId;
    if (this.staffId == 'null') query['isUnschedule'] = true;
    if (localStorage.getItem('userType').toString() === '1') {
      query['clientId'] = this.userId;
    }
    if (this.eventName) query['eventName'] = this.eventName;

    if (type) this.scheduleList = [];
    this.getShiftScheduleSubscription = this.apiService.get('shift/schedule-list', query).subscribe(
      (data) => {
        if (data['status'] === 200) {
          this.scheduleList = data['schedule'];
          this.scheduleList.forEach((clientSchedule) => {
            clientSchedule &&
              clientSchedule.data.forEach((clientLocation) => {
                clientLocation &&
                  clientLocation.data.forEach((shiftInfo) => {
                    shiftInfo.data = this.updateWorkDate(shiftInfo);
                    const groupedByEvent = _.groupBy(shiftInfo && shiftInfo.data, 'event');
                    _.forOwn(groupedByEvent, (value, key) => {
                      if (key !== 'null') {
                        shiftInfo.data = this.removeDuplicateEventsAndSetClass(key, shiftInfo.data, value);
                      }
                    });
                  });
              });
          });

          this.permission = data['permission'];
          if (type) {
            setTimeout(
              function () {
                this.setPageScroll();
              }.bind(this),
              1000,
            );
          }
        } else {
          this.scheduleList = [];
        }
        this.loader = false;
      },
      (err) => {
        this.loader = false;
      },
    );
  }

  handleChange() {
    this.getShiftSchedule(true);
  }

  getStaffList() {
    this.apiService.get('staff/list').subscribe((staff: any) => {
      if (staff.status === 200) {
        this.staffList = staff.staffs;
      } else {
        this.staffList = [];
      }
    });
  }

  redirectUrl(url, shiftId, index, type, workDate, keyDate, endTime) {
    const doc = document.scrollingElement;
    this.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const pageUrl = url + shiftId + '/' + index;
    this.router.navigate([pageUrl], {
      queryParams: {
        isAll: type,
        offset: this.offset,
        clientId: this.clientId,
        top: this.top,
        workDate,
        staffId: this.staffId,
        locationId: this.locationId,
        subLocationId: this.subLocationId,
        fromDate: this.startOfWeek,
        toDate: this.endOfWeek,
        eventName: this.eventName,
        isEvent: this.eventName !== '',
      },
    });
  }
  redirectEventUrl(url, type, shift, clientId, subLocationId, locationId) {
    const shiftId = shift.event;
    this.locationId = locationId;
    this.subLocationId = subLocationId ? subLocationId : '';
    this.clientId = clientId;
    const doc = document.scrollingElement;
    this.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.eventName = shiftId;
    this.router.navigate([url], {
      queryParams: {
        eventName: shiftId,
        isAll: type,
        offset: this.offset,
        clientId: this.clientId,
        locationId: this.locationId,
        subLocationId: this.subLocationId,
        staffId: this.staffId,
        top: this.top,
        fromDate: this.startOfWeek,
        toDate: this.endOfWeek,
      },
    });
    this.getShiftSchedule(true);
  }

  encodeURL(eventName: string) {
    return encodeURIComponent(eventName);
  }

  shiftAction(shift, type, workDate) {
    const data = { shiftId: shift.id, staff_id: shift.staff_id, index: shift.index, workDate: workDate };
    data['status'] = type === 'Inquire' ? 2 : type === 'Confirm' ? 3 : 4;

    this.apiService.post('shift/roster-shift', data).subscribe((shiftactions) => {
      if (shiftactions['status'] === 200) {
        this.toastr.success(shiftactions['message']);
        this.getShiftSchedule(false);
      } else {
        this.toastr.success(shiftactions['message']);
      }
    });
  }

  shiftAllAction(shift, type, clientId, workDate) {
    const data = {
      shiftId: shift.id,
      staff_id: shift.staff_id,
      client_id: clientId,
      index: shift.index,
      workDate,
    };
    data['status'] = type === 'Inquire' ? 2 : type === 'Confirm' ? 3 : 4;

    this.apiService.post('shift/roster-all-shift', data).subscribe((shiftactions) => {
      if (shiftactions['status'] === 200) {
        this.toastr.success(shiftactions['message']);
        this.getShiftSchedule(false);
      } else {
        this.toastr.success(shiftactions['message']);
      }
    });
  }

  validateCurrentDateEndHour() {
    const currentTime = moment().format('HH:mm') + ':00';

    const resultedWorkDate = Date.parse(this.editShift && this.editShift.work_date);
    const workDate = moment(resultedWorkDate).format('YYYY-MM-DD');

    const tDate = moment().format('YYYY-MM-DD');
    const startHour = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
    const endHour = this.shiftForm.get('endHr').value + ':' + this.shiftForm.get('endMin').value + ':00';
    if (workDate === tDate && this.editShift && this.editShift.status === 5) {
      if (!(startHour < currentTime)) {
        this.shiftForm.get('startHr').setErrors({ startHrTimeerrors: true });
      }
      if (!(endHour < currentTime)) {
        this.shiftForm.get('endHr').setErrors({ endHrTimeerrors: true });
      }
    } else {
      this.shiftForm.get('endHr').setErrors(null);
      this.shiftForm.get('endHr').clearValidators();
      this.shiftForm.get('startHr').setErrors(null);
      this.shiftForm.get('startHr').clearValidators();
    }
  }

  checkShiftEndTime(keyDate: string, shift: any) {
    const currentTime = moment(moment().format('HH:mm:ss'), 'HH:mm:ss');
    const today = moment().format('YYYY-MM-DD');

    const shiftStartTime = moment(shift.start_time, 'HH:mm:ss');
    let shiftEndTime = moment(shift.end_time, 'HH:mm:ss');

    if (moment(shiftEndTime).isBefore(shiftStartTime)) {
      shiftEndTime = shiftEndTime.add(1, 'day');
    }

    return (
      moment(keyDate).isAfter(moment(today)) ||
      (moment(today).isSame(moment(keyDate)) && moment(shiftEndTime).isSameOrAfter(currentTime))
    );
  }

  onSubmit() {
    this.checkTimeandDate();
    this.validateCurrentDateEndHour();
    this.submitted = true;
    if (this.shiftForm.invalid) {
      return;
    }
    const startHour = this.shiftForm.get('startHr').value + ':' + this.shiftForm.get('startMin').value + ':00';
    const endHrValue =
      this.shiftForm.get('endHr').value && this.shiftForm.get('endHr').value.indexOf(': ') > -1
        ? this.shiftForm.get('endHr').value.split(': ')[1]
        : this.shiftForm.get('endHr').value;
    const endHour = endHrValue + ':' + this.shiftForm.get('endMin').value + ':00';
    const params: any = {};
    params.shiftId = this.shiftForm.get('shiftId').value;
    params.brief = this.shiftForm.get('brief').value;
    params.code = this.shiftForm.get('code').value;
    params.additional_notes = this.shiftForm.get('additional_notes').value;
    params.start_time = startHour;
    params.end_time = endHour;
    params.index = this.shiftForm.get('index').value;
    params.workDate = this.editShift.work_date;
    params.isAll = this.editShift['isAll'];
    this.loading = true;
    // if (this.today === this.editShift['work_date'] && this.currentTime > endHour) {
    //   params.isPastShift = true;
    // }
    const apiRequest = this.editShift['isAll'] ? 'shift/edit-all-shifts' : 'shift/edit-shift';

    this.apiService.post(apiRequest, params).subscribe(
      (shift: any) => {
        this.loading = false;
        if (shift['status'] === 200) {
          this.cancelEdit();
          this.toastr.success(shift['message']);
        } else {
          this.toastr.success(shift['message']);
        }
      },
      (err) => {
        this.toastr.success(err['message']);
        this.loading = false;
      },
    );
  }

  cancelEdit() {
    this.editShift = {};
    this.modalRef.hide();
    this.getShiftScheduleList(true);
  }

  getCurrentWeekschedule() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/scheduler']));
    // this.offset = 0;
    // this.getWeekDays('New')
  }

  getNextShiftDate(shift) {
    return moment(shift.start_time, 'HH:mm:ss') < moment(shift.end_time, 'HH:mm:ss')
      ? moment(shift.work_date).format('YYYY-MM-DD')
      : moment(shift.work_date).add(1, 'days').format('YYYY-MM-DD');
  }

  ngOnDestroy() {
    this.unsubscribeSubscriptions();
  }

  public dropdownToggleClick(): void {
    this.currentTime = moment().format('HH:mm');
  }
}
