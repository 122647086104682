import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService } from '../services';
var AddClientComponent = /** @class */ (function () {
    function AddClientComponent(geolocationService, apiService, toastr, formBuilder, router) {
        this.geolocationService = geolocationService;
        this.apiService = apiService;
        this.toastr = toastr;
        this.formBuilder = formBuilder;
        this.router = router;
        this.isEdit = false;
        this.isSubmitted = false;
        this.loading = false;
        this.phoneCode = [];
        this.colorCodes = [
            { id: 1, color: '#e74c3c' },
            { id: 2, color: '#e67e22' },
            { id: 3, color: '#f39c12' },
            { id: 4, color: '#34495e' },
            { id: 5, color: '#8e44ad' },
            { id: 6, color: '#3498db' },
            { id: 7, color: '#27ae60' },
            { id: 8, color: '#16a085' },
        ];
        this.defaultCountryCode = 'NZ';
        this.isMobile = false;
        this.isPhone = false;
        this.isEmail = false;
    }
    AddClientComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.getCurrentLocation();
        this.getPhoneCode();
        if (this.editClientInfo && this.editClientInfo['id']) {
            this.setEditForm(this.editClientInfo);
        }
    };
    AddClientComponent.prototype.getCurrentLocation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.geolocationService.getCurrentCountry()];
                    case 1:
                        _a.defaultCountryCode = _b.sent();
                        this.setcountryFlag();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddClientComponent.prototype.setcountrycodeflag = function (intphoneData, type) {
        if (type === 'phone' || type === 'new') {
            this.pcountryCode = intphoneData.countries_isd_code;
            this.pcountryFlag = intphoneData.countries_iso_code;
            this.pnzcode = intphoneData.id;
        }
        if (type === 'mobile' || type === 'new') {
            this.mcountryCode = intphoneData.countries_isd_code;
            this.mcountryFlag = intphoneData.countries_iso_code;
            this.mnzcode = intphoneData.id;
        }
        this.clientForm.patchValue({
            phone_code: this.pnzcode,
            mobile_code: this.mnzcode,
        });
    };
    AddClientComponent.prototype.setcountryFlag = function () {
        var _this = this;
        var intphoneData = this.phoneCode.filter(function (obj) { return obj.countries_iso_code === _this.defaultCountryCode; });
        if (!this.editClientInfo) {
            if (intphoneData && intphoneData.length > 0) {
                this.setcountrycodeflag(intphoneData[0], 'new');
            }
        }
        else {
            if (this.editClientInfo) {
                var mobileData = this.phoneCode.filter(function (obj) { return obj.id === _this.editClientInfo.mobile_id; });
                var phoneData = this.phoneCode.filter(function (obj) { return obj.id === _this.editClientInfo.phone_id; });
                if (phoneData && phoneData.length > 0) {
                    this.setcountrycodeflag(phoneData[0], 'phone');
                }
                else {
                    this.setcountrycodeflag(intphoneData[0], 'phone');
                }
                if (mobileData && mobileData.length > 0) {
                    this.setcountrycodeflag(mobileData[0], 'mobile');
                }
                else {
                    this.setcountrycodeflag(intphoneData[0], 'mobile');
                }
            }
        }
    };
    AddClientComponent.prototype.getPhoneCode = function () {
        var _this = this;
        this.apiService.get('phone-code').subscribe(function (phoneCode) {
            if (phoneCode.status === 200) {
                _this.phoneCode = phoneCode['phonecodes'];
                if (_this.phoneCode && _this.phoneCode.length > 0) {
                    _this.setcountryFlag();
                }
            }
        });
    };
    AddClientComponent.prototype.createForm = function () {
        this.clientForm = this.formBuilder.group({
            email: ['', [Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9A-Z.-]+\.[a-z]{2,}$/)]],
            name: ['', Validators.required],
            mobile: [''],
            contact_person: [''],
            phone: [''],
            is_newsletter_subscribed: [0],
            background_color: ['#e74c3c'],
            phone_code: [this.pnzcode],
            mobile_code: [this.mnzcode, Validators.required],
        });
    };
    Object.defineProperty(AddClientComponent.prototype, "f", {
        get: function () {
            return this.clientForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    AddClientComponent.prototype.setEditForm = function (data) {
        this.isEdit = true;
        this.clientForm.patchValue({
            email: data.email,
            name: data.name,
            contact_person: data.contact_person,
            mobile: data.mobile,
            mobile_code: data.mobile_id ? data.mobile_id : this.mnzcode,
            phone: data.phone,
            phone_code: data.phone_id ? data.phone_id : this.pnzcode,
            is_newsletter_subscribed: data.is_newsletter_subscribed,
            background_color: data.background_color,
        });
    };
    AddClientComponent.prototype.setCountryInfo = function (code, type) {
        this.setcountrycodeflag(code, type);
    };
    AddClientComponent.prototype.isSpaceCheck = function (evt, value) {
        if (value.length === 0) {
            if (evt === 32) {
                return false;
            }
        }
        return true;
    };
    AddClientComponent.prototype.isNumberKey = function (evt, value) {
        //if (evt != 43 && evt > 31 && (evt < 48 || evt > 57)){
        if (evt > 31 && (evt < 48 || evt > 57)) {
            return false;
        }
        else if (value.length > 9) {
            return false;
        }
        return true;
    };
    AddClientComponent.prototype.checkError = function (err) {
        if (err.error.message === 'Mobile Number Already Registered') {
            this.isMobile = true;
        }
        if (err.error.message === 'Phone Number Already Registered') {
            this.isPhone = true;
        }
        if (err.error.message === 'Email Id already exists') {
            this.isEmail = true;
        }
    };
    AddClientComponent.prototype.createClient = function () {
        var _this = this;
        this.isSubmitted = true;
        if (this.clientForm.invalid) {
            return;
        }
        var datavalue = this.clientForm.value;
        if (datavalue['phone'] &&
            datavalue['phone'].toString() === datavalue['mobile'] &&
            datavalue['mobile'].toString() &&
            datavalue['phone_code'] === datavalue['mobile_code']) {
            this.isPhone = true;
            this.toastr.error('Mobile and Phone are Same');
            return;
        }
        datavalue['is_newsletter_subscribed'] = datavalue['is_newsletter_subscribed'] ? 1 : 0;
        if (datavalue['phone'] === '' || datavalue['phone'] === null)
            datavalue['phone_code'] = '';
        this.loading = true;
        if (this.isEdit) {
            if (this.editClientInfo && this.editClientInfo['userLogId'])
                datavalue['userLogId'] = this.editClientInfo['userLogId'];
            this.apiService.put('client/update/' + this.editClientInfo['id'], datavalue).subscribe(function (client) {
                if (client.status === 200) {
                    _this.apiService.closeClientModel.emit(7);
                    _this.loading = false;
                    _this.toastr.success(client.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
        else {
            this.apiService.post('client', datavalue).subscribe(function (client) {
                if (client.status === 200) {
                    _this.apiService.closeClientModel.emit(2);
                    _this.loading = false;
                    _this.router.navigate(['/client-details/' + client.client['id']]);
                    _this.toastr.success(client.message);
                }
            }, function (err) {
                _this.errorMessage(err);
            });
        }
    };
    AddClientComponent.prototype.errorMessage = function (err) {
        this.loading = false;
        if (err.error.message) {
            this.toastr.error(err.error.message);
            this.checkError(err);
        }
    };
    AddClientComponent.prototype.closeModel = function () {
        if (this.isEdit) {
            this.apiService.closeClientModel.emit(7);
        }
        else {
            this.apiService.closeClientModel.emit(1);
        }
        this.isEdit = false;
    };
    return AddClientComponent;
}());
export { AddClientComponent };
