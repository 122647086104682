import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
})
export class ClientsComponent implements OnInit {
  modalRef: BsModalRef;
  search;
  userGroup = localStorage.getItem('userGroup');
  userId: any = localStorage.getItem('userid');
  showHidden = 0;

  constructor(private modalService: BsModalService, private apiService: ApiService) {}

  addLocationModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }

  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 1) {
        this.closePopup();
      }
      if (data === 2) {
        this.search = '';
        this.closePopup();
      }
    });
  }

  closePopup() {
    this.modalRef.hide();
    this.apiService.searchValue.emit({ id: 1, value: this.search });
  }

  searchText(value) {
    this.search = value;
    this.apiService.searchValue.emit({ id: 1, value: value, showHidden: this.showHidden });
  }

  viewHideClient(id) {
    this.showHidden = id;
    this.searchText(this.search);
  }
}
