import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService, GeolocationService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-sublocation',
  templateUrl: './location-sublocation.component.html',
})
export class LocationSublocationComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() locationList;
  @Input() clientId;
  @Input() locationId;
  @Input() locationInfo;
  isSubmitted = false;
  loading = false;
  locationForm: FormGroup;
  availableTime: any = ['Anytime', '30 minutes before', '15 minutes before', '5 minutes before'];

  public latitude: number;
  public longitude: number;
  public zoom: number;
  marklatitude: any = '';
  marklongitude: any = '';

  constructor(
    private apiService: ApiService,
    private geolocationService: GeolocationService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.createForm();
    this.setCurrentLocation();
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 15;
        },
        (err) => {
          this.zoom = 15;
          this.latitude = -41.2935403;
          this.longitude = 174.7760212;
        },
      );
    } else {
      this.zoom = 15;
      this.latitude = -41.2935403;
      this.longitude = 174.7760212;
    }
  }

  createForm() {
    this.locationForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: [''],
      is_clockin: [0],
      is_clock_at_location: [0],
      is_exclude_time_limit: [0],
      clock_available: [''],
      default_brief: [''],
      latitude: [''],
      longitude: [''],
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }

  get f() {
    return this.locationForm.controls;
  }

  createLocation() {
    this.isSubmitted = true;
    if (this.locationForm.invalid) {
      return;
    }

    const datavalue = this.locationForm.value;
    datavalue['is_clockin'] = datavalue['is_clockin'] === true ? 1 : 0;
    datavalue['is_clock_at_location'] = datavalue['is_clock_at_location'] === true ? 1 : 0;
    datavalue['is_exclude_time_limit'] = datavalue['is_exclude_time_limit'] === true ? 1 : 0;
    datavalue['longitude'] = this.marklongitude;
    datavalue['latitude'] = this.marklatitude;
    datavalue['parent_id'] = this.locationId;
    this.loading = true;

    this.apiService.post('client/' + this.clientId + '/location', datavalue).subscribe((location) => {
      this.loading = false;
      if (location['status'] === 200) {
        this.toastr.success(location['message']);
        this.viewLocation(location['location']['id']);
        this.clearForm();
      }
    });
  }

  isSpaceCheck(evt, value) {
    if (value.length === 0) {
      if (evt === 32) {
        return false;
      }
    }
    return true;
  }

  clearForm() {
    this.modalRef.hide();
    this.locationForm.reset({ clock_available: '' });
    this.isSubmitted = false;
    this.loading = false;
    this.apiService.closeClientModel.emit(14);
    //this.apiService.closeClientModel.emit(10);
    this.marklongitude = '';
    this.marklatitude = '';
  }

  async addMarker(lat: number, lng: number) {
    this.marklatitude = lat;
    this.marklongitude = lng;
    const address = await this.geolocationService.getMarkedAddress({ lat, lng });
    this.locationForm.patchValue({ address: address['address'] });
  }

  async checkLatLong(value) {
    if (!value) {
      this.marklatitude = '';
      this.marklongitude = '';
      this.latitude = null;
      this.longitude = null;
      this.setCurrentLocation();
    } else {
      const address = await this.geolocationService.getLocationAddress(value);
      if (address && address['lat'] && address['long']) {
        this.marklatitude = address['lat'];
        this.marklongitude = address['long'];
        this.latitude = address['lat'];
        this.longitude = address['long'];
      }
    }
  }

  viewLocation(id) {
    this.router.navigate(['client/sublocation-details/' + id], { queryParams: { clientId: this.clientId } });
  }
}
