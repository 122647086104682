export const environment = {
  production: true,
  url: 'https://backend.r-101.com/api/v2/',
  fileUrl: 'https://d2wdhrezl38jci.cloudfront.net/',
  UserPoolId: 'ap-southeast-2_n1z8mLYaC',
  ClientId: '1sp5cfi3tjidmqmpfedou15ojl',
  Username: 'stephen.m@optisolbusiness.com',
  Password: 'Optisol@2020',
  DefaultRegion: 'ap-southeast-2',
  IdentityPoolId: 'ap-southeast-2:5837df6b-87bd-483a-a10b-4e178bccdaab',
  BucketName: 'justroster-staging',
  socketUrl: 'https://backend.r-101.com',
};
