import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService, ExcelService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
})
export class ReportDetailComponent implements OnInit {
  reportDetails: any;
  dateValue: any = [];
  reportView: any = [];
  reportCheck: any;
  dateCheck = false;
  fileName = '';
  selectReport: any = {};
  public model: any = {
    beginDate: { year: 2018, month: 10, day: 9 },
    endDate: { year: 2018, month: 10, day: 19 },
  };
  public loading = false;
  start_date;
  end_date;
  @ViewChild('table') table: ElementRef;
  showReport = false;
  domainInfo: any = {};
  dateFormat;
  constructor(private apiService: ApiService, private route: ActivatedRoute, private excelService: ExcelService) {
    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
  }

  ngOnInit() {
    this.getReportId();

    this.domainInfo = JSON.parse(localStorage.getItem('domainInfo'));
    this.dateFormat =
      this.domainInfo && this.domainInfo.dateformat ? this.domainInfo && this.domainInfo.dateformat : 'dd/MM/yyyy';
  }
  getReportId() {
    this.loading = true;
    const reportId = this.route.snapshot.params.id;
    this.apiService.get(`report/view/${reportId}`).subscribe(
      (reportRes) => {
        this.reportDetails = reportRes;
        if (this.reportDetails['report']['time_frame'] === 'Daily') {
          this.dateCheck = true;
        }
        if (this.reportDetails['report']['source'] === 'Hours per Staff') {
          this.reportCheck = 1;
        }
        if (this.reportDetails['report']['source'] === 'Number of incident types by client & location') {
          this.reportCheck = 2;
        }
        if (this.reportDetails['report']['source'] === 'Hours per Staff (detailed)') {
          this.reportCheck = 3;
        }
        if (this.reportDetails['report']['source'] === 'Hours per Client & Location') {
          this.reportCheck = 4;
        }
        this.reportView = reportRes['report']['reportList'];
        this.dateValue = reportRes['report']['dateList'];
        if (this.dateValue && this.dateValue.length > 0) {
          this.selectReport = this.dateValue[0];
        } else {
          this.selectReport = {};
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  reportDetail(value) {
    this.loading = true;
    this.selectReport = value;
    const obj = {
      startDate: moment(value.start_date).format('YYYY-MM-DD'),
      source: this.reportDetails['report']['source'],
    };
    if (value.end_date) obj['endDate'] = moment(value.end_date).format('YYYY-MM-DD');

    this.apiService.post('report/download', obj).subscribe(
      (reportRes) => {
        this.reportView = reportRes['report'];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  getDownload() {
    if (this.reportView && this.reportView.length > 0) {
      this.showReport = true;
      setTimeout((obj) => {
        this.excelService.exportAsExcelFile(this.table.nativeElement, 'report');
        this.showReport = false;
      }, 1000);
    }
  }
}
