<div class="card mb-3 border-0">
  <div class="card-header bg-white px-4">
    <h6 class="text-dark font-weight-bold fs16 my-2">DETAILS</h6>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 p-1">
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs14 m-0">{{ incidentInfo?.incidenttype?.incident_name }}</h2>
          <span class="color-light-black op-32 fs12">Incident type</span>
        </div>
        <div class="bg-user mt-4">
          <h2 class="text-dark fs14 m-0 c-pointer" (click)="redirectUrl(incidentInfo.client_id)">
            {{ incidentInfo?.client?.name }}
          </h2>
          <span class="color-light-black op-32 fs12">Client</span>
        </div>
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs14 m-0">{{ incidentInfo?.created_at | dateTimezoneConvert }}</h2>
          <span class="color-light-black op-32 fs12">Date of the report</span>
        </div>
      </div>
      <div class="col-md-6 p-1">
        <div class="bg-icon-mob mt-4">
          <h2 class="text-dark fs14 m-0">{{ incidentInfo?.incident_date | date: dateFormat }}</h2>
          <span class="color-light-black op-32 fs12">Date of the incident</span>
        </div>
        <div class="bg-user mt-4">
          <h2 class="text-dark fs14 m-0">{{ incidentInfo?.user?.firstName }} {{ incidentInfo?.user?.surName }}</h2>
          <span class="color-light-black op-32 fs12">Reported by</span>
        </div>

        <div class="bg-icon-envelope mt-4">
          <h2 class="text-dark fs14 m-0">{{ incidentInfo?.location?.name }}</h2>
          <span class="color-light-black op-32 fs12">Location</span>
        </div>
      </div>
    </div>
  </div>
</div>
