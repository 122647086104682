<div class="row px-5 pt-3">
  <div class="col-12">
    <div class="row pb-3">
      <div class="col-12 pb-4">
        <label class="fs16 color-black">Upload Profile photo</label>
      </div>

      <div class="col-12 pb-4">
        <div class="card imgprofile">
          <img
            *ngIf="profileImage != '' && profileImage != null"
            width="136"
            height="136"
            src="{{ fileUrl }}{{ profileImage }}"
            class="card-img rounded-circle viewprofileimg"
            alt="Sheep 9"
          />
          <img
            *ngIf="profileImage == '' || profileImage == null"
            width="136"
            height="136"
            class="card-img rounded-circle viewprofileimg"
            src="assets/images/profile.png"
            alt="Sheep 9"
          />
          <div
            (click)="fileInput.click()"
            class="card-img-overlay border-0 text-white c-pointer d-flex justify-content-center rounded-circle align-items-end overlay-dark"
          >
            <em class="fa fa-camera camicon fs24 color-white" aria-hidden="true"></em>
            <input
              type="file"
              #fileInput
              id="fileup"
              ngFileSelect
              [options]="options"
              (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput"
              accept="image/*"
            />
          </div>
        </div>
        <div *ngIf="fileError">
          <p class="text-danger" *ngIf="fileSizeError">*Large file. File Allowed Maximum 5MB.</p>
          <p class="text-danger" *ngIf="fileFormatError">Valid extenstions {{ supportFormat }}</p>
        </div>
      </div>
    </div>
    <form [formGroup]="viewprofileform" (ngSubmit)="onSubmit()">
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Name</label>
            <input
              type="text"
              formControlName="Name"
              class="form-control customercreateform"
              placeholder="Enter Name"
              [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
            />
            <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
              <div *ngIf="f.Name.errors.required">Name is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Email</label>
            <input
              type="text"
              formControlName="Email"
              class="form-control customercreateform"
              placeholder="Enter Email"
              [ngClass]="{ 'is-invalid': submitted && f.Email.errors }"
            />
            <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
              <div *ngIf="f.Email.errors.required">Email is required</div>
              <div *ngIf="f.Email.errors.pattern">Email is not valid</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Current Password</label>
            <input
              type="password"
              class="form-control customercreateform"
              formControlName="currentpassword"
              placeholder="Current Password"
              [ngClass]="{ 'is-invalid': submitted && f.currentpassword.errors }"
              (input)="valuechange($event.target.value, 'Current')"
            />
            <div *ngIf="submitted && f.currentpassword.errors" class="invalid-feedback">
              <div *ngIf="f.currentpassword.errors.required">Current Password is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">New Password</label>
            <input
              type="password"
              class="form-control customercreateform"
              formControlName="newpassword"
              placeholder="New Password"
              pattern="{{ passwordRegEx }}"
              [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }"
              (input)="valuechange($event.target.value, 'New')"
              (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
            />
            <div *ngIf="f.newpassword.errors" class="text-danger mt-1">
              <p class="mb-0" *ngIf="f.newpassword.errors.pattern">
                Password requires at least 8 characters, one must be a number and one special character
              </p>
            </div>
            <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
              <div *ngIf="f.newpassword.errors.required">New Password is required</div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="fs16 color-black">Confirm Password</label>
            <input
              type="password"
              class="form-control customercreateform"
              formControlName="confirmpassword"
              placeholder="Confirm New Password"
              [ngClass]="{ 'is-invalid': submitted && viewprofileform.hasError('notSame') && f.newpassword.errors }"
              (keypress)="isSpaceCheck($event.keyCode, $event.target.value)"
            />
            <p
              class="text-danger"
              *ngIf="
                viewprofileform.hasError('notSame') &&
                viewprofileform.value &&
                viewprofileform.value.confirmpassword &&
                viewprofileform.value.confirmpassword.length > 0
              "
            >
              Password Mismatch
            </p>
            <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mt-4">
        <div class="col-12 mb-4 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary btn-cancel border-0 fs14 rounded w-140px mr-3"
            tooltip="Cancel"
            (click)="closeModel()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-secondary rounded btn-create border-0 w-140px fs14" tooltip="Submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
