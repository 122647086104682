<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<nav aria-label="breadcrumb">
  <ol class="breadcrumb roaster-breakcrumb">
    <li class="breadcrumb-item fs14 fw500 c-pointer" (click)="backUrl()"><a>Role Detail</a></li>
    <li class="breadcrumb-item active fs14" aria-current="page">
      <em class="fas fa-user mr-1"></em>Requirement Detail
    </li>
  </ol>
</nav>
<div class="roaster-body client-layout">
  <p class="color-red" *ngIf="locationInfo.is_deleted">
    This requirement is hidden and won't be shown as an option to select.
  </p>
  <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">{{ locationInfo?.name }}</h1>
  <p class="color-grey-text">
    Any specific information related to this requirement is managed below. You can also manage required certifications
    and blacklisting for this requirement here.
  </p>
  <div class="row">
    <div class="col-md-8 col-xs-12">
      <app-sublocation-about
        [locationInfo]="locationInfo"
        [clientId]="clientId"
        [locationId]="locationId"
        [parentLocationId]="parentLocationId"
      ></app-sublocation-about>
      <app-sublocation-incident
        [clientId]="clientId"
        [locationId]="locationId"
        [incidentsList]="incidentsList"
        [clientInfo]="clientInfo"
      ></app-sublocation-incident>
    </div>
    <div class="col-md-4 col-12">
      <app-sublocation-certification
        [certificateList]="certificateList"
        [clientId]="clientId"
        [locationCertificateList]="locationCertificateList"
        [locationId]="locationId"
      ></app-sublocation-certification>
      <app-sublocation-blacklisting
        [blackList]="blackList"
        [clientId]="clientId"
        [locationBlackList]="locationBlackList"
        [locationId]="locationId"
      ></app-sublocation-blacklisting>
    </div>
  </div>
</div>
