<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<div class="roaster-body report-container">
  <div class="container-fluid px-0">
    <tabset class="report-tabs" #staticTabs>
      <tab heading="Reports" (selectTab)="onSelect($event)">
        <div class="row mt-5">
          <div class="col-xl-2 col-md-4 col-sm-6 m-auto report-container-item">
            <div class="search-box-square w200">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text pr-0" id="basic-addon1">
                    <em class="fas fa-search color-light-grey fs12"></em>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control fs14"
                  (input)="searchReports($event.target.value)"
                  placeholder="Search here"
                  name="search"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-2 m-auto col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Hours</label>

                <ng-select
                  [items]="byStaffDropDownList"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="name"
                  [closeOnSelect]="true"
                  bindValue="id"
                  [(ngModel)]="selectedHours"
                  (change)="selectHourType($event)"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-xl-2 m-auto col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown1="bs-dropdown" [autoClose]="false">
              <div class="form-group">
                <label for="floatingValue" class="d-block">General Report</label>
                <button
                  id="floatingValue"
                  dropdownToggle
                  type="button"
                  class="btn w200 form-control dropdown-toggle fs12"
                  aria-controls="dropdown-animated"
                >
                  <img class="w-14px" src="assets/images/excel-icon.png" alt="excel" />
                  {{ downloadReportType
                  }}<span class="caret"
                    ><img class="float-right w-14px" alt="arrow" src="assets/images/fi-sr-angle-small-right.png"
                  /></span>
                </button>
                <ul
                  id="dropdown-animated"
                  *dropdownMenu
                  class="dropdown-menu fs12"
                  role="menu"
                  aria-labelledby="button-animated"
                >
                  <li (click)="downloadReportType = 'Excel'" role="menuitem">
                    <a class="dropdown-item"
                      ><label><img class="w-14px mr-2" src="assets/images/excel-icon.png" alt="excel" />Excel</label>
                      <input type="radio" name="hours" class="float-right" id="custom-control1" checked
                    /></a>
                  </li>
                  <li (click)="downloadReportType = 'PDF'" role="menuitem">
                    <a class="dropdown-item"
                      ><label class="float-left"
                        ><img class="w-14px mr-2" src="assets/images/pdf.png" alt="pdf" />Pdf </label
                      ><input type="radio" name="hours" class="float-right" id="custom-control2"
                    /></a>
                  </li>

                  <li (click)="dropdown1.toggle(true); downloadReportsByExcel(downloadReportType)" role="menuitem">
                    <button class="dropdown-item btn btn-default border-0 fs12 mt-3">Download Report</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-2 m-auto col-md-4 col-sm-6 report-container-item">
            <form [formGroup]="form">
              <div class="form-group">
                <label class="pb10">Date</label>
                <div class="input-group dropdown-toggle w200">
                  <input
                    class="form-control datePicker fs12"
                    #datepickerFull="bsDatepicker"
                    bsDatepicker
                    (ngModelChange)="resetLocations(); searchText = ''; filterReports()"
                    formControlName="dateFull"
                    [bsConfig]="datePickerConfig"
                  />
                  <div class="input-group-append">
                    <img class="w-14px h15" src="assets/images/calender.png" alt="calender" />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="byStaffMemberTable" class="col-xl-2 m-auto col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Staff Members</label>

                <ng-multiselect-dropdown
                  #singleSelectStaff
                  name="staff"
                  [placeholder]="'Staff Members'"
                  [data]="staffDropDownList"
                  [settings]="staffSettings"
                  (onSelect)="onStaffSelect($event, 'multi')"
                  (onDeSelect)="onStaffDeSelect($event, 'multi')"
                  (onSelectAll)="onStaffSelectAll($event, 'multi')"
                  (onDeSelectAll)="onStaffDeselectAll('multi')"
                  [(ngModel)]="selectedStaffsForFilter"
                  tabindex="14"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>

          <div *ngIf="byLocationTable" class="col-xl-2 m-auto col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Roles</label>
                <ng-multiselect-dropdown
                  #multiFilterSelect
                  name="client"
                  [placeholder]="'Role List'"
                  [data]="clientDropDownList"
                  [settings]="clientFilterSettings"
                  (onSelect)="onClientFilterSelect($event)"
                  (onDeSelect)="onClientFilterDeSelect($event)"
                  (onSelectAll)="onClientFilterSelectAll($event)"
                  (onDeSelectAll)="onClientFilterDeselectAll()"
                  [(ngModel)]="selectedClientsForFilter"
                  tabindex="14"
                ></ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="byLocationTable" class="table-responsive accordion-table mt-3">
          <table class="table bg-white shadow mb-0">
            <thead class="thead-light fs12">
              <tr>
                <th></th>
                <th></th>
                <ng-container *ngFor="let date of dates">
                  <th scope="col" colspan="3">{{ date }}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr class="text-danger" *ngIf="reportList.length === 0">
                No Data Found
              </tr>
              <tr *ngFor="let report of reportList; let in = index">
                <td colspan="23">
                  <accordion>
                    <accordion-group panelClass="customClass bg-white">
                      <span class="justify-content-between d-flex w-100" accordion-heading type="button">
                        <div class="font-weight-bold">{{ report.clientName }}</div>
                        <span class="float-right w-14px mx-2"
                          ><img alt="arrow" src="assets/images/fi-sr-angle-small-right.png"
                        /></span>
                      </span>

                      <div class="table-responsive report-table">
                        <table class="table bg-white shadow mb-0">
                          <thead>
                            <tr>
                              <th scope="col" colspan="2"></th>
                              <th scope="col" colspan="3">Mon</th>
                              <th scope="col" colspan="3">Tue</th>
                              <th scope="col" colspan="3">Wed</th>
                              <th scope="col" colspan="3">Thu</th>
                              <th scope="col" colspan="3">Fri</th>
                              <th scope="col" colspan="3">Sat</th>
                              <th scope="col" colspan="3">Sun</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let reportDetail of report.shiftListInfo; let ii = index">
                              <ng-container *ngIf="ii == 0">
                                <tr class="font-weight-bold">
                                  <td></td>
                                  <td>Hours</td>
                                  <ng-container *ngFor="let date of dates">
                                    <td>Start</td>
                                    <td>End</td>
                                    <td>Hr</td>
                                  </ng-container>
                                </tr>
                              </ng-container>

                              <tr>
                                <td>{{ reportDetail.staffName }}</td>
                                <td>{{ reportDetail.totalWorkDuration }}</td>
                                <ng-container *ngFor="let weekDay of reportDetail.weekDays | reportListPipe">
                                  <td>{{ weekDay ? weekDay.startTime : '' }}</td>
                                  <td>{{ weekDay ? weekDay.endTime : '' }}</td>
                                  <td>{{ weekDay ? weekDay.duration : '' }}</td>
                                </ng-container>
                              </tr>
                              <ng-container *ngIf="ii + 1 === report.shiftListInfo.length">
                                <tr class="font-weight-bold">
                                  <td>Total Hours</td>
                                  <td>{{ report.totalWorkHours }}</td>
                                </tr>
                              </ng-container>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </accordion-group>
                  </accordion>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="byStaffMemberTable" class="mt-3 container-fluid">
          <div class="table-responsive report-table">
            <div class="text-danger" *ngIf="reportList.length === 0">No Data Found</div>
            <table *ngFor="let report of reportList; let i = index" class="table bg-white shadow mb-0">
              <thead>
                <tr class="thead-light">
                  <th>Location Name</th>
                  <th></th>
                  <th scope="col" colspan="3">{{ dates[0] }}</th>
                  <th scope="col" colspan="3">{{ dates[1] }}</th>
                  <th scope="col" colspan="3">{{ dates[2] }}</th>
                  <th scope="col" colspan="3">{{ dates[3] }}</th>
                  <th scope="col" colspan="3">{{ dates[4] }}</th>
                  <th scope="col" colspan="3">{{ dates[5] }}</th>
                  <th scope="col" colspan="3">{{ dates[6] }}</th>
                </tr>
                <tr>
                  <th scope="col" colspan="2" class="border">{{ report.staffName }}</th>
                  <th scope="col" colspan="3">Mon</th>
                  <th scope="col" colspan="3">Tue</th>
                  <th scope="col" colspan="3">Wed</th>
                  <th scope="col" colspan="3">Thu</th>
                  <th scope="col" colspan="3">Fri</th>
                  <th scope="col" colspan="3">Sat</th>
                  <th scope="col" colspan="3">Sun</th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-bold">
                  <td>Role Name</td>
                  <td>Hours</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                </tr>
                <tr *ngFor="let reportDetail of report.shiftListInfo; let ii = index">
                  <td>{{ reportDetail.locationName }}</td>
                  <td>{{ reportDetail.totalWorkDuration }}</td>

                  <ng-container *ngFor="let weekDay of reportDetail.weekDays | reportListPipe">
                    <td>{{ weekDay ? weekDay.startTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.endTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.duration : '' }}</td>
                  </ng-container>
                </tr>
                <tr class="font-weight-bold">
                  <td>Total Hours</td>
                  <td>{{ report.totalWorkHours }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="byStaffGroupTable" class="mt-3 container-fluid">
          <div class="text-danger" *ngIf="reportList.length === 0">No Data Found</div>
          <div class="table-responsive report-table" *ngIf="reportList.length !== 0">
            <table class="table bg-white shadow mb-0">
              <thead>
                <tr class="thead-light">
                  <th>Staff Name</th>
                  <th>Hours</th>
                  <th scope="col" colspan="3">{{ dates[0] }}</th>
                  <th scope="col" colspan="3">{{ dates[1] }}</th>
                  <th scope="col" colspan="3">{{ dates[2] }}</th>
                  <th scope="col" colspan="3">{{ dates[3] }}</th>
                  <th scope="col" colspan="3">{{ dates[4] }}</th>
                  <th scope="col" colspan="3">{{ dates[5] }}</th>
                  <th scope="col" colspan="3">{{ dates[6] }}</th>
                </tr>
                <tr>
                  <th scope="col" colspan="2" class="border"></th>
                  <th scope="col" colspan="3">Mon</th>
                  <th scope="col" colspan="3">Tue</th>
                  <th scope="col" colspan="3">Wed</th>
                  <th scope="col" colspan="3">Thu</th>
                  <th scope="col" colspan="3">Fri</th>
                  <th scope="col" colspan="3">Sat</th>
                  <th scope="col" colspan="3">Sun</th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-bold">
                  <td></td>
                  <td></td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                </tr>
                <tr *ngFor="let reportDetail of reportList">
                  <td>{{ reportDetail.staffName }}</td>
                  <td>{{ reportDetail.totalWorkDuration }}</td>
                  <ng-container *ngFor="let weekDay of reportDetail.weekDays | reportListPipe">
                    <td>{{ weekDay ? weekDay.startTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.endTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.duration : '' }}</td>
                  </ng-container>
                </tr>
                <tr class="font-weight-bold">
                  <td>Total Hours</td>
                  <td>{{ staffTotalWorkHours }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="byClientTable" class="mt-3 container-fluid">
          <div class="table-responsive report-table">
            <table *ngFor="let report of reportList; let i = index" class="table bg-white shadow mb-0">
              <thead>
                <tr class="thead-light">
                  <th>Name</th>
                  <th></th>
                  <th scope="col" colspan="3">{{ dates[0] }}</th>
                  <th scope="col" colspan="3">{{ dates[1] }}</th>
                  <th scope="col" colspan="3">{{ dates[2] }}</th>
                  <th scope="col" colspan="3">{{ dates[3] }}</th>
                  <th scope="col" colspan="3">{{ dates[4] }}</th>
                  <th scope="col" colspan="3">{{ dates[5] }}</th>
                  <th scope="col" colspan="3">{{ dates[6] }}</th>
                </tr>
                <tr>
                  <th scope="col" colspan="2" class="border">{{ report.clientName }}</th>
                  <th scope="col" colspan="3">Mon</th>
                  <th scope="col" colspan="3">Tue</th>
                  <th scope="col" colspan="3">Wed</th>
                  <th scope="col" colspan="3">Thu</th>
                  <th scope="col" colspan="3">Fri</th>
                  <th scope="col" colspan="3">Sat</th>
                  <th scope="col" colspan="3">Sun</th>
                </tr>
              </thead>
              <tbody>
                <tr class="font-weight-bold">
                  <td>Role Name</td>
                  <td>Hours</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                  <td>Start</td>
                  <td>End</td>
                  <td>Hr</td>
                </tr>
                <tr *ngFor="let reportDetail of report.shiftListInfo; let ii = index">
                  <td>{{ reportDetail.locationName }}</td>
                  <td>{{ reportDetail.totalWorkDuration }}</td>
                  <ng-container *ngFor="let weekDay of reportDetail.weekDays | reportListPipe">
                    <td>{{ weekDay ? weekDay.startTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.endTime : '' }}</td>
                    <td>{{ weekDay ? weekDay.duration : '' }}</td>
                  </ng-container>
                </tr>
                <tr class="font-weight-bold">
                  <td>Total Hours</td>
                  <td>{{ report.totalWorkHours }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </tab>
      <tab heading="Incident Report" (selectTab)="onSelect($event)">
        <div class="row mt-5">
          <div class="col-xl-3 col-md-4 col-sm-6 report-container-item">
            <div class="search-box-square w200">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text pr-0" id="basic-addon1">
                    <em class="fas fa-search color-light-grey fs12"></em>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control fs14"
                  (input)="searchIncidentReports($event.target.value)"
                  placeholder="Search here"
                  name="search"
                  autocomplete="off"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Location</label>
                <ng-multiselect-dropdown
                  #multiSelect
                  name="client"
                  [placeholder]="'Location List'"
                  [data]="clientDropDownList"
                  [settings]="clientSettings"
                  (onSelect)="onClientSelect($event)"
                  (onDeSelect)="onClientDeSelect($event)"
                  (onSelectAll)="onClientSelectAll($event)"
                  (onDeSelectAll)="onClientDeselectAll()"
                  tabindex="14"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Staff Members</label>
                <ng-multiselect-dropdown
                  #multiSelectStaff
                  name="staff"
                  [placeholder]="'Staff Members'"
                  [data]="staffDropDownList"
                  [settings]="staffSettings"
                  (onSelect)="onStaffSelect($event)"
                  (onDeSelect)="onStaffDeSelect($event)"
                  (onSelectAll)="onStaffSelectAll($event)"
                  (onDeSelectAll)="onStaffDeselectAll()"
                  tabindex="14"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>

          <div class="col-xl-3 m-auto col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown="bs-dropdown">
              <div class="form-group">
                <label for="floatingInputValue" class="d-block">Incident type</label>
                <ng-multiselect-dropdown
                  #multiSelectIncident
                  name="incidentType"
                  [placeholder]="'Incident Type'"
                  [data]="incidentDropDownList"
                  [settings]="incidentSettings"
                  (onSelect)="onIncidentSelect($event)"
                  (onDeSelect)="onIncidentDeSelect($event)"
                  (onSelectAll)="onIncidentSelectAll($event)"
                  (onDeSelectAll)="onIncidentDeselectAll()"
                  tabindex="14"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="offset-xl-3 offset-md-4 offset-sm-6 col-xl-3 col-md-4 col-sm-6 report-container-item">
            <form [formGroup]="form">
              <div class="form-group">
                <label class="pb10">From Date</label>
                <div class="input-group dropdown-toggle w200">
                  <input
                    class="form-control datePicker fs12"
                    #fromDatePickerFull="bsDatepicker"
                    bsDatepicker
                    formControlName="fromDateFull"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showClearButton: true, showTodayButton: true }"
                    bsDatepicker
                    placeholder="Select"
                    [bsValue]="bsValue"
                    (bsValueChange)="fromDateChange()"
                  />
                  <div class="input-group-append">
                    <img class="w-14px h15" src="assets/images/calender.png" alt="calender" />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-xl-3 m-auto col-md-4 col-sm-6 report-container-item">
            <form [formGroup]="form">
              <div class="form-group">
                <label class="pb10">To Date</label>
                <div class="input-group dropdown-toggle w200">
                  <input
                    class="form-control datePicker fs12"
                    #toDatePickerFull="bsDatepicker"
                    bsDatepicker
                    formControlName="toDateFull"
                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showClearButton: true, showTodayButton: true }"
                    placeholder="Select"
                    [bsValue]="bsValue"
                    (bsValueChange)="toDateChange()"
                  />
                  <div class="input-group-append">
                    <img class="w-14px h15" src="assets/images/calender.png" alt="calender" />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-xl-3 col-md-4 col-sm-6 report-container-item">
            <div class="btn-group d-block" dropdown #dropdown1="bs-dropdown" [autoClose]="false">
              <div class="form-group">
                <label for="floatingValue" class="d-block">General Report</label>
                <button
                  id="floatingValue"
                  dropdownToggle
                  type="button"
                  class="btn w200 form-control dropdown-toggle fs12"
                  aria-controls="dropdown-animated"
                >
                  <img class="w-14px" src="assets/images/excel-icon.png" alt="excel" />
                  {{ downloadReportType
                  }}<span class="caret"
                    ><img class="float-right w-14px" alt="arrow" src="assets/images/fi-sr-angle-small-right.png"
                  /></span>
                </button>
                <ul
                  id="dropdown-animated"
                  *dropdownMenu
                  class="dropdown-menu fs12"
                  role="menu"
                  aria-labelledby="button-animated"
                >
                  <li (click)="downloadReportType = 'Excel'" role="menuitem">
                    <a class="dropdown-item"
                      ><label><img class="w-14px mr-2" src="assets/images/excel-icon.png" alt="excel" />Excel</label>
                      <input type="radio" name="hours" class="float-right" id="custom-control1" checked
                    /></a>
                  </li>
                  <li (click)="downloadReportType = 'PDF'" role="menuitem">
                    <a class="dropdown-item"
                      ><label class="float-left"
                        ><img class="w-14px mr-2" src="assets/images/pdf.png" alt="pdf" />Pdf </label
                      ><input type="radio" name="hours" class="float-right" id="custom-control2"
                    /></a>
                  </li>

                  <li
                    (click)="dropdown1.toggle(true); downloadIncidentReportsByExcel(downloadReportType)"
                    role="menuitem"
                  >
                    <button class="dropdown-item btn btn-default border-0 fs12 mt-3">Download Report</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 container-fluid">
          <div class="table-responsive report-table">
            <table class="table incident bg-white shadow mb-0">
              <thead>
                <tr class="thead-light">
                  <th>Incident Done</th>
                  <th>Staff Member</th>
                  <th>Date</th>
                  <th>Incident Type</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let i of incidentReportList
                      | paginate: { currentPage: page, itemsPerPage: pagesize, totalItems: totalCount }
                  "
                >
                  <tr>
                    <td>{{ i.user_name }}</td>
                    <td>{{ i.staff_name }}</td>
                    <td>{{ i.incident_date }}</td>
                    <td>{{ i.incident_name }}</td>
                    <td>{{ i.client_name }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="bg-white text-center py-4 roaster-pagination" *ngIf="incidentReportList?.length > 0">
              <pagination-controls
                (pageChange)="pageChange($event)"
                maxSize="7"
                previousLabel=""
                nextLabel=""
                autoHide="true"
              >
              </pagination-controls>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</div>
