import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-staff',
  templateUrl: './about-staff.component.html',
})
export class AboutStaffComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() staffInfo;
  public isShow = false;
  fileUrl = environment.fileUrl;
  userGroup = localStorage.getItem('userGroup');
  userLogId = localStorage.getItem('userLogId');

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 8) {
        this.closeModel();
      }
    });

    this.apiService.getProfileChanges('profile:').subscribe((data) => {
      if (data && data.staff_id && data.group) {
        this.userGroup = data.group;
        localStorage.setItem('userGroup', data.group);
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', keyboard: false, class: 'modal-lg' });
  }
  editClientModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }
  changeLoginInfo(template: TemplateRef<any>) {
    this.editClientModal(template);
  }
  openDeleteModal(template: TemplateRef<any>) {
    this.editClientModal(template);
  }

  staffAvailabilityModal(template: TemplateRef<any>) {
    this.editClientModal(template);
  }

  showTime() {
    this.isShow = !this.isShow;
  }

  removeStaff() {
    this.apiService.delete('staff/delete/' + this.staffInfo.id).subscribe(
      (staff: any) => {
        if (staff.status === 200) {
          this.toastr.success(staff.message);
          this.closeModel();
        } else {
          this.toastr.error(staff.message);
          this.closeModel();
        }
      },
      (err) => {
        this.closeModel();
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }

  closeModel() {
    this.modalRef.hide();
    this.apiService.closeClientModel.emit(5);
  }

  revertStaff() {
    this.apiService.get('staff/revert/' + this.staffInfo.id).subscribe((staff: any) => {
      if (staff.status === 200) {
        this.toastr.success(staff.message);
        this.closeModel();
      }
    });
  }
}
