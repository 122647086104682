export const dateFormats = {
  'dd/MM/yyyy': 'DD/MM/YYYY',
  'MM/dd/yyyy': 'MM/DD/YYYY',
  'yyyy/MM/dd': 'YYYY/MM/DD',
  'MMM dd,yyyy': 'MMMM DD, YYYY',
  'dd MMM, yyyy': 'DD MMMM, YYYY',
};

export const days = [
  { id: 1, value: 'Monday', key: 'Monday' },
  { id: 2, value: 'Tuesday', key: 'Tuesday' },
  { id: 3, value: 'Wednesday', key: 'Wednesday' },
  { id: 4, value: 'Thursday', key: 'Thursday' },
  { id: 5, value: 'Friday', key: 'Friday' },
  { id: 6, value: 'Saturday', key: 'Saturday' },
  { id: 7, value: 'Sunday', key: 'Sunday' },
];

export const validFormat = [
  'bmp',
  'gif',
  'jpg',
  'jpeg',
  'pcx',
  'tif',
  'png',
  'alpha',
  'als',
  'cel',
  'icon',
  'ico',
  'ps',
  'aif',
  'au',
  'mid',
  'midi',
  'mp3',
  'ra',
  'ram',
  'rm',
  'mp3',
  'wav',
  'm4a',
  'snd',
  'aifc',
  'aiff',
  'wma',
  'apl',
  'avr',
  'cda',
  'ogg',
  'mpeg',
  'mpg',
  'mp4',
  'm1v',
  'mp2',
  'mpa',
  'mpe',
  'ifo',
  'vob',
  'avi',
  'wmv',
  'asf',
  'm2v',
  'qt',
  'ogv',
  'webm',
  'arc',
  'rar',
  'tar',
  'gz',
  'tgz',
  'bz2',
  'dmg',
  'jar',
  'ace',
  'arj',
  'bz',
  'cab',
  'doc',
  'docx',
  'txt',
  'rtf',
  'xls',
  'xlsx',
  'pages',
  'ppt',
  'pptx',
  'pps',
  'csv',
  'html',
  'htm',
  'xhtml',
  'xml',
  'pdf',
];

export const mapKey = 'AIzaSyDq_nc8tLJjvTtCWkEwxWYUIwlIAMnMPIc';

export const passwordRegEx = '^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+?\\/])[A-Za-z\\d!@#$%^&*()_+?\\/]{7,25}$';
