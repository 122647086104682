<div class="roaster-body custommargin">
  <div class="container-fluid px-0">
    <div class="mt-5 c-pointer" (click)="backiconclick()">
      <img src="assets/images/backicon.svg" alt="backicon" />
      <span class="fs14 pl-2 color-dark-blue">Back</span>
    </div>
    <h1 class="color-black fs20 text-uppercase pl-2 mt-4 heading-border">My Profile</h1>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 my-2">
        <div class="card border-0 c-pointer">
          <div class="card-body px-2 roaster-shadow">
            <div class="row">
              <div class="col-12 text-right" (click)="viewClientModal(addStaff)">
                <h3 class="fs14 color-dark-blue">
                  Edit
                  <img src="assets/images/schedule-dropdown-icons/pencil.svg" alt="user icon" class="pl-1 pr-2" />
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <img
                  *ngIf="profileImage != '' && profileImage != null"
                  width="136"
                  height="136"
                  src="{{ fileUrl }}{{ profileImage }}"
                  class="rounded-circle"
                  alt="Sheep 9"
                />
                <img
                  *ngIf="profileImage == '' || profileImage == null"
                  width="136"
                  height="136"
                  class="rounded-circle"
                  src="assets/images/profile.png"
                  alt="Sheep 9"
                />
              </div>
              <div class="col-12 text-center mt-4">
                <h4 class="fs20 font-weight-bold color-light-black">{{ profileinformation.staff_firstname }}</h4>
              </div>
              <div class="col-12 text-center pl-5 mt-5">
                <div class="bg-icon-envelope">
                  <div class="text-left">
                    <h2 class="color-light-black fs14 mb-1 font-weight-normal">{{ profileinformation.email }}</h2>
                    <p class="color-light-black mb-0 fs12 op-32">Email</p>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center pl-5 mt-4">
                <div class="bg-icon-lock">
                  <div class="text-left">
                    <h2 class="color-light-black fs14 mb-1 font-weight-normal" (click)="viewClientModal(addStaff)">
                      Change Password
                    </h2>
                    <p class="color-light-black mb-0 fs12 op-32">8 Characters must</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #addStaff>
    <div class="modal-header">
      <h4 class="modal-title pull-left w-100 text-center fs18 font-weight-normal">Edit Profile</h4>
      <button
        type="button"
        class="close pull-right text-white font-weight-normal op1"
        aria-label="Close"
        (click)="closePopup()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-viewprofile [info]="profileinformation"></app-viewprofile>
    </div>
  </ng-template>
</div>
