import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from '../services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
})
export class ClientInfoComponent implements OnInit {
  modalRef1: BsModalRef;
  modalRef2: BsModalRef;
  search;
  showHidden = 0;
  page = 1;
  clientList: any = [];
  pagesize = 5;
  totalCount = 0;
  // @Input() search;
  sortFiled;
  sortType: any = '';
  domainid = '';
  selectedfilter = '';
  isSelectedfilter = false;
  up;
  is_deleted;
  constructor(
    private modalService: BsModalService,
    private apiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.apiService.closeClientModel.subscribe((data) => {
      if (data === 14) {
        this.closePopup2();
      }
      if (data === 15) {
        // this.search = '';
        this.closePopup1();
      }
    });
    this.getclientlist();
  }
  getclientnamelist() {
    const queryParams = { page: this.page, pagesize: this.pagesize };

    queryParams['sort'] = 'name' + ':' + 'asc';

    this.apiService.get('domain/domainList', queryParams).subscribe((client: any) => {
      if (client.status === 200) {
        this.successMessage(client);
      }
    });
  }

  successMessage(client) {
    // alert("success")
    this.clientList = client.organizationdetails;
    this.totalCount = client.pageDetails.total;
    for (let i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].is_deleted == 0) {
        this.clientList[i].status = true;
      } else {
        this.clientList[i].status = false;
      }
    }
    //  this.apiService.closeClientModel.emit(2);
    //  this.loading = false;
    //  this.router.navigate(['/client-details/'+client.client['id']]);
    //  this.toastr.success(client.message);
  }
  getclientlist() {
    const queryParams = { page: this.page, pagesize: this.pagesize };
    if (this.search && this.search.length > 0) {
      queryParams['search'] = this.search;
    }
    if (this.sortType != '' && this.sortFiled != '') {
      queryParams['sort'] = this.sortFiled + ':' + this.sortType;
    }
    if (this.is_deleted == 0 || this.is_deleted == 1) {
      queryParams['is_deleted'] = this.is_deleted;
    }
    this.apiService.get('domain/domainList', queryParams).subscribe((client: any) => {
      if (client.status === 200) {
        this.successMessage(client);
      }
    });
  }
  statuschange(client) {
    client.status = !client.status;
    if (client.status) {
      client.is_deleted = 0;
    } else {
      client.is_deleted = 1;
    }
    const object = {};
    object['name'] = client.name;
    object['clientname'] = client.clientname;
    object['companyWebsite'] = client.companyWebsite;
    object['mobileNumber'] = client.mobileNumber;
    object['email'] = client.email;
    object['domain'] = client.subdomain;
    object['timeFormat'] = client.timeformat;
    object['dateFormat'] = client.dateformat;
    object['timezone'] = client.timezone;
    object['countryCode'] = client.countryCode;
    object['is_deleted'] = client.is_deleted;

    this.apiService.put('domain/update-domain/' + client.id, object).subscribe(
      (client: any) => {
        if (client.status === 200) {
          // alert("success")
          this.toastr.success(client.message);
        }
      },
      (err) => {
        // this.loading = false;
        if (err.error.message) {
          this.toastr.error(err.error.message);
        }
      },
    );
  }
  pageChange(newPage: number) {
    this.page = newPage;
    this.getclientlist();
  }
  upside(format, field) {
    if (this.sortType == 'asc') {
      this.sortType = 'desc';
      this.sortFiled = field;
      this.getclientlist();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getclientlist();
    }
  }

  down(format, field) {
    if (this.sortType == 'desc') {
      this.sortType = 'asc';
      this.sortFiled = field;
      this.getclientlist();
    } else {
      this.sortType = format;
      this.sortFiled = field;
      this.getclientlist();
    }
  }
  searchText(value) {
    this.search = value;
    this.getclientlist();
    // this.apiService.searchValue.emit({ id: 1, value: value, showHidden: this.showHidden });
  }
  filterby(id) {
    this.isSelectedfilter = true;
    if (id == 0) {
      this.is_deleted = 0;

      this.selectedfilter = 'Activate';
    } else if (id == 1) {
      this.is_deleted = 1;
      this.selectedfilter = 'Deactivate';
    } else {
      this.is_deleted = 2;
    }
    this.page = 1;
    this.getclientlist();
  }
  removefilter() {
    this.is_deleted = 2;
    this.isSelectedfilter = false;
    this.page = 1;
    this.getclientlist();
  }
  addclientModal(template: TemplateRef<any>) {
    localStorage.removeItem('Editarray');
    this.router.navigate(['/add-client']);
    // this.modalRef1 = this.modalService.show(template, {backdrop : 'static', keyboard : false, class: 'modal-lg modal-dialog-centered roaster-modal' });
  }
  viewClientModal(template: TemplateRef<any>, client) {
    localStorage.removeItem('Editarray');
    this.domainid = client.id;
    this.modalRef2 = this.modalService.show(template, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-dialog-centered roaster-modal',
    });
  }
  editclient(client) {
    localStorage.setItem('Editarray', JSON.stringify(client));
    this.router.navigate(['/add-client']);
  }
  closePopup1() {
    this.page = 1;
    this.modalRef1.hide();
    this.getclientlist();
  }
  closePopup2() {
    this.page = 1;
    this.modalRef2.hide();
    this.getclientlist();
  }
}
