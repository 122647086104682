<div class="row px-5 pt-3">
  <div class="col-12">
    <label class="radiocontainer" (click)="fdate5()">
      <p class="color-black fs18 pl-3 mb-5">{{ ft5 }}</p>
      <input type="radio" [checked]="dateformat == 'dd MMM, yyyy'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="fdate1()">
      <p class="color-black fs18 pl-3 mb-5">{{ ft1 }}</p>
      <input type="radio" [checked]="dateformat == 'dd/MM/yyyy'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="fdate2()">
      <p class="color-black fs18 pl-3 mb-5">{{ ft2 }}</p>
      <input type="radio" [checked]="dateformat == 'MM/dd/yyyy'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="fdate3()">
      <p class="color-black fs18 pl-3 mb-5">{{ ft3 }}</p>
      <input type="radio" [checked]="dateformat == 'yyyy/MM/dd'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <label class="radiocontainer" (click)="fdate4()">
      <p class="color-black fs18 pl-3 mb-5">
        {{ ft4 }}
        <span class="color-light-grey pl-2">(Default)</span>
      </p>
      <input type="radio" [checked]="dateformat == 'MMM dd,yyyy'" name="radio" />
      <span class="customradiobtn"></span>
    </label>
    <div class="form-row mt-2">
      <div class="col-12 mb-4 d-flex justify-content-start">
        <button
          type="button"
          class="btn btn-primary btn-cancel border-0 py-2 fs14 w-140px mr-3"
          tooltip="Cancel"
          (click)="closeModel()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-secondary py-2 btn-create border-0 w-140px fs14"
          (click)="dateformatsubmit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
