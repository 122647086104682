import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateTimezoneConvert',
})
export class DateTimezoneConvertPipe implements PipeTransform {
  transform(date: any): any {
    return moment(date).format('DD/MM/YYYY HH:mm:ss') || date;
  }
}
