import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { ClientsComponent } from './clients/clients.component';
import { AuthGuardService } from './services';
import { StaffComponent } from './staff/staff.component';
import { StaffLinksComponent } from './staff-details/staff-details.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AboutComponent } from './client-details-component/about/about.component';
import { LocationComponent } from './client-details-component/location/location.component';
import { IncidentsComponent } from './client-details-component/incidents/incidents.component';
import { CertificatesComponent } from './client-details-component/certificates/certificates.component';
import { FilesComponent } from './client-details-component/files/files.component';
import { BlacklistingComponent } from './client-details-component/blacklisting/blacklisting.component';
import { AboutStaffComponent } from './staff-details-component/about-staff/about-staff.component';
import { BlacklistingStaffComponent } from './staff-details-component/blacklisting-staff/blacklisting-staff.component';
import { CertificatesStaffComponent } from './staff-details-component/certificates-staff/certificates-staff.component';
import { EquipmentsStaffComponent } from './staff-details-component/equipments-staff/equipments-staff.component';
import { FilesStaffComponent } from './staff-details-component/files-staff/files-staff.component';
import { IncidentsStaffComponent } from './staff-details-component/incidents-staff/incidents-staff.component';
import { TimeTrackingComponent } from './scheduler/time-tracking/time-tracking.component';
import { DeleteTrackingComponent } from './scheduler/delete-tracking/delete-tracking.component';
import { StaffOnShiftComponent } from './scheduler/staff-on-shift/staff-on-shift.component';
import { SettingsComponent } from './settings/settings.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { IncidentDetailComponent } from './client-details-component/incident-detail/incident-detail.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { CertificationsComponent } from './settings/certifications/certifications.component';
import { IncidentTypeComponent } from './settings/incident-type/incident-type.component';
import { EquipmentsComponent } from './settings/equipments/equipments.component';
import { IncidentStaffDetailComponent } from './staff-details-component/incident-staff-detail/incident-staff-detail.component';
import { LocationDetailComponent } from './client-details-component/location-detail/location-detail.component';
import { SublocationDetailComponent } from './client-details-component/location-detail/sublocation-detail/sublocation-detail.component';
import { SchedulerHistoryComponent } from './scheduler/scheduler-history/scheduler-history.component';
import { EventComponent } from './scheduler/event/event.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SupportComponent } from './support/support.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CustomerCreationComponent } from './customer-creation/customer-creation.component';
import { SuperadminprofileComponent } from './superadminprofile/superadminprofile.component';
import { AppLayoutsuperadminComponent } from './layout/app-layoutsuperadmin/app-layoutsuperadmin.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { AddclientComponent } from './addclient/addclient.component';
import { ReportComponent } from './report/report.component';

const routes: Routes = [
  { path: '', redirectTo: 'clients', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'support', component: SupportComponent },
  { path: 'customer-creation', component: CustomerCreationComponent },

  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'clients', component: ClientsComponent },
      { path: 'staff', component: StaffComponent },
      { path: 'staff-details/:id', component: StaffLinksComponent },
      { path: 'client-details/:id', component: ClientDetailsComponent },
      { path: 'about', component: AboutComponent },
      { path: 'location', component: LocationComponent },
      { path: 'incidents', component: IncidentsComponent },
      { path: 'certificates', component: CertificatesComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'files', component: FilesComponent },
      { path: 'blacklisting', component: BlacklistingComponent },
      { path: 'app-about-staff', component: AboutStaffComponent },
      { path: 'app-blacklisting-staff', component: BlacklistingStaffComponent },
      { path: 'app-certificates-staff', component: CertificatesStaffComponent },
      { path: 'app-equipments-staff', component: EquipmentsStaffComponent },
      { path: 'app-files-staff', component: FilesStaffComponent },
      { path: 'app-incidents-staff', component: IncidentsStaffComponent },
      { path: 'client/location-details/:id', component: LocationDetailComponent },
      { path: 'client/sublocation-details/:id', component: SublocationDetailComponent },
      { path: 'incident-staff-detail/:id', component: IncidentStaffDetailComponent },
      { path: 'scheduler', component: SchedulerComponent },
      { path: 'scheduler/time-tracking/:id/:index', component: TimeTrackingComponent },
      { path: 'scheduler/delete-tracking/:id/:index', component: DeleteTrackingComponent },
      { path: 'scheduler/staff-on-shift/:id/:index', component: StaffOnShiftComponent },
      { path: 'scheduler-history/:id/:index', component: SchedulerHistoryComponent },
      { path: 'scheduler-event/:EventName', component: EventComponent },
      { path: 'incident-client-detail/:id', component: IncidentDetailComponent },
      { path: 'reports', component: ReportsComponent },
      { path: 'report', component: ReportComponent },
      { path: 'report-detail/:id', component: ReportDetailComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'setting-certification', component: CertificationsComponent },
      { path: 'setting-incident', component: IncidentTypeComponent },
      { path: 'setting-equipment', component: EquipmentsComponent },
      { path: 'profile', component: ProfileComponent },
    ],
  },
  {
    path: '',
    component: AppLayoutsuperadminComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'client-information', component: ClientInfoComponent },
      { path: 'profile-superadmin', component: SuperadminprofileComponent },
      { path: 'add-client', component: AddclientComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
